<template>
  <div class="dl-view-wrapper" :style="isMobile ? 'padding:0':''" v-resize="onResize">
    <div class="loading-wrapper" v-if="fetchSingleLocationLoading">
      <v-progress-circular :size="40" color="#1A6D9E" indeterminate>
      </v-progress-circular>
    </div>

    <div class="dl-view-contents-wrapper"
      :style="isMobile ? 'height: 100vh;':''"
      v-if="!fetchSingleLocationLoading && typeof deliveryLocation !== 'undefined' && deliveryLocation !== null">

      <div class="dl-header-wrapper-content stickyHeaderOfDetailView" ref="stickyHeaderOfDetailView" >
        <div class="details-breadcrumbs">
          <router-link to="/company-profile" class="warehouse-name">
            Company Profile
          </router-link>

          <span class="right-chevron">
            <img src="../../../assets/images/right_chevron.svg" alt="" srcset="" width="7px">
          </span>

          <button :style="isMobile ? 'color:#69758C':''" to="/company-profile" class="warehouse-name" @click="setActiveTabOfCompanyProfile(3)">
            Delivery Location
          </button>
        </div>


        <div class="inbound-header-wrapper" :style="isMobile ? 'align-items: flex-start !important;':''">
          <div class="inbound-info">
            <div class="info-wrapper">
              <div class="order-status" :style="isMobile ? 'margin-bottom:8px !important':''">
                <h2 :style="isMobile ? 'font-size:20px':''">{{ deliveryLocation.data.location_name }}</h2>
              </div>

              <div>
                <span class="header-dl-title">Type </span>
                <span class="header-dl-data">
                  {{ deliveryLocation.data.type }}
                </span>

                <span :style="!isMobile ? 'margin-left: 12px;':''" class="header-dl-title">Facility Required Appointment </span>
                <span class="header-dl-data">
                  {{ deliveryLocation.data.facility_operation_schedule == 1 ? "Yes" : "No" }}
                </span>


              </div>
            </div>
          </div>

          <div class="info-buttons">
            <v-btn color="primary" dark class="ml-2 btn-blue" @click="editDeliveryLocation(deliveryLocation.data)"
              :style="!isMobile ?  'min-width: 95px;':'min-width: 30px;min-height:30px;width:30px;height:30px !important;'">
              <img src="../../../../src/assets/icons/edit-dl-icon.svg" alt="" :class="isMobile ? '' : 'mr-1'" />
              <span v-if="!isMobile">Edit Location</span>
            </v-btn>

            <v-menu bottom left offset-y content-class="outbound-lists-menu">
              <template v-slot:activator="{ on, attrs }">
                <v-btn :style="isMobile ? 'min-width: 30px !important;min-height:30px;width:30px;height:30px !important':''" class="btn-white more-menu" icon v-bind="attrs" v-on="on" width="40px">
                  <img src="@/assets/icons/dots.svg" alt="dots" width="16px" height="16px">
                </v-btn>
              </template>

              <v-list class="outbound-lists">
                <v-list-item @click="showDeleteLocationDialog">
                  <v-list-item-title style="color: #B11B1B;">
                    Delete
                  </v-list-item-title>
                </v-list-item>

              </v-list>
            </v-menu>
          </div>
        </div>
      </div>


      <v-row no-gutters :style="isMobile ? 'flex-direction:column;width:inherit':''">
        
        <v-col  xs="12" lg="8" :style="isMobile ? 'margin-bottom: inherit;':''" :class="!isMobile ? 'pr-1':'pb-0 pl-0 pt-0 pr-0 mx-0'">
          <div class="dl-body-wrapper">
            <div  :style="!isMobile ? 'height: 70vh !important;':'padding:12px !important'" class="dl-informations">
              <div > 
              <h3 :class="isMobile ? 'pb-1':''">Facility Operation Hours</h3>

              <v-row  v-if="!isMobile" class="mt-0">

                <v-col sm="6">
                  <div v-if="deliveryLocation.data.facility_operation_hour.mon_open != null &&
                    deliveryLocation.data.facility_operation_hour.mon_open != 'Invalid date'"
                    class="dl-info dflex warehouse">
                    <p class="dl-title">Monday</p>
                    <p class="dl-data">
                      {{ deliveryLocation.data.facility_operation_hour.mon_open != null &&
                        deliveryLocation.data.facility_operation_hour.mon_open != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.mon_open) : "NA" }}
                      - {{ deliveryLocation.data.facility_operation_hour.mon_close != null &&
                        deliveryLocation.data.facility_operation_hour.mon_close != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.mon_close) : "NA" }}

                    </p>
                  </div>



                  <div v-if="deliveryLocation.data.facility_operation_hour.wed_open != null &&
                    deliveryLocation.data.facility_operation_hour.wed_open != 'Invalid date'"
                    class="dl-info dflex warehouse">
                    <p class="dl-title">Wednesday</p>
                    <p class="dl-data">
                      {{ deliveryLocation.data.facility_operation_hour.wed_open != null &&
                        deliveryLocation.data.facility_operation_hour.wed_open != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.wed_open) : "NA" }}
                      - {{ deliveryLocation.data.facility_operation_hour.wed_close != null &&
                        deliveryLocation.data.facility_operation_hour.wed_close != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.wed_close) : "NA" }}
                    </p>
                  </div>

                  <div v-if="deliveryLocation.data.facility_operation_hour.fri_open != null &&
                    deliveryLocation.data.facility_operation_hour.fri_open != 'Invalid date'" class="dl-info dflex warehouse">
                    <p class="dl-title">Friday</p>
                    <p class="dl-data">
                      {{ deliveryLocation.data.facility_operation_hour.fri_open != null &&
                        deliveryLocation.data.facility_operation_hour.fri_open != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.fri_open) : "NA" }}
                      - {{ deliveryLocation.data.facility_operation_hour.fri_close != null &&
                        deliveryLocation.data.facility_operation_hour.fri_close != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.fri_close) : "NA" }}
                    </p>
                  </div>

                  <div class="dl-info dflex warehouse" v-if="deliveryLocation.data.facility_operation_hour.sun_open != null &&
                    deliveryLocation.data.facility_operation_hour.sun_open != 'Invalid date'">
                    <p class="dl-title">Sunday</p>
                    <p class="dl-data">
                      {{ deliveryLocation.data.facility_operation_hour.sun_open != null &&
                        deliveryLocation.data.facility_operation_hour.sun_open != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.sun_open) : "NA" }}
                      - {{ deliveryLocation.data.facility_operation_hour.sun_close != null &&
                        deliveryLocation.data.facility_operation_hour.sun_close != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.sun_close) : "NA" }}
                    </p>


                  </div>
                </v-col>

                <v-col sm="6">
                  <div class="dl-info warehouse-3pl-hidden dflex" v-if="deliveryLocation.data.facility_operation_hour.tue_open != null &&
                    deliveryLocation.data.facility_operation_hour.tue_open != 'Invalid date'">
                    <p class="dl-title">Tuesday</p>
                    <p class="dl-data">
                      {{ deliveryLocation.data.facility_operation_hour.tue_open != null &&
                        deliveryLocation.data.facility_operation_hour.tue_open != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.tue_open) : "NA" }}
                      - {{ deliveryLocation.data.facility_operation_hour.tue_close != null &&
                        deliveryLocation.data.facility_operation_hour.tue_close != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.tue_close) : "NA" }}
                    </p>
                  </div>

                  <div class="dl-info dflex"  v-if="deliveryLocation.data.facility_operation_hour.thu_open != null &&
                    deliveryLocation.data.facility_operation_hour.thu_open != 'Invalid date'">
                    <p class="dl-title">Thursday</p>
                    <p class="dl-data">
                      {{ deliveryLocation.data.facility_operation_hour.thu_open != null &&
                        deliveryLocation.data.facility_operation_hour.thu_open != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.thu_open) : "NA" }}
                      - {{ deliveryLocation.data.facility_operation_hour.thu_close != null &&
                        deliveryLocation.data.facility_operation_hour.thu_close != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.thu_close) : "NA" }}
                    </p>
                  </div>

                  <div class="dl-info dflex"  v-if="deliveryLocation.data.facility_operation_hour.sat_open != null &&
                    deliveryLocation.data.facility_operation_hour.sat_open != 'Invalid date'">
                    <p class="dl-title">Saturday</p>
                    <p class="dl-data">
                      {{ deliveryLocation.data.facility_operation_hour.sat_open != null &&
                        deliveryLocation.data.facility_operation_hour.sat_open != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.sat_open) : "NA" }}
                      - {{ deliveryLocation.data.facility_operation_hour.sat_close != null &&
                        deliveryLocation.data.facility_operation_hour.sat_close != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.sat_close) : "NA" }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row style="flex-direction: column;margin: 0" v-if="isMobile" class="mt-0 mb-2">
                <v-col sm="12" class="pb-0 pl-0">
                  <div v-if="deliveryLocation.data.facility_operation_hour.mon_open != null &&
                    deliveryLocation.data.facility_operation_hour.mon_open != 'Invalid date'"
                    class="dl-info dflex warehouse">
                    <p class="dl-title">Monday</p>
                    <p class="dl-data">
                      {{ deliveryLocation.data.facility_operation_hour.mon_open != null &&
                        deliveryLocation.data.facility_operation_hour.mon_open != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.mon_open) : "NA" }}
                      - {{ deliveryLocation.data.facility_operation_hour.mon_close != null &&
                        deliveryLocation.data.facility_operation_hour.mon_close != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.mon_close) : "NA" }}

                    </p>
                  </div>



                  <div v-if="deliveryLocation.data.facility_operation_hour.wed_open != null &&
                    deliveryLocation.data.facility_operation_hour.wed_open != 'Invalid date'"
                    class="dl-info dflex warehouse">
                    <p class="dl-title">Wednesday</p>
                    <p class="dl-data">
                      {{ deliveryLocation.data.facility_operation_hour.wed_open != null &&
                        deliveryLocation.data.facility_operation_hour.wed_open != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.wed_open) : "NA" }}
                      - {{ deliveryLocation.data.facility_operation_hour.wed_close != null &&
                        deliveryLocation.data.facility_operation_hour.wed_close != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.wed_close) : "NA" }}
                    </p>
                  </div>

                  <div v-if="deliveryLocation.data.facility_operation_hour.fri_open != null &&
                    deliveryLocation.data.facility_operation_hour.fri_open != 'Invalid date'" class="dl-info dflex warehouse">
                    <p class="dl-title">Friday</p>
                    <p class="dl-data">
                      {{ deliveryLocation.data.facility_operation_hour.fri_open != null &&
                        deliveryLocation.data.facility_operation_hour.fri_open != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.fri_open) : "NA" }}
                      - {{ deliveryLocation.data.facility_operation_hour.fri_close != null &&
                        deliveryLocation.data.facility_operation_hour.fri_close != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.fri_close) : "NA" }}
                    </p>
                  </div>

                  <div class="dl-info dflex warehouse" v-if="deliveryLocation.data.facility_operation_hour.sun_open != null &&
                    deliveryLocation.data.facility_operation_hour.sun_open != 'Invalid date'">
                    <p class="dl-title">Sunday</p>
                    <p class="dl-data">
                      {{ deliveryLocation.data.facility_operation_hour.sun_open != null &&
                        deliveryLocation.data.facility_operation_hour.sun_open != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.sun_open) : "NA" }}
                      - {{ deliveryLocation.data.facility_operation_hour.sun_close != null &&
                        deliveryLocation.data.facility_operation_hour.sun_close != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.sun_close) : "NA" }}
                    </p>


                  </div>
                </v-col>

                <v-col sm="12" class="pb-0 pt-0 pl-0">
                  <div class="dl-info warehouse-3pl-hidden dflex" v-if="deliveryLocation.data.facility_operation_hour.tue_open != null &&
                    deliveryLocation.data.facility_operation_hour.tue_open != 'Invalid date'">
                    <p class="dl-title">Tuesday</p>
                    <p class="dl-data">
                      {{ deliveryLocation.data.facility_operation_hour.tue_open != null &&
                        deliveryLocation.data.facility_operation_hour.tue_open != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.tue_open) : "NA" }}
                      - {{ deliveryLocation.data.facility_operation_hour.tue_close != null &&
                        deliveryLocation.data.facility_operation_hour.tue_close != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.tue_close) : "NA" }}
                    </p>
                  </div>

                  <div class="dl-info dflex"  v-if="deliveryLocation.data.facility_operation_hour.thu_open != null &&
                    deliveryLocation.data.facility_operation_hour.thu_open != 'Invalid date'">
                    <p class="dl-title">Thursday</p>
                    <p class="dl-data">
                      {{ deliveryLocation.data.facility_operation_hour.thu_open != null &&
                        deliveryLocation.data.facility_operation_hour.thu_open != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.thu_open) : "NA" }}
                      - {{ deliveryLocation.data.facility_operation_hour.thu_close != null &&
                        deliveryLocation.data.facility_operation_hour.thu_close != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.thu_close) : "NA" }}
                    </p>
                  </div>

                  <div class="dl-info dflex"  v-if="deliveryLocation.data.facility_operation_hour.sat_open != null &&
                    deliveryLocation.data.facility_operation_hour.sat_open != 'Invalid date'">
                    <p class="dl-title">Saturday</p>
                    <p class="dl-data">
                      {{ deliveryLocation.data.facility_operation_hour.sat_open != null &&
                        deliveryLocation.data.facility_operation_hour.sat_open != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.sat_open) : "NA" }}
                      - {{ deliveryLocation.data.facility_operation_hour.sat_close != null &&
                        deliveryLocation.data.facility_operation_hour.sat_close != "Invalid date" ?
                        convertUtcToLocalTime(deliveryLocation.data.facility_operation_hour.sat_close) : "NA" }}
                    </p>
                  </div>
                </v-col>
                </v-row>
              <v-divider v-if="!isMobile" class="mt-5"></v-divider>
            </div>
              <h3 :class="!isMobile ? 'mt-3':'mt-4 mb-2'">Contact</h3>
              <v-divider v-if="!isMobile" class="mt-3"></v-divider>

              <v-data-table :headers="contactHeaders" :items="deliveryLocation.data.contacts" sort-by="calories"
                class="methods-table elevation-1"
                :id="!isMobile ? 'my-newClassIs-not-mobile':''"
                :class="{ 
                  'no-data-table': deliveryLocation.data.contacts !== undefined && deliveryLocation.data.contacts.length === 0,
                  'my-newClassIs': isMobile,
                }"
                hide-default-footer>


                <template v-slot:item="{ item, index }">
                  <tr v-if="!isMobile" style=" vertical-align:middle !important;">
                    <td style="border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important ;" class="td-start">

                      <span class="td-name"> {{ item.contact_name }} <br></span>
                      <span class="td-role"> {{ item.role }}</span>

                      <div v-if="item.on_site == 1" class="onsite-chip ml-1">
                        Onsite
                      </div>
                      <div v-if="item.primary == 1" class="onsite-chip ml-1">
                        Primary
                      </div>
                    </td>
                    <td style="border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important ;" class="td-start">
                      {{ item.phone_number }}
                    </td>
                    <td style="border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important ;" class="td-start">
                      {{ item.email }}
                    </td>
                    <td style="border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important ;" class="td-end">
                      <div class="actions-wrapper">
                        <v-btn class="btn-white icons" @click="editDeliveryLocation(deliveryLocation.data)">
                          <img src="../../../../src/assets/icons/edit-delivery-location.svg" alt="" />
                        </v-btn>

                        <v-btn class="btn-white icons" @click="showDeleteContactDialog(index)">
                          <img src="../../../../src/assets/icons/delete-delivery-location.svg" alt="" />
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                  <tr v-else>
                    <td style="padding-left: 3px !important;padding-right: 3px !important;border-bottom: none !important;padding-bottom: 0 !important;">
                      <div style="box-shadow: 0 0 5px rgba(0,0,0,0.1);">

                      <div class="d-flex justify-between align-center py-3 pl-3 mb-2" style="background-color: #F9FAFB;">
                        <div class="td-name" style="font-weight: 600;"> {{ item.contact_name }}</div>
                        <v-spacer></v-spacer>
                        <div class="actions-wrapper pr-3">
                          <v-btn style="width: 24px;height: 24px !important;min-height: 24px !important;min-width: 24px !important;" class="btn-white icons" @click="editDeliveryLocation(deliveryLocation.data)">
                            <img src="../../../../src/assets/icons/edit-delivery-location.svg" alt="" />
                          </v-btn>

                          <v-btn style="width: 24px;height: 24px !important;min-height: 24px !important;min-width: 24px !important;" class="btn-white icons" @click="showDeleteContactDialog(index)">
                            <img src="../../../../src/assets/icons/delete-delivery-location.svg" alt="" />
                          </v-btn>
                        </div>
                      </div>
                      <div class="d-flex align-center mb-2 ml-3">
                        <div style="font-size: 14px;color: #69758C;font-weight: 500;line-height: 20px;width: 80px;">Role</div>
                        <div class="td-role mx-2"> 
                          {{ item.role }} 
                        </div>
                        <div v-if="item.on_site == 1" class="onsite-chip ml-1">
                          Onsite
                        </div>
                        <div v-if="item.primary == 1" class="onsite-chip ml-1">
                          Primary
                        </div>
                      </div>
                      <div class="d-flex align-center mb-2 ml-3">
                        <div style="font-size: 14px;color: #69758C;font-weight: 500;line-height: 20px;width: 80px;">Phone</div>
                        <div class="ml-1">
                          {{ item.phone_number }}
                        </div>
                      </div>
                      <div class="d-flex align-center ml-3 pb-2">
                        <div style="font-size: 14px;color: #69758C;font-weight: 500;line-height: 20px;width: 80px;">Email</div>
                        <div class="ml-1">
                          {{ item.email }}
                        </div>
                      </div>
                    </div>

                    </td>
                  </tr>
                </template>
              </v-data-table>


            </div>
          </div>
        </v-col>

        <v-col xs="12" lg="4" :class="!isMobile ? 'pl-1':'pt-0 mt-0 pr-0'">
          <div class="dl-body-wrapper" :style="!isMobile ? '':'margin-top:0'">
            <div :style="!isMobile ? 'height: 70vh !important;':'padding:0 !important'" class="dl-informations">

              <v-row :style="isMobile ? 'margin: 0 !important;':''" class="mt-0">
                <v-col cols="12">
                  <div class="dl-info">
                    <span class="rs-dl-title">Special Delivery Instruction </span><br>
                    <span class="rs-dl-data">
                      {{ deliveryLocation.data.special_delivery_instruction }}
                    </span>
                  </div>
                  <div class="dl-info mt-4">
                    <span class="rs-dl-title">Address</span><br>
                    <span class="rs-dl-data">
                      {{ deliveryLocation.data.address }}
                    </span>
                  </div>


                  <div class="rs-map-view mt-4">
                    <div v-if="deliveryLocation.data.lat == null || deliveryLocation.data.long == null"
                      class="rs-map-view-message">
                      <img src="@/assets/icons/no-loc-marker.svg" />
                      <h3>Location not on Map</h3>
                      <p class="mt-2">Sorry! We could not find address on the <br v-if="isMobile"> map.</p>
                    </div>
                    <MapboxMap v-if="deliveryLocation.data.lat != null && deliveryLocation.data.long != null"
                      :latitude="deliveryLocation.data.lat" :longitude="deliveryLocation.data.long" />

                  </div>

                </v-col>

              </v-row>

            </div>
          </div>
        </v-col>
        <v-overlay :value="overlay" :absolute="absolute">
          <div v-if="isMobile && showDeleteLocationConfirmDialog"
            style="position: fixed;bottom: 0;height: 246px;left: 0;width: 100%;background-color: white;">
            <div style="position: relative;height: 100%;" class="py-4 pr-4">
              <h3 class="pt-1 pl-3" style="color: #253041;font-size: 20px;">
                <img src="@/assets/icons/icon-delete-red.svg" alt="alert">
                <p class="mb-2 mt-4 pl-1" style="font-size: 20px;color: #253041;">Delete Delivery Location</p>
              </h3>
              <p class="mb-5 pl-4" style="color: #253041;font-size: 14px;">
                Are you sure you want to delete <span class="font-semi-bold">{{ deliveryLocation.data !== null ?
                  deliveryLocation.data.location_name : 'N/A' }}</span> from delivery locations?
              </p>
              <div class="d-flex pl-4" style="bottom: 0;">
                <v-btn @click="deletelocationFunc" :disabled="loadingDeleteLocation" class="btn-blue mr-2" text>
                  {{ loadingDeleteLocation ? 'Deleting..' : 'Delete' }}
                </v-btn>
                <v-btn class="btn-white" text @click="closeDeleteLocationDialog" :disabled="loadingDeleteLocation">
                  Cancel
                </v-btn>
              </div>
            </div>

          </div>
        </v-overlay>


        <!---------- Dialog Codes ------------->
        <EditDeliveryLocationDialog :dialogEditDeliveryLocation.sync="showEditDeliveryLocationDialog"
          @closelocation="closeLocationDialog" :EditLocationItem="this.deliveryLocation.data"
          :defaultCustomer="this.deliveryLocation.data.customer_id" :comingFrom="this.screenName"
          :editDeliveryLocationIndex="true" :isMobile="isMobile"  />


        <ConfirmDialog v-if="!isMobile" :dialogData.sync="showDeleteLocationConfirmDialog">
          <template v-slot:dialog_icon>
            <div class="header-wrapper-close">
              <img src="@/assets/icons/icon-delete.svg" alt="alert">
            </div>
          </template>

          <template v-slot:dialog_title>
            <h2>Delete Delivery Location</h2>
          </template>

          <template v-slot:dialog_content>
            <div>
              <p>
                Are you sure you want to delete <span class="font-semi-bold">{{ deliveryLocation.data !== null ?
                  deliveryLocation.data.location_name : 'N/A' }}</span> from delivery locations?
              </p>

            </div>
          </template>

          <template v-slot:dialog_actions>
            <v-btn @click="deletelocationFunc" :disabled="loadingDeleteLocation" class="btn-blue" text>
              {{ loadingDeleteLocation ? 'Deleting..' : 'Delete' }}
            </v-btn>
            <v-btn class="btn-white" text @click="closeDeleteLocationDialog" :disabled="loadingDeleteLocation">
              Cancel
            </v-btn>
          </template>
        </ConfirmDialog>

        <ConfirmDialog :dialogData.sync="showDeleteContactConfirmDialog">
          <template v-slot:dialog_icon>
            <div class="header-wrapper-close">
              <img src="@/assets/icons/icon-delete.svg" alt="alert">
            </div>
          </template>

          <template v-slot:dialog_title>
            <h2>Delete Contact</h2>
          </template>

          <template v-slot:dialog_content>
            <div>
              <p>
                Are you sure you want to delete <span class="font-semi-bold">{{ deleteContactSelected !== null ?
                  deleteContactSelected.contact_name : 'N/A' }}</span> from delivery locations <span
                  class="font-semi-bold">{{ deliveryLocation.data !== null ?
                    deliveryLocation.data.location_name : 'N/A' }}</span>?
              </p>

            </div>
          </template>

          <template v-slot:dialog_actions>
            <v-btn @click="deleteContactFunc" :disabled="loadingDeleteContact" class="btn-blue" text>
              {{ loadingDeleteContact ? 'Deleting..' : 'Delete' }}
            </v-btn>
            <v-btn class="btn-white" text @click="closeDeleteContactDialog" :disabled="loadingDeleteContact">
              Cancel
            </v-btn>
          </template>
        </ConfirmDialog>


      </v-row>



    </div>



  </div>
</template>

<script>
import { mapActions, mapGetters,mapMutations } from "vuex"
import globalMethods from "../../../utils/globalMethods";
import EditDeliveryLocationDialog from "../Dialog/EditDeliveryLocationDialog.vue";
import ConfirmDialog from "../../Dialog/GlobalDialog/ConfirmDialog.vue";
import moment from 'moment'
import MapboxMap from './MapboxMap.vue';

export default {
  name: 'DeliveryLocationView',
  props: [],
  components: {
    EditDeliveryLocationDialog,
    ConfirmDialog,
    MapboxMap
  },

  watch: {

  },
  data: () => ({
    isMobile:false,
    absolute: true,
		overlay: false,
    screenName: "detailScreen",
    deliveryLocation_id: 0,
    deliveryLocation: null,
    fetchSingleLocationLoading: false,
    showEditDeliveryLocationDialog: false,
    showDeleteLocationConfirmDialog: false,
    showDeleteContactConfirmDialog: false,
    loadingDeleteContact: false,
    deleteContactSelected: null,
    loadingDeleteLocation: false,
    contactHeaders: [
      {
        text: 'Name & Role',
        align: 'start',
        sortable: false,
      },
      {
        text: 'Phone Number',
        align: 'start',
        sortable: false,
      },
      {
        text: 'Email Address',
        align: 'start',
        sortable: false,
      },
      {
        text: '',
        align: 'start',
        sortable: false,
      },
    ],
    getContacts: [
      {
        name: "Usman",
        phone: "03131",
        email: "usman@shifl.com"
      },

    ]


  }),
  methods: {
    ...mapGetters({
      getSingleDeliveryLocation: 'settings/getSingleDeliveryLocation',
      getSingleDeliveryLocationLoading: 'settings/getSingleDeliveryLocationLoading'
    }),
    ...mapActions({
      fetchSingleDeliveryLocation: 'settings/fetchSingleDeliveryLocation',
      deleteLocationApi: 'settings/deleteLocationApi',
      deleteContactApi: 'settings/deleteContactApi',
    }),
    ...mapMutations({
      setActiveTabOfCompanyProfileMutation:'settings/setActiveTabOfCompanyProfileMutation'
    }),
    ...globalMethods,
    setActiveTabOfCompanyProfile(tab){
      this.setActiveTabOfCompanyProfileMutation(tab)
      this.$router.push({name:"Company Profile"})
      
    },
    getSingleDeliveryLocation(location_id) {
      return this.fetchSingleDeliveryLocation(location_id)
    },
    getDeliveryLocationLoading() {
      let loading = false
      loading = this.getSingleDeliveryLocationLoading
      return loading
    },
    editDeliveryLocation() {
      console.log("OPEN: ",this.deliveryLocation)
      this.showEditDeliveryLocationDialog = true
    },
    closeLocationDialog() {
      this.showEditDeliveryLocationDialog = false
      this.editDeliveryLocationIndex = false
    },
    closeDeleteLocationDialog() {
      this.showDeleteLocationConfirmDialog = false
      this.overlay = false
      this.absolute = false
    },
    showDeleteLocationDialog() {
      this.showDeleteLocationConfirmDialog = true
      this.overlay = true
    },
    closeDeleteContactDialog() {
      this.showDeleteContactConfirmDialog = false
    },
    showDeleteContactDialog(index) {
      this.deleteContactSelected = this.deliveryLocation.data.contacts[index]
      this.showDeleteContactConfirmDialog = true
    },
    async deletelocationFunc() {
      try {
        this.loadingDeleteLocation = true
        await this.deleteLocationApi(this.deliveryLocation.data.id)
        this.notificationMessage('Delivery location deleted successfully')
        this.loadingDeleteLocation = false
        this.closeDeleteLocationDialog()
        window.location.href =
          window.location.origin + `/settings?tab=users`;
      } catch (e) {
        this.loadingDeleteLocation = false
        this.notificationError(e)
      }
    },

    async deleteContactFunc() {
      try {
        this.loadingDeleteContact = true
        await this.deleteContactApi(this.deleteContactSelected.id)
        this.notificationMessage('Contact deleted successfully')
        this.loadingDeleteContact = false
        this.closeDeleteContactDialog()
        this.callGetDeliveryLocation(this.deliveryLocation.data.id)


      } catch (e) {
        this.loadingDeleteContact = false
        this.notificationError(e)
      }

    },
    async callGetDeliveryLocation(location_id) {
      this.fetchSingleLocationLoading = true
      this.deliveryLocation = await this.getSingleDeliveryLocation(location_id)
      this.fetchSingleLocationLoading = false
    },
    convertUtcToLocalTime(time) {
      const utcMoment = moment.utc(time, 'HH:mm A');
      // Check if the parsed time is valid
      if (!utcMoment.isValid()) {
        this.localTime = 'Invalid time format';
        return;
      }
      return utcMoment.local().format('hh:mm A');
    },
    onResize() {
      if (window.innerWidth < 769) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    handleScroll() {
      const el = this.$refs.stickyHeaderOfDetailView;
      const isVisible = this.isElementInViewport(el);

      if (isVisible) {
        el.classList.add('is-pinned');
      } else {
        el.classList.remove('is-pinned');
      }

    },
    isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top == 25
      );
    }
  },
  async mounted() {
    //set current page
    this.$store.dispatch("page/setPage", "settings/deliverylocation");
    let delivery_location_id = new URL(location.href).searchParams.get('location_id')
    this.callGetDeliveryLocation(delivery_location_id)
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  updated() { }
}
</script>

<style lang="scss">@import '@/assets/scss/buttons.scss';
@import '@/assets/scss/pages_scss/settings/deliveryLocationView.scss';
@import "@/assets/scss/tables.scss";
.my-newClassIs.v-data-table .v-data-table__wrapper table thead tr th {
    color: #4B586C !important;
    text-transform: none;
    font-size: 14px;
    display: none;
    box-shadow: none;
}
#my-newClassIs-not-mobile.v-data-table .v-data-table__wrapper table thead tr th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important ;
}

.stickyHeaderOfDetailView {
  position: sticky;
  top: 25px;
  z-index:1;
  padding:16px 12px;
  background-color: #F1F6FA !important;
}
.stickyHeaderOfDetailView.is-pinned {
  background-color: white !important;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}
</style>