<!-- @format -->

<template>
	<div class="shipment-info-v2 shipment-top-info">
		<ShipmentGeneralInformationV2
			:getDetails="getDetails"
			:userDataDetails="userDataDetails"
			:isMobile="isMobile"
		/>

		<ShipmentShipper :getDetails="getDetails" :isMobile="isMobile" />

		<ShipmentConsignee :getDetails="getDetails" :isMobile="isMobile" />

		<ShipmentContainer
			:getDetails="getDetails"
			:isMobile="isMobile"
			v-if="getDetails.containers.length > 0"
		/>

		<ShipmentOtherInformation
			:getDetails="getDetails"
			v-if="
				getDetails.additional_instructions_and_notes ||
					getDetails.additional_services.length > 0
			"
		/>

		<ShipmentSchedulingOptionsV2
			:getDetails="getDetails"
			v-if="
				getDetails.scheduling_options.length > 0 &&
					getDetails.customer_id === userDataDetails.default_customer_id
			"
		/>
	</div>
</template>

<script>
import ShipmentGeneralInformationV2 from "@/components/ShipmentComponents/ShipmentInfo/ShipmentGeneralInformationV2";
import ShipmentShipper from "@/components/ShipmentComponents/ShipmentInfo/ShipmentShipper";
import ShipmentConsignee from "@/components/ShipmentComponents/ShipmentInfo/ShipmentConsignee";
import ShipmentContainer from "@/components/ShipmentComponents/ShipmentInfo/ShipmentContainer";
import ShipmentOtherInformation from "@/components/ShipmentComponents/ShipmentInfo/ShipmentOtherInformation";
import ShipmentSchedulingOptionsV2 from "@/components/ShipmentComponents/ShipmentInfo/ShipmentSchedulingOptionsV2";

export default {
	props: ["getDetails", "isMobile", "userDataDetails"],
	components: {
		ShipmentGeneralInformationV2,
		ShipmentShipper,
		ShipmentConsignee,
		ShipmentContainer,
		ShipmentOtherInformation,
		ShipmentSchedulingOptionsV2,
	},
	data: () => ({
		shipment_eta: null,
		shipment_etd: null,
		incoterms: [],
		mbl_num: null,
	}),
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import "../assets/scss/pages_scss/shipment/shipmentDetails/ShipmentInfoV2.scss";
</style>
