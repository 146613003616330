/** @format */

const shipmentBookingItem = {
	userRole: null,
	consignees: [],
	shippers: [],
	type: null,
	request_consolidations: false,
	mode: null,
	location_from: 0,
	location_to: 0,
	same_as_port_of_discharge: false,
	give_me_best_location: false,
	port_of_discharge: 0,
	warehouse_delivery_location: false,
	warehouse_pick_up_location: null,
	contact_person_name: null,
	contact_person_mobile_num: null,
	contact_person_email: null,
	earliest_shipment_date: null,
	latest_arrival_date: null,
	preferred_carrier: [],
	customer_reference_number: null,
	cargoDetailsData: [],
	orderItems: [],
	containerItems: [],
	additional_instructions_and_notes: "",
	additional_services: [],
	import_name_id: 0,
	selectedConsigneeIds: [],
	selectedShipperIds: [],
	forwarders: [],
	is_review: false,
	manualWarehouse: false,
	manualContact: false,
	requestBookingToken: null,
};

export default shipmentBookingItem;
