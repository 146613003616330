<template>
    <svg :style="`margin-top: ${marginTop}; margin-left: ${marginLeft}`" :width=width :height=height :viewBox="`0 0 ${(iconName=='archive') ? 14 : width} ${(iconName=='archive') ? 16 : height}`" fill="none" xmlns="http://www.w3.org/2000/svg">

        <path v-if="iconName=='archive'" d="M13.85 0.800781C14.5956 0.800781 15.2 1.4052 15.2 2.15078V3.95078C15.2 4.53834 14.8246 5.03824 14.3006 5.22374L14.3 12.0508C14.3 13.7905 12.8897 15.2008 11.15 15.2008H4.84999C3.11029 15.2008 1.69999 13.7905 1.69999 12.0508L1.70025 5.22406C1.17578 5.0388 0.799988 4.53868 0.799988 3.95078V2.15078C0.799988 1.4052 1.4044 0.800781 2.14999 0.800781H13.85ZM13.4 5.30078H2.59999V12.0508C2.59999 13.2934 3.60735 14.3008 4.84999 14.3008H11.15C12.3926 14.3008 13.4 13.2934 13.4 12.0508V5.30078ZM6.64999 7.10078H9.34999C9.59852 7.10078 9.79999 7.30225 9.79999 7.55078C9.79999 7.7717 9.6408 7.95543 9.43087 7.99353L9.34999 8.00078H6.64999C6.40146 8.00078 6.19999 7.79931 6.19999 7.55078C6.19999 7.32987 6.35918 7.14613 6.5691 7.10803L6.64999 7.10078H9.34999H6.64999ZM13.85 1.70078H2.14999C1.90146 1.70078 1.69999 1.90225 1.69999 2.15078V3.95078C1.69999 4.19931 1.90146 4.40078 2.14999 4.40078H13.85C14.0985 4.40078 14.3 4.19931 14.3 3.95078V2.15078C14.3 1.90225 14.0985 1.70078 13.85 1.70078Z" :fill="color"
        />

        <path v-if="iconName=='air'" d="M12.821 5.78385L10.026 6.06485L7.01443 1.23856C6.92763 1.10522 6.81042 0.99557 6.67294 0.919118C6.53546 0.842665 6.38187 0.801712 6.22547 0.799805H5.26331C5.11406 0.803972 4.9678 0.843679 4.8361 0.915784C4.70441 0.987889 4.5909 1.09041 4.50456 1.21524C4.41821 1.34007 4.3614 1.48378 4.33863 1.635C4.31585 1.78621 4.32773 1.94079 4.37333 2.08649L5.90796 6.48389L3.17545 6.75995C1.44839 4.98029 0.654611 5.63103 0.822988 6.21767C1.47725 8.45581 1.47725 7.54873 0.822988 9.78686C0.6498 10.3834 1.44839 11.0193 3.17064 9.25444L5.90315 9.53051L4.37333 13.9181C4.32873 14.0631 4.31746 14.2167 4.34041 14.3669C4.36336 14.5171 4.4199 14.6599 4.50561 14.784C4.59132 14.9081 4.70388 15.0102 4.83449 15.0823C4.9651 15.1545 5.11022 15.1947 5.2585 15.1998H6.22066C6.37706 15.1979 6.53065 15.1569 6.66813 15.0805C6.80561 15.004 6.92282 14.8944 7.00962 14.7611L10.026 9.9742L12.821 10.2207C13.1225 10.2498 13.4265 10.2142 13.7137 10.1159C14.0009 10.0177 14.265 9.8591 14.4889 9.65027C14.7128 9.44143 14.8917 9.18697 15.0142 8.90319C15.1366 8.6194 15.1998 8.31255 15.1998 8.00227C15.1998 7.69199 15.1366 7.38514 15.0142 7.10135C14.8917 6.81757 14.7128 6.56311 14.4889 6.35427C14.265 6.14544 14.0009 5.98683 13.7137 5.8886C13.4265 5.79037 13.1225 5.75469 12.821 5.78385Z" fill="#819FB2"/>

        <path v-if="iconName=='rail'" fill-rule="evenodd" clip-rule="evenodd" d="M3.2 0.599609H0V9.19961V9.39961V10.9996C0.662742 10.9996 1.2 10.4624 1.2 9.79961V9.39961H3.2V0.599609ZM0 12.1996H15.4C15.7314 12.1996 16 12.4682 16 12.7996C16 13.131 15.7314 13.3996 15.4 13.3996H0V12.1996ZM4.8 0.599609H10C11.1046 0.599609 12 1.49504 12 2.59961V2.99961L15.4472 4.72322C15.786 4.89261 16 5.23887 16 5.61764V8.39961C16 8.95189 15.5523 9.39961 15 9.39961H13.9317C13.9759 9.52472 14 9.65936 14 9.79961C14 10.4624 13.4627 10.9996 12.8 10.9996C12.1373 10.9996 11.6 10.4624 11.6 9.79961C11.6 9.65936 11.6241 9.52472 11.6683 9.39961H11.1317C11.1759 9.52472 11.2 9.65936 11.2 9.79961C11.2 10.4624 10.6627 10.9996 10 10.9996C9.33726 10.9996 8.8 10.4624 8.8 9.79961C8.8 9.65936 8.82406 9.52472 8.86828 9.39961H8.33172C8.37594 9.52472 8.4 9.65936 8.4 9.79961C8.4 10.4624 7.86274 10.9996 7.2 10.9996C6.53726 10.9996 6 10.4624 6 9.79961C6 9.65936 6.02406 9.52472 6.06828 9.39961H4.8V7.79961H13.8C14.1314 7.79961 14.4 7.53098 14.4 7.19961C14.4 6.86824 14.1314 6.59961 13.8 6.59961H4.8V0.599609ZM8.8 4.59961L8 2.19961H6.4L7.2 4.59961H8.8ZM9.2 2.19961H10L10.8 4.59961H10L9.2 2.19961Z" fill="#819FB2"/>

        <path v-if="iconName=='truck'" fill-rule="evenodd" clip-rule="evenodd" d="M3.2 0.599609H0V9.19961V9.39961V10.9996C0.662742 10.9996 1.2 10.4624 1.2 9.79961V9.39961H3.2V0.599609ZM0 12.1996H15.4C15.7314 12.1996 16 12.4682 16 12.7996C16 13.131 15.7314 13.3996 15.4 13.3996H0V12.1996ZM4.8 0.599609H10C11.1046 0.599609 12 1.49504 12 2.59961V2.99961L15.4472 4.72322C15.786 4.89261 16 5.23887 16 5.61764V8.39961C16 8.95189 15.5523 9.39961 15 9.39961H13.9317C13.9759 9.52472 14 9.65936 14 9.79961C14 10.4624 13.4627 10.9996 12.8 10.9996C12.1373 10.9996 11.6 10.4624 11.6 9.79961C11.6 9.65936 11.6241 9.52472 11.6683 9.39961H11.1317C11.1759 9.52472 11.2 9.65936 11.2 9.79961C11.2 10.4624 10.6627 10.9996 10 10.9996C9.33726 10.9996 8.8 10.4624 8.8 9.79961C8.8 9.65936 8.82406 9.52472 8.86828 9.39961H8.33172C8.37594 9.52472 8.4 9.65936 8.4 9.79961C8.4 10.4624 7.86274 10.9996 7.2 10.9996C6.53726 10.9996 6 10.4624 6 9.79961C6 9.65936 6.02406 9.52472 6.06828 9.39961H4.8V7.79961H13.8C14.1314 7.79961 14.4 7.53098 14.4 7.19961C14.4 6.86824 14.1314 6.59961 13.8 6.59961H4.8V0.599609ZM8.8 4.59961L8 2.19961H6.4L7.2 4.59961H8.8ZM9.2 2.19961H10L10.8 4.59961H10L9.2 2.19961Z" :fill="color" />

        <path v-if="iconName=='warning'" d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05888 0 0 8.05887 0 18C0 27.9411 8.05888 36 18 36ZM18 33.3004C9.54981 33.3004 2.69958 26.4502 2.69958 18C2.69958 9.54981 9.54981 2.69958 18 2.69958C26.4502 2.69958 33.3004 9.54981 33.3004 18C33.3004 26.4502 26.4502 33.3004 18 33.3004ZM17.9934 20.7013C18.6768 20.7018 19.2419 20.1943 19.3317 19.5356L19.3441 19.3524L19.3506 9.45108C19.3511 8.70561 18.7472 8.10089 18.0017 8.1004C17.3183 8.09996 16.7533 8.60739 16.6635 9.26616L16.651 9.44931L16.6445 19.3506C16.6441 20.0961 17.248 20.7008 17.9934 20.7013ZM18.0008 26.9963C18.9934 26.9963 19.7981 26.1916 19.7981 25.199C19.7981 24.2064 18.9934 23.4017 18.0008 23.4017C17.0082 23.4017 16.2035 24.2064 16.2035 25.199C16.2035 26.1916 17.0082 26.9963 18.0008 26.9963Z" :fill="color"/>

        <path v-if="iconName=='arrival-icon'" d="M5.75744 8.90078C5.93156 8.90078 6.09155 8.99213 6.1737 9.13847C6.25586 9.28481 6.24686 9.46229 6.15028 9.60044L2.8626 14.3008H5.75744C6.01819 14.3008 6.22957 14.5023 6.22957 14.7508C6.22957 14.9993 6.01819 15.2008 5.75744 15.2008H1.98042C1.8063 15.2008 1.64632 15.1094 1.56416 14.9631C1.482 14.8168 1.491 14.6393 1.58759 14.5012L4.87526 9.80078H1.98042C1.71967 9.80078 1.50829 9.59927 1.50829 9.35078C1.50829 9.10229 1.71967 8.90078 1.98042 8.90078H5.75744ZM10.9508 0.800781C11.2115 0.800781 11.423 1.00226 11.423 1.25078V13.6644L14.394 10.8325C14.5784 10.6569 14.8773 10.6569 15.0617 10.8325C15.2461 11.0083 15.2461 11.2933 15.0617 11.469L11.2847 15.069C11.1003 15.2447 10.8014 15.2447 10.617 15.069L6.83998 11.469C6.6556 11.2933 6.6556 11.0083 6.83998 10.8325C7.02436 10.6569 7.32329 10.6569 7.50766 10.8325L10.4787 13.6644V1.25078C10.4787 1.00226 10.6901 0.800781 10.9508 0.800781ZM3.86894 0.800781C4.06306 0.800781 4.2374 0.914028 4.30838 1.08623L6.90508 7.38623C7.00042 7.61755 6.88097 7.87874 6.63828 7.96962C6.39559 8.06045 6.12155 7.94665 6.02621 7.71533L5.40196 6.20078H2.33593L1.71168 7.71533C1.61633 7.94665 1.3423 8.06045 1.09961 7.96962C0.856918 7.87874 0.737461 7.61755 0.832811 7.38623L3.42951 1.08623C3.50049 0.914028 3.67482 0.800781 3.86894 0.800781ZM3.86894 2.48146L2.70689 5.30078H5.031L3.86894 2.48146Z" :fill="color"/>

        <path v-if="iconName=='three-dots'" d="M6 0.875C6.6177 0.875 7.11845 1.37868 7.11845 2C7.11845 2.62132 6.6177 3.125 6 3.125C5.38229 3.125 4.88155 2.62132 4.88155 2C4.88155 1.37868 5.38229 0.875 6 0.875ZM10.1315 0.875C10.7492 0.875 11.25 1.37868 11.25 2C11.25 2.62132 10.7492 3.125 10.1315 3.125C9.51384 3.125 9.01309 2.62132 9.01309 2C9.01309 1.37868 9.51384 0.875 10.1315 0.875ZM1.86845 0.875C2.48616 0.875 2.9869 1.37868 2.9869 2C2.9869 2.62132 2.48616 3.125 1.86845 3.125C1.25075 3.125 0.75 2.62132 0.75 2C0.75 1.37868 1.25075 0.875 1.86845 0.875Z" :fill="color"/>

        <path v-if="iconName=='notification'" d="M6.9967 0.799805C9.88303 0.799805 12.2397 3.15836 12.3389 6.1515L12.3419 6.33677V9.36141L13.3254 11.6914C13.3746 11.808 13.4 11.9338 13.4 12.0611C13.4 12.5708 13.0011 12.9839 12.5091 12.9839L9.13477 12.9835C9.13477 14.2082 8.17752 15.1998 6.9967 15.1998C5.85805 15.1998 4.9273 14.2778 4.86227 13.1152L4.85831 12.9833L1.49109 12.9839C1.36897 12.9839 1.24817 12.9579 1.13617 12.9075C0.684889 12.7045 0.477961 12.1609 0.673978 11.6934L1.65154 9.36211V6.33669C1.65196 3.26937 4.04053 0.799805 6.9967 0.799805ZM8.06541 12.9833L5.92767 12.9835C5.92767 13.5966 6.40629 14.0924 6.9967 14.0924C7.55238 14.0924 8.00904 13.6532 8.06084 13.0917L8.06541 12.9833ZM6.9967 1.9072C4.63046 1.9072 2.72091 3.8815 2.72057 6.33677V9.59223L1.76273 11.8765H12.2368L11.2728 9.59293L11.2729 6.34626L11.2702 6.18C11.1911 3.79285 9.30694 1.9072 6.9967 1.9072ZM4.50229 6.33956H5.92767C6.31243 6.33956 6.56262 6.74322 6.42461 7.09783L6.39176 7.16796L5.42336 8.92347H5.92767C6.22287 8.92347 6.46218 9.17137 6.46218 9.47717C6.46218 9.75749 6.2611 9.98915 6.0002 10.0258L5.92767 10.0309H4.50229C4.11753 10.0309 3.86734 9.6272 4.00535 9.27259L4.0382 9.20246L5.0066 7.44695H4.50229C4.20709 7.44695 3.96778 7.19905 3.96778 6.89325C3.96778 6.61294 4.16886 6.38128 4.42976 6.34461L4.50229 6.33956ZM7.35305 4.12477H9.13477C9.51686 4.12477 9.76716 4.52339 9.63387 4.87731L9.60202 4.94737L8.26146 7.44695H9.13477C9.42997 7.44695 9.66928 7.69485 9.66928 8.00065C9.66928 8.28096 9.4682 8.51262 9.2073 8.54929L9.13477 8.55434H7.35305C6.97096 8.55434 6.72065 8.15572 6.85395 7.8018L6.88579 7.73175L8.22635 5.23216H7.35305C7.05784 5.23216 6.81853 4.98427 6.81853 4.67847C6.81853 4.39815 7.01962 4.16649 7.28052 4.12983L7.35305 4.12477Z" :fill="color"/>

        <path v-if="iconName=='ship-second'" fill-rule="evenodd" clip-rule="evenodd" d="M6.94529 0H2.71063C2.43449 0 2.21063 0.223858 2.21063 0.5V1.2647C2.21063 1.54084 2.43449 1.7647 2.71063 1.7647H6.94529C7.22143 1.7647 7.44529 1.54084 7.44529 1.2647V0.5C7.44529 0.223858 7.22143 0 6.94529 0ZM8.57724 3.44116C8.57724 3.16502 8.35338 2.94116 8.07724 2.94116H1.5473C1.27115 2.94116 1.0473 3.16502 1.0473 3.44116V4.20586C1.0473 4.482 1.27115 4.70586 1.5473 4.70586H8.07724C8.35338 4.70586 8.57724 4.482 8.57724 4.20586V3.44116ZM11.7485 3.69238L10.0617 5.85398H0.529559C0.202425 5.85398 -0.0365867 6.16294 0.0455917 6.47958L0.862076 9.6256C0.919292 9.84606 1.11828 10 1.34604 10H13.5489C13.7598 10 13.9481 9.86766 14.0194 9.6692L15.9282 4.36158C16.0453 4.03578 15.8039 3.69238 15.4577 3.69238H11.7485ZM3.37388 7.64713C3.37388 7.972 3.11348 8.23536 2.79225 8.23536C2.47103 8.23536 2.21063 7.972 2.21063 7.64713C2.21063 7.32226 2.47103 7.0589 2.79225 7.0589C3.11348 7.0589 3.37388 7.32226 3.37388 7.64713ZM6.2822 8.23536C6.60342 8.23536 6.86382 7.972 6.86382 7.64713C6.86382 7.32226 6.60342 7.0589 6.2822 7.0589C5.96097 7.0589 5.70057 7.32226 5.70057 7.64713C5.70057 7.972 5.96097 8.23536 6.2822 8.23536ZM10.3535 7.64713C10.3535 7.972 10.0931 8.23536 9.77184 8.23536C9.45062 8.23536 9.19022 7.972 9.19022 7.64713C9.19022 7.32226 9.45062 7.0589 9.77184 7.0589C10.0931 7.0589 10.3535 7.32226 10.3535 7.64713Z" fill="#819FB2"/>

        <path v-if="iconName=='ship'" fill-rule="evenodd" clip-rule="evenodd" d="M6.94529 0H2.71063C2.43449 0 2.21063 0.223858 2.21063 0.5V1.2647C2.21063 1.54084 2.43449 1.7647 2.71063 1.7647H6.94529C7.22143 1.7647 7.44529 1.54084 7.44529 1.2647V0.5C7.44529 0.223858 7.22143 0 6.94529 0ZM8.57724 3.44116C8.57724 3.16502 8.35338 2.94116 8.07724 2.94116H1.5473C1.27115 2.94116 1.0473 3.16502 1.0473 3.44116V4.20586C1.0473 4.482 1.27115 4.70586 1.5473 4.70586H8.07724C8.35338 4.70586 8.57724 4.482 8.57724 4.20586V3.44116ZM11.7485 3.69238L10.0617 5.85398H0.529559C0.202425 5.85398 -0.0365867 6.16294 0.0455917 6.47958L0.862076 9.6256C0.919292 9.84606 1.11828 10 1.34604 10H13.5489C13.7598 10 13.9481 9.86766 14.0194 9.6692L15.9282 4.36158C16.0453 4.03578 15.8039 3.69238 15.4577 3.69238H11.7485ZM3.37388 7.64713C3.37388 7.972 3.11348 8.23536 2.79225 8.23536C2.47103 8.23536 2.21063 7.972 2.21063 7.64713C2.21063 7.32226 2.47103 7.0589 2.79225 7.0589C3.11348 7.0589 3.37388 7.32226 3.37388 7.64713ZM6.2822 8.23536C6.60342 8.23536 6.86382 7.972 6.86382 7.64713C6.86382 7.32226 6.60342 7.0589 6.2822 7.0589C5.96097 7.0589 5.70057 7.32226 5.70057 7.64713C5.70057 7.972 5.96097 8.23536 6.2822 8.23536ZM10.3535 7.64713C10.3535 7.972 10.0931 8.23536 9.77184 8.23536C9.45062 8.23536 9.19022 7.972 9.19022 7.64713C9.19022 7.32226 9.45062 7.0589 9.77184 7.0589C10.0931 7.0589 10.3535 7.32226 10.3535 7.64713Z" :fill="color"/>

        <path v-if="iconName=='trash-can'" d="M7.1 1.81818C7.1 1.21569 6.60751 0.727273 6 0.727273C5.39249 0.727273 4.9 1.21569 4.9 1.81818H4.16667C4.16667 0.814028 4.98748 0 6 0C7.01252 0 7.83333 0.814028 7.83333 1.81818H11.1333C11.3358 1.81818 11.5 1.98099 11.5 2.18182C11.5 2.38265 11.3358 2.54545 11.1333 2.54545H10.7271L9.77662 10.7122C9.69123 11.4461 9.0646 12 8.31963 12H3.68038C2.9354 12 2.30877 11.4461 2.22338 10.7122L1.2722 2.54545H0.866667C0.686663 2.54545 0.536954 2.41682 0.505908 2.24718L0.5 2.18182C0.5 1.98099 0.664162 1.81818 0.866667 1.81818H7.1ZM9.98787 2.54545H2.0114L2.95188 10.6288C2.99457 10.9958 3.30789 11.2727 3.68038 11.2727H8.31963C8.69211 11.2727 9.00543 10.9958 9.04812 10.6288L9.98787 2.54545ZM4.9 4.36364C5.08 4.36364 5.22971 4.47619 5.26076 4.62462L5.26667 4.68182V9.13636C5.26667 9.31209 5.1025 9.45455 4.9 9.45455C4.72 9.45455 4.57029 9.34199 4.53924 9.19356L4.53333 9.13636V4.68182C4.53333 4.50609 4.6975 4.36364 4.9 4.36364ZM7.1 4.36364C7.28 4.36364 7.42971 4.47619 7.46076 4.62462L7.46667 4.68182V9.13636C7.46667 9.31209 7.3025 9.45455 7.1 9.45455C6.92 9.45455 6.77029 9.34199 6.73924 9.19356L6.73333 9.13636V4.68182C6.73333 4.50609 6.8975 4.36364 7.1 4.36364Z" :fill="color"/>
    </svg>
</template>
<style scoped>
    
</style>
<script>

export default {
    name: 'CustomIcon',
    props: {
        marginTop: {
            default: 0
        },
        marginLeft: {
            default: 0
        },
        width: {
            default: 16
        },
        height: {
            default: 16
        },
        color: {
            type: String,
            default: '#4A4A4A'
        },
        iconName: {
            type: String,
            default: 'archive'
        }
    }
}
</script>