<template>
  <div class="location-form" style="height: 100% !important">
    <v-dialog v-model="dialogImport" max-width="85%" content-class="add-user-import-name-dialog location"
      :retain-focus="false" @click:outside="close" scrollable>
      <v-card>
          <v-card-title>
            <span class="headline">Edit Delivery Location</span>
            <button icon dark class="btn-close" @click="close">
              <v-icon>mdi-close</v-icon>
            </button>
          </v-card-title>

          <v-card-text style="background-color: #F3F4F7;" class="add-delivery-location-card">
            <v-form v-if="delivery_location != null"  ref="form" v-model="valid" action="#" @submit.prevent="">
              <div style="padding-top: 24px;" class="delivery-general-title">
                <span class="headline-title">General Information</span>
              </div>
              <v-row class="general-info-div">
                <v-col class="first-col">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <div class="card-name mb-3">
                        <p class="card-title">Location Name</p>
                        <v-text-field  v-model="delivery_location.location_name" height="40px" color="#002F44" width="200px"
                          dense class="text-fields select-items" :placeholder=" !isMobile ? 'Enter name':'Enter Location Name'" outlined validate-on-blur
                          :rules="[(v) => !!v || 'Input is required.']" hide-details="auto">
                        </v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="12"></v-col>
                    <v-col v-if="isMobile" cols="12">
                      <div class="card-name mb-3">
                        <p class="card-title">Type</p>
                        <v-autocomplete item-color="#253041" class="text-fields select-items" outlined
                          :items="['Company Owned Warehouse', '3PL Warehouse', 'Amazon Fulfillment', 'Retail Warehouse', 'Others']"
                          item-text="name" item-value="id" append-icon="mdi-chevron-down" placeholder="Select type"
                          v-model="delivery_location.type"  hide-details="auto" autocomplete="off">
                        </v-autocomplete>

                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="card-name mb-3">
                        <p class="card-title">address</p>

                        <v-autocomplete :loading="loadingLocationPlaces" v-model="address" return-object
                          :items="placesAddLeg" placeholder="Enter address" :search-input.sync="searchPlace"
                          item-text="place_name" item-value="id" no-filter hide-no-data @click:clear="clearSearchPlaces"
                          full-width @change="changeDeliveryLocationState" @blur="onAutocompleteBlur"  clearable
                          class="delivery-location-field text-fields " outlined hide-details="auto">
                          
                          <template slot="selection" slot-scope="{ item }">
                            <div class="pt-1" style="color: #253041; font-size: 14px; line-height: 20px; width: 100%;">
                              {{ item.place_name }}
                            </div>
                          </template>

                        </v-autocomplete>


                        <!-- <div class="dropdown pb-2">
                          <v-autocomplete :loading="loadingLocationPlaces" v-model="address" return-object
                            :items="placesAddLeg" label="Enter address" :search-input.sync="searchPlace"
                            item-text="place_name" item-value="id" no-filter clearable hide-no-data
                            @click:clear="clearSearchPlaces" :rules="requiredRules" full-width
                            @change="changeDeliveryLocationState" @blur="onAutocompleteBlur" solo
                            append-icon="ic-chevron-down" class="delivery-location-field" hide-details="">

                            <template slot="selection" slot-scope="{ item }">
                              <div class="pt-1" style="color: #253041; font-size: 14px; line-height: 20px; width: 100%;">
                                {{ item.place_name }}
                              </div>
                            </template>

                          </v-autocomplete>
                        </div> -->
                      </div>
                    </v-col>

                    <v-col v-if="isMobile" cols="12" class="pl-2 py-0 my-0">
                      <div class="card-name">
                        <p class="card-title">Special Delivery Instruction</p>
                        <v-textarea v-model="delivery_location.special_delivery_instruction"
                        style="min-height: 76px!important;" class="text-fields select-items" outlined name="input-7-4"
                          placeholder="Enter special Delivery Instruction" rows="2"
                           validate-on-blur hide-details="auto">
                        </v-textarea>

                      </div>
                    </v-col>


                    <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pr-2">
                      <div class="card-name mb-3">
                        <p class="card-title">Country</p>
                        <v-text-field disabled background-color="#F3F4F7" v-model="delivery_location.country" height="40px" color="#002F44" width="200px" dense
                          class="text-fields select-items" placeholder="Enter City name" outlined validate-on-blur
                          hide-details="auto">
                        </v-text-field>
                        <!-- <v-autocomplete class="text-fields select-items" v-model="delivery_location.country"
                          :items="countries" :disabled="getCountriesLoading" append-icon="mdi-chevron-down" :placeholder="getCountriesLoading
                            ? 'Fetching countries...'
                            : 'Type country name'
                            " @input="setSelectedCountry" outlined :rules="rules" hide-details="auto" autocomplete="off">
                        </v-autocomplete> -->

                      </div>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pl-2">
                      <div class="card-name mb-3">
                        <p class="card-title">State </p>
                        <v-text-field disabled background-color="#F3F4F7" v-model="delivery_location.state" height="40px" color="#002F44" width="200px" dense
                          class="text-fields select-items" placeholder="Enter City name" outlined validate-on-blur
                          hide-details="auto">
                        </v-text-field>
                        <!-- <v-autocomplete class="text-fields select-items" outlined :items="states" item-text="name"
                          item-value="id" append-icon="mdi-chevron-down" :placeholder="getStatesLoading ? 'Fetching states...' : 'Select state'
                            " :disabled="getStatesLoading" v-model="delivery_location.state" :rules="rules"
                          hide-details="auto" autocomplete="off" @change="setSelectedState">
                        </v-autocomplete> -->

                      </div>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pr-2 py-0 my-0">
                      <div class="card-name mb-3">
                        <p class="card-title">City</p>
                        <v-text-field disabled background-color="#F3F4F7" v-model="delivery_location.city" height="40px" color="#002F44" width="200px" dense
                          class="text-fields select-items" placeholder="Enter City name" outlined validate-on-blur
                          hide-details="auto">
                        </v-text-field>
                        <!-- <v-autocomplete class="text-fields select-items" v-model="delivery_location.city" :items="cities"
                          item-text="name" item-value="id" append-icon="mdi-chevron-down" :disabled="getCitiesLoading"
                          :placeholder="getCitiesLoading ? 'Fetching cities...' : 'Select city'
                            " outlined :rules="rules" hide-details="auto">
                          <template v-slot:no-data>
                            <div tabindex="-1" class="v-list-item theme--light">
                              <div class="v-list-item__content">
                                <div class="v-list-item__title">
                                  No data available
                                </div>
                              </div>
                            </div>

                          </template>
                        </v-autocomplete> -->

                      </div>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pl-2 py-0 my-0">
                      <div class="card-name mb-3">
                        <p class="card-title">Zip code</p>
                        <v-text-field disabled background-color="#F3F4F7" v-model="delivery_location.zip_code" height="40px" color="#002F44" width="200px" dense
                          class="text-fields select-items" placeholder="Enter zip code" outlined validate-on-blur
                           hide-details="auto">
                        </v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="second-col">
                  <v-row v-if="!isMobile" no-gutters>
                    <v-col cols="12">
                      <div class="card-name">
                        <p class="card-title">Type</p>
                        <v-autocomplete item-color="#253041" class="text-fields select-items" outlined
                          :items="['Company Owned Warehouse', '3PL Warehouse', 'Amazon Fulfillment', 'Retail Warehouse', 'Others']"
                          item-text="name" item-value="id" append-icon="mdi-chevron-down" placeholder="Select type"
                          v-model="delivery_location.type"  hide-details="auto" autocomplete="off">
                        </v-autocomplete>

                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="!isMobile">
                    <v-col cols="12" class="pl-2 py-0 my-0">
                      <div class="card-name mb-3">
                        <p class="card-title">Special Delivery Instruction</p>
                        <v-textarea v-model="delivery_location.special_delivery_instruction"
                        style="min-height: 76px!important;" class="text-fields select-items" outlined name="input-7-4"
                          placeholder="Enter special Delivery Instruction" rows="2"
                           validate-on-blur hide-details="auto">
                        </v-textarea>

                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <div class="mt-4 mark-facility-appointment">
                        <v-checkbox v-bind:false-value="0" v-bind:true-value="1" class="my-0 text-center" hide-details
                          color="green" v-model="delivery_location.facility_operation_schedule">
                          <template slot="label">
                            <p class="my-auto" style="background-color: #F9FAFB; color: #253041;font-weight: 400;">
                              Facility Requires Appointment</p>
                          </template>
                        </v-checkbox>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-container class="contact-container">
                <div :style="!isMobile ? 'padding-top: 24px':'padding:6px 0 12px 6px !important'" class="contact-general-title">
                  <span class="headline-title">Contact</span>
                </div>

                <div class="contact-table" v-if="!isMobile">
                  <v-data-table class="add-contacts-table" :headers="contactHeaders" :items="getContacts" item-key="email"
                    hide-default-footer dense>

                    <template v-slot:body="{ items }">
                      <tr v-for="(item, index) in items" :key="index">
                        <td class="text-start" style="border: 1px solid #F3F4F7; width: 200px">
                          <v-text-field type="text" dense hide-details="auto" placeholder="Enter contact name"
                            v-model="item.contact_name" class="text-fields qbContacts container-nbr-ph" flat solo
                            validate-on-blur />
                        </td>

                        <td class="text-end">
                          <vue-tel-input mode="international" defaultCountry="us" validCharactersOnly
                            :autoDefaultCountry="true" :inputOptions="vueTelInputOptions" v-model="item.phone_number">
                            <template v-slot:arrow-icon>
                              <v-icon class="ml-1">mdi-chevron-down</v-icon>
                            </template>
                          </vue-tel-input>
                        </td>


                        <td class="text-end">
                          <v-text-field type="email" dense hide-details="message" placeholder="Enter email address"
                            class="text-fields qbContacts inlinefield text-end" flat solo validate-on-blur
                            v-model="item.email" />
                        </td>

                        <td class="text-end">
                          <div class="flex justify-center">
                            <input 
                              :id="'onsite-checkbox-' + index"
                              class="empty-checkbox-contact my-checkbox"
                              type="checkbox"
                              style="display: none"
                              v-model="item.on_site" 
                            />
                            <label :for="'onsite-checkbox-' + index" class="checkbox-label-contact"></label>
                          </div>
                        </td>

                        <td class="text-end">
                          <div class="flex justify-center">
                            <!-- <v-radio-group v-model="item.primary" @change="handleRadioChange(index)">
                              <v-radio class="primary-contact-radio" value="primaryContact"></v-radio>
                            </v-radio-group> -->
                            <input
                              :id="'radio-' + index"
                              class="hidden-radio-for-contacts my-radio"
                              type="radio"
                              @click="handleRadioChange(index)" 
                              :value="1" 
                              v-model="item.primary">
                              <label :for="'radio-' + index" class="radio-label-for-contacts"></label>
                          </div>

                        </td>
                        <td class="text-end">
                          <v-text-field flat solo type="text" placeholder="Enter Role"
                            class="text-fields qbContacts text-end" dense hide-details="auto" v-model="item.role" />
                        </td>

                        <td class="text-center">
                          <v-icon size="19" :style="'color:red'" style="cursor: pointer"
                            @click="handleRemoveContact(index)">mdi-close</v-icon>
                        </td>
                      </tr>
                      <!-- <tr v-show="index === (contacts.length-1)"> -->
                      <tr class="px-8">
                        <td colspan="7" class="px-3 py-2" style="border-top: 1px solid #D0D5DD;">
                          <button class="add-more-btn" style="border-top: none;" text @click="handleAddContact">
                          + Add More Contact
                        </button>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>

                <!-- if mobile view -->
                <div v-else class="pa-2">
                  <div class="delivery-loc-contact-mobile-div" v-for="(item, index) in getContacts" :key="index">
                    <div class="delivery-contact-name delivery-mobile-field">
                      <v-text-field type="text" dense hide-details="auto" placeholder="Enter contact name"
                        v-model="item.contact_name" class="text-fields qbContacts container-nbr-ph" flat solo
                        validate-on-blur />
                    </div>
                    <div class="delivery-contact-phone delivery-mobile-field">
                      <vue-tel-input mode="international" defaultCountry="us" validCharactersOnly
                        :autoDefaultCountry="true" :inputOptions="vueTelInputOptions" v-model="item.phone_number">
                        <template v-slot:arrow-icon>
                          <v-icon class="ml-1">mdi-chevron-down</v-icon>
                        </template>
                      </vue-tel-input>
                    </div>
                    <div class="delivery-contact-email delivery-mobile-field">
                      <v-text-field type="email" dense hide-details="message" placeholder="Enter email address"
                        class="text-fields qbContacts inlinefield text-end" flat solo validate-on-blur
                        v-model="item.email" />
                    </div>
                    <div class="delivery-contact-role delivery-mobile-field">
                      <v-text-field flat solo type="text" placeholder="Enter Role"
                        class="text-fields qbContacts text-end" dense hide-details="auto" v-model="item.role" />
                    </div>
                    <div class="delivery-contact-onsite-primary delivery-mobile-field">
                      <div class="delivery-checkbox-radio-field">
                        <div class="flex justify-center checkbox-for-contact">
                          <input
                            :id="'onsite-checkbox-' + index"
                            class="empty-checkbox-contact my-checkbox"
                            type="checkbox"
                            v-model="item.on_site"
                            style="display: none"
                          />
                          <label :for="'onsite-checkbox-' + index" class="checkbox-label-contact"></label>
                          <span class="ml-2" style="color: #69758C;">Onsite</span>
                        </div>
                        <div class="flex justify-center ml-3">
                          <input
                            :id="'radio-' + index"
                            class="hidden-radio-for-contacts my-radio"
                            type="radio"
                            @click="handleRadioChange(index)"
                            :value="1"
                            v-model="item.primary"
                          />
                          <label :for="'radio-' + index" class="radio-label-for-contacts"></label>
                          <span class="ml-1" style="color: #69758C;">Primary</span>
                        </div>
                      </div>

                      <v-icon size="19" :style="'color:red'" style="cursor: pointer"
                        @click="handleRemoveContact(index)" :disabled="getContacts.length === 1">
                        mdi-close
                      </v-icon>
                    </div>
                  </div>

                  <div class="add-more-btn mt-2  ml-3" @click="handleAddContact">
                    + Add More Contact
                  </div>
                </div>
              </v-container>

              <!-- <div v-if="delivery_location.facility_operation_schedule == 1" style="margin-top: 32px;"
                class="div-facility-options"> -->
              <div v-if="delivery_location.facility_operation_hour !== undefined && delivery_location.facility_operation_hour.type !== undefined && delivery_location.facility_operation_hour.type !== 'undefined'" style="margin-top: 32px;"
                class="div-facility-options">
                <div class="d-flex justify-start align-center">
                  <span class="headline-title">Facility Operation Hours</span>
                  <v-radio-group class="px-5 mt-0 facility-radio-group" v-model="delivery_location.facility_operation_hour.type"
                    row @change="resetFohDaysDate" hide-details="auto" v-if="!isMobile">
                    <v-radio label="Weekly Basis" value="weekly"></v-radio>
                    <v-radio label="Daily Basis" value="daily"></v-radio>
                  </v-radio-group>
                </div>

                <div class="facility-operation-hours-wrapper">
                  <v-radio-group class="px-5 facility-radio-group" v-model="delivery_location.facility_operation_hour.type"
                    row @change="resetFohDaysDate" hide-details="auto" v-if="isMobile">
                    <v-radio label="Weekly Basis" value="weekly"></v-radio>
                    <v-radio label="Daily Basis" value="daily"></v-radio>
                  </v-radio-group>
                  <div  class="facility-days-container">
                  <v-row class="weeklyBasisTable" v-if="delivery_location.facility_operation_hour.type == 'weekly'">
                    <v-col cols="6">
                      <div class="card-name mb-3">
                        <p class="card-title">Days</p>
                        <!-- <v-autocomplete class="days-custom-autocomplete" outlined chips multiple clearable
                          :items="['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']"
                          append-icon="mdi-chevron-down" placeholder="Select Days" v-model="weekly_foh_days" :rules="rules"
                          autocomplete="off">
                        </v-autocomplete> -->

                        <v-select :items="['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']"
                          v-model="weekly_foh_days" :rules="rules" chips deletable-chips return-object multiple
                          class="text-fields select-items" outlined append-icon="mdi-chevron-down" background-color="white"
                          placeholder="Select days" :menu-props="{ contentClass: 'product-lists-items woh-days-lists' }"
                          hide-details="auto">

                          <template v-slot:selection="{ item, index }">
                            <v-chip :style="isMobile ? 'background-color:#F3F4F7':''" class="product-item-chip font-medium" close @click:close="removeChipDays(index)">
                              <span class="name">{{ item }}</span>
                            </v-chip>
                          </template>

                          <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs" #default="{ active }" :class="item.class">
                              <v-list-item-action class="mr-2 p-0">
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <p class=" mb-0" :style="active ? 'color:#253041':''">{{ item }}</p>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                      </div>
                    </v-col>

                    <v-col class="selectBox" cols="3" md="3">
                      <p class="card-title">OPEN TIME</p>
                      <div class="custom-datebox">
                        <DxDateBox :input-attr="{ 'aria-label': 'Time' }" placeholder="Enter Time" :openOnFieldClick="true"
                          type="time" v-model="weekly_open_time" class="date-field-dxtime" :use-mask-behavior="true"
                          display-format="hh:mm a" :show-clear-button="true" picker-type="list" />
                      </div>
                    </v-col>

                    <v-col class="selectBox" cols="3" md="3">
                      <p class="card-title">CLOSE TIME</p>
                      <div class="custom-datebox">
                        <DxDateBox :input-attr="{ 'aria-label': 'Time' }" placeholder="Enter Time" :openOnFieldClick="true"
                          type="time" v-model="weekly_close_time" class="date-field-dxtime" :use-mask-behavior="true"
                          display-format="hh:mm a" :show-clear-button="true" picker-type="list" />
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="dailyBasisTable" v-if="delivery_location.facility_operation_hour.type == 'daily'">
                    <v-data-table class="add-facility-days-table" :headers="facilityHeaders" :items="daily_foh_days"
                      hide-default-footer fixed>

                      <template v-slot:header="{ props: { headers } }" v-if="isMobile">
                        <thead>
                          <tr>
                            <th
                              v-for="(item, index) in headers"
                              :key="index"
                              class="op-"
                              role="column-header"
                              :aria-label="item.text"
                              scope="col">
                              {{ item.text }}
                            </th>
                          </tr>
                        </thead>
                      </template>

                      <template v-slot:item="{ item }">
                        <tr>
                          <td> <v-checkbox v-bind:false-value="0" v-bind:true-value="1" class="my-0 text-center" hide-details
                              v-model="item.isChecked">
                              <template slot="label">
                                <p class="my-auto" style="color: #253041;font-weight: 400;">
                                  {{ !isMobile ? item.day : item.day.substr(0, 3) }}</p>
                              </template>
                            </v-checkbox></td>
                          <td>
                            <div class="custom-datebox">
                              <DxDateBox :input-attr="{ 'aria-label': 'Time' }" placeholder="00:00 AM"
                                :openOnFieldClick="true" type="time" v-model="item.open_time" class="date-field-dxtime"
                                :use-mask-behavior="true" display-format="hh:mm a" :show-clear-button="true" picker-type="list" />
                            </div>
                          </td>
                          <td>
                            <div class="custom-datebox">
                              <DxDateBox :input-attr="{ 'aria-label': 'Time' }" placeholder="00:00 AM"
                                :openOnFieldClick="true" type="time" v-model="item.close_time" class="date-field-dxtime"
                                :use-mask-behavior="true" display-format="hh:mm a" :show-clear-button="true" picker-type="list" />
                            </div>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-row>
                </div>
                </div>
              </div>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <button @click="editDeliveryLocation" :disabled="createUpdateLoading" class="btn-blue mr-2">
              {{ createUpdateText }}
            </button>
            <button class="btn-white" @click="close" :disabled="createUpdateLoading">
              Cancel
            </button>
          </v-card-actions>        
      </v-card>
    </v-dialog>
    <ConfirmDialog :dialogData.sync="changeFromDailyToWeeklyDialog">
      <template v-slot:dialog_icon>
        <div class="header-wrapper-close">
            <img src="@/assets/icons/icon-delete-red.svg" width="48px" height="48px">
        </div>
      </template>

      <template v-slot:dialog_title>
        <h2>Change from Daily to Weekly Basis</h2>
      </template>

      <template v-slot:dialog_content>
        <p> Changing from daily basis to weekly basis will reset/clear all the 
          information provided on the daily basis.
        </p>
      </template>

      <template v-slot:dialog_actions>
        <v-btn class="btn-blue" @click="confirmToChangeFromWeeklyToDaily" text>
            <span>Confirm</span>
        </v-btn>

        <v-btn class="btn-white" text @click="cancelChangeFromWeeklyToDaily">
            Cancel
        </v-btn>
    </template>
  </ConfirmDialog>
  </div>
</template>

<script>
// import CustomRadio from './CustomRadio.vue'; // Adjust the import path

import DxDateBox from "devextreme-vue/date-box";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { mapGetters, mapActions } from 'vuex';
import ConfirmDialog from "../../Dialog/GlobalDialog/ConfirmDialog.vue";
import globalMethods from "../../../utils/globalMethods";
import moment from "moment";
import _ from "lodash"


export default {
  props: ["dialogEditDeliveryLocation", "editDeliveryLocationIndex", "EditLocationItem", "defaultCustomer", "defaultContactItems", "comingFrom", "isMobile"],
  components: {
    VueTelInput,
    DxDateBox,
    ConfirmDialog
  },


  data() {

    return {
      changeFromDailyToWeeklyDialog:false,
      defaultDate: new Date(), // Set your default date and time here
      callApiForDeliveryLoc: true,
      loadingLocationPlaces: false,
      searchPlace: "",
      address: {
        center: [],
        place_name: ""
      },
      lat: "",
      long: "",
      country: "USM",
      placesAddLeg: [],
      editedAddress: null,
      defaultAddress: { place_name: 'Address 2' },
      selectedAddressLocationObj: null,
      requiredRules: [(v) => !!v || v === 0 || "This field is required"],

      delivery_location: null,
      valid: true,
      facilityHeaders: [
        {
          text: 'DAYS', value: 'column1', width: "50%", sortable: false,
          fixed: true,
          align: "start",

        },
        {
          text: 'OPEN TIME', value: 'column2', width: "25%", sortable: false,
          fixed: true,
        },
        {
          text: 'CLOSE TIME', value: 'column3', width: "25%", sortable: false,
          fixed: true,
        }
      ],
      vueTelDropdownOptions: {
        showDialCodeInSelection: true,
        showDialCodeInList: true,
        showFlags: true,
        showSearchBox: true,
      },
      vueTelInputOptions: {
        autocomplete: false,
        placeholder: "Enter digit",
        styleClasses: "tel-input-class",
        required: true,
      },
      contactHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
          fixed: true,
          width: "20%",
        },
        {
          text: "Phone Number",
          align: "start",
          sortable: false,
          value: "phone_number",
          fixed: true,
          width: "20%",
        },
        {
          text: "Email Address",
          align: "start",
          sortable: false,
          value: "email",
          fixed: true,
          width: "20%",
        },
        {
          text: "Onsite",
          align: "center",
          sortable: false,
          value: "onsite",
          fixed: true,
          width: "4%",
        },
        {
          text: "Primary",
          align: "center",
          sortable: false,
          value: "primary",
          fixed: true,
          width: "8%",
        },
        {
          text: "Role",
          align: "start",
          sortable: false,
          value: "role",
          fixed: true,
          width: "13%",
        },
        {
          text: "",
          sortable: false,
          value: "",
          fixed: true,
          width: "4%",
        },
      ],

      delivery_types: ['Company Owned Warehouse', '3PL Warehouse', 'Amazon Fulfillment', 'Retail Warehouse', 'Others'],
      daily_foh_days: [{ day: 'Monday', open_time: null, close_time: null, isChecked: 0 },
      { day: 'Tuesday', open_time: null, close_time: null, isChecked: 0 },
      { day: 'Wednesday', open_time: null, close_time: null, isChecked: 0 },
      { day: 'Thursday', open_time: null, close_time: null, isChecked: 0 },
      { day: 'Friday', open_time: null, close_time: null, isChecked: 0 },
      { day: 'Saturday', open_time: null, close_time: null, isChecked: 0 },
      { day: 'Sunday', open_time: null, close_time: null, isChecked: 0 },],
      weekly_foh_days: [],
      weekly_open_time: null,
      weekly_close_time: null,
      days: [],
      isPhoneNumberEmpty: false,
      files: [],
      // url: null,
      rules: [(v) => !!v || "Input is required."],
      emailRules: [
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Email must be valid.",
        (v) => !!v || "Email is required.",
      ],
    }
  },

  computed: {
    ...mapGetters({
      getCountries: 'warehouse/getCountries',
      getCountriesLoading: 'warehouse/getCountriesLoading',
      getStates: 'warehouse/getStates',
      getStatesLoading: 'warehouse/getStatesLoading',
      getCities: 'warehouse/getCities',
      getCitiesLoading: 'warehouse/getCitiesLoading',
      getupdateDeliveryLocationLoading: 'settings/getupdateDeliveryLocationLoading',
    }),
    getContacts: {
      get() {
        return this.delivery_location.contacts
      }
    },
    createUpdateLoading() {
      let loading = false
      loading = this.getupdateDeliveryLocationLoading

      return loading
    },
    createUpdateText() {
      let text = ''
      if (this.getupdateDeliveryLocationLoading) {
        text = 'Saving...'
      } else {
        text = 'Save Changes'
      }

      return text
    },
    // weekly_open_time: {
    //   get() {
    //     let time = moment(moment().format('YYYY-MM-DD') + " " + "06:00 AM").utc(false)
    //     return time._d
    //     // if (this.newdatetimepickup !== "" && this.newdatetimepickup !== null && this.newdatetimepickup !== "Invalid date") {
    //     //   let time = moment(moment().format('YYYY-MM-DD') + " " + this.newdatetimepickup).utc(false)
    //     //   console.log("this.newdatetimepickup: ",this.newdatetimepickup)
    //     //   return time._d
    //     // } else {
    //     //   // return moment()
    //     //   return null
    //     // }

    //   },
    //   set(value) {
    //     if (value) {
    //       console.log("SET WTO: ", moment(moment().format('YYYY-MM-DD') + " " + value))
    //       return moment(moment().format('YYYY-MM-DD') + " " + value)
    //     }
    //     return null
    //   }
    // },
    dialogImport: {
      get() {
        return this.dialogEditDeliveryLocation
      },
      set(value) {
        this.$emit('update:dialogEditDeliveryLocation', value)
      }
    },
    editIndex: {
      get() {
        return this.editDeliveryLocationIndex
      },
      set(value) {
        this.$emit('update:editDeliveryLocationIndex', value)
      }
    },
    importLocationData: {
      get() {
        return this.EditLocationItem
      },
      set(value) {
        this.$emit('update:EditLocationItem', value)
      }
    },
    nameTitle() {
      return this.editIndex ? 'Update Location Name' : 'Add New Location Name'
    },
    countries() {
      return typeof this.getCountries !== 'undefined' && this.getCountries !== null && this.getCountries.length !== 0 ? this.getCountries : []
    },
    states() {
      return typeof this.getStates !== 'undefined' && this.getStates !== null && this.getStates.length !== 0 ? this.getStates : []
    },
    cities() {
      return typeof this.getCities !== 'undefined' && this.getCities !== null && this.getCities.length !== 0 ? this.getCities : []
    },
  },
  watch: {
    EditLocationItem(newVal) {
      if (typeof newVal !== "undefined" &&
        newVal !== "" &&
        newVal !== null) {
        this.delivery_location = newVal
        this.setSelectedCountry(newVal.country)
        this.setSelectedState(newVal.state)
        this.checkAndSetFohDays()
        if(this.delivery_location.address === '' || this.delivery_location.address === null ) return
        this.placesAddLeg.push({ place_name: this.delivery_location.address })
        this.address.place_name = this.delivery_location.address
        this.address.center[0] = this.delivery_location.lat
        this.address.center[1] = this.delivery_location.long

      }

    },
    searchPlace(val) {

      if (val && this.callApiForDeliveryLoc) {
        this.getAddressLocation(val);
      }
    },

  },


  mounted() {

    if (typeof this.EditLocationItem !== "undefined" &&
      this.EditLocationItem !== "" &&
      this.EditLocationItem !== null
    ) {
      this.delivery_location = this.EditLocationItem
      
      this.fetchCountries()
      this.setSelectedCountry(this.delivery_location.country)
      this.setSelectedState(this.delivery_location.state)
      this.checkAndSetFohDays()
      if(this.delivery_location.address === '' || this.delivery_location.address === null) return
      this.placesAddLeg.push({ place_name: this.delivery_location.address })
      this.address.place_name = this.delivery_location.address
      this.address.center[0] = this.delivery_location.lat
      this.address.center[1] = this.delivery_location.long


    }
  },
  methods: {
    removeChipDays(index) {
      this.weekly_foh_days.splice(index, 1)

    },
    onAutocompleteBlur() {
      // Check if no value was selected and set to default value
      if (!this.address) {

        this.placesAddLeg.push({ place_name: this.delivery_location.address })
        this.address = {
          center: [],
          place_name: ""
        }
        this.address.place_name = this.delivery_location.address
        this.address.center[0] = this.delivery_location.lat
        this.address.center[1] = this.delivery_location.long

      }
    },
    clearSearchPlaces() {
      this.placesAddLeg = [];
      this.delivery_location.country = '';
      this.delivery_location.state = '';
      this.delivery_location.city = '';
      this.delivery_location.zip_code = '';
    },

    changeDeliveryLocationState(newLocation) {
      // this.callApiForDeliveryLoc = !this.callApiForDeliveryLoc;
      console.log("CALLED: ", !this.callApiForDeliveryLoc)

      let location = newLocation?.place_name;
      if(location?.length){
        // let countryIndex = location.lastIndexOf(",");
        // let stateIndex = location.lastIndexOf(",", countryIndex - 1)

        // let newCountry = location.substr(countryIndex + 1).trim();
        // let newState = location.substring(stateIndex + 1, countryIndex).replace(/\d/g, '').trim();
        // let newCity = location.substring(0, stateIndex).trim();

        // this.delivery_location.country = newCountry;
        // this.delivery_location.state = newState;
        // this.delivery_location.city = newCity;

        let LocationArray = newLocation.place_name.split(",")
        
        let newCountry = LocationArray[LocationArray.length - 1];
        this.delivery_location.country = newCountry.trim();

        let newStateandZip = LocationArray[LocationArray.length - 2];
        
        let newState = newStateandZip.replace(/\d/g, '').trim();
        let ShouldZipCode = newStateandZip.trim().match(/\d+/);

        this.delivery_location.state = newState

        if(LocationArray.length >2){
          let newCity = LocationArray[LocationArray.length - 3];
          this.delivery_location.city = newCity.trim();
        }
        
        if (ShouldZipCode) {
          this.delivery_location.zip_code =  parseInt(ShouldZipCode[0], 10).toString();
        }else{
          this.delivery_location.zip_code = ""
        }
        // console.log(this.delivery_location.country,"--",newCountry)
        if(_.has(this.address, 'center')){
          return
        }else{
         
          Object.assign(this.address, {center: [ this.delivery_location.lat, this.delivery_location.long]});
        }
      }
    },

    async getAddressLocation(val) {
      if (val.length > 3) {
        this.placesAddLeg = []
        this.loadingLocationPlaces = true;
        await fetch(
          `${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` +
          val +
          `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}&country=US`
        )
          .then((response) => response.json())
          .then(async (json) => {

            this.loadingLocationPlaces = false;
            this.placesAddLeg = [...json.features];
            console.log("placesAddLg: ", this.placesAddLeg)

            // this.places = [...json.features, ...customInput];
          })
      }

    },
    handleRadioChange(selected_index) {
      const currentPrimaryState = this.delivery_location.contacts[selected_index].primary;
      this.delivery_location.contacts[selected_index].primary = currentPrimaryState === 1 ? 0 : 1;

      this.delivery_location.contacts.forEach((element, index) => {
      if (selected_index !== index) {
        element.primary = 0;
      }
    });
    },
    ...mapActions({
      fetchCountries: 'warehouse/fetchCountries',
      fetchStates: 'warehouse/fetchStates',
      fetchCities: 'warehouse/fetchCities',
      addDeliveryLocationApi: 'settings/addDeliveryLocationApi',
      updateDeliveryLocationApi: 'settings/updateDeliveryLocationApi',
      fetchSingleDeliveryLocation: 'settings/fetchSingleDeliveryLocation'
    }),
    ...globalMethods,
    close(value) {
      if (value == 'refresh') {
        this.$emit('getDeliveryLocations')
      }else if (value == 'reload-page'){
        window.location.reload()
      }
      this.$refs.form.resetValidation()
      this.$emit('closelocation')
      this.placesAddLeg = []
    },

    checkAndSetFohDays() {

      if (typeof this.delivery_location !== "undefined" && this.delivery_location != null) {
        if (this.delivery_location.facility_operation_hour !== undefined && typeof this.delivery_location.facility_operation_hour.days !== "undefined" &&  this.delivery_location.facility_operation_hour.days != null && this.delivery_location.facility_operation_hour.days.length > 0) {

          this.days = JSON.parse(this.delivery_location.facility_operation_hour.days)

          if (this.delivery_location.facility_operation_hour.type == 'weekly') {
            this.days.map(day => {
              if (day == 'mon') {
                this.weekly_foh_days.push('Monday')
                this.weekly_open_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.mon_open)
                this.weekly_close_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.mon_close)
              } else if (day == 'tue') {
                this.weekly_foh_days.push('Tuesday')
                this.weekly_open_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.tue_open)
                this.weekly_close_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.tue_close)
              } else if (day == 'wed') {
                this.weekly_foh_days.push('Wednesday')
                this.weekly_open_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.wed_open)
                this.weekly_close_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.wed_close)
              } else if (day == 'thu') {
                this.weekly_foh_days.push('Thursday')
                this.weekly_open_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.thu_open)
                this.weekly_close_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.thu_close)
              } else if (day == 'fri') {
                this.weekly_foh_days.push('Friday')
                this.weekly_open_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.fri_open)
                this.weekly_close_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.fri_close)
              } else if (day == 'sat') {
                this.weekly_foh_days.push('Saturday')
                this.weekly_open_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.sat_open)
                this.weekly_close_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.sat_close)
              } else if (day == 'sun') {
                this.weekly_foh_days.push('Sunday')
                this.weekly_open_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.sun_open)
                this.weekly_close_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.sun_close)
              }
            })


          } else {
            this.days.map(day => {
              if (day == 'mon') {
                this.daily_foh_days[0].isChecked = 1
                this.daily_foh_days[0].open_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.mon_open)
                this.daily_foh_days[0].close_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.mon_close)

              } else if (day == 'tue') {
                this.daily_foh_days[1].isChecked = 1
                this.daily_foh_days[1].open_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.tue_open)
                this.daily_foh_days[1].close_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.tue_close)

              } else if (day == 'wed') {
                this.daily_foh_days[2].isChecked = 1
                this.daily_foh_days[2].open_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.wed_open)
                this.daily_foh_days[2].close_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.wed_close)

              } else if (day == 'thu') {
                this.daily_foh_days[3].isChecked = 1
                this.daily_foh_days[3].open_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.thu_open)
                this.daily_foh_days[3].close_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.thu_close)

              } else if (day == 'fri') {
                this.daily_foh_days[4].isChecked = 1
                this.daily_foh_days[4].open_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.fri_open)
                this.daily_foh_days[4].close_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.fri_close)

              } else if (day == 'sat') {
                this.daily_foh_days[5].isChecked = 1
                this.daily_foh_days[5].open_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.sat_open)
                this.daily_foh_days[5].close_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.sat_close)

              } else if (day == 'sun') {
                this.daily_foh_days[6].isChecked = 1
                this.daily_foh_days[6].open_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.sun_open)
                this.daily_foh_days[6].close_time = this.convertUtcToLocalTime(this.delivery_location.facility_operation_hour.sun_close)

              }
            })
          }
        }
      }
    },
    convertUtcToLocalTime(resTime) {

      let time = moment(moment().format('YYYY-MM-DD') + " " + resTime).utc(true)
      return time._d
    },
    confirmToChangeFromWeeklyToDaily(){
      this.daily_foh_days = [{ day: 'Monday', open_time: null, close_time: null, isChecked: 0 },
      { day: 'Tuesday', open_time: null, close_time: null, isChecked: 0 },
      { day: 'Wednesday', open_time: null, close_time: null, isChecked: 0 },
      { day: 'Thursday', open_time: null, close_time: null, isChecked: 0 },
      { day: 'Friday', open_time: null, close_time: null, isChecked: 0 },
      { day: 'Saturday', open_time: null, close_time: null, isChecked: 0 },
      { day: 'Sunday', open_time: null, close_time: null, isChecked: 0 },]

      this.weekly_foh_days = []
      this.weekly_open_time = null
      this.weekly_close_time = null

      this.delivery_location.facility_operation_hour.days = []
      this.delivery_location.facility_operation_hour.mon_open = ""
      this.delivery_location.facility_operation_hour.mon_close = ""
      this.delivery_location.facility_operation_hour.tue_open = ""
      this.delivery_location.facility_operation_hour.tue_close = ""
      this.delivery_location.facility_operation_hour.wed_open = ""
      this.delivery_location.facility_operation_hour.wed_close = ""
      this.delivery_location.facility_operation_hour.thu_open = ""
      this.delivery_location.facility_operation_hour.thu_close = ""
      this.delivery_location.facility_operation_hour.fri_open = ""
      this.delivery_location.facility_operation_hour.fri_close = ""
      this.delivery_location.facility_operation_hour.sat_open = ""
      this.delivery_location.facility_operation_hour.sat_close = ""
      this.delivery_location.facility_operation_hour.sun_open = ""
      this.delivery_location.facility_operation_hour.sun_close = ""

      this.changeFromDailyToWeeklyDialog = false
    },
    cancelChangeFromWeeklyToDaily(){
      if(this.delivery_location.facility_operation_hour.type === 'weekly'){
        this.delivery_location.facility_operation_hour.type = 'daily'
      }else if(this.delivery_location.facility_operation_hour.type === 'daily'){
        this.delivery_location.facility_operation_hour.type = 'weekly'
      }
      this.changeFromDailyToWeeklyDialog = false
    },
    resetFohDaysDate() {
      if(this.weekly_foh_days.length) return this.changeFromDailyToWeeklyDialog = true
      if(this.weekly_open_time !== null) return this.changeFromDailyToWeeklyDialog = true
      if(this.weekly_close_time !== null) return this.changeFromDailyToWeeklyDialog = true
      let dailyFoChecked = this.daily_foh_days.some(val => val.isChecked || val.open_time !== null || val.close_time !== null)
      if(dailyFoChecked) return this.changeFromDailyToWeeklyDialog = true
    },

    async editDeliveryLocation() {

      if (this.$refs.form.validate()) {

        if (this.delivery_location.facility_operation_hour.type == 'weekly') {
          this.delivery_location.facility_operation_hour.days = []

          this.weekly_foh_days.map(element => {
            if (element == 'Monday') {
              this.delivery_location.facility_operation_hour.mon_open = this.weekly_open_time
              this.delivery_location.facility_operation_hour.mon_close = this.weekly_close_time
              this.delivery_location.facility_operation_hour.days.push('mon')
            } else if (element == 'Tuesday') {
              this.delivery_location.facility_operation_hour.tue_open = this.weekly_open_time
              this.delivery_location.facility_operation_hour.tue_close = this.weekly_close_time
              this.delivery_location.facility_operation_hour.days.push('tue')
            } else if (element == 'Wednesday') {
              this.delivery_location.facility_operation_hour.wed_open = this.weekly_open_time
              this.delivery_location.facility_operation_hour.wed_close = this.weekly_close_time
              this.delivery_location.facility_operation_hour.days.push('wed')
            } else if (element == 'Thursday') {
              this.delivery_location.facility_operation_hour.thu_open = this.weekly_open_time
              this.delivery_location.facility_operation_hour.thu_close = this.weekly_close_time
              this.delivery_location.facility_operation_hour.days.push('thu')
            } else if (element == 'Friday') {
              this.delivery_location.facility_operation_hour.fri_open = this.weekly_open_time
              this.delivery_location.facility_operation_hour.fri_close = this.weekly_close_time
              this.delivery_location.facility_operation_hour.days.push('fri')
            } else if (element == 'Saturday') {
              this.delivery_location.facility_operation_hour.sat_open = this.weekly_open_time
              this.delivery_location.facility_operation_hour.sat_close = this.weekly_close_time
              this.delivery_location.facility_operation_hour.days.push('sat')
            } else if (element == 'Sunday') {
              this.delivery_location.facility_operation_hour.sun_open = this.weekly_open_time
              this.delivery_location.facility_operation_hour.sun_close = this.weekly_close_time
              this.delivery_location.facility_operation_hour.days.push('sun')
            }
          });


        } else {
          this.delivery_location.facility_operation_hour.days = []

          this.daily_foh_days.map(element => {
            if (element.day == 'Monday' && element.isChecked == 1) {
              this.delivery_location.facility_operation_hour.mon_open = element.open_time
              this.delivery_location.facility_operation_hour.mon_close = element.close_time
              this.delivery_location.facility_operation_hour.days.push('mon')
            } else if (element.day == 'Tuesday' && element.isChecked == 1) {
              this.delivery_location.facility_operation_hour.tue_open = element.open_time
              this.delivery_location.facility_operation_hour.tue_close = element.close_time
              this.delivery_location.facility_operation_hour.days.push('tue')
            } else if (element.day == 'Wednesday' && element.isChecked == 1) {
              this.delivery_location.facility_operation_hour.wed_open = element.open_time
              this.delivery_location.facility_operation_hour.wed_close = element.close_time
              this.delivery_location.facility_operation_hour.days.push('wed')
            } else if (element.day == 'Thursday' && element.isChecked == 1) {
              this.delivery_location.facility_operation_hour.thu_open = element.open_time
              this.delivery_location.facility_operation_hour.thu_close = element.close_time
              this.delivery_location.facility_operation_hour.days.push('thu')
            } else if (element.day == 'Friday' && element.isChecked == 1) {
              this.delivery_location.facility_operation_hour.fri_open = element.open_time
              this.delivery_location.facility_operation_hour.fri_close = element.close_time
              this.delivery_location.facility_operation_hour.days.push('fri')
            } else if (element.day == 'Saturday' && element.isChecked == 1) {
              this.delivery_location.facility_operation_hour.sat_open = element.open_time
              this.delivery_location.facility_operation_hour.sat_close = element.close_time
              this.delivery_location.facility_operation_hour.days.push('sat')
            } else if (element.day == 'Sunday' && element.isChecked == 1) {
              this.delivery_location.facility_operation_hour.sun_open = element.open_time
              this.delivery_location.facility_operation_hour.sun_close = element.close_time
              this.delivery_location.facility_operation_hour.days.push('sun')
            }
          });

        }

        console.log("this.address: ", this.address)

        let update_payload = {
          id: this.importLocationData.id,
          customer_id: this.defaultCustomer,
          location_name: this.delivery_location.location_name,
          address: this.address.place_name != this.delivery_location.address ? this.address.place_name : this.delivery_location.address,
          lat: this.address.center[0] != this.delivery_location.lat ? this.address.center[0] : this.delivery_location.lat,
          long: this.address.center[1] != this.delivery_location.long ? this.address.center[1] : this.delivery_location.long,
          country: this.delivery_location.country,
          state: this.delivery_location.state,
          city: this.delivery_location.city,
          zip_code: this.delivery_location.zip_code,
          type: this.delivery_location.type,
          special_delivery_instruction: this.delivery_location.special_delivery_instruction,
          facility_operation_schedule: this.delivery_location.facility_operation_schedule,
          contacts: this.delivery_location.contacts,
          facility_operation_hour: {
            id: this.delivery_location.facility_operation_hour.id,
            type: this.delivery_location.facility_operation_hour.type,
            days: JSON.stringify(this.delivery_location.facility_operation_hour.days),
            mon_open: moment(this.delivery_location.facility_operation_hour.mon_open).utc().format("hh:mm A"),
            mon_close: moment(this.delivery_location.facility_operation_hour.mon_close).utc().format("hh:mm A"),
            tue_open: moment(this.delivery_location.facility_operation_hour.tue_open).utc().format("hh:mm A"),
            tue_close: moment(this.delivery_location.facility_operation_hour.tue_close).utc().utc().format("hh:mm A"),
            wed_open: moment(this.delivery_location.facility_operation_hour.wed_open).utc().format("hh:mm A"),
             wed_close: moment(this.delivery_location.facility_operation_hour.wed_close).utc().format("hh:mm A"),
             thu_open: moment(this.delivery_location.facility_operation_hour.thu_open).utc().format("hh:mm A"),
             thu_close: moment(this.delivery_location.facility_operation_hour.thu_close).utc().format("hh:mm A"),
             fri_open: moment(this.delivery_location.facility_operation_hour.fri_open).utc().format("hh:mm A"),
             fri_close: moment(this.delivery_location.facility_operation_hour.fri_close).utc().format("hh:mm A"),
             sat_open: moment(this.delivery_location.facility_operation_hour.sat_open).utc().format("hh:mm A"),
             sat_close: moment(this.delivery_location.facility_operation_hour.sat_close).utc().format("hh:mm A"),
             sun_open: moment(this.delivery_location.facility_operation_hour.sun_open).utc().format("hh:mm A"),
             sun_close: moment(this.delivery_location.facility_operation_hour.sun_close).utc().format("hh:mm A")
          }
        }

        console.log("UPDATE: ", update_payload)

        try {
          // this.getupdateDeliveryLocationLoading = true
          await this.updateDeliveryLocationApi(update_payload)
          await this.fetchSingleDeliveryLocation(this.defaultCustomer)
          this.notificationMessage('Delivery location updated successfully')

          if (this.comingFrom == 'dlTable') {
            this.close("refresh")
          } else {
            this.close("reload-page")
          }
        } catch (e) {
          this.notificationError(e)
        }

      }
    },
    inputChanged() {

    },
    addLogo() {
      //this.$refs.upload_logo_reference.click()
    },
    handleAddContact() {

      let getItem = this.delivery_location.contacts

      getItem.push({
        id: null,
        contact_name: "",
        phone_number: "",
        email: "",
        primary: 0,
        on_site: 0,
        role: ""
      })


      this.delivery_locatio.contacts = getItem

    },
    handleRemoveContact(index) {
      if (this.delivery_location.contacts.length > 1) {
        this.delivery_location.contacts.splice(index, 1)
      }
    },
    handlePrimaryChange(index) {
      this.delivery_location.contacts[index].primary = 1
    },
    handleChange(model, el, index) {
      if (model !== "primary" && model !== "email") {
        this.delivery_location.contacts[index][model] = el
      }
      else if (model === "email") {
        let validate = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(el)
        if (!validate) {
          this.isValidEmail = true
          this.notificationRedError('Email must be valid')
        } else {
          this.isValidEmail = false
        }
      }
      else {
        this.delivery_location.contacts[index][model] = el.target.checked
      }
    },
    handleFacilityDays() {
    },
    async setSelectedCountry(value) {
      if (value !== '' && value !== null) {
        try {
          await this.fetchStates(value)
        } catch (e) {
          console.log(e);
        }
      }
    },
    async setSelectedState(value) {
      if (value !== '' && value !== null) {
        let payload = {
          country: this.delivery_location.country,
          states: value
        }

        try {
          await this.fetchCities(payload)
        } catch (e) {
          console.log(e);
        }
      }
    },

    getImgUrl() {

      //get image url directory from po online
      //        let imageUrl = 'https://staging.shifl.com/storage/'
      //
      //        //if pic is not null and defined
      //        if (typeof pic !== 'undefined' && pic !== null) {
      //          if (this.editIndex && this.importNameData.url !== null) {
      //            return this.importNameData.url
      //          }
      //          if (pic.includes(imageUrl) !== 'undefined' && !pic.includes(imageUrl)) {
      //            //concatonate the imageurl with the pic
      //            let newImage = `${imageUrl}${pic}`
      //            return newImage
      //          } else
      //            return pic
      //        } else if (this.importNameData.url !== null) {
      //          return this.importNameData.url
      //        } else
      //          return require('../../../assets/icons/import-name-logo.svg')
    },
    closeForm() {
      this.close()
    },

  }
}
</script>

<style lang="scss">
@import '@/assets/scss/pages_scss/settings/deliveryLocations.scss';
@import '@/assets/scss/buttons.scss';

/*  .location {
        max-height: 100% !important;
      height: 100%;
      right: 0 !important;
      margin: 0%;
      position: absolute !important;
      border-radius: 0 !important;
  }
  .location-form{
      height: 100% !important;
  }
  .v-dialog > * {
      height: 100% !important;
    }*/

.dl-row+.row {
  margin-top: -12px !important;
}

.location-form {
  height: 70% !important;
  max-width: 70% !important;
  width: 70% !important;
}



.delivery-location-field {

.v-label {
  color: $shifl-neutral-n40 !important;
  font-family: "Inter-Regular", sans-serif;
  font-size: 14px;
  position: unset !important;
  overflow: unset !important;
  white-space: normal !important;
  height: 100% !important;
  padding: 4px 0 !important;
}

.v-input__control {

  .v-input__slot {

    .v-select__slot {
      min-height: 76px;
      padding-top: 4px;

      .v-select__selections {
        // height: 100% !important;
        align-self: flex-start;
        flex-wrap: wrap;
        flex-direction: column;
      }

      input {
        align-self: flex-start;
      }
    }
  }


  .v-input__append-inner {
    display: block !important;

    .v-input__icon {
      height: 24px;

      i {
        transition: none !important;
        transform: none !important;
      }


      i.mdi-menu-down::before {
        content: "" !important;
        background-image: url('../../../assets/icons/search-address.svg') !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        width: 16px !important;
        height: 18px !important;
        opacity: 0.9;
      }


    }
  }

}

}

.empty-checkbox-contact {
  /* Apply styles to hide the default checkbox */
  display: none;
}
.checkbox-label-contact {
  /* Set the background image for the label, adjust the path accordingly */
  background-image: url('/checkbox-empty-icon-1.svg');
  /* Set the width and height of the image */
  width: 16px;
  height: 16px;
  /* You can add more styling to the label as needed */
  display: inline-block;
  cursor: pointer;
}
.empty-checkbox-contact:checked + .checkbox-label-contact {
  background-image: url('/checkbox-filled-icon-1.svg');
  width: 16px;
  height: 16px;
}

.hidden-radio-for-contacts {
  /* Hide the default radio button */
  display: none;
}

.radio-label-for-contacts {
  /* Set the background image for the label */
  background-image: url('/radio-empty-2.svg');
  /* Set the width and height of the image */
  width: 18px;
  height: 18px;
  /* You can add more styling to the label as needed */
  display: inline-block;
  cursor: pointer;
}

.hidden-radio-for-contacts:checked + .radio-label-for-contacts {
  /* Change the background image when the radio button is checked */
  background-image: url('/radio-checked-2.svg');
  width: 18px;
  height: 18px;
}

@media screen and (max-width: 768px) {
  .add-user-import-name-dialog.location {
    max-height: 100% !important;
    margin: 0 !important;
    max-width: 100% !important;
    width: 100% !important;

    .v-card {
      .v-card__text {
        padding: 12px 8px !important;

        .headline-title {
          font-size: 16px;
        }

        .delivery-general-title {
          padding: 0 6px !important;
        }

        .contact-general-title {
          padding: 12px 6px 0 !important;          
        }

        .general-info-div {
          flex-direction: column;
          .first-col {
            padding-bottom: 0 !important;
          }

          .second-col {
            padding-top: 0 !important;
            .row {
              &:nth-child(2) {
                padding: 0 12px !important;
                .card-name {
                  margin-bottom: 0 !important;
                }
              }

              &:last-child {
                margin-top: 0 !important;
              }

              .mark-facility-appointment {
                margin-top: 4px !important;
                .v-input {
                  .v-input__control {
                    .v-input__slot {
                      width: 100% !important;
                      min-width: 100% !important;
                    }
                  }
                }
              }
            }
          }

          .pl-2, .pr-2 {
            padding: 0 !important;
          }
        }
        
        .contact-container {
          .delivery-loc-contact-mobile-div {
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0px 1px 2px 0px #121B2B0F,
                        0px 1px 3px 0px #121B2B1A;
            border: 1px solid $shifl-neutral-n20;
            margin-bottom: 12px;

            .delivery-mobile-field {
              border-bottom: 1px solid $shifl-neutral-n20;

              &.delivery-contact-onsite-primary {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 7px 10px;
                border-top: 1px solid $shifl-neutral-n20;
                border-bottom: none !important;

                .delivery-checkbox-radio-field {
                  display: flex;

                  .radio-label-for-contacts {
                    margin-top: 2px;
                  }
                  .checkbox-label-contact {
                    zoom: .9;
                  }
                }
              }
            }
          }

          .add-more-btn {
            border-top: none !important;
            width: 145px;
          }
        }

        .div-facility-options {
          flex-direction: column;
          margin-top: 12px !important;
          padding: 4px;
          .headline-title {
            padding: 0 6px !important;
          }
          .facility-operation-hours-wrapper {
            background-color: #fff !important;
            margin-top: 12px;
            border-radius: 4px;
            box-shadow: 0px 1px 2px 0px #121B2B0F,
                        0px 1px 3px 0px #121B2B1A;
            width: 100%;

            .facility-radio-group {
              width: 100%;
              padding: 0 12px !important;
              border-top-right-radius: 4px;
              border-top-left-radius: 4px;
              .v-input__control {
                background-color: #fff !important;

                .v-label {
                  color: $shifl-neutral-n90;
                  font-size: 14px;
                }
              }
            }
            .facility-days-container {
              width: 100%;
              padding: 4px 12px 0;
              .weeklyBasisTable {
                flex-direction: column;
                .col-6, .selectBox.col-3 {
                  width: 100%;
                  max-width: 100%;
                  padding: 0;
                }
                .selectBox.col-3 {
                  padding-bottom: 12px;

                  .dx-placeholder::before {
                    padding: 7px 12px !important;
                  }
                }
              }
              .dailyBasisTable {
                padding: 0 0 16px !important;
                margin: 0 !important;

                table {
                  thead {
                    background-color: $shifl-neutral-n20;
                    
                    &.v-data-table-header {
                      display: none;
                    }
                  }
                  tbody {
                    tr {
                      td {
                        padding: 8px !important;

                        .custom-datebox {
                          .dx-dropdowneditor-input-wrapper {
                            input {
                              padding: 0 !important;
                              font-family: "Inter-Regular", sans-serif;
                            }

                            .dx-placeholder::before {
                              padding: 7px 0;
                            }
                          }

                          .dx-texteditor-buttons-container {
                            .dx-clear-button-area {
                              width: 30px;
                              min-width: 30px;
                            }
                            .dx-dropdowneditor-button {
                              width: 23px;
                              min-width: 23px
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }  
        }      
      }
    }
  }
}
</style>