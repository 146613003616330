<template>
    <div class="calendar-tooltip-component">
        <v-tooltip 
            :content-class="`calendar-cards-tooltip tooltip-bottom ${isFor}`" 
            bottom 
            :nudge-top="!isNudge ? '0' : '50'" 
            :disabled="!isShowTooltip"
            open-delay="300"
            close-delay="0"
            transition="fade-transition">
            
            <template v-slot:activator="{ on, attrs }">
                <slot name="tooltip-main-data" :attrs="attrs" :on="on"></slot>
            </template>
            <slot name="tooltip-data"></slot>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    name: "CalendarTooltip",
    props: [
        "data",
        "isFor",
        "isShowTooltip",
        "nudge"
    ],
    data: () => ({

    }),
    computed: {
        isNudge() {
            if (typeof this.nudge !== "undefined") {
                return this.nudge
            } else {
                return true
            }
        }
    },
    methods: {},
    mounted() {}
}
</script>

<style lang="scss">
.calendar-tooltip-component {
    cursor: pointer;
    width: 100%;
}

.v-tooltip__content {
    &.calendar-cards-tooltip {
        width: 250px !important;
        background-color: #fff !important;
        padding: 5px 16px !important;
        transition: none !important;

        &.menuable__content__active {
            background-color: #fff !important;
            opacity: 1 !important;
            box-shadow: 0px 0px 1px 0px #29292929,
                0px 8px 16px 0px #29292933;
            max-width: 260px !important;
            min-width: 70px !important;

            &::before {
                display: none !important;
            }
        }

        &.tooltip-bottom {
            width: 250px !important;
            background-color: #fff !important;
            padding: 5px 16px !important;

            &::before {
                // border-right: solid 8px transparent;
                // border-left: solid 8px transparent;
                // transform: translateX(-50%);
                // position: absolute;
                // z-index: -21;
                // content: '';
                // bottom: 100%;
                // // left: 50%;
                // left: 25%;
                // height: 0;
                // width: 0;
                // border-bottom: solid 8px #fff !important;
                display: none !important;
            }
        }

        span, p, div {
            color: $shifl-neutral-n90 !important;
            font-size: 12px !important;
            font-family: "Inter-Medium", sans-serif !important;
            line-height: 18px !important;
            text-align: start !important;
            align-items: flex-start !important;
            justify-content: flex-start !important;

            &.tooltip-title {
                font-size: 12px !important;
                font-family: "Inter-Medium", sans-serif !important;
                color: $shifl-neutral-n60 !important;
                text-align: start !important;
            }
        }

        .flex-content {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .tooltip-data-info {
            margin-block: 6px !important;
        }
    }
}
</style>