<template>
    <div :class="`${(iconName==='chevron-down-radio') ? 'd-flex align-center justify-center' : ''}`" :style="`${(iconName==='chevron-right') ? 'height:10px;' : (iconName==='not-checked' || iconName==='checked') ? 'height: 16px;': ''}`">

        <svg v-if="iconName=== 'warning'" width="48" height="48" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05888 0 0 8.05887 0 18C0 27.9411 8.05888 36 18 36ZM18 33.3004C9.54981 33.3004 2.69958 26.4502 2.69958 18C2.69958 9.54981 9.54981 2.69958 18 2.69958C26.4502 2.69958 33.3004 9.54981 33.3004 18C33.3004 26.4502 26.4502 33.3004 18 33.3004ZM17.9934 20.7013C18.6768 20.7018 19.2419 20.1943 19.3317 19.5356L19.3441 19.3524L19.3506 9.45108C19.3511 8.70561 18.7472 8.10089 18.0017 8.1004C17.3183 8.09996 16.7533 8.60739 16.6635 9.26616L16.651 9.44931L16.6445 19.3506C16.6441 20.0961 17.248 20.7008 17.9934 20.7013ZM18.0008 26.9963C18.9934 26.9963 19.7981 26.1916 19.7981 25.199C19.7981 24.2064 18.9934 23.4017 18.0008 23.4017C17.0082 23.4017 16.2035 24.2064 16.2035 25.199C16.2035 26.1916 17.0082 26.9963 18.0008 26.9963Z" :fill="color"/>
        </svg>

        <svg v-if="iconName==='info-icon'" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99961 0.600098C8.98195 0.600098 11.3996 3.01776 11.3996 6.0001C11.3996 8.98244 8.98195 11.4001 5.99961 11.4001C3.01727 11.4001 0.599609 8.98244 0.599609 6.0001C0.599609 3.01776 3.01727 0.600098 5.99961 0.600098ZM5.99961 1.40997C3.46455 1.40997 1.40948 3.46504 1.40948 6.0001C1.40948 8.53515 3.46455 10.5902 5.99961 10.5902C8.53467 10.5902 10.5897 8.53515 10.5897 6.0001C10.5897 3.46504 8.53467 1.40997 5.99961 1.40997ZM5.99764 5.1897C6.20265 5.18957 6.37217 5.3418 6.39911 5.53943L6.40284 5.59437L6.40479 8.56477C6.40494 8.78841 6.22376 8.96983 6.00012 8.96998C5.79511 8.97011 5.62559 8.81788 5.59865 8.62025L5.59491 8.5653L5.59297 5.5949C5.59282 5.37126 5.774 5.18985 5.99764 5.1897ZM5.99985 3.3012C6.29764 3.3012 6.53905 3.54261 6.53905 3.8404C6.53905 4.13818 6.29764 4.37959 5.99985 4.37959C5.70206 4.37959 5.46066 4.13818 5.46066 3.8404C5.46066 3.54261 5.70206 3.3012 5.99985 3.3012Z" :fill="color" />
        </svg>
        <svg v-if="iconName==='add'" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.8002 1.20039C5.8002 0.758563 5.44202 0.400391 5.0002 0.400391C4.55837 0.400391 4.2002 0.758563 4.2002 1.20039V4.40039H1.0002C0.558367 4.40039 0.200195 4.75856 0.200195 5.20039C0.200195 5.64222 0.558367 6.00039 1.0002 6.00039H4.2002V9.20039C4.2002 9.64222 4.55837 10.0004 5.0002 10.0004C5.44202 10.0004 5.80019 9.64222 5.80019 9.20039V6.00039H9.0002C9.44202 6.00039 9.8002 5.64222 9.8002 5.20039C9.8002 4.75856 9.44202 4.40039 9.0002 4.40039H5.8002V1.20039Z" :fill="color"/>
        </svg>
        <svg v-if="iconName==='subtract'" width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.200195 1.20039C0.200195 0.758563 0.558367 0.400391 1.0002 0.400391H9.0002C9.44202 0.400391 9.8002 0.758563 9.8002 1.20039C9.8002 1.64222 9.44202 2.00039 9.0002 2.00039H1.0002C0.558367 2.00039 0.200195 1.64222 0.200195 1.20039Z" :fill="color"/>
        </svg>
        <svg v-if="iconName==='upload'" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1946 11.3996C10.4742 11.3996 10.7009 11.1714 10.7009 10.8899C10.7009 10.6319 10.5104 10.4186 10.2633 10.3849L10.1946 10.3802H2.80709C2.52746 10.3802 2.30078 10.6084 2.30078 10.8899C2.30078 11.1479 2.49125 11.3611 2.73838 11.3949L2.80709 11.3996H10.1946ZM6.52693 9.7759C6.78326 9.7759 6.9951 9.58416 7.02865 9.33538L7.03324 9.26622L7.03284 2.3392L9.03525 4.35799C9.21489 4.5391 9.49613 4.55582 9.69447 4.40795L9.75131 4.35866C9.93122 4.17788 9.94782 3.8947 9.80093 3.69504L9.75198 3.63782L6.88776 0.749186L6.84003 0.706711L6.7786 0.666889L6.75403 0.652481L6.69246 0.626724L6.61112 0.606269L6.5644 0.600901L6.52385 0.599609C6.49001 0.599609 6.45567 0.603347 6.4221 0.610482L6.3675 0.626181C6.30269 0.647248 6.24388 0.681431 6.19382 0.725468L3.29803 3.63727C3.10016 3.83619 3.09993 4.15885 3.29752 4.35806C3.47714 4.53915 3.75841 4.55579 3.95672 4.40789L4.01354 4.35858L6.02024 2.34199L6.02066 9.26622C6.02066 9.5477 6.24734 9.7759 6.52693 9.7759Z" fill="#0171A1"/>
        </svg>
        <svg v-if="iconName==='circle-check'" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22C0 9.84974 9.84974 0 22 0ZM22 3.3C11.6723 3.3 3.3 11.6723 3.3 22C3.3 32.3277 11.6723 40.7 22 40.7C32.3277 40.7 40.7 32.3277 40.7 22C40.7 11.6723 32.3277 3.3 22 3.3ZM19.25 25.1665L29.0833 15.3333C29.7276 14.6889 30.7724 14.6889 31.4167 15.3333C32.0025 15.9191 32.0558 16.8357 31.5765 17.4817L31.4167 17.6667L20.4167 28.6667C19.8309 29.2525 18.9143 29.3058 18.2683 28.8265L18.0833 28.6667L12.5833 23.1667C11.9389 22.5224 11.9389 21.4776 12.5833 20.8333C13.1691 20.2475 14.0857 20.1942 14.7317 20.6735L14.9167 20.8333L19.25 25.1665L29.0833 15.3333L19.25 25.1665Z" fill="#16B442"/>
        </svg>
        <svg :style="`margin-left:${marginLeft}px;margin-top: ${marginTop}px;`" v-if="iconName==='not-checked'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1H4C2.34315 1 1 2.34315 1 4V12C1 13.6569 2.34315 15 4 15H12C13.6569 15 15 13.6569 15 12V4C15 2.34315 13.6569 1 12 1ZM4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0H4Z" fill="#B4CFE0"/>
        </svg>
        <svg :style="`margin-left:${marginLeft}px;;margin-top: ${marginTop}px;`" v-if="iconName==='checked'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 1H12C13.6569 1 15 2.34315 15 4V12C15 13.6569 13.6569 15 12 15H4C2.34315 15 1 13.6569 1 12V4C1 2.34315 2.34315 1 4 1ZM0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4ZM4.18409 7.96226L6.45878 10.3507L11.7616 3.66918C12.0758 3.27319 12.6363 3.21969 13.0135 3.54968C13.3906 3.87967 13.4416 4.4682 13.1273 4.86419L7.20137 12.3308C6.86675 12.7525 6.25957 12.7814 5.88997 12.3933L2.92702 9.28219C2.57988 8.9177 2.57988 8.32675 2.92702 7.96226C3.27415 7.59778 3.83696 7.59778 4.18409 7.96226Z" fill="#0171A1"/>
        </svg>
        <svg  v-if="iconName==='chevron-right'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.47655 8L5.18853 11.9787C4.93163 12.217 4.93874 12.6118 5.2009 12.842C5.44504 13.0565 5.82542 13.0524 6.06423 12.8309L10.8115 8.42609C11.0628 8.19286 11.0628 7.80714 10.8115 7.57391L6.06423 3.16915C5.82542 2.94756 5.44504 2.94353 5.2009 3.15797C4.93874 3.38825 4.93163 3.78297 5.18853 4.02133L9.47655 8Z" :fill="color"/>
        </svg>

        <svg v-if="iconName==='document'" width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6532 6.56217H14.9276C15.2438 6.56217 15.5 6.80738 15.5 7.10874V17.4476C15.5 18.386 14.7038 19.1663 13.721 19.1663H2.3C1.3178 19.1663 0.5 18.386 0.5 17.4476V2.53171C0.5 1.59327 1.3178 0.833008 2.3 0.833008H8.9276C9.2438 0.833008 9.5 1.07764 9.5 1.37957V5.46103C9.5 6.06947 10.016 6.56217 10.6532 6.56217ZM4.7 15.1559H11.3C11.6312 15.1559 11.9 14.8998 11.9 14.583C11.9 14.2662 11.6312 14.0101 11.3 14.0101H4.7C4.3688 14.0101 4.1 14.2662 4.1 14.583C4.1 14.8998 4.3688 15.1559 4.7 15.1559ZM4.7 12.8643H11.3C11.6312 12.8643 11.9 12.6082 11.9 12.2913C11.9 11.9745 11.6312 11.7184 11.3 11.7184H4.7C4.3688 11.7184 4.1 11.9745 4.1 12.2913C4.1 12.6082 4.3688 12.8643 4.7 12.8643ZM4.7 10.5726H11.3C11.6312 10.5726 11.9 10.3159 11.9 9.99967C11.9 9.68342 11.6312 9.42676 11.3 9.42676H4.7C4.3688 9.42676 4.1 9.68342 4.1 9.99967C4.1 10.3159 4.3688 10.5726 4.7 10.5726ZM14.117 5.41634H11.2724C10.9562 5.41634 10.7 5.17113 10.7 4.86978V2.15358C10.7 1.93186 10.8392 1.73192 11.054 1.64827C11.267 1.56348 11.5142 1.61046 11.6774 1.76629L14.522 4.48249C14.6858 4.6389 14.735 4.87494 14.6462 5.07832C14.558 5.28343 14.3492 5.41634 14.117 5.41634Z" fill="#6D858F"/>
        </svg>

        <svg v-if="iconName==='chevron-down-radio'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 9.47655L11.9787 5.18853C12.217 4.93163 12.6118 4.93874 12.842 5.2009C13.0565 5.44504 13.0524 5.82542 12.8309 6.06423L8.42609 10.8115C8.19286 11.0628 7.80714 11.0628 7.57391 10.8115L3.16915 6.06423C2.94756 5.82542 2.94353 5.44504 3.15797 5.2009C3.38825 4.93874 3.78297 4.93163 4.02133 5.18853L8 9.47655Z" fill="#0171A1"/>
        </svg>
        <svg style="position: relative; top: 5px !important; left: 3px !important;" v-if="iconName==='chevron-up'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 1.52345L8.97867 5.81147C9.21703 6.06837 9.61175 6.06126 9.84203 5.7991C10.0565 5.55496 10.0524 5.17458 9.83085 4.93577L5.42609 0.188523C5.19286 -0.062841 4.80714 -0.062841 4.57391 0.188523L0.169146 4.93577C-0.0524368 5.17458 -0.0564694 5.55496 0.15797 5.7991C0.388249 6.06126 0.782967 6.06837 1.02133 5.81147L5 1.52345Z" :fill="color" />
        </svg>
        <svg v-if="iconName==='chevron-down'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 9.47655L11.9787 5.18853C12.217 4.93163 12.6118 4.93874 12.842 5.2009C13.0565 5.44504 13.0524 5.82542 12.8309 6.06423L8.42609 10.8115C8.19286 11.0628 7.80714 11.0628 7.57391 10.8115L3.16915 6.06423C2.94756 5.82542 2.94353 5.44504 3.15797 5.2009C3.38825 4.93874 3.78297 4.93163 4.02133 5.18853L8 9.47655Z" :fill="color" />
        </svg>
        <svg v-if="iconName==='trash-product-light'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1533 2.84737C13.3938 3.08786 13.3938 3.47779 13.1533 3.71829L8.87125 8.00032L13.1533 12.2824C13.3938 12.5229 13.3938 12.9128 13.1533 13.1533C12.9128 13.3938 12.5229 13.3938 12.2824 13.1533L8.00033 8.87125L3.71829 13.1533C3.47779 13.3938 3.08786 13.3938 2.84737 13.1533C2.60687 12.9128 2.60687 12.5229 2.84737 12.2824L7.1294 8.00032L2.84737 3.71829C2.60687 3.47779 2.60687 3.08786 2.84737 2.84737C3.08787 2.60687 3.47779 2.60687 3.71829 2.84737L8.00033 7.1294L12.2824 2.84737C12.5229 2.60687 12.9128 2.60687 13.1533 2.84737Z" :fill="color"/>
        </svg>
        <svg v-if="iconName==='trash-product'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1523 2.84737C13.3928 3.08786 13.3928 3.47779 13.1523 3.71829L8.87027 8.00032L13.1523 12.2824C13.3928 12.5229 13.3928 12.9128 13.1523 13.1533C12.9118 13.3938 12.5219 13.3938 12.2814 13.1533L7.99935 8.87125L3.71731 13.1533C3.47681 13.3938 3.08689 13.3938 2.84639 13.1533C2.60589 12.9128 2.60589 12.5229 2.84639 12.2824L7.12843 8.00032L2.84639 3.71829C2.60589 3.47779 2.60589 3.08786 2.84639 2.84737C3.08689 2.60687 3.47681 2.60687 3.71731 2.84737L7.99935 7.1294L12.2814 2.84737C12.5219 2.60687 12.9118 2.60687 13.1523 2.84737Z" :fill="color" />
        </svg>

        <svg v-if="iconName==='check-success'" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22C0 9.84974 9.84974 0 22 0ZM22 3.3C11.6723 3.3 3.3 11.6723 3.3 22C3.3 32.3277 11.6723 40.7 22 40.7C32.3277 40.7 40.7 32.3277 40.7 22C40.7 11.6723 32.3277 3.3 22 3.3ZM19.25 25.1665L29.0833 15.3333C29.7276 14.6889 30.7724 14.6889 31.4167 15.3333C32.0025 15.9191 32.0558 16.8357 31.5765 17.4817L31.4167 17.6667L20.4167 28.6667C19.8309 29.2525 18.9143 29.3058 18.2683 28.8265L18.0833 28.6667L12.5833 23.1667C11.9389 22.5224 11.9389 21.4776 12.5833 20.8333C13.1691 20.2475 14.0857 20.1942 14.7317 20.6735L14.9167 20.8333L19.25 25.1665L29.0833 15.3333L19.25 25.1665Z" fill="#16B442"/>
        </svg>
        <svg v-if="iconName==='close'" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1523 0.847366C11.3928 1.08786 11.3928 1.47779 11.1523 1.71829L6.87027 6.00032L11.1523 10.2824C11.3928 10.5229 11.3928 10.9128 11.1523 11.1533C10.9118 11.3938 10.5219 11.3938 10.2814 11.1533L5.99935 6.87125L1.71731 11.1533C1.47681 11.3938 1.08689 11.3938 0.84639 11.1533C0.605891 10.9128 0.605891 10.5229 0.84639 10.2824L5.12843 6.00032L0.84639 1.71829C0.605892 1.47779 0.605892 1.08786 0.84639 0.847366C1.08689 0.606867 1.47681 0.606868 1.71731 0.847366L5.99935 5.1294L10.2814 0.847366C10.5219 0.606868 10.9118 0.606867 11.1523 0.847366Z" fill="#F93131"/>
        </svg>
        <svg v-if="iconName==='container'" width="40" height="37" viewBox="0 0 40 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 11.4356L21.0368 0.917201C21.3649 0.753157 21.7318 0.682502 22.0974 0.712964L40 2.20485L18.4615 12.9741L0 11.4356ZM40.0001 3.7433L18.4616 14.5125V36.051L38.8945 25.8345C39.5721 25.4958 40.0001 24.8032 40.0001 24.0457V3.7433ZM16.9231 14.5125L0 12.9741V32.6861C0 33.7205 0.78877 34.5842 1.81893 34.6779L16.9231 36.051V14.5125ZM36.9233 8.35871L35.3848 9.12794V24.5126L36.9233 23.7433V8.35871ZM30.7692 11.4356L32.3077 10.6664V26.051L30.7692 26.8202V11.4356ZM27.6924 12.9741L26.154 13.7433V29.1279L27.6924 28.3587V12.9741ZM23.0769 15.2818L21.5384 16.051V31.4356L23.0769 30.6664V15.2818ZM7.69236 17.5895H9.23082V31.4356H7.69236V17.5895Z" fill="#0171A1"/>
        </svg>

        <svg v-if="iconName==='plus'" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7 1C7 0.447715 6.55229 0 6 0C5.44772 -1.19209e-07 5 0.447715 5 1V5H1C0.447715 5 0 5.44772 0 6C0 6.55229 0.447715 7 1 7H5V11C5 11.5523 5.44771 12 6 12C6.55228 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55229 12 6C12 5.44772 11.5523 5 11 5H7V1Z" :fill="color"/>
        </svg>


        <svg v-if="iconName==='supplier'" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M31.3342 23.3215C32.6227 23.3215 33.6673 24.3507 33.6673 25.6204V31.3675C33.6673 32.6371 32.6227 33.6663 31.3342 33.6663H22.0017C21.1381 33.6663 20.3841 33.204 19.9807 32.5169H5.00078C4.79454 32.5169 4.58851 32.5034 4.38407 32.4766C1.82958 32.141 0.034857 29.8285 0.375457 27.3116L0.804288 24.1426C0.993922 22.7412 2.03248 21.5959 3.42448 21.253L8.88651 19.9076C9.51155 19.7536 10.1449 20.128 10.3012 20.7439C10.4574 21.3597 10.0774 21.9838 9.45238 22.1378L3.99035 23.4832C3.52635 23.5975 3.18016 23.9793 3.11695 24.4464L2.68812 27.6154C2.51782 28.8739 3.41518 30.0301 4.69243 30.1979C4.79465 30.2113 4.89766 30.2181 5.00078 30.2181H19.6685V25.6204C19.6685 24.3507 20.7131 23.3215 22.0017 23.3215C22.0017 20.7823 24.0908 18.7238 26.6679 18.7238C29.245 18.7238 31.3342 20.7823 31.3342 23.3215ZM29.0011 23.3215C29.0011 22.0519 27.9565 21.0227 26.6679 21.0227C25.3794 21.0227 24.3348 22.0519 24.3348 23.3215H29.0011ZM20.6467 5.41262L19.9649 5.07672C19.7845 4.98785 19.5702 4.99714 19.3984 5.10128C18.3219 5.75385 17.2454 6.08013 16.1688 6.08013C15.0923 6.08013 14.0158 5.75385 12.9392 5.10128C12.7674 4.99712 12.5532 4.98784 12.3727 5.07673L11.6918 5.4122C11.2893 6.24003 11.1185 7.18879 11.2508 8.16658L12.0422 14.0145C12.318 16.0524 14.0823 17.5744 16.1688 17.5744C18.2554 17.5744 20.0197 16.0524 20.2955 14.0145L21.0868 8.16658C21.1158 7.95239 21.1304 7.73656 21.1304 7.52048C21.1304 6.76569 20.9568 6.05085 20.6467 5.41262ZM16.1688 0.333008C20.1976 0.333008 23.4635 3.55095 23.4635 7.52048C23.4635 7.83816 23.4421 8.1555 23.3995 8.4704L22.6081 14.3184C22.1778 17.4983 19.4248 19.8732 16.1688 19.8732C12.9129 19.8732 10.1599 17.4983 9.72954 14.3184L8.93818 8.4704C8.40573 4.53569 11.2114 0.920685 15.2048 0.396057C15.5243 0.35407 15.8464 0.333008 16.1688 0.333008ZM22.0017 25.6204V31.3675H31.3342V25.6204H22.0017Z" :fill="color"/>
        </svg>
    </div>
</template>
<style scoped>
</style>
<script>
export default {
    props: {
        iconName: {
          type: String,
          default: 'supplier'
        },
        color: {
            type: String,
            default: '#0171A1'
        },
        marginLeft: {
            default: 16
        },
        marginTop: {
            default: 0
        }
    }
}
</script>