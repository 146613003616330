<template>
    <div class="import-name-dialosg">
        <v-dialog v-model="dialogImport" max-width="560px" content-class="add-user-import-name-dialog" :retain-focus="false" @click:outside="close" scrollable>
            <v-card>                
                <v-card-title>
                    <span class="headline">{{nameTitle}}</span>

                    <button icon dark class="btn-close" @click="close">
                        <v-icon>mdi-close</v-icon>
                    </button>
                </v-card-title>

                <v-card-text class="import-name-card">
                    <v-form ref="form" v-model="valid" action="#" @submit.prevent="">
                        <div class="add-user-wrapper">
                            <div>
                                <v-row no-gutters>
                                    <v-col cols="2" class="add-import-image-wrapper mb-3 mr-4">
                                        <div class="image">
                                            <img :height="getImgUrl(importNameData.image) == '/img/import-name-logo.1493ffc2.svg' ? '50px' : '100%'" :width="getImgUrl(importNameData.image) == '/img/import-name-logo.1493ffc2.svg' ? '50px' : '100%'" :src="getImgUrl(importNameData.image)" alt="no" style="border-radius: 4px;">   
                                        </div>    
                                    </v-col>

                                    <v-col cols="8" class="mb-3">
                                        <div class="upload-logo">
                                            <v-btn @click="addLogo" class="btn-white custom-btn">Upload Logo</v-btn>
                                            <p class="mb-0 caption mt-2" style="color: #9CA5B4; font-family: Inter-Regular, sans-serif !important; letter-spacing: 0 !important;">Maximum file size is 5 MB</p>
                                            <p class="mb-0 caption" style="color: #9CA5B4; font-family: Inter-Regular, sans-serif !important; letter-spacing: 0 !important;">Acceptable File formats are PNG & JPG</p>
                                            <input
                                                ref="upload_logo_reference" 
                                                type="file" 
                                                id="upload_logo_import" 
                                                @change="(e) => inputChanged(e)" 
                                                name="documents[]"
                                                accept=".png,.jpg,.jpeg"
                                                multiple />
                                        </div>
                                    </v-col>

                                    <v-col cols="12">
                                        <div class="card-name mb-3">
                                            <p class="card-title">import Name</p>
                                            <v-text-field
                                                v-model="importNameData.import_name"
                                                height="40px"
                                                color="#002F44"
                                                width="200px"
                                                dense
                                                class="text-fields select-items"
                                                placeholder="Enter company name"
                                                outlined
                                                validate-on-blur
                                                :rules="[(v) => !!v || 'Input is required.']"
                                                hide-details="auto">
                                            </v-text-field>
                                        </div>
                                    </v-col>

                                    <v-col cols="12">
                                        <div class="card-name mb-3">
                                            <p class="card-title">Email Address</p>
                                            <v-text-field
                                                v-model="importNameData.email"
                                                height="40px"
                                                color="#002F44"
                                                width="200px"
                                                dense
                                                :rules="emailRules"
                                                type="email"
                                                class="text-fields select-items"
                                                placeholder="Enter email address"
                                                outlined
                                                validate-on-blur
                                                hide-details="auto">
                                            </v-text-field>
                                        </div>
                                    </v-col>

                                    <v-col cols="12">
                                        <div class="card-name import-name-v-tele-phone mb-3">
                                            <p class="card-title">Phone number</p>
                                            <vue-tel-input
                                                v-model="importNameData.phone_number"
                                                mode="international"
                                                defaultCountry="us"
                                                validCharactersOnly
                                                :autoDefaultCountry="true"
                                                :dropdownOptions="vueTelDropdownOptions"
                                                :inputOptions="vueTelInputOptions"
                                                :styleClasses="
                                                isPhoneNumberEmpty && importNameData.phone_number === ''
                                                    ? 'is-error'
                                                    : ''
                                                "
                                            >
                                            <template v-slot:arrow-icon>
                                                <v-icon class="ml-1">mdi-chevron-down</v-icon>
                                            </template>
                                            </vue-tel-input>
                            
                                            <span
                                            class="error-message"
                                            style="color: red; font-size: 12px;"
                                            v-if="isPhoneNumberEmpty"
                                            >
                                            {{ importNameData.phone_number === "" ? "Phone number is required." : "" }}
                                            </span>
                                        </div>
                                    </v-col>

                                    <v-col cols="12">
                                        <div class="card-name mb-3">
                                            <p class="card-title">address</p>
                                            <v-textarea
                                                v-model="importNameData.address"
                                                class="text-fields select-items"
                                                outlined
                                                name="input-7-4"
                                                placeholder="Enter address"
                                                rows="2"
                                                :rules="[(v) => !!v || 'Input is required.']"
                                                validate-on-blur
                                                hide-details="auto">
                                            </v-textarea>
                                        </div>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pr-2">
                                        <div class="card-name mb-3">
                                            <p class="card-title">Country</p>
                                            <v-select
                                                :items="countries"
                                                v-model="importNameData.country"
                                                height="40px"
                                                color="#002F44"
                                                width="200px"
                                                dense
                                                class="text-fields select-items"
                                                :placeholder="getCountriesLoading ? 'Fetching countries...' : 'Select country'"
                                                outlined
                                                :disabled="getCountriesLoading"
                                                :rules="[(v) => !!v || 'Input is required.']"
                                                hide-details="auto"
                                                :menu-props="{ contentClass: 'product-lists-items', bottom: true, offsetY: true }"
                                                validate-on-blur
                                                @change="setSelectedCountry">
                                            </v-select>
                                        </div>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pl-2">
                                        <div class="card-name mb-3">
                                            <p class="card-title">State</p>
                                            <v-select
                                                :items="states"
                                                v-model="importNameData.state"
                                                item-text="name"
                                                item-value="id"
                                                height="40px"
                                                color="#002F44"
                                                width="200px"
                                                dense
                                                class="text-fields select-items"
                                                :rules="[(v) => !!v || 'Input is required.']"
                                                :placeholder="getStatesLoading ? 'Fetching states...' : 'Select state'"
                                                outlined
                                                :disabled="getStatesLoading"
                                                :menu-props="{ contentClass: 'product-lists-items', bottom: true, offsetY: true }"
                                                hide-details="auto"
                                                validate-on-blur
                                                @change="setSelectedState">
                                            </v-select>
                                        </div>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pr-2 py-0 my-0">
                                        <div class="card-name mb-3">
                                            <p class="card-title">City</p>
                                            <v-select
                                                :items="cities"
                                                v-model="importNameData.city"
                                                item-text="name"
                                                item-value="id"
                                                height="40px"
                                                color="#002F44"
                                                width="200px"
                                                dense
                                                class="text-fields select-items"
                                                :rules="[(v) => !!v || 'Input is required.']"
                                                :placeholder="getCitiesLoading ? 'Fetching cities...' : 'Select city'"
                                                outlined
                                                :disabled="getCitiesLoading"
                                                :menu-props="{ contentClass: 'product-lists-items', bottom: true, offsetY: true }"
                                                validate-on-blur
                                                hide-details="auto">
                                            </v-select>
                                        </div>
                                    </v-col>
                                    
                                    <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pl-2 py-0 my-0">
                                        <div class="card-name mb-3">
                                            <p class="card-title">Zip code</p>
                                            <v-text-field
                                                v-model="importNameData.zip"
                                                height="40px"
                                                color="#002F44"
                                                width="200px"
                                                dense
                                                class="text-fields select-items"
                                                placeholder="Enter zip code"
                                                :rules="[(v) => !!v || 'Input is required.']"
                                                outlined
                                                validate-on-blur
                                                hide-details="auto">
                                            </v-text-field>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <button @click="importNameFunction" :disabled="createUpdateLoading" class="btn-blue mr-2"> 
                        {{createUpdateText}}
                    </button>

                    <button class="btn-white" @click="close" :disabled="createUpdateLoading">
                        Cancel
                    </button>
                </v-card-actions>                
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { mapGetters,mapActions } from 'vuex';
import globalMethods from "../../../utils/globalMethods";
    export default {
        props:["dialogImportName","editImportIndex","defaultItems","defaultCustomer"],
        components:{
            VueTelInput
        },
        data(){
            return {
                valid :true,
                vueTelDropdownOptions: {
                    showDialCodeInSelection: true,
                    showDialCodeInList: true,
                    showFlags: true,
                    showSearchBox: true,
                },
                vueTelInputOptions: {
                    autocomplete: false,
                    placeholder: "Type phone number",
                    styleClasses: "tel-input-class",
                    required: true,
                },
                isPhoneNumberEmpty: false,
                files:[],
                // url: null,
                rules: [(v) => !!v || "Input is required."],
                emailRules: [
                    (v) =>
                        !v ||
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                        "Email must be valid.",
                    (v) => !!v || "Email is required.",
                ],
            }
        },
        // watch: {
        //     dialogImport:function(newVal){
        //         if(newVal == true && this.editIndex){
        //            this.files = [this.importNameData.url]
        //         }
        //     }
        // },
        computed:{
            ...mapGetters({
                getCountries: 'warehouse/getCountries',
			    getCountriesLoading: 'warehouse/getCountriesLoading',
                getStates: 'warehouse/getStates',
                getStatesLoading: 'warehouse/getStatesLoading',
                getCities: 'warehouse/getCities',
                getCitiesLoading: 'warehouse/getCitiesLoading',
                getUpdateImportNameLoading:'settings/getUpdateImportNameLoading',
                getCreateImportNameLoading:'settings/getCreateImportNameLoading'
            }),
            createUpdateLoading(){
                let loading = false
                if(this.editIndex){
                    loading = this.getUpdateImportNameLoading
                }else {
                    loading = this.getCreateImportNameLoading
                }
                return loading
            },
            createUpdateText(){
                let text = ''
                if(this.editIndex){
                    if(this.getUpdateImportNameLoading){
                        text = 'Saving...'
                    }else{
                        text = 'Save Changes'
                    } 
                }else {
                    if(this.getCreateImportNameLoading){
                        text = 'Add Importing...'
                    }else{
                        text = 'Add Import Name'
                    }
                }
                return text
            },
            dialogImport: {
                get() {
                    return this.dialogImportName
                },
                set(value) {
                    this.$emit('update:dialogImportName', value)
                }
            },
            editIndex: {
                get() {
                    return this.editImportIndex
                },
                set(value) {
                    this.$emit('update:editImportIndex', value)
                }
            },
            importNameData: {
                get() {
                    return this.defaultItems
                },
                set(value) {
                    this.$emit('update:defaultItems', value)
                }
            },
            nameTitle(){
                return this.editIndex ? 'Update Import Name' : 'Add New Import Name'
            },
            countries() {
                return typeof this.getCountries !== 'undefined' && this.getCountries !== null && this.getCountries.length !== 0 ? this.getCountries : []
            },
            states() {
                return typeof this.getStates !== 'undefined' && this.getStates !== null && this.getStates.length !== 0 ? this.getStates : []
            },
            cities() {
                return typeof this.getCities !== 'undefined' && this.getCities !== null && this.getCities.length !== 0 ? this.getCities : []
            },
        },
        mounted(){},
        methods:{
            ...mapActions({
                fetchCountries: 'warehouse/fetchCountries',
                fetchStates: 'warehouse/fetchStates',
                fetchCities: 'warehouse/fetchCities',
                createImportNameApi:'settings/createImportNameApi',
                updateImportNameApi:'settings/updateImportNameApi',
                fetchAllImportNames:'settings/fetchAllImportNames'
            }),
            ...globalMethods,
            close() {
                this.$refs.form.resetValidation()
                this.isPhoneNumberEmpty = false
                this.files = []
                this.importNameData.url = null,
                this.$refs.upload_logo_reference.value = ''
                this.importNameData.phone_number = ''
                this.$emit('closeImport')
            },
            async importNameFunction(){
                this.isPhoneNumberEmpty = this.importNameData.phone_number == "" ? true : false;
                if((this.isPhoneNumberEmpty && this.importNameData.phone_number == '') || !this.$refs.form.validate()) return
                let bodyFormData = new FormData()
                let payload = {
                    import_name:this.importNameData.import_name,
                    customer_id:this.defaultCustomer,
                    country:this.importNameData.country,
                    state:this.importNameData.state,
                    city:this.importNameData.city,
                    address:this.importNameData.address,
                    phone_number:this.importNameData.phone_number.trim(),
                    zipcode:this.importNameData.zip,
                    // image:this.files[0],
                    email:this.importNameData.email
                }
                let getPayloadKeys = Object.keys(payload)
					if (getPayloadKeys.length > 0) {
					    getPayloadKeys.filter(gpk => {
						    bodyFormData.append(gpk, payload[gpk])
					    })
					}
                    if(this.files.length){
                        bodyFormData.append('image',this.files[0])
                    }
                if(this.editIndex){
                    try{
                        bodyFormData.append('_method', 'PUT')
                        let update_payload = {
                            bodyFormData,
                            id:this.importNameData.id
                        }
                        await this.updateImportNameApi(update_payload)
                        await this.fetchAllImportNames(this.defaultCustomer)
                        this.notificationMessage('Import Name updated successfully')
                        this.close()
                    }catch(e){
                        this.notificationError(e)
                    }
                }else{
                    try{
                        
                        await this.createImportNameApi(bodyFormData)
                        await this.fetchAllImportNames(this.defaultCustomer)
                        this.notificationMessage('Import Name created successfully')
                        this.close()
                    }catch(e){
                        this.notificationError(e)
                    }
                }
                
            },
            inputChanged(e) {
                const fileSize = e.target.files[0].size / 1024 / 1024;
                const fileTypeCheck = e.target.files[0].type
                if(fileSize > 5) return this.notificationError('Maximum file size exceed');
                else if(fileTypeCheck !== 'image/png' && fileTypeCheck !== 'image/jpg' && fileTypeCheck !== 'image/jpeg') return this.notificationError('Acceptable File formats are PNG & JPG')
                const preview = e.target.files[0];
                this.importNameData.url = URL.createObjectURL(preview);
                let files = this.$refs.upload_logo_reference.files;
                

                if (!files.length) {
                    return false;
                }

                for (let i = 0; i < files.length; i++) {
                    this.files = files 
                }
            },
            addLogo() {
                this.$refs.upload_logo_reference.click()
            },
            async setSelectedCountry(value) {
                if (value !== '' && value !== null) {
                    try {
                        await this.fetchStates(value)
                    } catch (e) {
                        console.log(e);
                    }
                }
            },
            async setSelectedState(value) {
                if (value !== '' && value !== null) {
                    let payload = {
                        country: this.importNameData.country,
                        states: value
                    }

                    try {
                        await this.fetchCities(payload)
                    } catch (e) {
                        console.log(e);
                    }
                }
            },
            getImgUrl(pic) {

            //get image url directory from po online
            let imageUrl = 'https://staging.shifl.com/storage/'

            //if pic is not null and defined
            if (typeof pic !== 'undefined' && pic !== null) {
                if(this.editIndex && this.importNameData.url !== null){
                    return this.importNameData.url
                }
                if (pic.includes(imageUrl) !== 'undefined' && !pic.includes(imageUrl)) {
                    //concatonate the imageurl with the pic
                    let newImage = `${imageUrl}${pic}`
                    return newImage
                } else
                    return pic
            }else if(this.importNameData.url !== null){
                return this.importNameData.url
            }
             else
                return require('../../../assets/icons/import-name-logo.svg')
            },
        }
    }
</script>

<style lang="scss">
@import '@/assets/scss/pages_scss/settings/importName.scss';
@import '@/assets/scss/buttons.scss';
</style>