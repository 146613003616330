var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
		_vm.getDetails.shipment_suppliers !== 'undefined' &&
			_vm.getDetails.shipment_suppliers !== null &&
			_vm.getDetails.shipment_suppliers.length > 0
	)?_c('div',{staticClass:"shipment-info supplier-wrapper"},[_c('v-divider',{staticClass:"shipment-info-divider"}),_c('v-container',{staticClass:"cont-fluid-wrapper",attrs:{"fluid":""}},[_c('h3',{staticClass:"supplier-title"},[_vm._v("Suppliers")]),_vm._l((_vm.getDetails.shipment_suppliers),function(item,index){return _c('v-card',{key:index,staticClass:"customCard"},[(_vm.getDetails.shipment_suppliers.length > 0)?_c('div',{staticClass:"ribbon"},[_vm._v(" "+_vm._s(_vm.getDetails.shipment_suppliers.length > 9 ? ("" + (index + 1)) : ("0" + (index + 1)))+" ")]):_vm._e(),_c('v-container',{staticClass:"container-wrapper"},[_c('v-row',{staticStyle:{"margin":"0px","margin-right":"15%"}},[_c('h3',{staticClass:"supplier-name"},[_vm._v(" "+_vm._s(_vm.getSupplierDisplayName(item.supplier_id))+" ")])]),_c('div',{staticClass:"po-wrapper"},_vm._l((_vm.poItems),function(ref,key){
	var po_number = ref.po_number;
return _c('span',{key:("po-number-" + key),staticClass:"po-num-item"},[_vm._v(" "+_vm._s("PO #" + po_number)+" ")])}),0),(!_vm.isMobile)?_c('v-row',{staticClass:"supplier-desktop-row"},[(
							item.total_cartons !== 'undefined' &&
								item.total_cartons !== null &&
								item.total_cartons !== ''
						)?_c('div',{staticClass:"col-sm-2 col-md-2 supplier-col"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("Total Cartons")]),_c('p',{staticClass:"heading-content"},[_vm._v(" "+_vm._s(item.total_cartons)+" ")])]):_vm._e(),(
							item.cbm !== 'undefined' && item.cbm !== null && item.cbm !== ''
						)?_c('div',{staticClass:"col-sm-12 col-md-2 supplier-col"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("Volume")]),_c('p',{staticClass:"heading-content"},[_vm._v(" "+_vm._s(item.cbm)+" ")])]):_vm._e(),(
							item.kg !== 'undefined' && item.kg !== null && item.kg !== ''
						)?_c('div',{staticClass:"col-sm-12 col-md-2 supplier-col"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("Weight")]),_c('p',{staticClass:"heading-content"},[_vm._v(" "+_vm._s(item.kg)+" ")])]):_vm._e(),(
							item.ams_num !== 'undefined' &&
								item.ams_num !== null &&
								item.ams_num !== ''
						)?_c('div',{staticClass:"col-sm-12 col-md-3 supplier-col"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("AMS")]),_c('p',{staticClass:"heading-content"},[_vm._v(" "+_vm._s(item.ams_num)+" ")])]):_vm._e(),(
							item.hbl_num !== 'undefined' &&
								item.hbl_num !== null &&
								item.hbl_num !== ''
						)?_c('div',{staticClass:"col-sm-12 col-md-3 supplier-col"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("HBL")]),_c('p',{staticClass:"heading-content"},[_vm._v(" "+_vm._s(item.hbl_num)+" ")])]):_vm._e(),(
							item.incoterm_id !== 'undefined' &&
								item.incoterm_id !== null &&
								item.incoterm_id !== ''
						)?_c('div',{staticClass:"col-sm-12 col-md-2 supplier-col"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("Terms")]),_c('p',{staticClass:"heading-content"},[_vm._v(" "+_vm._s(_vm.getSupplierTerms(item.incoterm_id))+" ")])]):_vm._e(),(
							item.bl_status !== 'undefined' &&
								item.bl_status !== null &&
								item.bl_status !== ''
						)?_c('div',{staticClass:"col-sm-12 col-md-2 supplier-col"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("BL Status")]),_c('p',{staticClass:"heading-content",staticStyle:{"color":"#0171A1 !important"}},[_vm._v(" "+_vm._s(item.bl_status)+" ")])]):_vm._e(),(_vm.shipment_status == 'Pending Approval')?_c('div',{staticClass:"col-sm-12 col-md-3 supplier-col"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("Cargo Ready Date")]),_c('p',{staticClass:"heading-content"},[_vm._v(" "+_vm._s(_vm.date(item.cargo_ready_date))+" ")])]):_vm._e(),(
							item.product_description !== 'undefined' &&
								item.product_description !== null &&
								item.product_description !== ''
						)?_c('div',{staticClass:"col-sm-12 col-md-8 supplier-col"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("Product Description")]),_c('p',{staticClass:"heading-content"},[_vm._v(" "+_vm._s(_vm.productDescription ? _vm.productDescription : _vm.getProductDescription(item.supplier_id))+" ")])]):_vm._e()]):_vm._e(),(_vm.isMobile)?_c('v-row',{style:(item.po_num !== ''
							? 'padding: 10px 0 15px;'
							: 'padding: 0 0 15px;')},[(
							(item.total_cartons !== 'undefined' &&
								item.total_cartons !== null &&
								item.total_cartons !== '') ||
								(item.cbm !== 'undefined' &&
									item.cbm !== null &&
									item.cbm !== '')
						)?_c('div',{staticClass:"mobile-supplier-info-wrapper"},[(
								item.total_cartons !== 'undefined' &&
									item.total_cartons !== null &&
									item.total_cartons !== ''
							)?_c('div',{staticClass:"mobile-supplier-info"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("Total Cartons")]),_c('p',{staticClass:"heading-content"},[_vm._v(" "+_vm._s(item.total_cartons)+" ")])]):_vm._e(),(
								item.cbm !== 'undefined' &&
									item.cbm !== null &&
									item.cbm !== ''
							)?_c('div',{staticClass:"mobile-supplier-info"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("Volume")]),_c('p',{staticClass:"heading-content"},[_vm._v(" "+_vm._s(item.cbm)+" ")])]):_vm._e()]):_vm._e(),(
							(item.kg !== 'undefined' &&
								item.kg !== null &&
								item.kg !== '') ||
								(item.ams_num !== 'undefined' &&
									item.ams_num !== null &&
									item.ams_num !== '')
						)?_c('div',{staticClass:"mobile-supplier-info-wrapper"},[(
								item.kg !== 'undefined' && item.kg !== null && item.kg !== ''
							)?_c('div',{staticClass:"mobile-supplier-info"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("Weight")]),_c('p',{staticClass:"heading-content"},[_vm._v(" "+_vm._s(item.kg)+" ")])]):_vm._e(),(
								item.ams_num !== 'undefined' &&
									item.ams_num !== null &&
									item.ams_num !== ''
							)?_c('div',{staticClass:"mobile-supplier-info"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("AMS")]),_c('p',{staticClass:"heading-content"},[_vm._v(" "+_vm._s(item.ams_num)+" ")])]):_vm._e()]):_vm._e(),(
							(item.hbl_num !== 'undefined' &&
								item.hbl_num !== null &&
								item.hbl_num !== '') ||
								(item.incoterm_id !== 'undefined' &&
									item.incoterm_id !== null &&
									item.incoterm_id !== '')
						)?_c('div',{staticClass:"mobile-supplier-info-wrapper"},[(
								item.hbl_num !== 'undefined' &&
									item.hbl_num !== null &&
									item.hbl_num !== ''
							)?_c('div',{staticClass:"mobile-supplier-info"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("HBL")]),_c('p',{staticClass:"heading-content"},[_vm._v(" "+_vm._s(item.hbl_num)+" ")])]):_vm._e(),(
								item.incoterm_id !== 'undefined' &&
									item.incoterm_id !== null &&
									item.incoterm_id !== ''
							)?_c('div',{staticClass:"mobile-supplier-info"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("Terms")]),_c('p',{staticClass:"heading-content"},[_vm._v(" "+_vm._s(_vm.getSupplierTerms(item.incoterm_id))+" ")])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"mobile-supplier-info-wrapper"},[(
								item.bl_status !== 'undefined' &&
									item.bl_status !== null &&
									item.bl_status !== ''
							)?_c('div',{staticClass:"mobile-supplier-full"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("BL Status")]),_c('p',{staticClass:"heading-content",staticStyle:{"color":"#0171A1 !important"}},[_vm._v(" "+_vm._s(item.bl_status)+" ")])]):_vm._e()]),_c('div',{staticClass:"mobile-supplier-info-wrapper"},[(
								item.product_description !== 'undefined' &&
									item.product_description !== null &&
									item.product_description !== ''
							)?_c('div',{staticClass:"mobile-supplier-full"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("Product Description")]),_c('p',{staticClass:"heading-content-desc"},[_vm._v(" "+_vm._s(_vm.productDescription)+" ")])]):_vm._e()]),(_vm.shipment_status == 'Pending Approval')?_c('div',{staticClass:"mobile-supplier-info-wrapper"},[_c('div',{staticClass:"mobile-supplier-full"},[_c('p',{staticClass:"heading-supplier"},[_vm._v("Cargo Ready Date")]),_c('p',{staticClass:"heading-content-desc"},[_vm._v(" "+_vm._s(_vm.date(item.cargo_ready_date))+" ")])])]):_vm._e()]):_vm._e()],1)],1)})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }