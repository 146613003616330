import { calculateFilterExpressionFunction } from './compactViewMethods'
import moment from "moment";

// calculate filter expressions
export function calculateFilterExpressionReference(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "contains", target, 'shifl_ref', this
    )
}

export function calculateFilterExpressionDepature(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "contains", target, 'location_from_name', this
    )
}

export function calculateFilterExpressionArrival(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "contains", target, 'location_to_name', this
    )
}

export function calculateFilterExpressionContQty(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "contains", target, 'containers_qty', this
    )
}

export function calculateFilterExpressionType(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "contains", target, 'shipment_type', this
    )
}

export function calculateFilterExpressionMode(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "contains", target, 'mode', this
    )
}

export function calculateFilterExpressionStatus(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "contains", target, 'shipment_status', this
    )
}

export function calculateFilterExpressionPO(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "contains", target, 'po_num', this
    )
}

export function calculateFilterExpressionETD(filterValue, selectedFilterOperations, target) {
    let convertValue = moment(filterValue).format("YYYY-MM-DD");
    return calculateFilterExpressionFunction(
        convertValue, "equals", target, 'etd', this
    )
}

export function calculateFilterExpressionETA(filterValue, selectedFilterOperations, target) {
    let convertValue = moment(filterValue).format("YYYY-MM-DD");
    return calculateFilterExpressionFunction(
        convertValue, "equals", target, 'eta', this
    )
}