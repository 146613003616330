<template>
    <div class="calendar-view-wrapper-top" style="height: 100%;">
        <div class="no-data-preloader calendar pb-5" v-if="isFetchingData">
            <v-progress-circular
                :size="30"
                color="#1A6D9E"
                indeterminate
                v-if="isFetchingData">
            </v-progress-circular>
        </div>
        
        <v-sheet class="calendar-view-wrapper" v-if="type === 'week'">
            <div class="calendar-view-content" style="height: 100%;">
                <v-row class="calendar-content-row-wrapper ma-0">
                    <v-col v-for="(col, i) in weekdays" :key="i" class="weekday-data text-start">
                        <div class="weekdays-name-wrapper">
                            <div class="weekday-names-content">
                                <span>{{ col }}</span>
                                <span class="ml-1">{{ formatDateWeekly(datesInRageSelected[i]) }}</span>
                            </div>
                            <div class="weekday-badges">
                                <span>{{ getShipmentsByDateLength(datesInRageSelected[i]) }}</span>
                            </div>
                        </div>

                        <div class="calendar-content-row content-row ma-0">
                            <div class="weekday-contents">
                                <div v-if="
                                    typeof calendarWeeklyData[datesInRageSelected[i]] !== 'undefined' && 
                                    calendarWeeklyData[datesInRageSelected[i]]">

                                    <div class="event-info mb-2"
                                        v-for="(item, index) in calendarWeeklyData[datesInRageSelected[i]]" 
                                        :key="index" :class="item.className"
                                        :style="`background-color: ${item.bgColor}`"
                                        @mouseenter="detectMouseOverWeekly(item, item.id)"
                                        @click="handleClick(item)">

                                        <CalendarTooltip :data="item" isFor="event-info-inner-content" :isShowTooltip.sync="isTruncatedText">
                                            <template slot="tooltip-main-data" slot-scope="{ on, attrs }">
                                                <div class="event-info-inner-content mb-1" v-on="on" v-bind="attrs">
                                                    <div class="event-content-wrapper container-num font-medium mb-1" 
                                                        v-if="item.className !== 'eta' && item.className !== 'etd'">
                                                        <div class="event-content-wrapper-info">
                                                            <img class="mr-1"
                                                                src="@/assets/images/big-container-po.svg" 
                                                                alt="container" 
                                                                width="15px" 
                                                                height="15px">
                                                            <p>{{ item.current_container.container_number }}</p>
                                                        </div>
                                                    </div>

                                                    <div class="event-po subheader appt mb-0 text-start" 
                                                        v-if="item.className === 'appointment'">
                                                        <span style="color: #69758C;" class="font-semi-bold">APPT @
                                                            {{ formatTime(item.current_container) }}
                                                        </span>
                                                    </div>

                                                    <p class="event-po mb-0" :class="!checkItemDisplay(item) ? 'subheader' : ''"  :id="`po-element-${item.id}`">
                                                        <span style="color: #69758C;" class="font-semi-bold">PO: </span>
                                                        {{ item.po_text !== "" ? item.po_text : "N/A" }}<span class="font-semi-bold po-other-items" v-if="item.po_num.length > 2">, +{{ (item.po_num.length - 2) }}</span>
                                                    </p>

                                                    <div class="event-content-wrapper supplier font-medium" v-if="checkItemDisplay(item)">
                                                        <div class="event-content-wrapper-info">
                                                            <img class="mr-1"
                                                                src="@/assets/icons/shipment-view-icons/calendar-card-icons/suppliers-gray.svg" 
                                                                alt="supplier" 
                                                                width="14px" 
                                                                height="14px"
                                                                style="margin-top: 1px;">
                                                            <p :id="`supplier-element-${item.id}`">
                                                                {{ item.suppliers_names.length > 0 
                                                                    ? item.suppliers_names.join(", ") 
                                                                    : "N/A" 
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="event-content-wrapper note font-medium">
                                                        <div class="event-content-wrapper-info">
                                                            <img class="mr-1"
                                                                src="@/assets/icons/shipment-view-icons/calendar-card-icons/note-gray.svg" 
                                                                alt="note" 
                                                                width="14px" 
                                                                height="14px"
                                                                style="margin-top: 2px;">
                                                            <p :id="`desc-element-${item.id}`">
                                                                {{ 
                                                                    !checkIfFieldIsEmpty(item, 'shipment_suppliers') 
                                                                    ? getShipmentProductDescription(item.shipment_suppliers) 
                                                                    : "N/A"
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="event-content-wrapper map font-medium">
                                                        <div class="event-content-wrapper-info">
                                                            <img class="mr-1"
                                                                src="@/assets/icons/shipment-view-icons/calendar-card-icons/map-pin-gray.svg" 
                                                                alt="pin" 
                                                                width="14px" 
                                                                height="14px"
                                                                style="margin-top: 1px;">
                                                            <p :id="`address-element-${item.id}`">
                                                                {{ item.location_to_name }}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="event-status-wrapper" :class="getShipmentStatusClass(item.status)">
                                                        <div class="event-status" :class="`${selectedCalendarOption === 'color' ? 'color' : ''}`">
                                                            <!-- <p class="calendar-weekly-status" v-if="item.status === 'In transit - hold'">
                                                                <span class="def-text">In Transit</span>
                                                                <span class="red-text pl-1">- Hold</span>
                                                            </p>

                                                            <p class="calendar-weekly-status" v-else-if="item.status === 'In transit - released'">
                                                                <span class="def-text">In Transit</span>
                                                                <span class="green-text pl-1">- Released</span>
                                                            </p>

                                                            <p class="calendar-weekly-status" v-else-if="item.status === 'Partially discharged - released'">
                                                                <span class="def-text">Partially Discharged</span>
                                                                <span class="green-text pl-1">- Released</span>
                                                            </p>

                                                            <p class="calendar-weekly-status" v-else-if="item.status === 'Partially discharged - hold'">
                                                                <span class="def-text">Partially Discharged</span>
                                                                <span class="red-text pl-1">- Hold</span>
                                                            </p> -->

                                                            <p class="calendar-weekly-status">
                                                                {{ ucFirst(item.status.toLowerCase()) }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>

                                            <template slot="tooltip-data">
                                                <div class="tooltip-data-contents">
                                                    <div class="tooltip-data-info-po mt-1" 
                                                        :class="(checkFieldLength(item, 'po_num') < 28) ? 'flex-content' : ''">
                                                        <p class="tooltip-title mb-0">PO: </p>
                                                        <p class="mb-0" v-if="item.po_num.length > 0" 
                                                            :class="(checkFieldLength(item, 'po_num') < 28) ? 'pl-1' : ''">
                                                            <span class="po-content" v-for="(po, i) in item.po_num" :key="i">
                                                                {{ po }}<template v-if="i + 1 < item.po_num.length">, </template>
                                                            </span>
                                                        </p>
                                                        <p v-else class="mb-0 pl-1">N/A</p>
                                                    </div>

                                                    <div class="tooltip-data-info" v-if="isApptAndLfd(item)"
                                                        :class="(checkFieldLength(item, 'suppliers_names') < 25) ? 'flex-content' : ''">
                                                        <p class="tooltip-title mb-0">Vendor: </p>
                                                        <p class="mb-0" :class="(checkFieldLength(item, 'suppliers_names') < 25) ? 'pl-1' : ''"> 
                                                            {{ 
                                                                item.suppliers_names.join(", ") !== "" 
                                                                ? item.suppliers_names.join(", ") : "N/A"
                                                            }} 
                                                        </p>
                                                    </div>

                                                    <div class="tooltip-data-info"
                                                        :class="(checkFieldLength(item, 'shipment_suppliers') < 20 )? 'flex-content' : ''">
                                                        <span class="tooltip-title">Description: <br/></span>
                                                        <p class="mb-0" :class="(checkFieldLength(item, 'shipment_suppliers') < 20 )? 'pl-1' : ''">
                                                            {{ 
                                                                getShipmentProductDescription(item.shipment_suppliers) !== "" 
                                                                ? getShipmentProductDescription(item.shipment_suppliers) : "N/A"
                                                            }}
                                                        </p>
                                                    </div>

                                                    <div class="tooltip-data-info"
                                                        :class="(checkFieldLength(item, 'location_to_name') < 15) ? 'flex-content' : ''">
                                                        <span class="tooltip-title">Delivery Address:</span>
                                                        <p class="mb-1" :class="(checkFieldLength(item, 'location_to_name') < 15 )? 'pl-1' : ''">
                                                            {{ item.location_to_name }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </template>
                                        </CalendarTooltip>
                                    </div>
                                </div>

                                <div class="no-shipments-wrapper" 
                                    v-else-if="!isFetchingData && calendarWeeklyData[datesInRageSelected[i]] === undefined">
                                    <div class="no-data-heading pt-1">
                                        <p class="mb-0" style="color: #9CA5B4;"> No Shipments </p>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-sheet>

        <v-sheet class="calendar-view-wrapper monthly" v-if="type === 'month'">
            <v-calendar
                ref="calendarReference"
                v-model="focus"
                color="primary"
                :events="calendarMonthlyData"
                :type="type"
                :event-color="getEventColor"
                @change="updateRange"
                :event-more="false"
                :short-weekdays="false"
                :show-month-on-first="false"
                :start="getStartDate">

                <template v-slot:event="{ event }">
                    <div class="event-wrapper-custom mb-1" 
                        :style="`background-color: ${event.bgColor}; border-left: 3px solid ${event.borderColor}`"
                        @mouseenter="detectMouseOverMonthly(event, event.id)"
                        @click="handleClick(event)"> <!-- :class="event.className"  -->

                        <CalendarTooltip :data="event" isFor="event-info" :isShowTooltip.sync="isTruncatedText">
                            <template slot="tooltip-main-data" slot-scope="{ on, attrs }">
                                <div class="event-info" v-on="on" v-bind="attrs">
                                    <div class="event-po mb-0 d-flex align-center">
                                        <span style="color: #69758C;" class="font-semi-bold">PO: </span>
                                        <div class="event-po-inner-wrapper font-semi-bold" :id="`month-po-element-${event.id}`">
                                            {{ event.po_text !== "" ? event.po_text : "N/A" }}<span class="font-semi-bold po-other-items" v-if="event.po_num.length > 2">, +{{ (event.po_num.length - 2) }}</span>
                                        </div>
                                    </div>

                                    <p class="mb-0 font-semi-bold" :id="`month-address-element-${event.id}`">
                                        {{ event.location_to_name !== null ? event.location_to_name : 'N/A' }} 
                                    </p>
                                </div>
                            </template>
                            <template slot="tooltip-data">
                                <div class="tooltip-data-destination mb-2 mt-1">
                                    <div class="tooltip-data-po mt-1">
                                        <div class="row">
                                            <span class="tooltip-title font-semi-bold col-2 pb-0 pr-0">PO: </span>
                                            <span class="col-10 pl-0">
                                                {{ event.po_num.join(", ") }}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="tooltip-data-destination mb-2">
                                    <span class="font-semi-bold tooltip-title">Destination:</span>
                                    <p class="mb-0 font-medium" style="font-size: 11px;">
                                        {{ event.location_to_name !== null ? event.location_to_name : 'N/A' }} 
                                    </p>
                                </div>
                            </template>
                        </CalendarTooltip>
                    </div>
                </template>

                <template v-slot:day="{ date }">
                    <div v-if="Object.keys(currentDayDataForMonthly).length !== 0 && !isFetchingData">
                        <div class="no-shipments-wrapper" v-if="currentDayDataForMonthly[date] === undefined">
                            <div class="no-data-heading pt-1">
                                <p class="mb-0" style="color: #9CA5B4; font-size: 12px !important;"> 
                                    No Shipments 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="no-shipments-wrapper" 
                        v-else-if="Object.keys(currentDayDataForMonthly).length === 0 && !isFetchingData">
                        <div class="no-data-heading pt-1">
                            <p class="mb-0" style="color: #9CA5B4; font-size: 12px !important;"> 
                                No Shipments 
                            </p>
                        </div>
                    </div>
                </template>

                <template v-slot:day-label="{ day }">
                    <span class="monthly-day-label font-medium">
                        {{ getDayLabel(day) }}
                    </span>            
                </template>
            </v-calendar>
            
            <!-- <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
                <v-card color="grey lighten-4" min-width="350px" flat>
                    <v-toolbar :color="selectedEvent.color" dark>
                        <v-btn icon>
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>

                        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>                            
                        <v-spacer></v-spacer>

                        <v-btn icon>
                            <v-icon>mdi-heart</v-icon>
                        </v-btn>
                        <v-btn icon>
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text>
                        <span v-html="selectedEvent.details"></span>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn text color="secondary" @click="selectedOpen = false">
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu> -->
        </v-sheet>

        <div v-if="isCalendarFilterSelectionEmpty && !isFetchingData" class="no-shipment-overlay">
            <div class="no-shipments-wrapper">
                <div class="no-data-icon">
                    <img src="@/assets/icons/icon-delete.svg" alt="" width="45px">
                </div>

                <div class="no-data-heading pt-3">
                    <p class="font-semi-bold mb-1" style="color: #253041; font-size: 16px !important;"> 
                        No Shipment
                    </p>
                    
                    <p class="font-regular" style="color: #69758C; font-size: 14px !important;"> 
                        Please select at least one date type under the ‘Show by’ filter to see shipment data
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import globalMethods from '@/utils/globalMethods'
import moment from 'moment';

const baseURL = process.env.VUE_APP_BASE_URL;
import axios from "axios";
import _ from "lodash";
import CalendarTooltip from './components/CalendarTooltip.vue';
import { shipmentStatusesLists } from "../js/statusLists";

export default {
    props: [
        'activeTab', 
        'isMobile', 
        'search', 
        'currentCalendarType', 
        'currentDateCalendar', 
        'tab', 
        'selectedDefaultFilter', 
        'selectedCalendarOption',
        'isAllSelected',
        'isCalendarFilterSelectionEmpty',
        'pendingShipmentTab'
    ],
    components: {
        CalendarTooltip
    },
    data: () => ({
        windowWidth: window.innerWidth,
        focus: '',
        typeToLabel: {
            month: 'Month',
            week: 'Week',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        weekdays: ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'],
        datesInRageSelected: [],
        calendarViewDataSource: [],
        calendarViewDataSourceCopy: [],
        isFetchingData: false,
        filterClass: '',
        bgColors: {
            defaultBgColor: "#FFFFFF",
            etdColored: "#F5F0FC",
            etaColored: "#E6F6FB",
            lfdColored: "#FFF7EB",
            apptColored: "#FAE6FB",
            returnLfdColored: "#FFF0F0"
        },
        borderColors: {
            etdColored: "#3D03DC",
            etaColored: "#03A2DC",
            lfdColored: "#DC6B03",
            apptColored: "#CE03DC",
            returnLfdColored: "#EF4444"
        },
        currentDayDataForMonthly: {},
        request: null,
        isTruncatedText: false
    }),
    computed: {
        ...mapGetters([
            "getAllShipmentsCalendarView"
        ]),
        type: {
            get() {
                let currentType = "week"

                if (this.currentCalendarType !== null) {
                    currentType = this.currentCalendarType === 0 ? "week" : "month"
                }

                return currentType
            },
            set(v) {
                let type = v === "week" ? 0 : 1;
                this.$emit("update:currentCalendarType", type)
            }
        },
        getStartDate() {
            if (this.currentDateCalendar !== "" && this.currentDateCalendar !== null) {
                return moment(this.currentDateCalendar).format("YYYY-MM-DD")
            } else {
                let currentDate = moment().format()
                return moment(currentDate).format("YYYY-MM-DD")
            }
        },
        selectedFilter: {
            get() {
                return this.selectedDefaultFilter;
            },
            set(v) {
                this.$emit("update:selectedDefaultFilter", v)
            }
        },
        calendarMonthlyData() {
            let data = [];
            let shipmentsArray = this.calendarViewDataSource;
            let startOfMonth = moment(this.currentDateCalendar).startOf('month').format("YYYY-MM-DD");
            let endOfMonth = moment(this.currentDateCalendar).endOf('month').format("YYYY-MM-DD");
            let allDays = this.calculateMonthDays(startOfMonth, endOfMonth);

            let { defaultBgColor, etdColored, etaColored, lfdColored, apptColored, returnLfdColored } = this.bgColors;
            let assignedColors = {
                etd: this.selectedCalendarOption === "color" ? etdColored : defaultBgColor,
                eta: this.selectedCalendarOption === "color" ? etaColored : defaultBgColor,
                pickup_lfd: this.selectedCalendarOption === "color" ? lfdColored : defaultBgColor,
                appointment: this.selectedCalendarOption === "color" ? apptColored : defaultBgColor,
                return_lfd: this.selectedCalendarOption === "color" ? returnLfdColored : defaultBgColor,
            }
            
            if (shipmentsArray.length > 0) {
                for (let index = 0; index < allDays.length; index++) {
                    shipmentsArray.forEach(shipment => {
                        shipment.etd = shipment.etd !== null ? moment(shipment.etd).format("YYYY-MM-DD") : "";
                        shipment.eta = shipment.eta !== null ? moment(shipment.eta).format("YYYY-MM-DD") : "";
                        shipment.po_text = "";
                        let containerLists = shipment.containers;

                        if (shipment.po_num !== null && shipment.po_num.length > 0) {
                            shipment.po_text = shipment.po_num.join(", ");
                            if (shipment.po_num.length > 2) {
                                let newArr = [];

                                for (let index = 0; index < 2; index++) {
                                    newArr.push(shipment.po_num[index])                                    
                                }

                                shipment.po_text = newArr.join(", ");
                            } else {
                                shipment.po_text = shipment.po_num.join(", ");
                            }
                        }

                        this.selectedFilter.map(sf => {
                            if (sf.value !== "pickup_lfd" && sf.value !== "appointment" && sf.value !== "return_lfd") {
                                let date = shipment[sf.value];
                                let findId = _.findIndex(data, e => (e.id === shipment.id && e.className === sf.value));
                                shipment.className = sf.value;
                                shipment.bgColor = assignedColors[sf.value];
                                shipment.borderColor = this.borderColors[sf.value + 'Colored'];
                                
                                if (findId === -1) {
                                    data.push({
                                        name: `${shipment.shifl_ref}`,
                                        start: new Date(date),
                                        end: null,
                                        timed: true,
                                        color: '',
                                        className: sf.value,
                                        ...shipment
                                    })
                                }
                            } else {
                                if (containerLists.length > 0) {
                                    containerLists.map(v => {
                                        let newData = { 
                                            name: `${shipment.shifl_ref}`,
                                            start: null,
                                            end: null,
                                            timed: true,
                                            color: '',
                                            className: "",
                                            bgColor: "",
                                            current_container: v,
                                            ...shipment
                                        }

                                        if (sf.value === "pickup_lfd") {
                                            let lfd = v.pickup_lfd !== "" ? moment(v.pickup_lfd).format("YYYY-MM-DD") : "";
                                            newData.className = "pickup_lfd";
                                            newData.bgColor = assignedColors["pickup_lfd"];
                                            newData.borderColor = this.borderColors.lfdColored;
                                            newData.start = new Date(lfd);

                                            if (lfd !== "" && allDays[index] === lfd) {
                                                data.push(newData)
                                            }
                                        } 
                                        
                                        if (sf.value === "appointment") {
                                            let appt = v.appointment !== "" ? moment(v.appointment).format("YYYY-MM-DD") : "";
                                            newData.className = "appointment";
                                            newData.bgColor = assignedColors["appointment"];
                                            newData.borderColor = this.borderColors.apptColored;
                                            newData.start = new Date(appt);

                                            if (appt !== "" && allDays[index] === appt) {
                                                data.push(newData)
                                            }
                                        } 

                                        if (sf.value === "return_lfd") {
                                            let fullOut = v.full_out !== null ? moment(v.full_out).format("YYYY-MM-DD") : "";
                                            let emptyP = v.empty_in !== null ? moment(v.empty_in).format("YYYY-MM-DD") : "";
                                            newData.className = "return_lfd";
                                            newData.bgColor = assignedColors["return_lfd"];
                                            newData.borderColor = this.borderColors.returnLfdColored;
                                            
                                            if (fullOut !== "" && allDays[index] === fullOut) {
                                                newData.start = new Date(fullOut);
                                                data.push(newData)
                                            }

                                            if (emptyP !== "" && allDays[index] === emptyP) {
                                                newData.start = new Date(emptyP);
                                                data.push(newData)
                                            }
                                        }
                                        
                                        // if (sf.value === "return_lfd" && 
                                        //     (shipment.status === "Full Out" || shipment.status === "Full Out Partial")) {
                                        //     // let rlfd = v.return_lfd !== "" ? moment(v.return_lfd).format("YYYY-MM-DD") : "";
                                        //     // newData.className = "return_lfd";
                                        //     // newData.bgColor = assignedColors["return_lfd"];
                                        //     // newData.start = new Date(rlfd);

                                        //     // if (rlfd !== "" && allDays[index] === rlfd) {
                                        //     //     data.push(newData)
                                        //     // }

                                        //     let fullOut = v.full_out !== "" && v.full_out !== null 
                                        //         ? moment(v.full_out).format("YYYY-MM-DD") : "";
                                        //     newData.className = "return_lfd";
                                        //     newData.bgColor = assignedColors["return_lfd"];
                                        //     newData.borderColor = this.borderColors.returnLfdColored;
                                        //     newData.start = new Date(fullOut);

                                        //     if (fullOut !== "" && allDays[index] === fullOut) {
                                        //         data.push(newData)
                                        //     }
                                        // }

                                        // if (sf.value === "return_lfd" && shipment.status === "Empty In Partial") {
                                        //     let emptyP = v.empty_in !== "" && v.empty_in !== null 
                                        //         ? moment(v.empty_in).format("YYYY-MM-DD") : "";
                                        //     newData.bgColor = assignedColors["return_lfd"];
                                        //     newData.borderColor = this.borderColors.returnLfdColored;
                                        //     newData.start = new Date(emptyP);

                                        //     if (emptyP !== "" && allDays[index] === emptyP) {
                                        //         data.push(newData)
                                        //     }
                                        // }
                                    })
                                }
                            }
                        })
                    });
                }
            }

            return data
        },
        calendarWeeklyData() {
            let dateRange = this.datesInRageSelected
            let shipmentsArray = this.calendarViewDataSource
            let finalObj = {}

            let { defaultBgColor, etdColored, etaColored, lfdColored, apptColored, returnLfdColored } = this.bgColors;
            let assignedColors = {
                etd: this.selectedCalendarOption === "color" ? etdColored : defaultBgColor,
                eta: this.selectedCalendarOption === "color" ? etaColored : defaultBgColor,
                pickup_lfd: this.selectedCalendarOption === "color" ? lfdColored : defaultBgColor,
                appointment: this.selectedCalendarOption === "color" ? apptColored : defaultBgColor,
                return_lfd: this.selectedCalendarOption === "color" ? returnLfdColored : defaultBgColor,
            }

            for (let index = 0; index < dateRange.length; index++) {
                shipmentsArray.forEach(shipment => {
                    shipment.etd = shipment.etd !== null ? moment(shipment.etd).format("YYYY-MM-DD") : "";
                    shipment.eta = shipment.eta !== null ? moment(shipment.eta).format("YYYY-MM-DD") : "";
                    shipment.po_text = "";
                    let containerLists = shipment.containers;

                    if (shipment.po_num !== null && shipment.po_num.length > 0) {
                        shipment.po_text = shipment.po_num.join(", ");
                        if (shipment.po_num.length > 2) {
                            let newArr = [];

                            for (let index = 0; index < 2; index++) {
                                newArr.push(shipment.po_num[index])                                    
                            }

                            shipment.po_text = newArr.join(", ");
                        } else {
                            shipment.po_text = shipment.po_num.join(", ");
                        }
                    }

                    this.selectedFilter.map(sf => {
                        if (sf.value !== "pickup_lfd" && sf.value !== "appointment" && sf.value !== "return_lfd") {
                            let newData = { ...shipment }

                            if (sf.value === "etd") {
                                if (shipment.etd !== "" && dateRange[index] === shipment.etd) {
                                    newData.className = "etd";
                                    newData.bgColor = assignedColors["etd"];

                                    if (finalObj[shipment.etd]) {
                                        finalObj[shipment.etd].push(newData);
                                    } else {
                                        finalObj[shipment.etd] = [newData];
                                    }
                                }
                            } 
                            
                            if (sf.value === "eta") {
                                if (shipment.eta !== "" && dateRange[index] === shipment.eta) {
                                    newData.className = "eta";
                                    newData.bgColor = assignedColors["eta"];

                                    if (finalObj[shipment.eta]) {
                                        finalObj[shipment.eta].push(newData);
                                    } else {
                                        finalObj[shipment.eta] = [newData];
                                    }
                                }
                            } 
                        } else {
                            if (containerLists.length > 0) {
                                containerLists.map(v => {
                                    let newData = { current_container: v, ...shipment }

                                    if (sf.value === "pickup_lfd") {
                                        let lfd = v.pickup_lfd !== "" ? moment(v.pickup_lfd).format("YYYY-MM-DD") : "";
                                        if (lfd !== "" && dateRange[index] === lfd) {
                                            newData.className = "pickup_lfd";
                                            newData.bgColor = assignedColors["pickup_lfd"];

                                            if (finalObj[lfd]) {
                                                finalObj[lfd].push(newData);
                                            } else {
                                                finalObj[lfd] = [newData];
                                            }
                                        }
                                    } 
                                    
                                    if (sf.value === "appointment") {
                                        let appt = v.appointment !== null ? moment(v.appointment).format("YYYY-MM-DD") : "";
                                        if (appt !== "" && dateRange[index] === appt) {
                                            newData.className = "appointment";
                                            newData.bgColor = assignedColors["appointment"];

                                            if (finalObj[appt]) {
                                                finalObj[appt].push(newData);
                                            } else {
                                                finalObj[appt] = [newData];
                                            }
                                        }
                                    } 
                                    
                                    if (sf.value === "return_lfd") {
                                        let fullOut = v.full_out !== null ? moment(v.full_out).format("YYYY-MM-DD") : "";
                                        let emptyP = v.empty_in !== null ? moment(v.empty_in).format("YYYY-MM-DD") : "";
                                        newData.className = "return_lfd";
                                        newData.bgColor = assignedColors["return_lfd"];
                                        
                                        if (fullOut !== "" && dateRange[index] === fullOut) {
                                            if (finalObj[fullOut]) {
                                                finalObj[fullOut].push(newData);
                                            } else {
                                                finalObj[fullOut] = [newData];
                                            }
                                        }

                                        if (emptyP !== "" && dateRange[index] === emptyP) {
                                            if (finalObj[emptyP]) {
                                                finalObj[emptyP].push(newData);
                                            } else {
                                                finalObj[emptyP] = [newData];
                                            }
                                        }
                                    }
                                })
                            }
                        }
                    })
                });
            }

            return finalObj
        },
    },
    watch: {
        getStartDate(v) {
            if (v !== null && v !== "") {
                this.calculateSelectedDates(v)
            }
        },
        type(nv, ov) {
            if (nv !== ov) {
                this.cancel();
                this.getShipmentsCalendarViewData();
            }
        },
        selectedFilter(nv, ov) {
            if (nv !== ov) {
                this.cancel();
                this.getShipmentsCalendarViewData();
            }
        },
        pendingShipmentTab(nv, ov) {
            if (nv !== ov) {
                this.cancel();
                this.getShipmentsCalendarViewData();
            }
        }
    },
    mounted() {
        //set current page
        this.$store.dispatch("page/setPage", "shipments");
        let date = moment(this.currentDateCalendar).format("YYYY-MM-DD");
        this.calculateSelectedDates(date);
        
        if (this.$refs.calendarReference) {
            this.$refs.calendarReference.checkChange();
        }
    },
    methods: {
        ...mapActions([
            "fetchShipments",
            "setShipmentOrder",
            "fetchShipmentsSearched",
            "cancelShipment",
            "markShipmentCompleted",
            "fetchCompletedShipments",
            "setShipmentsCalendarView"
        ]),
        ...globalMethods,
        checkIfFieldIsEmpty(item, field) {
            if (field !== null) {
                if (typeof item[field] !== "undefined" && item[field] !== null) {
                    if (Array.isArray(item[field]) && item[field].length > 0) {
                        if (field === "shipment_suppliers") {
                            let desc = this.getShipmentProductDescription(item.shipment_suppliers);
                            if (desc !== "") return false
                            else return true                            
                        }

                        return false
                    } else {
                        if (item[field] !== null && item[field] !== "") {
                            return false
                        }
                    }
                }
            }

            return true
        },
        checkFieldLength(item, field) {
            if (field !== null) {
                if (typeof item[field] !== "undefined" && item[field] !== null) {
                    if (Array.isArray(item[field]) && item[field].length > 0) {                        
                        let isJoinArray = "";

                        if (field === "shipment_suppliers") {
                            isJoinArray = this.getShipmentProductDescription(item.shipment_suppliers);
                        } else {
                            isJoinArray = item[field].join(", ");
                        }

                        return isJoinArray.length
                    } else {
                        if (item[field] !== null && item[field] !== "") {
                            return item[field].length
                        }
                    }
                }
            }

            return 0
        },
        cancel() {
			if (this.request) this.request.cancel("cancel_previous_request");
		},
        async getShipmentsCalendarViewData() {
            this.isFetchingData = true;
            
            // cancel
			this.cancel();
			let axiosSource = axios.CancelToken.source();
			this.request = { cancel: axiosSource.cancel };

            this.calendarViewDataSourceCopy = [];
            this.calendarViewDataSource = [];
            let startOfMonth = moment(this.currentDateCalendar).startOf('month').format("YYYY-MM-DD");
            let endOfMonth = moment(this.currentDateCalendar).endOf('month').format("YYYY-MM-DD");
            let filterData = [
                {
                    field: "",
                    operation: "orBetween",
                    value: []
                }
            ]

            let passedData = {
                method: "get",
                url: `${baseURL}/v2/shipment-tab`,
                params: { 
                    compact_view: false,
                    shipment_tab: this.tab,
                    paginate: false,
                    order_by: "asc",
                    sort_column: "eta",
                },
                cancelToken: axiosSource.token,
            }

            if (this.selectedFilter.length > 0) {
                this.selectedFilter.map(v => {
                    if (v.value !== "return_lfd") {
                        filterData[0].value.push({
                            field: v.value,
                            from: this.type === "week" ? this.datesInRageSelected[0] : startOfMonth,
                            to: this.type === "week" ? this.datesInRageSelected[6] : endOfMonth,
                        })
                    } else {
                        filterData.push({
                            field: "status",
                            // operation: "equals",
                            operation: "orEquals",
                            value: ["Full Out", "Empty In Partial", "Full Out Partial"]
                        })
                    }
                })
            } else if (this.selectedFilter.length === 1) {
                if (this.selectedFilter[0].value === "return_lfd") {
                    filterData[0].field = "status";
                    // filterData[0].operation = "equals";
                    filterData[0].operation = "orEquals";
                    filterData[0].value = ["Full Out", "Empty In Partial", "Full Out Partial"];
                }
            }
            
            passedData.params.sort_column = "etd";
            passedData.params.filters = JSON.stringify(filterData);

            await axios(passedData)
            .then(res => {
                if (res.data) {
                    this.calendarViewDataSource = res.data.data;
                    this.calendarViewDataSourceCopy = res.data.data;
                    this.isFetchingData = false;
                }
            }).catch(e => {
                if (e.message !== "cancel_previous_request") {
                    this.isFetchingData = false;
                    console.log(e, 'error');
                }                
            })
            // this.isFetchingData = false;
            this.getCurrentMonthDay();
        },
        ucFirst(str) {
            let pieces = str.split(" ")
            for ( let i = 0; i < pieces.length; i++ ) {
                let j = pieces[i].charAt(0).toUpperCase()
                pieces[i] = j + pieces[i].substr(1)
            }
            return pieces.join(" ")
        },
        getShipmentStatusClass(status) {
            let setClass = _.find(shipmentStatusesLists, e => e.value === status.toLowerCase());
            setClass = (typeof setClass !== 'undefined' && this.selectedCalendarOption !== 'color') ? setClass.class : '';
            return setClass;
        },
        handleClick(value) {
            this.$router.push(`shipment/${value.id}`);
            // this.$emit('handleClick', value);
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        getStatus(status) {
            this.$emit("getStatus", status);
        },
        getStatusClass(status) {
            let additionalClass = "";
            let statusLowercase = status !== null && status !== "" ? status.toLowerCase() : ""

            if (this.selectedCalendarOption === "color") {
                additionalClass = "color"
            }
            if (statusLowercase == 'past last free day') {
                return `Past-day ${additionalClass}`
            } else if (statusLowercase == 'empty in') {
                return `Empty-in ${additionalClass}`
            } else if (statusLowercase == 'full out') {
                return `Full-out ${additionalClass}`
            } else if (statusLowercase == 'discharged - hold') {
                return `Discharged-hold ${additionalClass}`
            } else if (statusLowercase == 'discharged - released') {
                return `Discharged-released ${additionalClass}`
            } else if (statusLowercase == 'partially discharged - hold') {
                return `Partial-hold ${additionalClass}`
            } else if (statusLowercase == 'partially discharged - released') {
                return `Partial-released ${additionalClass}`
            } else if (statusLowercase == 'in transit - hold') {
                return `Transit-hold ${additionalClass}`
            } else {
                return `${status} ${additionalClass}`
            }
        },
        // calendar view
        getEventColor(event) {
            return event.color
        },
        showEvent({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },
        updateRange() { // start, end params
            const events = []
            const eventCount = this.calendarMonthlyData.length

            for (let i = 0; i < eventCount; i++) {
                events.push({
                    name: this.calendarMonthlyData[i].name,
                    start: this.calendarMonthlyData[i].start,
                    end: null,
                    color: this.calendarMonthlyData[i].color,
                    bgColor: this.calendarMonthlyData[i].bgColor,
                    timed: true,
                    ...this.calendarMonthlyData[i]
                })
            }

            this.events = events;
        },
        // 
        calculateSelectedDates(date) {
            var currentDate = moment(date);
            var weekStart = currentDate.clone().startOf('week');
            // var weekEnd = currentDate.clone().endOf('week');

            var days = [];
            for (var i = 0; i <= 6; i++) {
                days.push(moment(weekStart).add(i, 'days').format("YYYY-MM-DD"));
            }
            this.datesInRageSelected = days;
            this.cancel();
            this.getShipmentsCalendarViewData();
        },
        calculateMonthDays(startDate, endDate) {
            var currentDate = moment(startDate);
            var now = currentDate.clone(), dates = [];
        
            while (now.isSameOrBefore(endDate)) {
                dates.push(now.format('YYYY-MM-DD'));
                now.add(1, 'days');
            }
            return dates;
        },
        formatDateWeekly(v) {
            if (v !== null && v !== "") 
                return moment(v).format("M/DD")            
        },
        checkItemDisplay(shipment) {
            if (shipment.className === 'eta' || shipment.className === 'etd') {
                return true
            } else {
                return false
            }
        },
        getShipmentsByDateLength(item) {
            if (typeof this.calendarWeeklyData[item] !== "undefined") {
                return this.calendarWeeklyData[item].length
            } else {
                return 0
            }
        },
        formatTime(item) {
            if (item !== null) {
                if (item.appointment !== null) {
                    return moment(item.appointment).format("h:mm A")
                }
            }
        },
        getShipmentProductDescription(item) {
            let descriptions = [];

            if (item !== null && item.length > 0) {
                item.map(v => {
                    descriptions.push(v.product_description)
                })
            }

            return descriptions.length > 0 ? descriptions.join(", ") : "N/A";
        },
        getCurrentMonthDay() {
            let monthDays = this.calendarMonthlyData;
            this.currentDayDataForMonthly = {};

            monthDays.map(v => {
                if (v.start !== null) {
                    let startDate = moment(v.start).format("YYYY-MM-DD");

                    if (this.currentDayDataForMonthly[startDate]) {
                        this.currentDayDataForMonthly[startDate].push(v);
                    } else {
                        this.currentDayDataForMonthly[startDate] = [v];
                    }
                }
            })
        },
        getDayLabel(day) {
            let currentDay = parseInt(day);

            if (currentDay <= 9) {
                return `0${currentDay}`
            }

            return currentDay
        },
        detectMouseOverWeekly(item, id) {
            const element1 = document.querySelector(`#supplier-element-${id}`);
            const element2 = document.querySelector(`#desc-element-${id}`);
            const element3 = document.querySelector(`#po-element-${id}`);
            const element4 = document.querySelector(`#address-element-${id}`);

            if (item !== null) {
                let findIndex = -1;

                if (item.className === "pickup_lfd") {
                    let elementValues = [
                        this.isEllipsisActive(element2), this.isEllipsisActive(element3), this.isEllipsisActive(element4)
                    ]
                    findIndex = _.findIndex(elementValues, e => (e === true));
                } else if (item.className === "appointment") {
                    let elementValues = [
                        this.isEllipsisActive(element2), this.isEllipsisActive(element3), this.isEllipsisActive(element4)
                    ]
                    findIndex = _.findIndex(elementValues, e => (e === true));
                } else {
                    let elementValues = [
                        this.isEllipsisActive(element1), this.isEllipsisActive(element2), 
                        this.isEllipsisActive(element3), this.isEllipsisActive(element4)
                    ]
                    findIndex = _.findIndex(elementValues, e => (e === true));
                }

                if (findIndex > -1 || item.po_num.length > 2) this.isTruncatedText = true;
                else this.isTruncatedText = false;
            }
        },
        detectMouseOverMonthly(item, id) {
            const element1 = document.querySelector(`#month-po-element-${id}`);
            const element2 = document.querySelector(`#month-address-element-${id}`);

           if (item !== null) {
                let elementValues = [ this.isEllipsisActive(element1), this.isEllipsisActive(element2) ]
                let findIndex = _.findIndex(elementValues, e => (e === true));

                if (findIndex > -1 || item.po_num.length > 2) this.isTruncatedText = true;
                else this.isTruncatedText = false;
           }
        },
        isEllipsisActive(element) {
            if (element !== null) {
                if (element.clientWidth < element.scrollWidth) {
                    var style = element.currentStyle || window.getComputedStyle(element);
                    return style.textOverflow === 'ellipsis'
                }
            }
            return false;
        },
        isApptAndLfd(item) {
            if (item.className !== 'pickup_lfd' && item.className !== 'appointment')
                return true
            else 
                return false
        }
    },
    updated() {}
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import '@/assets/scss/utilities.scss';
@import '../scss/shipmentsTabTable.scss';

.vc-popover-content {
    z-index: 100;
}
</style>
