<!-- @format -->

<template>
	<div>
		<div class="booking-info-wrapper">
			<h4 class="role-label">
				Consignee
				<span class="user-role ml-1">Your Role</span>
			</h4>
			<v-row v-for="(item, index) in getDetails.consignees" :key="index">
				<v-col class="booking-details-section">
					<div class="general-info">
						<p class="mb-0 info-label">Name</p>
						<p class="mb-0 info-value">
							{{ item.company_name ? item.company_name : 'N/A' }}
						</p>
					</div>
					<div class="general-info">
						<p class="mb-0 info-label">Address</p>
						<p class="mb-0 info-value">
							{{
								item.consignee_details_info
									? item.consignee_details_info
									: "N/A"
							}}
						</p>
					</div>
					<div class="general-info">
						<p class="mb-0 info-label">Notify</p>
						<p class="mb-0 info-value">
							{{ item.notify_details_info ? item.notify_details_info : "N/A" }}
						</p>
					</div>
				</v-col>
			</v-row>
		</div>
		<div class="booking-info-wrapper">
			<h4 class="role-label">
				Shipper
			</h4>
			<div
				class="sub-component"
				v-for="(item, index) in getDetails.shippers"
				:key="index"
			>
				<h5 class="sub-label">Shipper {{ index + 1 }}</h5>
				<v-row>
					<v-col class="booking-details-section">
						<div class="sub-general-info">
							<p class="mb-0 info-label">Name</p>
							<p class="mb-0 info-value">
								{{ item.company_name ? item.company_name : 'N/A' }}
							</p>
						</div>
						<div class="sub-general-info">
							<p class="mb-0 info-label">Address</p>
							<p class="mb-0 info-value">
								{{ item.detailsInfo ? item.detailsInfo : 'N/A' }}
							</p>
						</div>
						<div
							class="sub-general-info-component"
							v-if="(item.pickupLocation.location !== '' && item.pickupLocation.location !== null)  || (item.pickupLocation.name !== '' && item.pickupLocation.name !== null) || (item.pickupLocation.phone !== '' && item.pickupLocation.phone !== null) || (item.pickupLocation.email !== '' && item.pickupLocation.email !== null)"
						>
							<div class="general-info">
								<p class="mb-0 info-label">Pickup Location</p>
								<p class="mb-0 info-value">
									{{ item.pickupLocation.location ? item.pickupLocation.location : 'N/A' }}
								</p>
							</div>
							<div class="sub-component">
								<div class="general-info">
									<p class="mb-0 info-label">Contact Name</p>
									<p class="mb-0 info-value">
										{{ item.pickupLocation.name ? item.pickupLocation.name : 'N/A' }}
									</p>
								</div>
								<div class="general-info">
									<p class="mb-0 info-label">Contact Tel/Mobile</p>
									<p class="mb-0 info-value">
										{{ item.pickupLocation.phone ? item.pickupLocation.phone : 'N/A' }}
									</p>
								</div>
								<div class="general-info">
									<p class="mb-0 info-label">Contact Email</p>
									<p class="mb-0 info-value">
										{{ item.pickupLocation.email ? item.pickupLocation.email : 'N/A' }}
									</p>
								</div>
							</div>
						</div>
					</v-col>
				</v-row>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ConsigneeInfo",
	props: ["getDetails"],
	methods: {
		checkIsEmpty(value) {
			return value === "" || value === null || value === undefined
				? true
				: false;
		},
	},
};
</script>
