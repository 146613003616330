const item = {
    name: "",
    phone: "",
    address: "",
    emails: null,
    isSupplier: true,
    company_key: "",
    warehouses: []
}

export default item

