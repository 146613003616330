<!-- @format -->

<template>
	<div class="api-key-and-token-wrapper">
		<div class="api-key-token pa-5">
			<div class="d-flex justify-space-between align-center pb-5 token-title">
				<h3>API Key & Token</h3>
				<v-btn
					class="save-btn btn-blue mr-2"
					text
					@click="generateTokenDialog = true"
					v-if="getApiTokenData.length == 0"
				>
					Generate Token
				</v-btn>
			</div>
			<div
				class="preloader d-flex w-full justify-center align-center pt-4 pb-4"
				v-if="getFetchApiTokenLoading"
			>
				<v-progress-circular :size="40" color="#1A6D9E" indeterminate>
				</v-progress-circular>
			</div>
			<div v-else class="token-key">
				<div v-if="getApiTokenData && getApiTokenData.length > 0">
					<div
						class="generated-token-list"
						v-for="(item, index) in getApiTokenData"
						:key="index"
					>
						<div class="token-list">
							<h4>{{ item.name }}</h4>
							<p class="mb-0">(Created: {{ formatDate(item.created_at) }})</p>
						</div>
						<div class="action-btn">
							<v-btn
								class="save-btn btn-blue copy-link"
								@click="copyLink(item.access_token)"
								>Copy API Token</v-btn
							>
							<v-btn
								class="save-btn btn-white revoke-btn mr-2"
								text
								@click="revokeToken(item.access_token_id)"
							>
								Revoke Token
							</v-btn>
						</div>
					</div>
				</div>
				<div v-else class="no-token-data">
					<h3>No API Token Available</h3>
				</div>
			</div>
		</div>
		<!-- <SwaggerUI /> -->
		<v-dialog
			v-model="generateTokenDialog"
			width="500px"
			height="500px"
			:content-class="'generate-api-token-key'"
			persistent
		>
			<v-card>
				<v-card-title>
					<span class="headline">Generate API Token</span>
					<button
						icon
						dark
						class="btn-close"
						@click="generateTokenDialog = false"
						:disabled="getGenerateApiTokenLoading"
					>
						<v-icon>mdi-close</v-icon>
					</button>
				</v-card-title>
				<v-card-text class="card-content pa-5">
					<v-form
						ref="token"
						class="token-details"
						action="#"
						@submit.prevent=""
					>
						<div class="generate-token">
							<div
								class="input-field-wrapper input-token-field d-flex align-center mb-0 gap-3"
							>
								<p class="input-field-label mb-1 d-flex align-center">
									Name
								</p>
								<v-text-field
									v-model="token"
									:height="40"
									width="200px"
									type="text"
									dense
									outlined
									class="token-field"
									hide-details="auto"
									:rules="[(v) => !!v || '']"
								/>
							</div>
						</div>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<div class="d-flex footer">
						<v-btn
							class="save-btn btn-blue mr-2"
							text
							@click="generateToken()"
							:disabled="getGenerateApiTokenLoading"
						>
							Generate Token
						</v-btn>

						<v-btn
							class="btn-white"
							text
							@click="generateTokenDialog = false"
							:disabled="getGenerateApiTokenLoading"
						>
							Cancel
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import globalMethods from "../../../utils/globalMethods";
// import SwaggerUI from "../../SwaggerUI.vue";
export default {
	name: "APIToken",
	components: {
		// SwaggerUI,
	},
	data: () => ({
		token: null,
		access_token_id: null,
		generateTokenDialog: false,
	}),
	mounted() {
		this.fetchTokenList();
	},
	computed: {
		...mapGetters({
			getGenerateApiTokenLoading: "token/getGenerateApiTokenLoading",
			getFetchApiTokenLoading: "token/getFetchApiTokenLoading",
			getApiTokenData: "token/getApiTokenData",
		}),
	},
	methods: {
		...globalMethods,
		fetchTokenList() {
			this.$store.dispatch("token/fetchAPIToken");
		},
		formatDate(date) {
			return moment(date).format("DD MMM YYYY");
		},
		async generateToken() {
			if (this.$refs.token.validate()) {
				await this.$store
					.dispatch("token/createAPIToken", { key_name: this.token })
					.then((res) => {
						console.log(res, res);
						if (res.status == 200) {
							this.token = null;
							this.generateTokenDialog = false;
							this.fetchTokenList();
						}
					});
			}
		},
		async revokeToken(token) {
			await this.$store
				.dispatch("token/revokeAPIToken", {
					access_token_id: token,
				})
				.then((res) => {
					console.log("res", res);
					if (res.status == 200) {
						this.notificationMessageCustomSuccess(
							"API token revoked successfully."
						);
						this.fetchTokenList();
					}
				});
		},
		async copyLink(token) {
			await navigator.clipboard.writeText(token);
			this.notificationMessageCustomSuccess("API Token copied successfully.");
		},
	},
};
</script>

<style lang="scss">
@import "../../ShipmentComponents/BookingShipment/SubComponents/scss/Common.scss";
@import "../../../assets/scss/pages_scss/settings/apiToken.scss";
</style>