<!-- @format -->

<template>
	<div class="generate-booking-link--wrapper">
		<div>
			<v-dialog
				v-model="dialog"
				width="480px"
				:content-class="'booking-link-wrapper'"
				persistent
			>
				<v-card>
					<v-card-title>
						<span class="headline" v-if="!token_key"
							>Generate Booking Link</span
						>
						<span class="headline" v-else>Edit Booking Link</span>
						<button
							icon
							dark
							class="btn-close"
							@click="close"
							:disabled="disabledBtn"
						>
							<v-icon>mdi-close</v-icon>
						</button>
					</v-card-title>
					<v-card-text class="card-content">
						<v-form
							ref="linkform"
							class="link-form-details"
							action="#"
							@submit.prevent=""
						>
							<div class="input-field-wrapper">
								<span class="input-field-label">Link Type</span>
								<v-radio-group
									class="mt-0 booking-link-radio-group"
									v-model="linkType"
									row
									hide-details="auto"
									outlined
									:rules="[
										(v) => !!v || 'Link is required. Please select a link',
									]"
								>
									<v-radio
										class="new-radio-button"
										v-for="(n, key) in links"
										:key="key"
										:label="n.label"
										:value="n.value"
									>
										<template v-slot:label>
											<p class="mb-0 label">{{ n.label }}</p>
											<p class="mb-0 desc">{{ n.desc }}</p>
										</template>
									</v-radio>
								</v-radio-group>
							</div>

							<div class="input-field-wrapper">
								<span class="input-field-label">Vendors</span>
								<v-autocomplete
									v-model="vendors"
									:height="40"
									color="#002F44"
									class="template-to-vendor"
									:items="filteredShipperOptions"
									item-text="name"
									item-value="id"
									dense
									outlined
									hide-details="auto"
									placeholder="Select vendor"
									spellcheck="false"
									multiple
									:menu-props="{
										contentClass: 'vendor-name-wrapper shifl-ui-checkbox',
										bottom: true,
										offsetY: true,
									}"
									small-chips
									clearable
									deletable-chips
									:disabled="disabledField"
									:error-messages="errorMessages"
									@input="clearError"
								/>
							</div>
							<div class="input-field-wrapper">
								<span class="input-field-label"
									>Booking Template <small>(Optional)</small>
								</span>
								<v-autocomplete
									v-model="vendor_template"
									:height="40"
									color="#002F44"
									class="template-to-vendor"
									:items="vendorsTemplates"
									item-text="template_name"
									item-value="id"
									dense
									outlined
									hide-details="auto"
									placeholder="Select Booking Template"
									spellcheck="false"
									:menu-props="{
										contentClass: 'vendor-name-wrapper',
										bottom: true,
										offsetY: true,
									}"
									clearable
								/>
							</div>

							<div class="d-flex align-start pa-2 info-text">
								<img
									src="@/assets/icons/icon-delete.svg"
									alt="info-delete"
									width="18px"
								/>
								<p class="mb-0 info-desc">
									Booking template serve as predefined frameworks that allows to
									create reusable templates for common types of bookings.
								</p>
							</div>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<div class="d-flex footer">
							<v-btn
								class="save-btn btn-blue mr-2"
								text
								@click="generateLink()"
								v-if="!token_key"
								:disabled="disabledBtn"
							>
								Generate Link
							</v-btn>
							<v-btn
								class="save-btn btn-blue mr-2"
								text
								@click="updateLink()"
								v-if="token_key"
								:disabled="disabledBtn"
							>
								Save Changes
							</v-btn>

							<v-btn
								class="btn-white"
								text
								@click="close()"
								:disabled="disabledBtn"
							>
								Cancel
							</v-btn>
						</div>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>

		<ConfirmDialog :dialogData.sync="linkGenaratedDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/CircleCheckOutline.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Link generated and shared</h2>
			</template>

			<template v-slot:dialog_content>
				<p class="mb-4" v-if="!is_recurring">
					A one-time booking link has been successfully generated and shared
					with the selected vendor via email.
				</p>
				<p class="mb-4" v-if="is_recurring">
					A recurring booking link has been successfully generated. you can copy
					and share with your vendor.
				</p>
				<div
					class="input-field-wrapper copy-link-field"
					v-if="!copiedLink && copy_link"
				>
					<v-text-field
						v-model="copy_link"
						:height="40"
						width="200px"
						type="text"
						dense
						outlined
						hide-details="auto"
					/>
					<v-btn class="save-btn btn-blue copy-link" @click="copyLink"
						>Copy Link</v-btn
					>
				</div>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-white" text @click="closeLinkDialog()">
					Close
				</v-btn>
			</template>
		</ConfirmDialog>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmDialog from "../../Dialog/GlobalDialog/ConfirmDialog.vue";
import globalMethods from "../../../utils/globalMethods";

export default {
	name: "BookingLinkDialog",
	props: ["showDialog", "selectedVendorId", "vendorLinkData"],
	components: {
		ConfirmDialog,
	},
	data: () => ({
		vendors: [],
		vendor_template: null,
		linkType: "recurring_link",
		copy_link: null,
		copiedLink: null,
		links: [
			{
				label: "Recurring Link",
				desc: "A link that can be reused for multiple booking  requests",
				value: "recurring_link",
			},
			{
				label: "One-Time Link",
				desc:
					"A link that can only be used one time for submitting booking request",
				value: "one_time_link",
			},
		],
		linkGenaratedDialog: false,
		disabledField: false,
		is_recurring: false,
		userDetails: {},
		errorMessages: [],
		token_key: null,
	}),
	async mounted() {
		try {
			this.userDetails = await JSON.parse(this.getUser);
		} catch (e) {
			this.userDetails = await this.getUser;
		}

		if (this.selectedVendorId) {
			this.vendors = this.selectedVendorId;
			this.disabledField = true;
		}

		if (this.vendorLinkData?.token_key) {
			const {
				token_key,
				is_recurring,
				booking_template_id,
				supplier_id,
			} = this.vendorLinkData;

			this.token_key = token_key;
			this.vendors.push(supplier_id);
			this.linkType = is_recurring == 1 ? "recurring_link" : "one_time_link";
			this.vendor_template = booking_template_id;
		}

		if (this.getBookingTemplatesList.length == 0) {
			await this.$store.dispatch(
				"bookingTemplate/fetchBookingTemplate",
				this.userDetails?.default_customer_id
			);
		}

		await this.fetchvendors();
	},
	computed: {
		...mapGetters({
			getUser: "getUser",
			getBookingTemplatesList: "bookingTemplate/getBookingTemplatesList",
			getShippers: "suppliers/getSuppliers",
			GenerateBookingLinkLoading: "booking/getGenerateBookingLinkLoading",
			UpdateBookingLinkLoading: "booking/getUpdateBookingLinkLoading",
		}),
		dialog: {
			get() {
				return this.showDialog;
			},
			set(value) {
				this.$emit("update:showDialog", value);
			},
		},
		filteredShipperOptions() {
			//shippers array container
			let newShippers = [];

			newShippers = this.getShippers.data;

			//leaves only company name and address
			if (newShippers && newShippers.length > 0) {
				newShippers = newShippers.map(({ name, id, emails }) => {
					return {
						name,
						id,
						emails,
					};
				});
			}
			return newShippers;
		},
		vendorsTemplates() {
			return this.getBookingTemplatesList.filter((item) =>
				this.vendors.some(
					(vendor_id) =>
						item.vendor_ids &&
						item.vendor_ids.split(";").includes(vendor_id.toString())
				)
			);
		},
		disabledBtn() {
			return this.UpdateBookingLinkLoading || this.GenerateBookingLinkLoading;
		},
	},
	methods: {
		...globalMethods,
		...mapActions({
			fetchSuppliers: "suppliers/fetchSuppliers",
		}),
		close() {
			this.vendor_template = null;
			this.vendors = [];
			this.$emit("close");
		},
		fetchvendors() {
			let parms = {
				pageNumber: 1,
			};
			this.fetchSuppliers(parms);
		},
		async copyLink() {
			await navigator.clipboard.writeText(this.copy_link);
			this.copiedLink = this.copy_link;
		},
		async generateLink() {
			if (this.$refs.linkform.validate() && this.vendors.length > 0) {
				let validate = true;

				let selectedVendors = this.filteredShipperOptions.filter((vendor) =>
					this.vendors.includes(vendor.id)
				);

				let suppliersData = selectedVendors.map((supplier) => {
					let companyEmailsData = [];
					if (supplier.emails.length == 0) {
						validate = false;
						this.notificationError(
							`Missing Email Address for ${supplier.name}`
						);
					} else {
						supplier?.emails &&
							supplier?.emails.map((item) => {
								if (typeof item == "object") {
									if (item.email) {
										companyEmailsData.push(item.email);
									}
								} else {
									if (item) {
										companyEmailsData.push(item);
									}
								}
							});
					}

					return {
						supplier_id: supplier.id,
						companyEmails: companyEmailsData,
						companyName: supplier.name,
					};
				});

				if (validate) {
					let paylods = {
						suppliers: suppliersData,
						customer_id: this.userDetails?.default_customer_id,
						is_recurring: this.linkType == "recurring_link",
						template_id: this.vendor_template,
					};

					this.$store
						.dispatch("booking/generateBookingLink", paylods)
						.then((res) => {
							console.log("res", res);

							if (res.status == 200) {
								this.copy_link = res.data.link;
								this.is_recurring = res.data.is_recurring;
								this.linkGenaratedDialog = true;
								this.fetchvendors();
							}
						})
						.catch((e) => {
							this.notificationError("SOMETHING WENT WRONG!");
							console.log(e);
						});
				}
			} else {
				if (this.vendors.length == 0) {
					this.errorMessages.push("Vendor is required");
				}
			}
		},
		clearError() {
			this.errorMessages = [];
		},
		closeLinkDialog() {
			this.linkGenaratedDialog = false;
			this.copiedLink = null;
			this.close();
		},
		updateLink() {
			let paylods = {
				token_key: this.token_key,
				is_recurring: this.linkType == "recurring_link",
				template_id: this.vendor_template,
			};

			this.$store
				.dispatch("booking/updateBookingLink", paylods)
				.then((res) => {
					console.log("res", res);
					if (res.status == 200) {
						this.close();
						this.fetchvendors();
					}
				})
				.catch((e) => {
					this.notificationError("SOMETHING WENT WRONG!");
					console.log(e);
				});
		},
	},
};
</script>

<style lang="scss">
@import "./scss/BookingLinkDialog.scss";
@import "./SubComponents/scss/Common.scss";
</style>
