var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"containers-table-wrapper",style:(("" + (_vm.isMobile ? 'width: 100% !important;' : '')))},[_c('v-data-table',{staticStyle:{"box-shadow":"none !important"},attrs:{"headers":_vm.headers,"items":_vm.items,"mobile-breakpoint":"100","page":1,"items-per-page":100,"hide-default-footer":"","fixed-header":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(item,index){return _c('th',{key:index,style:(("text-transform: uppercase !important;text-align: " + (item.textAlign) + "; color: #6D858F;width: " + (item.width) + ";} !important;} !important; height: 36px !important; background-color: " + _vm.headerBackground + "; box-shadow: none !important;")),attrs:{"role":"column-header","aria-label":item.text,"scope":"col","placeholder":0}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)])]}},{key:"item.plus",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"operator",on:{"click":function($event){$event.stopPropagation();return _vm.add(item)}}},[_c('generic-icon',{attrs:{"color":"#0171A1","marginLeft":0,"iconName":"add"}})],1)]}},{key:"item.how_many",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"how-many-wrapper",staticStyle:{"width":"100%","text-align":"center"}},[_c('v-text-field',{attrs:{"height":"40px","color":"#002F44","width":"200px","type":"number","dense":"","placeholder":"0","outlined":"","hide-details":"auto"},model:{value:(item.how_many),callback:function ($$v) {_vm.$set(item, "how_many", $$v)},expression:"item.how_many"}})],1)]}},{key:"item.minus",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"operator",on:{"click":function($event){$event.stopPropagation();return _vm.subtract(item)}}},[_c('generic-icon',{attrs:{"color":"#0171A1","marginLeft":0,"iconName":"subtract"}})],1)]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"checkbox-wrapper-create checkbox-wrapper-desktop checkbox-wrapper-notify-party"},[_c('label',{class:((_vm.isMobile ? 'd-flex flex-row align-items' : '') + " " + (item.checked ? 'checked': '')),staticStyle:{"position":"relative"}},[_c('generic-icon',{attrs:{"marginLeft":0,"iconName":("" + ((item.checked) ? 'checked' : 'not-checked'))}}),_c('input',{staticStyle:{"position":"absolute","opacity":"0"},attrs:{"type":"checkbox"},domProps:{"checked":item.checked},on:{"click":function($event){$event.preventDefault();return _vm.toggleSize(item)}}}),_c('span',{staticStyle:{"color":"#4A4A4A","padding-left":"12px !important"}},[_vm._v(" "+_vm._s(item.size)+" ")])],1)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }