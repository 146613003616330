var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:((_vm.isMobile ? 'shipment-info-mobile' : '') + " shipment-info top-info")},[_c('v-container',{staticClass:"cont-fluid-wrapper"},[_c('shipment-general-information',{attrs:{"details":_vm.getDetails,"isMobile":_vm.isMobile}}),(_vm.getDetails.is_tracking_shipment == 0 && 1 == 2)?_c('div',{staticClass:"row shipment-info-wrapper"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6"},[(
							_vm.getDetails.customer !== 'undefined' &&
								_vm.getDetails.customer !== null &&
								_vm.getDetails.customer.company_name !== 'undefined'
						)?_c('div',{staticClass:"row"},[_c('h3',{staticClass:"building-name"},[_vm._v(" "+_vm._s(_vm.getDetails.customer.company_name)+" ")])]):_vm._e(),(
							_vm.getDetails.location_from_name !== null || _vm.getDetails.etd !== null
						)?_c('div',{staticClass:"row"},[_c('span',{staticClass:"heading shipment-info-content"},[_vm._v(_vm._s("FROM & ETD"))]),_c('p',{staticClass:"shipment-info-content"},[(
									_vm.getDetails.location_from_name !== 'undefined' &&
										_vm.getDetails.location_from_name !== '' &&
										_vm.getDetails.location_from_name !== null
								)?_c('span',{staticClass:"heading-place"},[_vm._v(" "+_vm._s(_vm.getDetails.shipment_status == "Pending Approval" ? "N/A" : _vm.getDetails.location_from_name)+", ")]):_vm._e(),(_vm.shipment_etd !== null)?_c('span',{staticClass:"heading-date"},[_vm._v(" "+_vm._s(_vm.getDetails.shipment_status == "Pending Approval" ? "N/A" : _vm.shipment_etd)+" ")]):_vm._e()])]):_vm._e(),(
							_vm.getDetails.location_to_name !== null || _vm.getDetails.eta !== null
						)?_c('div',{staticClass:"row"},[_c('span',{staticClass:"heading shipment-info-content"},[_vm._v(_vm._s("TO & ETA"))]),_c('p',{staticClass:"shipment-info-content"},[(
									_vm.getDetails.location_to_name !== 'undefined' &&
										_vm.getDetails.location_to_name !== '' &&
										_vm.getDetails.location_to_name !== null
								)?_c('span',{staticClass:"heading-place"},[_vm._v(" "+_vm._s(_vm.getDetails.shipment_status == "Pending Approval" ? "N/A" : _vm.getDetails.location_to_name)+", ")]):_vm._e(),(_vm.shipment_eta !== null)?_c('span',{staticClass:"heading-date"},[_vm._v(" "+_vm._s(_vm.getDetails.shipment_status == "Pending Approval" ? "N/A" : _vm.shipment_eta)+" ")]):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6"},[(
							_vm.getDetails.carrier !== 'undefined' &&
								_vm.getDetails.carrier !== null &&
								_vm.getDetails.carrier !== '' &&
								_vm.getDetails.shipment_status !== 'Pending Approval'
						)?_c('div',{staticClass:"row"},[_c('span',{staticClass:"heading shipment-info-content"},[_vm._v("Carrier")]),_c('p',{staticClass:"heading-place shipment-info-content"},[_vm._v(" "+_vm._s(typeof _vm.getDetails.carrier === "String" ? _vm.getDetails.carrier : _vm.getDetails.carrier.name)+" ")])]):_vm._e(),(
							_vm.getDetails.vessel !== 'undefined' && _vm.getDetails.vessel !== null
						)?_c('div',{staticClass:"row"},[_c('span',{staticClass:"heading shipment-info-content"},[_vm._v("Vessel")]),_c('p',{staticClass:"heading-place shipment-info-content"},[_vm._v(" "+_vm._s(_vm.getDetails.vessel)+" ")])]):_vm._e(),(
							_vm.getDetails.mbl_num !== 'undefined' && _vm.getDetails.mbl_num !== null
						)?_c('div',{staticClass:"row"},[_c('span',{staticClass:"heading shipment-info-content"},[_vm._v("MBL#")]),_c('p',{staticClass:"heading-place shipment-info-content"},[_vm._v(" "+_vm._s(_vm.getDetails.mbl_num)+" ")])]):_vm._e()])]):_vm._e(),(_vm.getDetails.is_tracking_shipment == 1)?_c('div',{staticClass:"row shipment-info-wrapper",class:_vm.getFilteredShipmentDetails.external_shipment_tracking === 1
						? ''
						: 'k-pt-0'},[(_vm.getMilestonesLoading)?_c('div',{staticClass:"preloader"},[_c('v-progress-circular',{attrs:{"size":40,"color":"#0171a1","indeterminate":""}}),_c('p',[_vm._v("Fetching tracking details...")])],1):_vm._e(),(
						!_vm.getMilestonesLoading && _vm.getMilestonesAttributes !== null && 1 == 2
					)?_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6"},[(
							_vm.getDetails.customer !== 'undefined' &&
								_vm.getDetails.customer !== null &&
								_vm.getDetails.customer.company_name !== 'undefined'
						)?_c('div',{staticClass:"row"},[_c('h3',{staticClass:"building-name"},[_vm._v(" "+_vm._s(_vm.getDetails.customer.company_name)+" ")])]):_vm._e(),(_vm.getMilestonesAttributes.port_of_lading_name !== null)?_c('div',{staticClass:"row"},[_c('span',{staticClass:"heading shipment-info-content"},[_vm._v(_vm._s("FROM & ETD"))]),_c('p',{staticClass:"shipment-info-content"},[(
									_vm.getMilestonesAttributes.port_of_lading_name !== 'undefined' &&
										_vm.getMilestonesAttributes.port_of_lading_name !== '' &&
										_vm.getMilestonesAttributes.port_of_lading_name !== null
								)?_c('span',{staticClass:"heading-place"},[_vm._v(" "+_vm._s(_vm.getMilestonesAttributes.port_of_lading_name)+", ")]):_vm._e(),(_vm.getMilestonesAttributes.pol_etd_at !== null)?_c('span',{staticClass:"heading-date"},[_vm._v(" "+_vm._s(_vm.trackingDateConvert(_vm.getMilestonesAttributes.pol_etd_at))+" ")]):_vm._e(),(_vm.getMilestonesAttributes.pol_etd_at === null)?_c('span',{staticClass:"heading-date"},[_vm._v(" "+_vm._s(_vm.trackingDateConvert(_vm.getMilestonesAttributes.pol_atd_at))+" ")]):_vm._e()])]):_vm._e(),(_vm.getMilestonesAttributes.port_of_discharge_name !== null)?_c('div',{staticClass:"row"},[_c('span',{staticClass:"heading shipment-info-content"},[_vm._v(_vm._s("TO & ETA"))]),_c('p',{staticClass:"shipment-info-content"},[(
									_vm.getMilestonesAttributes.port_of_discharge_name !==
										'undefined' &&
										_vm.getMilestonesAttributes.port_of_discharge_name !== '' &&
										_vm.getMilestonesAttributes.port_of_discharge_name !== null
								)?_c('span',{staticClass:"heading-place"},[_vm._v(" "+_vm._s(_vm.getMilestonesAttributes.port_of_discharge_name)+", ")]):_vm._e(),(_vm.getMilestonesAttributes.pod_eta_at !== null)?_c('span',{staticClass:"heading-date"},[_vm._v(" "+_vm._s(_vm.trackingDateConvert(_vm.getMilestonesAttributes.pod_eta_at))+" ")]):_vm._e(),(
									_vm.getMilestonesAttributes.pod_eta_at === null &&
										_vm.getMilestonesAttributes.pod_ata_at !== null
								)?_c('span',{staticClass:"heading-date"},[_vm._v(" "+_vm._s(_vm.trackingDateConvert(_vm.getMilestonesAttributes.pod_ata_at))+" ")]):_vm._e()])]):_vm._e()]):_vm._e(),(
						!_vm.getMilestonesLoading && _vm.getMilestonesAttributes !== null && 1 == 2
					)?_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6"},[(
							_vm.getMilestonesAttributes.shipping_line_short_name !==
								'undefined' &&
								_vm.getMilestonesAttributes.shipping_line_short_name !== null &&
								_vm.getMilestonesAttributes.shipping_line_short_name !== ''
						)?_c('div',{staticClass:"row"},[_c('span',{staticClass:"heading shipment-info-content"},[_vm._v("Carrier")]),_c('p',{staticClass:"heading-place shipment-info-content"},[_vm._v(" "+_vm._s(_vm.getMilestonesAttributes.shipping_line_short_name)+" ")])]):_vm._e(),(
							_vm.getMilestonesAttributes.pod_vessel_name !== 'undefined' &&
								_vm.getMilestonesAttributes.pod_vessel_name !== null
						)?_c('div',{staticClass:"row"},[_c('span',{staticClass:"heading shipment-info-content"},[_vm._v("Vessel")]),_c('p',{staticClass:"heading-place shipment-info-content"},[_vm._v(" "+_vm._s(_vm.getMilestonesAttributes.pod_vessel_name)+" ")])]):_vm._e(),(
							_vm.getDetails.mbl_num !== 'undefined' && _vm.getDetails.mbl_num !== null
						)?_c('div',{staticClass:"row"},[_c('span',{staticClass:"heading shipment-info-content"},[_vm._v("MBL#")]),_c('p',{staticClass:"heading-place shipment-info-content"},[_vm._v(" "+_vm._s(_vm.getDetails.mbl_num)+" ")])]):_vm._e()]):_vm._e()]):_vm._e(),(
					_vm.getDetails.is_tracking_shipment == 1 &&
						!_vm.getMilestonesLoading &&
						_vm.getMilestonesAttributes == null &&
						1 == 2
				)?_c('div',{staticClass:"row shipment-info-wrapper k-pt-0"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6"},[(
							_vm.getDetails.customer !== 'undefined' &&
								_vm.getDetails.customer !== null &&
								_vm.getDetails.customer.company_name !== 'undefined'
						)?_c('div',{staticClass:"row"},[_c('h3',{staticClass:"building-name"},[_vm._v(" "+_vm._s(_vm.getDetails.customer.company_name)+" ")])]):_vm._e(),(
							_vm.getDetails.location_from_name !== null || _vm.getDetails.etd !== null
						)?_c('div',{staticClass:"row"},[_c('span',{staticClass:"heading shipment-info-content"},[_vm._v(_vm._s("FROM & ETD"))]),_c('p',{staticClass:"shipment-info-content"},[(
									_vm.getDetails.location_from_name !== 'undefined' &&
										_vm.getDetails.location_from_name !== '' &&
										_vm.getDetails.location_from_name !== null
								)?_c('span',{staticClass:"heading-place"},[_vm._v(" "+_vm._s(typeof _vm.getDetails.location_from_name !== "undefined" ? _vm.getDetails.location_from_name : "N/A")+", ")]):_vm._e(),(_vm.shipment_etd !== null)?_c('span',{staticClass:"heading-date"},[_vm._v(" "+_vm._s(_vm.shipment_etd === "" || _vm.shipment_etd === null ? "N/A" : _vm.shipment_etd)+" ")]):_vm._e()])]):_vm._e(),(
							_vm.getDetails.location_to_name !== null || _vm.getDetails.eta !== null
						)?_c('div',{staticClass:"row"},[_c('span',{staticClass:"heading shipment-info-content"},[_vm._v(_vm._s("TO & ETA"))]),_c('p',{staticClass:"shipment-info-content"},[(
									_vm.getDetails.location_to_name !== 'undefined' &&
										_vm.getDetails.location_to_name !== '' &&
										_vm.getDetails.location_to_name !== null
								)?_c('span',{staticClass:"heading-place"},[_vm._v(" "+_vm._s(typeof _vm.getDetails.location_to_name !== "undefined" ? _vm.getDetails.location_to_name : "N/A")+", ")]):_vm._e(),(_vm.shipment_eta !== null)?_c('span',{staticClass:"heading-date"},[_vm._v(" "+_vm._s(_vm.shipment_eta === "" || _vm.shipment_eta === null ? "N/A" : _vm.shipment_eta)+" ")]):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6"},[(
							_vm.getDetails.carrier !== 'undefined' &&
								_vm.getDetails.carrier !== null &&
								_vm.getDetails.carrier !== ''
						)?_c('div',{staticClass:"row"},[_c('span',{staticClass:"heading shipment-info-content"},[_vm._v("Carrier")]),_c('p',{staticClass:"heading-place shipment-info-content"},[_vm._v(" "+_vm._s(typeof _vm.getDetails.carrier === "String" ? _vm.getDetails.carrier : _vm.getDetails.carrier.name)+" ")])]):_vm._e(),(
							_vm.getDetails.vessel !== 'undefined' && _vm.getDetails.vessel !== null
						)?_c('div',{staticClass:"row"},[_c('span',{staticClass:"heading shipment-info-content"},[_vm._v("Vessel")]),_c('p',{staticClass:"heading-place shipment-info-content"},[_vm._v(" "+_vm._s(_vm.getDetails.vessel)+" ")])]):_vm._e(),(
							_vm.getDetails.mbl_num !== 'undefined' && _vm.getDetails.mbl_num !== null
						)?_c('div',{staticClass:"row"},[_c('span',{staticClass:"heading shipment-info-content"},[_vm._v("MBL#")]),_c('p',{staticClass:"heading-place shipment-info-content"},[_vm._v(" "+_vm._s(_vm.getDetails.mbl_num)+" ")])]):_vm._e()])]):_vm._e()],1),_vm._v(" "+_vm._s(/* missing suppliers */)+" "),(
				_vm.getDetails.suppliers == 'undefined' ||
					_vm.getDetails.shipment_suppliers == null ||
					(_vm.getDetails.shipment_suppliers.length === 0 &&
						!_vm.getMilestonesLoading &&
						_vm.getMilestonesAttributes == null)
			)?_c('v-container',{staticClass:"k-p-0 k-border-t-1 k-border-l-0 k-border-r-0 k-border-b-0 k-border-white-blue no-resource-wrapper",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex k-p-16 missing-title k-pl-20 k-pr-20"},[_vm._v(" Suppliers ")]),_c('div',{staticClass:"no-details-row k-pb-24 k-pt-12"},[_c('generic-icon',{attrs:{"iconName":"supplier","color":"#0171A1"}}),_c('div',{staticClass:"no-details-row-header"},[_vm._v(" "+_vm._s("No Supplier Info")+" ")]),_c('div',{staticClass:"no-details-row-body"},[_vm._v(" "+_vm._s("We couldn’t track any supplier. When you add POs to the shipment, supplier list will be automatically populated.")+" ")])],1)]):_vm._e(),_vm._v(" "+_vm._s(/* missing containers */)+" "),(
				_vm.getDetails.containers == 'undefined' ||
					_vm.getDetails.containers == null ||
					(_vm.getDetails.containers.length === 0 &&
						_vm.getDetails.is_tracking_shipment == 1 &&
						!_vm.getMilestonesLoading &&
						_vm.getMilestonesAttributes == null)
			)?_c('v-container',{staticClass:"k-p-0 k-border-t-1 k-border-l-0 k-border-r-0 k-border-b-0 k-border-white-blue no-resource-wrapper",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex justify-space-between k-p-16 k-pr-20 missing-title k-pl-20"},[_c('div',[_vm._v("Containers")]),_c('div',{staticClass:"d-flex justify-end"},[_c('a',{staticClass:"add-container-info-btn"},[_vm._v(" "+_vm._s("Add Container Info")+" ")])])]),_c('div',{staticClass:"no-details-row k-pb-24 k-pt-24"},[_c('generic-icon',{attrs:{"iconName":"container","color":"#0171A1"}}),_c('div',{staticClass:"no-details-row-header"},[_vm._v(" "+_vm._s("No Container info")+" ")]),_c('div',{staticClass:"no-details-row-body"},[_vm._v(" "+_vm._s("We couldn’t track any container information. Please edit the shipment and add information related to container.")+" ")])],1)]):_vm._e(),_c('ShipmentSupplier',{attrs:{"getDetails":_vm.getDetails,"isMobile":_vm.isMobile,"shipment_status":_vm.shipment_status}}),_c('MileStoneContainers',{attrs:{"getDetails":_vm.getDetails,"isMobile":_vm.isMobile}}),(_vm.displaySchedulingSection)?_c('ShipmentSchedulingOptions',{attrs:{"getDetails":_vm.getDetails,"isMobile":_vm.isMobile,"reloadAllShipments":_vm.reloadAllShipments}}):_vm._e(),(
				_vm.getDetails.is_tracking_shipment == 1 &&
					!_vm.getMilestonesLoading &&
					_vm.getMilestonesAttributes == null &&
					(_vm.getDetails.containers == 'undefined' ||
						_vm.getDetails.containers == null ||
						(_vm.getDetails.containers.length === 0 && 1 == 2))
			)?_c('v-container',{staticClass:"k-p-0 k-border-t-1 k-border-l-0 k-border-r-0 k-border-b-0 k-border-white-blue",attrs:{"fluid":""}},[_c('div',{staticClass:"no-details-row k-pb-20"},[_c('custom-icon',{attrs:{"width":"36","height":"36","iconName":"warning","color":"#D68A1A"}}),_c('div',{staticClass:"no-details-row-header"},[_vm._v(" Missing Information ")]),_c('div',{staticClass:"no-details-row-body"},[_vm._v(" We can’t track the information for some reason. Can you make sure the MBL# is correct? ")])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }