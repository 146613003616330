<!-- @format -->

<template>
	<div class="shipment-general-information-wrapper">
		<v-row class="shipment-info-general-content-wrapper">
			<v-col :cols="`${isMobile ? 12 : 6}`" class="shipment-details-section">
				<div class="general-info" v-if="bookingConfirmed">
					<p class="mb-0 info-label">MBL#</p>
					<p
						class="mb-0 info-value"
						:class="
							getDetails.mbl_num || getDetails.external_shipment == 0
								? ''
								: 'editable-field'
						"
						v-if="!editMblField || getDetails.external_shipment == 0"
						@click="
							getDetails.mbl_num || getDetails.external_shipment == 0
								? ''
								: editMblFieldClick(true)
						"
					>
						{{ checkValue(getDetails.mbl_num) }}
					</p>
					<div
						class="editable-text-field d-flex"
						v-if="editMblField && getDetails.external_shipment == 1"
					>
						<v-text-field
							v-model="getDetails.mbl_num"
							class="editable-input-field"
							outlined
						></v-text-field>
						<template v-if="!isMblCalling">
							<div
								style="margin-right: 2%; margin-left: 4%; margin-top: 1.5%; cursor: pointer;"
							>
								<button @click="MBLSubmittedDialog = true">
									<img src="@/assets/icons/check-blue.svg" />
								</button>
							</div>
							<div style="cursor: pointer; margin-top: 1.5%;">
								<button @click="editMblFieldClick(false)">
									<img src="@/assets/icons/close-red.svg" />
								</button>
							</div>
						</template>
						<template v-else>
							<div
								style="margin-right: 2%; margin-left: 4%; margin-top: 0.5%; cursor: pointer;"
							>
								<v-progress-circular :size="20" color="#1A6D9E" indeterminate />
							</div>
						</template>
					</div>
				</div>
				<div class="general-info">
					<p class="mb-0 info-label">
						From
						<span class="info-label" v-if="bookingConfirmed">& ETD</span>
					</p>
					<p class="mb-0 info-value">
						{{ checkValue(getDetails.location_from) }}<span v-if="bookingConfirmed">, 
							{{ checkValue(getDetails.etd_date) }}
						</span>
					</p>
				</div>
				<div class="general-info">
					<p class="mb-0 info-label">Port of Discharge</p>
					<p class="mb-0 info-value">
						{{ checkValue(getDetails.port_of_discharge) }}
					</p>
				</div>
				<div class="general-info">
					<p class="mb-0 info-label">
						To
						<span class="info-label" v-if="bookingConfirmed">& ETA</span>
					</p>
					<p class="mb-0 info-value">
						{{ checkValue(getDetails.location_to) }}<span v-if="bookingConfirmed">, 
							{{ checkValue(getDetails.eta_date) }}
						</span>
					</p>
				</div>
				<div class="general-info">
					<p class="mb-0 info-label">Mode & Type</p>
					<p class="mb-0 info-value d-flex align-center">
						<ModeIcon
							:iconName="getDetails.mode.toLowerCase()"
							class="ma-0"
							v-if="getDetails.mode"/>
						<img
							:src="shipmentTypeImg(getDetails.type)"
							v-if="getDetails.type" 
							class="mx-2"
							width="18px" />

						<span>{{ checkValue(getDetails.mode) }}</span><span>, 
							{{ checkValue(getDetails.type) }}
						</span>
					</p>
				</div>
				<div class="general-info" v-if="bookingConfirmed">
					<p class="mb-0 info-label">Cut Off Date</p>
					<p class="mb-0 info-value">
						{{ checkValue(getDetails.cut_off_date) }}
					</p>
				</div>
			</v-col>
			<v-col :cols="`${isMobile ? 12 : 6}`" class="shipment-details-section">
				<div class="general-info" v-if="bookingConfirmed">
					<p class="mb-0 info-label">Booking #</p>
					<p
						class="mb-0 info-value"
						:class="getDetails.booking_number ? '' : 'editable-field'"
						v-if="!editBookingField"
						@click="
							getDetails.booking_number ? '' : editBookingFieldClick(true)
						"
					>
						{{ checkValue(getDetails.booking_number) }}
					</p>
					<div class="editable-text-field d-flex" v-if="editBookingField">
						<v-text-field
							v-model="getDetails.booking_number"
							class="editable-input-field"
							outlined
						></v-text-field>
						<template v-if="!getUpdateShipmentFieldsLoading">
							<div class="edit-check-button">
								<button @click="checkEditedField('booking_num')">
									<img src="@/assets/icons/check-white.svg" />
								</button>
							</div>
							<div class="edit-close-button">
								<button @click="editBookingFieldClick(false)">
									<v-icon color="#253041" size="18">mdi-close</v-icon>
								</button>
							</div>
						</template>
						<template v-else>
							<div
								style="margin-right: 2%; margin-left: 4%; margin-top: 0.5%; cursor: pointer;"
							>
								<v-progress-circular
									:size="20"
									color="#1A6D9E"
									indeterminate
								></v-progress-circular>
							</div>
						</template>
					</div>
				</div>

				<div class="general-info" @mouseenter="detectLineClamp(getDetails, 'reference')">
					<p class="mb-0 info-label">Customer Ref #</p>
					<!-- <p class="mb-0 info-value editable-field"
						@click="editCustomerRefFieldClick(true)"
						v-if="!editCustomerRefField">
						{{ checkValue(getDetails.customer_reference_number) }}
					</p> -->

					<CalendarTooltip :data="getDetails" isFor="event-info-inner-content" 
						:isShowTooltip.sync="isTruncatedText" :nudge="false" v-if="!editCustomerRefField">
						<template slot="tooltip-main-data" slot-scope="{ on, attrs }">
							<div v-on="on" v-bind="attrs" id="td-reference-details" class="info-value editable-field">
								<p @click="editCustomerRefFieldClick(true)"
									v-if="!editCustomerRefField">
									{{ checkValue(getDetails.customer_reference_number) }}
								</p>
							</div>
						</template>

						<template slot="tooltip-data">
							<div class="tooltip-data-destination mb-2 mt-1">
								<span class="font-semi-bold tooltip-title">Customer Reference Number:</span>
								<p class="mb-0 mt-1 font-medium" style="font-size: 11px;">
									{{ checkValue(getDetails.customer_reference_number) }}
								</p>
							</div>
						</template>
					</CalendarTooltip>

					<div class="editable-text-field d-flex " v-if="editCustomerRefField">
						<v-text-field
							v-model="getDetails.customer_reference_number"
							class="editable-input-field"
							outlined
						></v-text-field>
						<template v-if="!getUpdateShipmentFieldsLoading">
							<div class="edit-check-button">
								<button @click="checkEditedField('customer_ref')">
									<img src="@/assets/icons/check-white.svg" />
								</button>
							</div>
							<div class="edit-close-button">
								<button @click="editCustomerRefFieldClick(false)">
									<v-icon color="#253041" size="18">mdi-close</v-icon>
								</button>
							</div>
						</template>
						<template v-else>
							<div
								style="margin-right: 2%; margin-left: 4%; margin-top: 0.5%; cursor: pointer;"
							>
								<v-progress-circular
									:size="20"
									color="#1A6D9E"
									indeterminate
								></v-progress-circular>
							</div>
						</template>
					</div>
				</div>
				<div class="general-info" v-if="bookingConfirmed">
					<p class="mb-0 info-label">Carrier</p>
					<p class="mb-0 info-value">
						{{ checkValue(getDetails.carrier) }}
					</p>
				</div>
				<div class="general-info" v-if="bookingConfirmed">
					<p class="mb-0 info-label">Vessel & Voyage</p>
					<p class="mb-0 info-value">
						{{ checkValue(getDetails.vessel) }},
						{{ checkValue(getDetails.voyage_number) }}
					</p>
				</div>
				<div class="general-info" v-if="bookingConfirmed">
					<p class="mb-0 info-label">Terminal</p>
					<p
						class="mb-0 info-value d-flex align-center gap-2"
						@click="showTerminalInfo()"
					>
						{{
							getDetails.terminalInfo && getDetails.terminalInfo.name
								? getDetails.terminalInfo.name
								: null
						}}

						<v-menu
							content-class="info-details-menu terminal-info"
							:close-on-content-click="false"
							:nudge-left="200"
							:nudge-bottom="40"
							offset-x
							v-if="
								getDetails.terminalInfo &&
									(getDetails.terminalInfo.firms_code ||
										getDetails.terminalInfo.address)
							"
						>
							<template v-slot:activator="{ on, attrs }">
								<span
									class="d-flex gap-1 align-center info-menu-label"
									v-on="on"
									v-bind="attrs"
								>
									<GenericIcon color="#253041" iconName="info-icon" />
									info
								</span>
							</template>
							<v-card v-if="!isMobile" class="info-menu-details-wrapper">
								<v-card-text>
									<p class="info-title">
										Terminal Details
									</p>
									<div class="general-info">
										<p class="mb-0 info-label">FIRMs Code</p>
										<p class="mb-0 info-value">
											{{ checkValue(getDetails.terminalInfo.firms_code) }}
										</p>
									</div>
									<div class="general-info">
										<p class="mb-0 info-label">Location</p>
										<p class="mb-0 info-value">
											{{ checkValue(getDetails.terminalInfo.address) }}
										</p>
									</div>
								</v-card-text>
							</v-card>
						</v-menu>
						<span v-else>N/A</span>
					</p>
				</div>
				<div class="general-info" v-if="bookingConfirmed">
					<p class="mb-0 info-label">Container Free Days</p>
					<p class="mb-0 info-value">
						{{
							getDetails.containerFreeDays
								? getDetails.containerFreeDays + " Days (Business days)"
								: "N/A"
						}}
					</p>
				</div>

				<div class="general-info" v-if="!bookingConfirmed">
					<p class="mb-0 info-label">Earliest Shipment Date</p>
					<p class="mb-0 info-value">
						{{ formatDate(getDetails.earliest_shipment_date) }}
					</p>
				</div>
				<div class="general-info" v-if="!bookingConfirmed">
					<p class="mb-0 info-label">Latest Arrival Date</p>
					<p class="mb-0 info-value">
						{{ formatDate(getDetails.latest_arrival_date) }}
					</p>
				</div>
				<div class="general-info" v-if="!bookingConfirmed">
					<p class="mb-0 info-label">Preferred Carrier</p>
					<p class="mb-0 info-value">
						{{
							getDetails.preferred_carrier.length > 0
								? getDetails.preferred_carrier.join(", ")
								: "N/A"
						}}
					</p>
				</div>
				<div class="general-info" v-if="getDetails.consolidation_status == 2">
					<p class="mb-0 info-label">Shipment Merged</p>
					<p class="mb-0 info-value">
						<span
							v-for="(item, index) in getDetails.merged_shipments"
							:key="index"
						>
							Ref# {{ item }}
							<span
								v-if="
									index != Object.keys(getDetails.merged_shipments).length - 1
								"
								>,
							</span></span
						>
					</p>
				</div>
			</v-col>

			<v-col :cols="12" class="delivery-address-section">
				<div class="warehouse-delivery-location">
					<h4 class="d-flex align-center justify-space-between">
						Warehouse/Delivery Location
						<div class="action-icons d-flex align-center gap-4">
							<span
								@click="
									updateWareHouseLocation(Array.isArray(wareHouseLocation))
								"
								class="icons d-flex gap-1 align-center"
							>
								<KeneticIcon
									iconName="edit-square"
									color="#0171A1"
									:height="14"
									:width="14"
								/>
								<span v-if="!isMobile">{{
									!Array.isArray(wareHouseLocation) ? "Update" : "Add Location"
								}}</span>
							</span>
							<span
								v-if="!Array.isArray(wareHouseLocation)"
								@click="deleteConfirm = true"
								class="icons remove d-flex gap-1 align-center"
							>
								<KeneticIcon
									iconName="delete-red"
									color="#B11B1B"
									:height="14"
									:width="14"
								/>
								<span v-if="!isMobile">Remove</span>
							</span>
						</div>
					</h4>
					<div
						class="dl-sections d-flex"
						v-if="!Array.isArray(wareHouseLocation)"
					>
						<div class="shipment-details-section col-md-6">
							<div class="general-info">
								<p class="mb-0 info-label">Address</p>
								<p class="mb-0 info-value">
									<a href="#" class="location">
										{{ checkValue(wareHouseLocation.address) }}</a
									>
									<img src="../../../assets/icons/map-filled-pin.svg" />
								</p>
							</div>
							<div class="general-info">
								<p class="mb-0 info-label">Contact Name</p>
								<p class="mb-0 info-value">
									{{ checkValue(wareHouseLocation.contact_name) }}
								</p>
							</div>
							<div class="general-info">
								<p class="mb-0 info-label">Contact Email</p>
								<p class="mb-0 info-value">
									{{ checkValue(wareHouseLocation.email) }}
								</p>
							</div>
						</div>
						<div class="shipment-details-section col-md-6">
							<div class="general-info">
								<p class="mb-0 info-label">Type</p>
								<p class="mb-0 info-value">
									{{ checkValue(wareHouseLocation.type) }}
								</p>
							</div>
							<div class="general-info">
								<p class="mb-0 info-label">Contact Tel</p>
								<p class="mb-0 info-value">
									{{ checkValue(wareHouseLocation.phone) }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>

		<v-dialog
			v-model="showTerminalInfoDialog"
			width="auto"
			content-class="shipper-consignee-detail-dialog"
			v-if="isMobile"
		>
			<v-card class="info-menu-details-wrapper rounded-0">
				<v-card-text>
					<p class="info-title">
						Terminal Details
					</p>
					<div class="general-info">
						<p class="mb-0 info-label">FIRMs Code</p>
						<p class="mb-0 info-value">
							{{ checkValue(getDetails.terminalInfo.firms_code) }}
						</p>
					</div>
					<div class="general-info">
						<p class="mb-0 info-label">Location</p>
						<p class="mb-0 info-value">
							{{ checkValue(getDetails.terminalInfo.address) }}
						</p>
					</div>

					<div class="close-btn mt-2">
						<v-btn class="btn-white" @click="showTerminalInfoDialog = false">
							close
						</v-btn>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
		<EditWareHouseLocation
			v-if="editWarehouseDeliveryLocationDialog"
			:deliveryAddressData="wareHouseLocation"
			:isMobile="isMobile"
			:userDataDetails="userDataDetails"
			:updateLocation="updateLocation"
			:shipment_id="getDetails.shipment_id"
			:dialog.sync="editWarehouseDeliveryLocationDialog"
			@close="editWarehouseDeliveryLocationDialog = false"
		/>
		<ConfirmDialog :dialogData.sync="deleteConfirm">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Delete Warehouse/Delivery Location</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					Are you sure you want to remove warehouse/delivery location for this
					shipment?
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" text @click="removeWareHouseLocation()">
					<span>Delete</span>
				</v-btn>
				<v-btn class="btn-white" text @click="deleteConfirm = false">
					<span>Cancel</span>
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="MBLSubmittedDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Confirm new MBL</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					You are trying to add a different MBL number. This will update all the
					related shipment information. Do you confirm this change?
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" text @click="checkEditedField('mbl_num')">
					<span>Yes, Confirm</span>
				</v-btn>
				<v-btn class="btn-white" text @click="MBLSubmittedDialog = false">
					No, Edit MBL
				</v-btn>
			</template>
		</ConfirmDialog>
	</div>
</template>

<script>
import moment from "moment";
import ModeIcon from "../../Icons/ModeIcon";
import KeneticIcon from "../../Icons/KeneticIcon.vue";
import EditWareHouseLocation from "./EditWareHouseLocation.vue";
import { mapActions, mapGetters } from "vuex";
import globalMethods from "../../../utils/globalMethods";
import GenericIcon from "../../Icons/GenericIcon.vue";
import ConfirmDialog from "@/components/Dialog/GlobalDialog/ConfirmDialog.vue";
import CalendarTooltip from '../../Tables/Shipments/Tabs/Views/components/CalendarTooltip.vue';

export default {
	name: "ShipmentGeneralInformationV2",
	props: ["isMobile", "getDetails", "userDataDetails"],
	components: {
		ModeIcon,
		KeneticIcon,
		GenericIcon,
		EditWareHouseLocation,
		ConfirmDialog,
		CalendarTooltip
	},
	data: () => ({
		editWarehouseDeliveryLocationDialog: false,
		updateLocation: false,
		deleteConfirm: false,
		editCustomerRefField: false,
		editBookingField: false,
		editMblField: false,
		MBLSubmittedDialog: false,
		isMblCalling: false,
		old_mbl_num: "",
		old_booking_num: "",
		old_customer_ref: "",
		trackInfoData: "",
		containerInfo: "",
		checkTrackingNumber: false,
		showTerminalInfoDialog: false,
		isTruncatedText: false,
	}),
	computed: {
		...mapGetters(["getUpdateShipmentFieldsLoading"]),
		wareHouseLocation() {
			return this.getDetails?.warehouseDeliveryLocation;
		},
		bookingConfirmed() {
			return this.getDetails?.booking_confirmed == 1;
		},
	},
	methods: {
		...globalMethods,
		...mapActions({
			fetchDeliveryLocations: "settings/fetchDeliveryLocationsApi",
			deleteShipmentWareHouseLocation:
				"shipmentDetailsV2/deleteShipmentWareHouseLocation",
			fetchNewShipmentDetails: "shipmentDetailsV2/fetchNewShipmentDetails",
			updateShipmentFields: "updateShipmentFields",
		}),
		formatDate(value) {
			return value == "" || value == null || value == "Invalid date"
				? "N/A"
				: moment(value).format("MMM DD, YYYY");
		},
		shipmentTypeImg(type) {
			if (type == "LCL") {
				return require("../../../assets/images/small-container.svg");
			} else if (type == "Air" || type == "AIR") {
				return require("../../../assets/images/airplane-shipment.svg");
			} else if (type == "FCL") {
				return require("../../../assets/images/big-container.svg");
			}
		},
		async updateWareHouseLocation(item) {
			await this.fetchDeliveryLocations(this.wareHouseLocation.warehouse_id);
			this.updateLocation = item;
			this.editWarehouseDeliveryLocationDialog = true;
		},
		async removeWareHouseLocation() {
			this.deleteConfirm = true;
			await this.deleteShipmentWareHouseLocation(
				this.getDetails.shipment_id
			).then((response) => {
				console.log("response", response);
				if (response.status == 200) {
					this.deleteConfirm = false;
					this.notificationMessage("Warehouse/Delivery Location deleted");
				}
			});
			await this.fetchNewShipmentDetails(this.getDetails.shipment_id);
		},
		checkEditedField(editedField) {
			if (editedField === "mbl_num") {
				this.MBLSubmittedDialog = false;
				let mbl_num = this.getDetails.mbl_num;
				if (mbl_num === null || mbl_num === "") {
					this.notificationErrorCustom("Please fill the MBL number");
				} else {
					this.isMblCalling = true;
					this.$store
						.dispatch("booking/checkMblNumber", mbl_num)
						.then((r) => {
							if (typeof r.data !== "undefined") {
								this.mblNumInfo = r.data;
								this.mblTracked = true;
								this.mblTrackFail = false;
								if (
									this.mblNumInfo.is_already_exists.is_already_exists == true
								) {
									this.isMblCalling = false;
									if (
										this.mblNumInfo.is_already_exists.type &&
										this.mblNumInfo.is_already_exists.type == "LCL"
									) {
										this.notificationErrorCustom(
											"LCL Shipment is already exist"
										);
										this.editMblField = false;
										this.getDetails.mbl_num = this.old_mbl_num;
									} else if (
										this.mblNumInfo.is_already_exists.type &&
										this.mblNumInfo.is_already_exists.type == "FCL"
									) {
										this.shipmentExistId = this.mblNumInfo.is_already_exists.shipment_id;
										this.notificationErrorCustom(
											"FCL Shipment is already exist"
										);
										this.editMblField = false;
										this.getDetails.mbl_num = this.old_mbl_num;
									} else {
										this.notificationErrorCustom("Shipment already exist");
										this.editMblField = false;
										this.getDetails.mbl_num = this.old_mbl_num;
									}
								} else {
									if (this.mblNumInfo.attributes === undefined) {
										this.isMblCalling = false;
										this.notificationErrorCustom(
											"Please provide a valid MBL number"
										);
										this.editMblField = false;
										this.getDetails.mbl_num = this.old_mbl_num;
									} else {
										this.trackInfoData = {
											eta:
												this.mblNumInfo.attributes.pod_eta_at == null
													? this.mblNumInfo.attributes.pod_ata_at
													: this.mblNumInfo.attributes.pod_eta_at,
											etd:
												this.mblNumInfo.attributes.pol_etd_at == null
													? this.mblNumInfo.attributes.pol_atd_at
													: this.mblNumInfo.attributes.pol_etd_at,
											location_from: this.mblNumInfo.attributes
												.port_of_lading_name,
											location_to: this.mblNumInfo.attributes
												.port_of_discharge_name,
											carrier: this.mblNumInfo.attributes
												.shipping_line_short_name,
											vessel: this.mblNumInfo.attributes.pod_vessel_name,
											voyage_number: this.mblNumInfo.attributes
												.pod_voyage_number,
											terminal_name: this.mblNumInfo.terminal_name,
										};
										this.containerInfo = this.mblNumInfo.containers;
										this.updateFields("mbl_num");
									}
								}
							} else {
								this.mblTrackFail = true;
								this.notificationErrorCustom(
									"Please provide a valid MBL number"
								);
								this.editMblField = false;
								this.isMblCalling = false;
								this.getDetails.mbl_num = this.old_mbl_num;
							}
							this.checkTrackingNumber = false;
						})
						.catch((e) => {
							console.log("e", e);
							this.mblTrackFail = true;
							this.notificationErrorCustom("Please provide a valid MBL number");
							this.editMblField = false;
							this.getDetails.mbl_num = this.old_mbl_num;
							this.checkTrackingNumber = false;
							this.isMblCalling = false;
						});
				}
			} else if (editedField === "booking_num") {
				if (
					this.getDetails.booking_number === "" ||
					this.getDetails.booking_number === null
				) {
					this.notificationErrorCustom("Please fill the Booking number");
				} else {
					this.updateFields("booking_num");
				}
			} else if (editedField === "final_address") {
				this.updateFields("final_address");
			} else {
				this.updateFields("customer_ref");
			}
		},
		updateFields(field) {
			let payloadObject = {
				field: field,
				shipmentId: this.getDetails.shipment_id,
				mbl_num: this.getDetails.mbl_num,
				booking_num: this.getDetails.booking_number,
				customer_ref: this.getDetails.customer_reference_number,
				date_id: new Date().getTime(),
				final_address: this.getDetails.final_address,
			};
			this.updateShipmentFields(payloadObject)
				.then((response) => {
					this.editMblField = false;
					this.editBookingField = false;
					this.editCustomerRefField = false;
					this.editFinalDeliveryAddressField = false;
					this.notificationMessageCustomSuccess(response.data.message);
					this.isMblCalling = false;
					setTimeout(() => {
						location.reload();
					}, 2000);
				})
				.catch((e) => {
					console.log(e);
					this.notificationErrorCustom("SOMETHING WENT WRONG!");
					this.isMblCalling = false;
				});
		},
		editCustomerRefFieldClick(val) {
			if (val === true) {
				this.old_customer_ref = this.getDetails.customer_reference_number;
				this.editCustomerRefField = true;
			} else {
				this.getDetails.customer_reference_number = this.old_customer_ref;
				this.editCustomerRefField = false;
			}
		},
		editMblFieldClick(val) {
			if (val === true) {
				this.old_mbl_num = this.getDetails.mbl_num;
				if (this.old_mbl_num === "" || this.old_mbl_num === null) {
					this.editMblField = true;
				}
			} else {
				this.getDetails.mbl_num = this.old_mbl_num;
				this.editMblField = false;
			}
		},
		editBookingFieldClick(val) {
			if (val === true) {
				this.old_booking_num = this.getDetails.booking_number;
				if (this.old_booking_num === "" || this.old_booking_num === null) {
					this.editBookingField = true;
				}
			} else {
				this.getDetails.booking_number = this.old_booking_num;
				this.editBookingField = false;
			}
		},
		showTerminalInfo() {
			this.showTerminalInfoDialog = true;
		},
		checkValue(value) {
			return value == "" || value == null || value == "null" ? "N/A" : value;
		},
		// for detecting ellipsis
        detectLineClamp(item) {
            let element = document.querySelector("#td-reference-details");
            if (item !== null && element !== null) {
                let elementValues = this.isEllipsisActive(element, "line");
                this.isTruncatedText = elementValues;
            }
        },
        isEllipsisActive(element, type) {
            if (type === "line") {
                if (element.clientHeight < element.scrollHeight) return true;                
            } else {
                if (element.clientWidth < element.scrollWidth) {
                    var style = element.currentStyle || window.getComputedStyle(element);
                    return style.textOverflow === 'ellipsis'
                }
            }
            return false;
        },
	},
};
</script>
<style lang="scss">
@import "../../../assets/scss/pages_scss/shipment/shipmentDetails/ShipmentGeneralInformationV2.scss";
</style>
