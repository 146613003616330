<template>
    <v-dialog v-model="dialog" max-width="815px" content-class="periodic-statement-dialog" v-resize="onResize" :retain-focus="false" scrollable>
        <v-card class="periodic-supplier-card">            
            <v-card-title>
                <span class="headline" v-if="!isMobile">{{ formTitle }}</span>
                <span class="headline" v-if="isMobile">
                    Periodic Monthly <br> Statements (PMS) Dates <br> For {{ currentYear }}
                </span>

                <button icon dark class="btn-close" @click="close">
                    <v-icon>mdi-close</v-icon>
                </button>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" v-model="valid" action="#" @submit.prevent="">
                    <div class="preloader d-flex justify-center align-center mt-5 pt-5" v-if="getPeriodicStatementsLoading">
                        <v-progress-circular
                            :size="30"
                            color="#1A6D9E"
                            indeterminate>
                        </v-progress-circular>
                    </div> 

                    <v-data-table 
                        :headers="headers"
                        mobile-breakpoint="0"
                        :items="periodicStatements"
                        class="periodic-table-inner-content elevation-1"
                        hide-default-footer
                        fixed-header
                        :items-per-page="15"
                        v-if="!getPeriodicStatementsLoading">

                        <template v-slot:[`item.entry_month`]="{ item }">
                            <span class="month_year" style="color: #4B586C;">
                                <!-- {{ item.entry_month || '--' }} -->

                                {{ getEntryMonthValue(item) }}
                            </span>                                        
                        </template>

                        <!-- for pms -->
                        <template v-slot:[`item.month`]="{ item }">
                            <span class="month_year" style="color: #4B586C;">
                                {{ formatDate(item, item.month, 'month') }}
                            </span>                                        
                        </template>

                        <template v-slot:[`item.preliminary_statement`]="{ item }">
                            <span class="full-date">
                                {{ formatDate(item, item.preliminary_statement, 'full_date') }}
                            </span>                                        
                        </template>

                        <template v-slot:[`item.final_statement`]="{ item }">
                            <span class="full-date">
                                {{ formatDate(item, item.final_statement, 'full_date') }}
                            </span>                                        
                        </template>
                    </v-data-table>

                    <div class="notes-section mt-2" v-if="!getPeriodicStatementsLoading">
                        <div class="notes-title-section d-flex align-center">
                            <p class="mb-0 ml-1">Notes</p>
                        </div>
                        <div class="notes-content-section">
                            <ol>
                                <li>Preliminary statement date is the 11th workday of the month</li>
                                <li>Final Statement date is the 15th workday of the month</li>
                            </ol>
                        </div>
                    </div>
                </v-form>
            </v-card-text>            
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
    name: "PMSDatesDialog",
    props: ['dialogData'],
    data: () => ({
        valid: true,
        headers: [
            {
                text: "Entry Month",
                align: "start",
                sortable: false,
                value: "entry_month",
                width: "25%", 
                fixed: false
            },
            {
                // text: "Month",
                text: "PMS",
                align: "start",
                sortable: false,
                value: "month",
                width: "25%", 
                fixed: false
            },
            {
                text: "Preliminary Statement",
				align: "start",
                value: "preliminary_statement",
                sortable: false,
                width: "25%", 
                fixed: false
            },
            {
                text: "Final Statement",
				align: "start",
                value: "final_statement",
                sortable: false,
                width: "25%", 
                fixed: true
            },
        ],
        isMobile: false
    }),
    computed: {
        ...mapGetters({
            getPeriodicStatements: "statements/getPeriodicStatements",
            getPeriodicStatementsLoading: "statements/getPeriodicStatementsLoading"
        }),
        formTitle() {
            return `Periodic Monthly Statements (PMS) Dates For ${moment().year()}`
        },
        dialog: {
            get () {
                return this.dialogData
            },
            set (value) {
                if (!value) {
                    this.$emit('update:dialogData', false)
                } else {
                    this.$emit('update:dialogData', true)
                }
            }
        },
        periodicStatements() {
            let statements = []

            if (typeof this.getPeriodicStatements !== "undefined" && this.getPeriodicStatements !== null) {
                if (this.getPeriodicStatements.length !== undefined && this.getPeriodicStatements.length > 0) {
                    statements = this.getPeriodicStatements

                    // statements.map(v => {
                    //     v.preliminary_statement = moment(v.preliminary_statement).format("MM/DD/YYYY");
                    //     v.final_statement = moment(v.final_statement).format("MM/DD/YYYY");
                    //     v.month = this.formatMonth(v.month);
                    // })
                }
            }

            return statements
        },
        currentYear() {
            return moment().year();
        }
    },
    methods: {
        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        close() {
            this.dialog = false;
        },
        formatMonth(num) {
            return moment(num, 'MM').format('MMMM');
        },
        formatDate(item, date, isFor) {
            if (date !== null) {
                if (isFor === "month") {
                    let formatted = this.formatMonth(date);
                    return formatted === "January" ? `${formatted}, ${item.year}` : formatted;
                } else {
                    return moment(date).format("MM/DD/YYYY");
                }
            }
        },
        getEntryMonthValue(item) {
            if (item !== null) {
                if (item.entry_month === "December") {
                    let year = item.year - 1;
                    return `${item.entry_month}, ${year}`
                } else if (item.entry_month === "January") {
                    return `${item.entry_month}, ${item.year}`
                } else {
                    return item.entry_month;
                }
            }
        }
    }
}
</script>

<style lang="scss">
.periodic-statement-dialog {
    .v-card.periodic-supplier-card {
        .v-card__title {
            border: none !important;
        }

        .v-card__text {
            min-height: 400px;
            padding: 0 24px 24px;

            .v-data-table {
                &.periodic-table-inner-content {
                    border-radius: 0px;
                    box-shadow: none !important;

                    .v-data-table__wrapper {
                        table {
                            border: none;

                            thead {
                                tr {
                                    th {
                                        background-color: $shifl-neutral-n10;
                                        text-transform: capitalize;
                                        font-size: 14px;
                                        color: $shifl-neutral-n70;
                                        height: 40px;
                                        font-weight: unset;
                                        padding: 8px 16px;

                                        span {
                                            font-family: "Inter-SemiBold", sans-serif !important;
                                            color: $shifl-neutral-n70 !important;
                                        }
                                    }
                                }
                            }

                            tbody {
                                tr {
                                    td {
                                        padding: 8px 16px;
                                        height: 45px;
                                    }

                                    &:last-child {
                                        td {
                                            border: none !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .notes-section {
                min-height: 80px;
                background-color: $shifl-primary-b20;
                padding: 12px;

                .notes-title-section {
                    p {
                        font-family: "Inter-SemiBold", sans-serif !important;
                        color: $shifl-neutral-n90;
                        font-size: 15px;
                    }
                }

                .notes-content-section {
                    padding: 0 2px;

                    ol {
                        li {
                            color: $shifl-neutral-n60;
                        }
                    }
                }
            }
        }
    }
}
</style>