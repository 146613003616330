/** @format */

const headers = {
	containerDetailsHeader: {
		data: [
			{
				text: "Size",
				align: "start",
				sortable: false,
				value: "size",
				fixed: true,
				width: "20%",
				textAlign: "left",
			},
			{
				text: "Capacity",
				align: "center",
				sortable: false,
				value: "capacity",
				fixed: true,
				width: "30%",
				textAlign: "center",
			},
			{
				text: "",
				align: "start",
				sortable: false,
				value: "minus",
				fixed: true,
				width: "15%",
				textAlign: "left",
			},
			{
				text: "Quantity",
				align: "start",
				sortable: false,
				value: "how_many",
				fixed: true,
				width: "15%",
				textAlign: "left",
			},
			{
				text: "",
				align: "start",
				sortable: false,
				value: "plus",
				fixed: true,
				width: "15%",
				textAlign: "left",
			},
		],
	},
	cargoDetailsHeader: {
		data: [
			{
				text: "Total Cartons",
				align: "end",
				sortable: false,
				value: "total_carton",
				fixed: false,
				width: "13%",
				textAlign: "right",
				sort_order: 2,
			},
			{
				text: "Total Volume",
				align: "end",
				sortable: false,
				value: "total_volume",
				fixed: false,
				width: "13%",
				textAlign: "right",
				sort_order: 3,
			},
			{
				text: "Total Weight",
				align: "end",
				sortable: false,
				value: "total_weight",
				width: "13%",
				fixed: false,
				textAlign: "right",
				sort_order: 4,
			},
			{
				text: "Commodity Description Summary",
				align: "start",
				sortable: false,
				value: "commodity_description",
				fixed: false,
				width: "28%",
				textAlign: "center",
				sort_order: 5,
			},
			{
				text: "Incoterm",
				align: "start",
				sortable: false,
				value: "incoterm",
				fixed: false,
				width: "10%",
				textAlign: "center",
				sort_order: 6,
			},
		],
		shipper: [
			{
				text: "Shipper",
				align: "start",
				sortable: false,
				value: "shipper",
				fixed: true,
				width: "23%",
				textAlign: "left",
				sort_order: 1,
			},
		],
		consignee: [
			{
				text: "Consignee",
				align: "start",
				sortable: false,
				value: "consignee",
				fixed: true,
				width: "23%",
				textAlign: "left",
				sort_order: 1,
			},
		],
	},
};

export default headers;
