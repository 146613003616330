<!-- @format -->

<template>
	<div class="order-details-wrapper">
		<h2 class="pb-4">Orders</h2>
		<div id="OrderSection" class="orders-section auto-orders">
			<div id="new-booking-shipment">
				<div class="order-details">
					<div
						id="purchaseOrderSection"
						ref="purchaseOrderSection"
						class="d-flex flex-column new-purchase-orders-section"
					>
						<div
							v-bind:key="`poipom-${key}`"
							v-for="(poi, key) in shipmentItem.orderItems"
						>
							<div
								v-if="poi.layout === 'default'"
								style="margin-bottom: 16px;"
								class="new-purchase-order-wrapper"
							>
								<div
									:class="
										`new-select-items-wrapper-v2 new-select-autocomplete-wrapper-v2 select-autocomplete-wrapperpoi-${key}`
									"
								>
									<v-autocomplete
										class="text-fields selected-input-field selected-supplier-buyer"
										:items="selectedShipperConsigneeOptions"
										v-model="poi.selected_order_user_id"
										height="40px"
										outlined
										item-text="name"
										item-value="id"
										hide-details="auto"
										:placeholder="
											`Select ${
												shipmentItem.userRole === 'shipper'
													? 'Buyer'
													: 'Supplier'
											}`
										"
										:rules="[(v) => !!v || '']"
									/>

									<v-autocomplete
										:filter="customFilterPo"
										v-model="poi.purchase_order_id"
										spellcheck="false"
										:attach="`.select-autocomplete-wrapperpoi-${key}`"
										class="select-purchase-order-po  selected-input-field select-purchase-order-mobile ma-0"
										:items="
											filterOrderByUser(
												poi.purchase_order_options,
												poi.selected_order_user_id
											)
										"
										:placeholder="
											`Enter ${
												shipmentItem.userRole === 'shipper' ? 'SO' : 'PO'
											}`
										"
										outlined
										item-text="po_number"
										item-value="id"
										hide-details="auto"
										@change="updateProducts(poi, key)"
										@click="ordersSearchText = ''"
										clearable
										v-if="!poi.quickAddOrder"
										:menu-props="{
											contentClass: 'order-items-lists',
											...menuProps,
										}"
										:rules="[(v) => !!v || '']"
									>
										<template v-slot:item="{ item }">
											<div class="d-flex flex-column" style="width: 100%;">
												<div
													style="width: 100%; padding-bottom: 12px;"
													class="d-flex first-row justify-space-between"
												>
													<div class="d-flex flex-row align-center">
														<span
															>{{
																shipmentItem.userRole === "shipper"
																	? "SO"
																	: "PO"
															}}#</span
														>
														<span style="padding-left: 2px;">{{
															item.po_number
														}}</span>
													</div>
													<div style="font-size: 14px !important;">
														{{ currencyNumberFormat(item.total) }}
													</div>
												</div>
												<div
													class="d-flex second-row"
													style="width: 100%; padding-bottom: 4px;"
												>
													{{ getWarehouseName(item) }}
												</div>
												<div class="d-flex third-row">
													{{ item.ship_to }}
												</div>
												<div
													style="width: 100%; padding-bottom: 8px;"
													class="d-flex fourth-row flex-row justify-space-between"
												>
													<div>
														<span>Date:</span>
														<span>{{ " " + formatDate(item.created_at) }}</span>
													</div>
													<div>
														<span>CRD:</span>
														<span>{{
															" " + formatDate(item.cargo_ready_date)
														}}</span>
													</div>
												</div>
												<div class="d-flex last-row" style="width: 100%;">
													<a class="last-row-status">
														{{
															item.status === "pending"
																? "Pending"
																: "Partially Booked"
														}}
													</a>
													<span
														class="production-status-label ml-1"
														v-if="item.production_status_name"
														>{{ item.production_status_name }}</span
													>
												</div>
											</div>
										</template>
										<template v-slot:no-data>
											<div
												class="no-data-found"
												v-if="searchOrders && !ordersSearchText"
											>
												<img
													src="@/assets/icons/empty-document-blue.svg"
													width="24px"
													height="24px"
													alt=""
												/>
												<h3 class="text-center">No Purchase Order</h3>
												<p class="mb-0 text-center">
													You have not added any purchase order yet. Quickly add
													one here for this booking request.
												</p>
											</div>
											<div class="no-data-found" v-else>
												<img
													src="@/assets/icons/empty-document-blue.svg"
													width="24px"
													height="24px"
													alt=""
												/>
												<h3 class="text-center">No Match Found</h3>
												<p class="mb-0 text-center">
													There is no PO with ‘{{ ordersSearchText }}’ as order
													ID.
												</p>
											</div>
										</template>
										<v-list
											slot="append-item"
											class="px-4 add-new-orders-options quick-add-content"
											v-if="reference !== 'booking-request-form'"
										>
											<div class="add-new-orders-buttons">
												<p
													class="mb-0 quick-order"
													@click.stop="quickAddOrders(key)"
												>
													<img
														src="@/assets/icons/plus.svg"
														class="mr-2"
														width="12px"
														height="12px"
														alt=""
													/>
													Quick Add
													<span>{{
														shipmentItem.userRole === "shipper"
															? ordersSearchText
																? " ‘SO " + ordersSearchText + "’"
																: "SO"
															: ordersSearchText
															? " ‘PO " + ordersSearchText + "’"
															: "PO"
													}}</span>
												</p>
												<p class="mb-0 add-order" @click.stop="createOrder">
													Open Full Form
													<v-icon>mdi-chevron-right</v-icon>
												</p>
											</div>
										</v-list>
									</v-autocomplete>
									<div v-if="poi.quickAddOrder">
										<v-text-field
											height="40px"
											color="#002F44"
											type="text"
											dense
											v-model="poi.order_number"
											class="select-purchase-order-po selected-input-field ma-0"
											:placeholder="
												`${
													shipmentItem.userRole === 'shipper'
														? 'Enter SO'
														: 'Enter PO'
												}`
											"
											outlined
											hide-details="auto"
											@click.prevent="selectAllProducts(poi, key)"
											:rules="[(v) => !!v || '']"
										/>
									</div>

									<div
										class="checkbox-wrapper-create checkbox-wrapper-desktop"
										v-if="!poi.quickAddOrder && poi.purchase_order_id"
									>
										<label
											style="position: relative;"
											:class="`${poi.selectAll ? 'checked' : ''}`"
										>
											<GenericIcon
												:marginLeft="0"
												:iconName="
													`${poi.selectAll ? 'checked' : 'not-checked'}`
												"
											/>
											<input
												@click.prevent="selectAllProducts(poi, key)"
												style="position: absolute; opacity: 0;"
												type="checkbox"
												:checked="poi.selectAll"
												class=""
												:disabled="
													shipmentItem.orderItems.length == 0 || poi.loading
												"
											/>
											<span
												style="color: #253041 !important; font-weight: 400; font-size: 14px;"
											>
												Select All Products
											</span>
										</label>
									</div>
									<div
										class="text-field-wrapper dates-wrapper"
										v-if="poi.quickAddOrder"
									>
										<v-menu
											v-model="poi.menuCargoReadyDate"
											:close-on-content-click="true"
											transition="scale-transition"
											offset-y
											min-width="auto"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													class="text-fields etd-field date-fields"
													placeholder="Cargo Ready Date"
													outlined
													v-bind="attrs"
													v-on="on"
													type="text"
													hide-details="auto"
													clear-icon
													:height="40"
													v-model="poi.cargo_ready_date"
													@input="(val) => updateCargoReadyDateInput(poi, val)"
													append-icon="mdi-calendar"
												/>
											</template>
											<v-date-picker v-model="poi.cargo_ready_date" />
										</v-menu>
									</div>
									<div class="delete-btn-wrapper">
										<v-btn
											class="btn-white mr-4"
											@click="removePurchaseOrderItem(key)"
										>
											<CustomIcon
												marginLeft="3px"
												iconName="trash-can"
												color="#ff5252"
											/>
										</v-btn>
									</div>
								</div>
								<div class="table-wrapper">
									<v-data-table
										:headers="headersProducts"
										:item-class="getItemClass"
										:items="filterProducts(poi)"
										mobile-breakpoint="769"
										:page="1"
										:items-per-page="100"
										class="purchase-orders-table"
										hide-default-footer
										style="box-shadow: none !important"
										fixed-header
										hide-default-header
										ref="create-shipment-purchase-order-table"
									>
										<template v-slot:header="{ props: { headers } }">
											<thead>
												<tr>
													<th
														v-for="(item, index) in headers"
														:key="index"
														class="op-"
														role="column-header"
														:aria-label="item.text"
														:style="
															`color: #69758C !important;padding-right: 12px;padding-left: 12px !important;width: ${item.width};text-align: ${item.textAlign}`
														"
														scope="col"
													>
														{{ item.text }}
													</th>
												</tr>
											</thead>
										</template>

										<template v-slot:[`item.product_id`]="{ item }">
											<div
												id="select-autocomplete-products-v2"
												@click="selectOpenedProductBox(item)"
											>
												<div v-if="!poi.quickAddOrder && !item.quickAddProduct">
													<v-autocomplete
														v-if="
															typeof item.addSpecial == 'undefined' &&
																typeof item.special == 'undefined'
														"
														:filter="customFilterProduct"
														v-model="item.product_id"
														spellcheck="false"
														class="select-order-products"
														:items="poi.product_options"
														@change="
															updateProductPurchaseOrder(
																poi.product_options,
																item,
																key
															)
														"
														placeholder="Select Product"
														outlined
														item-text="product.name"
														item-value="product_id"
														hide-details="auto"
														clearable
														@click="orderProductsSearchText = ''"
														:menu-props="{
															contentClass: 'product-items-lists',
															...menuProps,
														}"
														:rules="[(v) => !!v || '']"
													>
														<template v-slot:item="{ item }">
															<div
																class="d-flex flex-row justify-space-between new-product-item-wrapper-v2"
															>
																<div
																	style="width: 50%;"
																	class="d-flex flex-column"
																>
																	<div>
																		<span class="product-category-sku"
																			>#{{ item.product.category_sku }}</span
																		>
																	</div>
																	<div>
																		<p>{{ item.product.name }}</p>
																		<p class="product-unit-price">
																			${{
																				item.product.unit_price !== null
																					? item.product.unit_price.toFixed(2)
																					: "0.00"
																			}}
																		</p>
																	</div>
																</div>
																<div
																	style="width: 50%;"
																	class="d-flex justify-end"
																>
																	<img
																		class="product-image"
																		:src="getImgUrl(item.product.image)"
																		alt=""
																	/>
																</div>
															</div>
														</template>
														<template v-slot:no-data>
															<div
																class="no-data-found"
																v-if="
																	searchProducts && !orderProductsSearchText
																"
															>
																<img
																	src="@/assets/icons/empty-product-icon.svg"
																	width="24px"
																	height="24px"
																	alt=""
																/>
																<h3 class="text-center">No Product</h3>
																<p class="mb-0 text-center">
																	You have not added any product yet. Quickly
																	add one here for this order.
																</p>
															</div>
															<div class="no-data-found" v-else>
																<img
																	src="@/assets/icons/empty-product-icon.svg"
																	width="24px"
																	height="24px"
																	alt=""
																/>
																<h3 class="text-center">
																	No Match Found
																</h3>
																<p class="mb-0 text-center">
																	There is no product with ‘{{
																		orderProductsSearchText
																	}}’ as SKU ID.
																</p>
															</div>
														</template>
														<v-list
															slot="append-item"
															class="px-4 add-new-orders-options quick-add-content"
															v-if="reference !== 'booking-request-form'"
														>
															<div class="add-new-orders-buttons">
																<p
																	class="mb-0 quick-order"
																	@click.stop="
																		quickAddProducts(
																			key,
																			selectedProductForQuickAdd
																		)
																	"
																>
																	<img
																		src="@/assets/icons/plus.svg"
																		class="mr-1"
																		width="12px"
																		height="12px"
																	/>
																	Quick Add
																	<span>{{
																		orderProductsSearchText
																			? orderProductsSearchText
																			: "Product"
																	}}</span>
																</p>
																<p
																	class="mb-0 add-order"
																	@click.stop="createProduct(key)"
																>
																	Open Full Form
																	<v-icon>mdi-chevron-right</v-icon>
																</p>
															</div>
														</v-list>
													</v-autocomplete>
												</div>

												<div v-if="poi.quickAddOrder && !item.quickAddProduct">
													<v-autocomplete
														v-if="
															typeof item.addSpecial == 'undefined' &&
																typeof item.special == 'undefined'
														"
														:filter="customFilterSku"
														v-model="item.product_id"
														spellcheck="false"
														class="select-order-products"
														:items="
															shipmentItem.userRole === 'shipper'
																? getBuyersProducts
																: getVendorsProducts
														"
														placeholder="Select Product"
														outlined
														item-text="name"
														item-value="id"
														hide-details="auto"
														clearable
														@change="
															updateProductPurchaseOrder(
																shipmentItem.userRole === 'shipper'
																	? getBuyersProducts
																	: getVendorsProducts,
																item,
																key,
																true
															)
														"
														@click="orderProductsSearchText = ''"
														:menu-props="{
															contentClass: 'product-items-lists ',
															...menuProps,
														}"
														:rules="[(v) => !!v || '']"
													>
														<template v-slot:item="{ item }">
															<div
																class="d-flex flex-row justify-space-between new-product-item-wrapper-v2"
															>
																<div
																	style="width: 50%;"
																	class="d-flex flex-column"
																>
																	<div>
																		<span class="product-category-sku"
																			>#{{ item.category_sku }}</span
																		>
																	</div>
																	<div>
																		<p>{{ item.name }}</p>
																		<p class="product-unit-price">
																			${{
																				item.unit_price !== null
																					? item.unit_price.toFixed(2)
																					: "0.00"
																			}}
																		</p>
																	</div>
																</div>
																<div
																	style="width: 50%;"
																	class="d-flex justify-end"
																>
																	<img
																		class="product-image"
																		:src="getImgUrl(item.image)"
																		alt=""
																	/>
																</div>
															</div>
														</template>
														<template v-slot:no-data>
															<div
																class="no-data-found"
																v-if="
																	searchProducts && !orderProductsSearchText
																"
															>
																<img
																	src="@/assets/icons/empty-product-icon.svg"
																	width="24px"
																	height="24px"
																	alt=""
																/>
																<h3 class="text-center">No Product</h3>
																<p class="mb-0 text-center">
																	You have not added any product yet. Quickly
																	add one here for this order.
																</p>
															</div>
															<div class="no-data-found" v-else>
																<img
																	src="@/assets/icons/empty-product-icon.svg"
																	width="24px"
																	height="24px"
																	alt=""
																/>
																<h3 class="text-center">
																	No Match Found
																</h3>
																<p class="mb-0 text-center">
																	There is no product with ‘{{
																		orderProductsSearchText
																	}}’ as SKU ID.
																</p>
															</div>
														</template>
														<v-list
															slot="append-item"
															class="px-4 add-new-orders-options quick-add-content"
															v-if="reference !== 'booking-request-form'"
														>
															<div class="add-new-orders-buttons">
																<p
																	class="mb-0 quick-order"
																	@click.stop="
																		quickAddProducts(
																			key,
																			selectedProductForQuickAdd
																		)
																	"
																>
																	<img
																		src="@/assets/icons/plus.svg"
																		class="mr-1"
																		width="12px"
																		height="12px"
																	/>
																	Quick Add
																	<span>{{
																		orderProductsSearchText
																			? orderProductsSearchText
																			: "Product"
																	}}</span>
																</p>
																<p
																	class="mb-0 add-order"
																	@click.stop="createProduct()"
																>
																	Open Full Form
																	<v-icon>mdi-chevron-right</v-icon>
																</p>
															</div>
														</v-list>
													</v-autocomplete>
												</div>

												<div v-if="item.quickAddProduct">
													<v-text-field
														v-if="
															typeof item.addSpecial == 'undefined' &&
																typeof item.special == 'undefined'
														"
														height="40px"
														color="#002F44"
														v-model="item.product_number"
														type="text"
														dense
														class="sku-id"
														placeholder="SKU ID"
														outlined
														hide-details="auto"
														:rules="[(v) => !!v || '']"
													/>
												</div>

												<div
													class="product-error-message red--text"
													v-if="
														typeof item.addSpecial == 'undefined' &&
															typeof item.special == 'undefined' &&
															validateProduct(item, key) === 'error'
													"
												>
													This product has already been selected.
												</div>

												<div
													v-if="
														item.purchase_order_id !== '' &&
															typeof item.addSpecial !== 'undefined'
													"
													class="add-product-purchase-order-wrapper add-product-item"
												>
													<a
														style="cursor: pointer; display: flex; flex-direction: row;"
														@click="addProductToPurchaseOrders(key)"
													>
														<span style="margin-right: 6.3px;">
															<GenericIcon color="#1A6D9E" iconName="plus" />
														</span>
														<span>Add Product</span>
													</a>
												</div>
											</div>
										</template>

										<template v-slot:[`item.product_description`]="{ item }">
											<div
												v-if="
													typeof item.special == 'undefined' &&
														typeof item.addSpecial == 'undefined'
												"
											>
												<div class="action-image" v-show="item.isEditingField">
													<v-btn @click="saveProductDescriptions(item, key)">
														<img
															src="@/assets/icons/check-white.svg"
															alt=""
															width="18px"
														/>
													</v-btn>
													<v-btn @click="clearProductDescriptions(item, key)">
														<img
															src="@/assets/icons/white-close.svg"
															alt=""
															width="18px"
														/>
													</v-btn>
												</div>

												<div
													style="text-align: right !important;"
													:class="item.isEditingField ? 'text-description' : ''"
													v-if="!item.isEditingField"
													@click="showField(item, key)"
												>
													<v-text-field
														height="40px"
														color="#002F44"
														width="200px"
														v-model="item.description"
														dense
														class="unit"
														placeholder="Product Description"
														outlined
														hide-details="auto"
														readonly
													/>
												</div>
												<div
													style="text-align: right !important;"
													:class="item.isEditingField ? 'text-description' : ''"
													v-if="item.isEditingField"
												>
													<textarea
														class="field-value form-control"
														v-model="productDescription"
														rows="3"
														cols="24"
													/>
												</div>
											</div>
										</template>

										<template v-slot:[`item.addAll`]="{ item }">
											<div
												v-if="
													typeof item.special == 'undefined' &&
														typeof item.addSpecial == 'undefined' &&
														disabledProductsField(
															poi.quickAddOrder,
															item.quickAddProduct
														)
												"
												class="d-flex"
											>
												<label
													:class="`${item.addAll ? 'checked' : ''}`"
													style="position: relative;"
												>
													<GenericIcon
														class="add-all-icon ml-4"
														:iconName="
															`${item.addAll ? 'checked' : 'not-checked'}`
														"
													/>
													<input
														@click.prevent="addAllCartons(item, key)"
														style="position: absolute; opacity: 0;"
														type="checkbox"
														v-model="item.addAll"
														:checked="item.addAll"
													/>
												</label>
											</div>
										</template>

										<template v-slot:[`item.carton`]="{ item }">
											<v-tooltip
												left
												:content-class="
													`carton-tooltip ${cartonTooltipClass(item)}`
												"
											>
												<template v-slot:activator="{ on, attrs }">
													<div
														v-on="on"
														v-bind="attrs"
														:class="
															`${
																cartonTooltipClass(item) === 'tooltip-has-error'
																	? 'carton-wrapper-error'
																	: ''
															}`
														"
													>
														<v-text-field
															v-if="
																typeof item.special == 'undefined' &&
																	typeof item.addSpecial == 'undefined'
															"
															height="40px"
															color="#002F44"
															width="200px"
															:disabled="item.addAll"
															v-model="item.carton"
															type="number"
															dense
															:rules="[(v) => !!v || '', cartonRules(item)]"
															class="carton product-carton"
															placeholder=""
															@change="
																updateProductCarton(item, key),
																	calculateOverAllTotal()
															"
															outlined
															:max="item.unship_cartons"
															min="1"
															hide-details="auto"
														/>
													</div>
												</template>
												<span>
													{{ cartonTooltipMessage(item, poi.quickAddOrder) }}
												</span>
											</v-tooltip>
										</template>

										<template v-slot:[`item.volume`]="{ item }">
											<v-text-field
												v-if="
													typeof item.special == 'undefined' &&
														typeof item.addSpecial == 'undefined'
												"
												height="40px"
												color="#002F44"
												width="200px"
												v-model="item.volume"
												:disabled="item.addAll"
												type="number"
												dense
												class="unit"
												placeholder="Volume"
												outlined
												hide-details="auto"
												@change="calculateOverAllTotal()"
											/>
										</template>

										<template v-slot:[`item.weight`]="{ item }">
											<v-text-field
												v-if="
													typeof item.special == 'undefined' &&
														typeof item.addSpecial == 'undefined'
												"
												height="40px"
												color="#002F44"
												width="200px"
												v-model="item.weight"
												:disabled="item.addAll"
												type="number"
												dense
												class="unit"
												placeholder="0.00"
												outlined
												hide-details="auto"
												@change="calculateOverAllTotal()"
											/>
										</template>

										<template v-slot:[`item.unit`]="{ item }">
											<v-text-field
												v-if="
													typeof item.special == 'undefined' &&
														typeof item.addSpecial == 'undefined'
												"
												height="40px"
												color="#002F44"
												width="200px"
												:disabled="disabledProductFields(item, poi)"
												v-model="item.unit"
												@keyup="updateProductUnit(item, key)"
												type="number"
												dense
												class="unit"
												placeholder="Unit"
												outlined
												hide-details="auto"
												min="1"
											/>
										</template>

										<template v-slot:[`item.unit_price`]="{ item }">
											<div
												v-if="
													typeof item.special == 'undefined' &&
														typeof item.addSpecial == 'undefined'
												"
											>
												<v-text-field
													height="40px"
													color="#002F44"
													width="200px"
													v-model="item.unit_price"
													type="number"
													dense
													class="unit"
													placeholder="0"
													outlined
													hide-details="auto"
													:disabled="disabledProductFields(item, poi)"
												/>
											</div>
										</template>

										<template v-slot:[`item.amount`]="{ item }">
											<div>
												<div
													v-if="
														typeof item.special == 'undefined' &&
															typeof item.addSpecial == 'undefined'
													"
													class="d-flex amount"
												>
													{{ "$" + calculateAmount(item) }}
												</div>
											</div>
										</template>

										<template v-slot:[`item.commodity_description`]="{ item }">
											<div
												style="text-align: right !important;"
												class="product-classification-description"
												v-if="
													typeof item.special == 'undefined' &&
														typeof item.addSpecial == 'undefined'
												"
											>
												<v-text-field
													height="40px"
													color="#002F44"
													width="200px"
													v-model="item.product_classification_description"
													type="text"
													dense
													class="unit"
													placeholder="Enter Description"
													outlined
													hide-details="auto"
													:disabled="disabledProductFields(item, poi)"
												/>
											</div>
										</template>

										<template v-slot:[`item.hs_code`]="{ item }">
											<div
												style="text-align: right !important;"
												v-if="
													typeof item.special == 'undefined' &&
														typeof item.addSpecial == 'undefined'
												"
											>
												<v-text-field
													height="40px"
													color="#002F44"
													width="200px"
													v-model="item.classification_code"
													type="text"
													dense
													class="unit"
													placeholder="Enter Code"
													outlined
													hide-details="auto"
													:disabled="disabledProductFields(item, poi)"
												/>
											</div>
										</template>

										<template v-slot:[`item.action`]="{ item }">
											<div
												:class="
													`action-wrapper ${
														typeof item.special == 'undefined' &&
														typeof item.addSpecial == 'undefined'
															? 'x-wrapper'
															: ''
													}`
												"
											>
												<a
													v-if="
														typeof item.special == 'undefined' &&
															typeof item.addSpecial == 'undefined'
													"
													@click.stop="
														removeProductFromPurchaseOrders(key, item)
													"
													style="cursor: pointer;"
													class="d-flex"
												>
													<GenericIcon
														iconName="trash-product-light"
														:color="IconColor(poi)"
													/>
												</a>
											</div>
										</template>
									</v-data-table>
									<div
										v-if="filterProducts(poi).length > 0"
										class="d-flex flex-row order-total-wrapper"
									>
										<div class="order-total-item">
											<p>
												Total Cartons:
												{{ calculateTotals(poi, "carton") }}
											</p>
											<p>Total Volume: {{ calculateTotals(poi, "volume") }}</p>
											<p>Total Weight: {{ calculateTotals(poi, "weight") }}</p>
											<p>
												Cargo Ready Date:
												{{
													poi.cargo_ready_date ? poi.cargo_ready_date : "N/A"
												}}
											</p>
										</div>
										<div class="order-total-item info-message">
											<img
												src="@/assets/icons/info.svg"
												width="16px"
												height="16px"
											/>
											<span>
												The unit price and amount column is for custom filling
											</span>
										</div>
									</div>
								</div>
							</div>
							<div
								v-if="poi.layout === 'manual'"
								class="new-purchase-order-wrapper mb-4"
							>
								<div
									:class="
										`new-select-items-wrapper-v2 new-select-autocomplete-wrapper-v2 select-autocomplete-wrapperpom-${key}`
									"
								>
									<div class="input-general-wrapper">
										<v-autocomplete
											class="selected-input-field selected-supplier-buyer"
											:items="selectedShipperConsigneeOptions"
											v-model="poi.selected_order_user_id"
											height="40px"
											outlined
											item-text="name"
											item-value="id"
											hide-details="auto"
											:placeholder="
												`Select ${
													shipmentItem.userRole === 'shipper'
														? 'Buyer'
														: 'Supplier'
												}`
											"
											:rules="[(v) => !!v || '']"
										/>
									</div>
									<v-text-field
										v-model="poi.order_number"
										:height="40"
										width="200px"
										type="text"
										dense
										:class="`custom-font-weight-400 custom-font-14`"
										:placeholder="
											`${
												shipmentItem.userRole === 'shipper'
													? 'Enter SO'
													: 'Enter PO'
											}`
										"
										outlined
										hide-details="auto"
										:rules="[(v) => !!v || 'Order number is required']"
										class="selected-input-field"
									/>

									<div class="text-field-wrapper dates-wrapper">
										<v-menu
											v-model="poi.menuCargoReadyDate"
											:close-on-content-click="true"
											transition="scale-transition"
											offset-y
											min-width="auto"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													class="text-fields  etd-field date-fields"
													placeholder="Cargo Ready Date"
													outlined
													v-bind="attrs"
													v-on="on"
													type="text"
													hide-details="auto"
													clear-icon
													:height="40"
													v-model="poi.cargo_ready_date"
													@input="(val) => updateCargoReadyDateInput(poi, val)"
													append-icon="mdi-calendar"
												/>
											</template>
											<v-date-picker v-model="poi.cargo_ready_date" />
										</v-menu>
									</div>
									<div class="delete-btn-wrapper">
										<v-btn
											class="btn-white mr-4"
											@click="removePurchaseOrderManualItem(key)"
										>
											<CustomIcon
												marginLeft="3px"
												iconName="trash-can"
												color="#ff5252"
											/>
										</v-btn>
									</div>
								</div>

								<div class="d-flex flex-row total-carton-volume-weight">
									<div class="text-field-wrapper">
										<span class="text-field-label">Total Carton</span>
										<v-text-field
											:height="40"
											color="#002F44"
											width="200px"
											v-model="poi.total_cartons"
											type="number"
											dense
											outlined
											hide-details="auto"
											placeholder="Enter Total Carton"
											:rules="[(v) => !!v || 'Total Carton is required']"
											@input="calculateOverAllTotal()"
										/>
									</div>

									<div class="text-field-wrapper">
										<span class="text-field-label">Total Volume</span>
										<v-text-field
											:height="40"
											color="#002F44"
											width="200px"
											v-model="poi.total_volumes"
											type="number"
											dense
											outlined
											hide-details="auto"
											placeholder="Enter Total Volume"
											:rules="[(v) => !!v || 'Total Volume is required']"
											@input="calculateOverAllTotal()"
										/>
									</div>
									<div class="text-field-wrapper">
										<span class="text-field-label">Total Weight</span>
										<v-text-field
											:height="40"
											color="#002F44"
											width="200px"
											v-model="poi.total_weights"
											type="number"
											dense
											outlined
											hide-details="auto"
											placeholder="Enter Total Weight"
											:rules="[(v) => !!v || 'Total Weight is required']"
											@input="calculateOverAllTotal()"
										/>
									</div>
								</div>
								<div class="flex-row pa-4">
									<GenericTable
										:headers="headersDocuments"
										:items.sync="poi.documents"
										:pomKey="key"
										shipmentId="1"
										textColor="#253041"
										headerBackground="#F9FAFB"
									/>
								</div>
							</div>
						</div>
						<div
							style="padding-left: 0px !important;"
							class="d-flex add-product-purchase-order-wrapper add-purchase-order-wrapper flex-row"
						>
							<v-btn
								class="btn-white mr-4 shipment-header-button-btn add-purchase-order-btn-wrapper btn-white-custom"
								@click="addPurchaseOrderItem()"
								:disabled="defaultBtnDisable"
							>
								<span class="add-purchase-order-span">{{
									shipmentItem.userRole === "shipper"
										? "+ Add Sales Orders"
										: "+ Add Purchase Orders"
								}}</span>
							</v-btn>
							<v-btn
								class="btn-white mr-4 shipment-header-button-btn add-purchase-order-btn-wrapper black--text btn-white-custom"
								@click="addManualPurchaseOrder()"
								:disabled="manualBtnDisable"
								>Add Manual Order
							</v-btn>
						</div>
					</div>
				</div>
			</div>
		</div>
		<POCreateDialog
			v-if="dialogCreatePo"
			:dialog.sync="dialogCreatePo"
			:editedIndex.sync="editedOrderIndex"
			:editedItems.sync="editedOrderItems"
			@close="closeCreateOrder"
			:fromComponent="'BookingShipmentDialog'"
			:isMobile="isMobile"
			ref="customModal"
		/>

		<SalesOrderCreateDialog
			v-if="dialogCreateSo"
			:dialog.sync="dialogCreateSo"
			:editedIndex.sync="editedOrderIndex"
			:editedItems.sync="editedOrderItems"
			@close="closeCreateOrder"
			:fromComponent="'BookingShipmentDialog'"
			:isMobile="isMobile"
			ref="customModal"
		/>

		<ProductAddDialog
			v-if="dialogAddProduct"
			:dialog.sync="dialogAddProduct"
			:editedIndex.sync="editedIndexProduct"
			:defaultItem.sync="defaultProductItem"
			:editedItem.sync="editedProductItem"
			:categoryLists="categoryListData"
			@close="closeProduct"
			:isMobile="isMobile"
			:isInventoryPage="false"
			:isWarehouse3PL="false"
			:isWarehouse3PLProvider="false"
			:isBookingShipmentDialog="true"
			:shipmentData="shipmentData"
		/>
	</div>
</template>

<script>
import shipmentBookingItem from "../js/ShipmentBooking";
import headers from "../../../Dialog/FormShipmentDialog/Datas/tableHeaders";
import _ from "lodash";
import moment from "moment";
import GenericIcon from "../../../Icons/GenericIcon.vue";
import CustomIcon from "../../../Icons/CustomIcon";

import GenericTable from "../../../Dialog/FormShipmentDialog/Tables/GenericTable.vue";
import globalMethods from "../../../../utils/globalMethods";
import POCreateDialog from "../../../PosComponents/Dialog/POCreateDialog.vue";
import SalesOrderCreateDialog from "../../../SalesOrdersComponenets/Dialog/SalesOrderCreateDialog.vue";
import ProductAddDialog from "../../../ProductComponents/Dialog/ProductAddDialog.vue";
import createOrderItems from "../../../Dialog/FormShipmentDialog/Structures/createOrderItems";
import createProductItems from "../../../Dialog/FormShipmentDialog/Structures/createProductItems";

export default {
	name: "Orders",
	components: {
		GenericIcon,
		CustomIcon,
		GenericTable,
		POCreateDialog,
		SalesOrderCreateDialog,
		ProductAddDialog,
	},
	props: [
		"isMobile",
		"userDetails",
		"filteredConsigneeOptions",
		"filteredShipperOptions",
		"reference",
		"ordersData",
	],
	data: () => ({
		shipmentItem: shipmentBookingItem,
		defaultBtnDisable: false,
		manualBtnDisable: false,
		headersProducts: headers.shipmentBookingProducts.data,
		searchOrders: true,
		ordersSearchText: "",
		headersDocuments: headers.documents.data,
		orderProductsSearchText: "",
		searchProducts: true,
		cartonRules(i) {
			return (v) => {
				if (parseInt(v) == 0) {
					return "Carton should be greater than 0";
				} else {
					if (parseFloat(v) > i.unship_cartons) {
						return "";
					} else {
						return true;
					}
				}
			};
		},
		menuProps: {
			bottom: true,
			offsetY: true,
		},
		resourcesLoaded: 0,
		resourcesLimit: 6,
		loaded: false,
		dialogCreatePo: false,
		dialogCreateSo: false,
		editedOrderItems: createOrderItems,
		defaultOrdersItems: createOrderItems,
		editedOrderIndex: -1,
		dialogAddProduct: false,
		editedIndexProduct: -1,
		editedProductItem: createProductItems,
		defaultProductItem: createProductItems,
		categoryListData: [],
		editField: "",
		focusFields: false,
		productLoading: false,
		selectedProductForQuickAdd: null,
		productDescription: "",
		shipmentData: null,
		productId: 0,
	}),
	computed: {
		console: () => console,
		getVendorsProducts() {
			return this.$store.getters["po/getVendorSkus"];
		},
		getBuyersProducts() {
			return this.$store.getters["salesOrders/getSuppliersSkus"];
		},
		purchaseOrderOptions() {
			let options = this.$store.getters["po/getPurchaseOrderOptions"];
			options = _.filter(
				options,
				(e) => e.status === "pending" || e.status === "partial_shipped"
			);
			return options;
		},
		selectedShipperConsigneeOptions() {
			if (this.shipmentItem.userRole === "shipper") {
				let data = this.filteredConsigneeOptions.filter((item) =>
					this.shipmentItem.selectedConsigneeIds.includes(item.id)
				);
				data.forEach((item) => {
					item["disabled"] = false;
				});
				return data;
			} else {
				let data = this.filteredShipperOptions.filter((item) =>
					this.shipmentItem.selectedShipperIds.includes(item.id)
				);
				data.forEach((item) => {
					item["disabled"] = false;
				});
				return data;
			}
		},
		consolidateBookingShipment() {
			return this.reference === "consolidte-booking-shipment";
		},
	},
	mounted() {
		this.shipmentItem.orderItems = this.ordersData;
	},
	methods: {
		...globalMethods,
		countShipmentsDecimals(value) {
			value = Number(value);
			if (Number.isInteger(value)) {
				return 0;
			} else {
				return value.toString().split(".")[1].length;
			}
		},
		formatDate(value) {
			return value ? moment(value).format("MMM DD, YYYY") : "N/A";
		},
		getItemClass(item) {
			if (
				typeof item.addSpecial == "undefined" &&
				typeof item.special === "undefined"
			) {
				if (item.addAll) {
					return "item-row item-row-add";
				} else {
					return "item-row";
				}
			} else {
				return "item-row-special";
			}
		},
		checkOrdersButton() {
			if (this.shipmentItem.orderItems.length > 0) {
				var _this = this;
				_this.shipmentItem.orderItems.forEach(function(item) {
					if (item.layout === "default") {
						_this.manualBtnDisable = true;
						_this.defaultBtnDisable = false;
					} else {
						_this.manualBtnDisable = false;
						_this.defaultBtnDisable = true;
					}
				});
			} else {
				this.defaultBtnDisable = false;
				this.manualBtnDisable = false;
			}
		},
		setPurchaseOrderOptions() {
			let { selectedOrderIds } = this;
			this.purchaseOrderId++;

			selectedOrderIds = [];

			//get all seelcted purchase order id
			let items = this.shipmentItem.orderItems;
			let newOptions = [];

			if (items.length > 0) {
				items.map((i) => {
					if (selectedOrderIds.indexOf(i.purchase_order_id) == -1)
						selectedOrderIds.push(i.purchase_order_id);
				});
			}

			//iterate purchase order options
			this.purchaseOrderOptions.map((i) => {
				if (selectedOrderIds.length > 0) {
					if (selectedOrderIds.indexOf(i.id) == -1) {
						newOptions.push(i);
					}
				} else {
					newOptions.push(i);
				}
			});

			//assigned to data
			this.selectedOrderIds = selectedOrderIds;
			return newOptions;
		},
		addPurchaseOrderItem() {
			let newOptions = this.setPurchaseOrderOptions();
			this.shipmentItem.orderItems.push({
				id: new Date().getTime(),
				products: [],
				purchase_order_id: "",
				product_options: [],
				purchase_order_options: newOptions,
				selectAll: false,
				layout: "default",
				quickAddOrder: false,
				cargo_ready_date: "",
				order_number: "",
				selected_order_user_id: "",
			});
			this.ordersSearchText = "";
			this.checkOrdersButton();
		},
		customFilterPo(item, queryText, itemText) {
			//funtion for searching po
			//append the 3 data and then store them in data variable
			//convert them all to lowercase
			const data =
				item.po_number +
				item.ship_to.toLowerCase() +
				itemText.toLocaleLowerCase();

			//let's also convert search text to lowercase to match case with the data to search with
			this.ordersSearchText = queryText;

			//do the searching and return the index of the result
			//if no result then return with -1
			this.searchOrders = data.indexOf(queryText.toLowerCase()) > -1;
			return this.searchOrders;
		},
		createOrder() {
			if (this.shipmentItem.userRole === "shipper") {
				let consigneeIds = this.shipmentItem.selectedConsigneeIds;
				this.$store.dispatch("salesOrders/fetchBuyerLists");
				this.$store.dispatch("salesOrders/fetchSuppliersSku", consigneeIds);

				this.dialogCreateSo = true;
				this.editedOrderItems.buyer_id = consigneeIds;
				this.editedOrderItems.entity_id = this.userDetails.default_customer.id;
			} else {
				let shipperIds = this.shipmentItem.selectedShipperIds;
				this.$store.dispatch("po/fetchVendorLists");
				this.$store.dispatch("po/fetchVendorSku", shipperIds);

				this.dialogCreatePo = true;
				this.editedOrderItems.supplier_id = shipperIds;
				this.editedOrderItems.entity_id = this.userDetails.default_customer.id;
			}

			(this.editedOrderItems.products = [
				{
					id: null,
					carton_count: 0,
					units: 0,
					unit_price: 0,
					amount: 0,
					units_per_carton: 0,
					unship_cartons: 0,
					shipped_cartons: 0,
					shipped_units: 0,
					unshipped_units: 0,
					volume: 0,
					weight: 0,
				},
			]),
				(this.editedOrderIndex = -1);
		},
		closeCreateOrder() {
			if (this.shipmentItem.userRole === "shipper") {
				this.dialogCreateSo = false;
				this.editedOrderItems.buyer_id = 0;
			} else {
				this.dialogCreatePo = false;
				this.editedOrderItems.supplier_id = 0;
			}
			let newOptions = this.setPurchaseOrderOptions();
			this.shipmentItem.orderItems.map(
				(item) => (item.purchase_order_options = newOptions)
			);

			this.$nextTick(() => {
				this.editedOrderItems = Object.assign({}, this.defaultOrdersItems);
				this.editedOrderIndex = -1;
			});
		},
		quickAddOrders(key) {
			this.shipmentItem.orderItems[key].quickAddOrder = true;
			this.shipmentItem.orderItems[key].order_number = this.ordersSearchText;
			if (this.shipmentItem.userRole === "shipper") {
				this.$store.dispatch(
					"salesOrders/fetchSuppliersSku",
					this.shipmentItem.consignees
				);
			} else {
				this.$store.dispatch("po/fetchVendorSku", this.shipmentItem.shippers);
			}
			this.addProductToPurchaseOrders(key);
			this.ordersSearchText = "";
		},
		removePurchaseOrderItem(key) {
			this.shipmentItem.orderItems.splice(key, 1);
			this.checkOrdersButton();
		},
		deleteAllPurchaseOrders() {
			this.ordersSearchText = "";
			this.shipmentItem.orderItems = [];
		},
		addManualPurchaseOrder() {
			this.shipmentItem.orderItems.push({
				id: new Date().getTime(),
				products: [],
				supplier_id: 0,
				selected_order_user_id: "",
				documents: [],
				cargo_ready_date: "",
				menuCargoReadyDate: false,
				selectAll: false,
				containers: [],
				order_number: "",
				total_cartons: 0,
				total_volumes: 0,
				total_weights: 0,
				layout: "manual",
			});
			this.checkOrdersButton();
		},
		removePurchaseOrderManualItem(key) {
			this.shipmentItem.orderItems.splice(key, 1);
			this.checkOrdersButton();
		},
		updateCargoReadyDateInput(pom, value) {
			if (moment(value).isValid())
				pom.cargo_ready_date = moment(value).format("YYYY-MM-DD");
			else pom.cargo_ready_date = "";
			pom.menuCargoReadyDate = false;
		},
		addProductToPurchaseOrders(key) {
			this.productId++;
			this.shipmentItem.orderItems[key].products.push({
				product_id: "",
				addAll: false,
				carton: 0,
				unit: 0,
				volume: 0,
				weight: 0,
				unit_price: 0,
				units_per_carton: 0,
				amount: 0,
				id: this.productId,
				action: "",
				description: "",
				classification_code: "",
				product_classification_description: "",
				quickAddProduct: false,
				isEditingField: false,
				product_number: "",
			});

			if (!this.isMobile) {
				let getProducts = this.shipmentItem.orderItems[key].products;
				getProducts = _.filter(
					getProducts,
					(e) => typeof e.addSpecial == "undefined"
				);
				getProducts.push({
					addSpecial: true,
					product_id: "",
					amount: 0,
					carton: 0,
					unit: 0,
					weight: 0,
					volume: 0,
					units_per_carton: 0,
					unit_price: 0,
					description: "",
					classification_code: "",
					product_classification_description: "",
				});
				this.shipmentItem.orderItems[key].products = getProducts;
			}

			this.shipmentItem.orderItems[key].products.map((p, k) => {
				let setIndex = parseInt(k) + 1;
				this.shipmentItem.orderItems[key].products[k].index =
					setIndex >= 10 ? setIndex : `0${setIndex}`;
			});
		},
		removeProductFromPurchaseOrders(key, item) {
			this.orderProductsSearchText = "";
			let getIndex = this.shipmentItem.orderItems[key].products.indexOf(item);
			this.shipmentItem.orderItems[key].selectAll = false;
			this.shipmentItem.orderItems[key].products.splice(getIndex, 1);
			this.calculateOverAllTotal();
		},
		selectAllProducts(item, key) {
			//if there is selected purchase order then add all the products under that po
			if (item.purchase_order_id !== "") {
				this.shipmentItem.orderItems[key].products = [];

				//it means that user select all the purchase order
				if (!item.selectAll) {
					this.shipmentItem.orderItems[key].product_options.map((poi) => {
						let carton = 1;
						let unit = poi.units_per_carton * carton;
						let volume;

						if (poi.product.volume !== null) {
							volume = poi.product.volume * unit;

							volume =
								this.countShipmentsDecimals(volume) > 5
									? volume.toFixed(5)
									: volume;
							volume = volume == 0 ? 0.00001 : volume;
						} else {
							volume = 0.00001;
						}

						let weight =
							poi.product.weight !== null ? poi.product.weight * unit : 0;

						this.shipmentItem.orderItems[key].products.push({
							product_id: poi.product_id,
							addAll: false,
							meta: poi,
							carton: carton,
							unit: unit,
							weight: weight,
							volume: volume,
							unit_price: poi.unit_price,
							units_per_carton: poi.units_per_carton,
							amount: 0,
							unship_cartons: poi.unship_cartons,
							cargo_ready_date: item.cargo_ready_date,
							action: "",
							classification_code: poi.product.classification_code,
							product_classification_description:
								poi.product.product_classification_description,
							description: poi.product.description,
						});
					});

					if (!this.isMobile) {
						//take out products with add special
						let getProducts = this.shipmentItem.orderItems[key].products;
						getProducts = _.filter(
							getProducts,
							(e) => typeof e.addSpecial == "undefined"
						);
						getProducts.push({
							addSpecial: true,
							product_id: "",
							amount: 0,
							carton: 0,
							volume: 0,
							weight: 0,
							unit: 0,
							units_per_carton: 0,
							unit_price: 0,
						});

						//take out products with special
						getProducts = _.filter(
							getProducts,
							(e) => typeof e.special == "undefined"
						);
						//set purchase order's products
						this.shipmentItem.orderItems[key].products = getProducts;
					}
				} else {
					//reset to default
					//clear products
					this.shipmentItem.orderItems[key].products = [];

					//if not mobile then do the logic
					//add the add products button and total section
					if (!this.isMobile) {
						this.shipmentItem.orderItems[key].products.push({
							addSpecial: true,
							product_id: "",
							amount: 0,
							carton: 0,
							volume: 0,
							unit: 0,
							weight: 0,
							units_per_carton: 0,
							unit_price: 0,
						});
					}
				}

				//set product label numbering
				this.shipmentItem.orderItems[key].products.map((p, k) => {
					let setIndex = parseInt(k) + 1;
					this.shipmentItem.orderItems[key].products[k].index =
						setIndex >= 10 ? setIndex : `0${setIndex}`;
				});

				//toggle select all
				this.shipmentItem.orderItems[key].selectAll = !item.selectAll;
			}
			this.calculateOverAllTotal();
		},
		async createProduct() {
			this.$store.dispatch("products/fetchProductContacts");
			await this.$store.dispatch("category/fetchCategoriesDropdown", 1);
			this.loadMoreCategories();
			this.shipmentData = {
				role: this.shipmentItem.userRole,
				consignee: this.shipmentItem.selectedConsigneeIds,
				shipper: this.shipmentItem.selectedShipperIds,
			};
			this.dialogAddProduct = true;
		},
		quickAddProducts(key, productKey) {
			this.shipmentItem.orderItems[key].products[
				productKey
			].quickAddProduct = true;
			this.shipmentItem.orderItems[key].products[productKey].description = "";
			this.shipmentItem.orderItems[key].products[productKey].carton = 0;
			this.shipmentItem.orderItems[key].products[productKey].unit = 0;
			this.shipmentItem.orderItems[key].products[productKey].volume = 0;
			this.shipmentItem.orderItems[key].products[productKey].weight = 0;
			this.shipmentItem.orderItems[key].products[productKey].unit_price = 0;
			this.shipmentItem.orderItems[key].products[
				productKey
			].classification_code = "";
			this.shipmentItem.orderItems[key].products[
				productKey
			].product_classification_description = "";
			this.shipmentItem.orderItems[key].products[productKey].product_id = "";

			this.validateProduct(
				this.shipmentItem.orderItems[key].products[productKey],
				key
			);
			this.shipmentItem.orderItems[key].products[
				productKey
			].product_number = this.orderProductsSearchText;
			this.orderProductsSearchText = "";
			this.productDescription = "";
		},
		filterProducts(po) {
			let { products } = po;

			let newProducts = products;
			let validProducts = _.filter(
				products,
				(e) =>
					typeof e.addSpecial == "undefined" && typeof e.special == "undefined"
			);
			if (
				po.selectAll ||
				(validProducts.length > 0 &&
					po.product_options.length > 0 &&
					validProducts.length == po.product_options.length)
			) {
				po.selectAll = true;
			}
			return newProducts;
		},
		customFilterSku(item, queryText, itemText) {
			const data =
				item.category_sku +
				item.category_id +
				item.name.toLowerCase() +
				item.sku +
				itemText.toLocaleLowerCase();
			this.orderProductsSearchText = queryText.toLowerCase();

			this.searchProducts = data.indexOf(this.orderProductsSearchText) > -1;
			return this.searchProducts;
		},
		customFilterProduct(item, queryText, itemText) {
			const data =
				item.product.category_sku +
				item.product.category_id +
				item.product.name.toLowerCase() +
				item.product.sku +
				itemText.toLocaleLowerCase();
			this.orderProductsSearchText = queryText;

			this.searchProducts = data.indexOf(queryText.toLowerCase()) > -1;
			return this.searchProducts;
		},
		updateProductPurchaseOrder(
			options,
			item,
			key,
			quickOrderAutoProduct = false
		) {
			let findIndex = this.shipmentItem.orderItems[key].products.indexOf(item);

			let findProduct = quickOrderAutoProduct
				? _.find(options, (e) => e.id == item.product_id)
				: _.find(options, (e) => e.product_id == item.product_id);

			if (typeof findProduct !== "undefined") {
				let {
					units_per_carton,
					weight,
					volume,
					classification_code,
					product_classification_description,
					description,
					product,
					unit_price,
					unship_cartons,
				} = findProduct;

				this.shipmentItem.orderItems[key].products[findIndex].carton = 1;

				this.shipmentItem.orderItems[key].products[
					findIndex
				].units_per_carton = units_per_carton;

				this.shipmentItem.orderItems[key].products[findIndex].unit =
					this.shipmentItem.orderItems[key].products[findIndex].carton *
					units_per_carton;

				this.shipmentItem.orderItems[key].products[
					findIndex
				].unit_price = unit_price;

				this.shipmentItem.orderItems[key].products[
					findIndex
				].meta = findProduct;

				this.shipmentItem.orderItems[key].products[
					findIndex
				].cargo_ready_date = this.shipmentItem.orderItems[key].cargo_ready_date;

				if (quickOrderAutoProduct) {
					this.shipmentItem.orderItems[key].products[findIndex].weight =
						weight == null
							? 0
							: weight *
							  this.shipmentItem.orderItems[key].products[findIndex].unit;

					this.shipmentItem.orderItems[key].products[findIndex].volume =
						volume == null
							? 0.00001
							: volume *
							  this.shipmentItem.orderItems[key].products[findIndex].unit;

					this.shipmentItem.orderItems[key].products[
						findIndex
					].classification_code = classification_code;

					this.shipmentItem.orderItems[key].products[
						findIndex
					].product_classification_description = product_classification_description;

					this.shipmentItem.orderItems[key].products[
						findIndex
					].description = description;
				} else {
					this.shipmentItem.orderItems[key].products[findIndex].weight =
						product.weight == null
							? 0
							: product.weight *
							  this.shipmentItem.orderItems[key].products[findIndex].unit;

					this.shipmentItem.orderItems[key].products[findIndex].volume =
						product.volume == null
							? 0.00001
							: product.volume *
							  this.shipmentItem.orderItems[key].products[findIndex].unit;
					this.shipmentItem.orderItems[key].products[
						findIndex
					].unship_cartons = unship_cartons;

					this.shipmentItem.orderItems[key].products[
						findIndex
					].classification_code = product.classification_code;

					this.shipmentItem.orderItems[key].products[
						findIndex
					].product_classification_description =
						product.product_classification_description;

					this.shipmentItem.orderItems[key].products[findIndex].description =
						product.description;
				}

				this.shipmentItem.orderItems[key].products[findIndex].volume =
					this.countShipmentsDecimals(
						this.shipmentItem.orderItems[key].products[findIndex].volume
					) > 5
						? this.shipmentItem.orderItems[key].products[
								findIndex
						  ].volume.toFixed(5)
						: this.shipmentItem.orderItems[key].products[findIndex].volume;

				this.shipmentItem.orderItems[key].products[findIndex].volume =
					this.shipmentItem.orderItems[key].products[findIndex].volume == 0
						? 0.00001
						: this.shipmentItem.orderItems[key].products[findIndex].volume;
			}
			this.calculateOverAllTotal();
		},
		updateProducts(item, key) {
			//always clear the checkbox when channging po
			this.shipmentItem.orderItems[key].loading = true;

			const currentOrder = this.shipmentItem.orderItems[
				key
			].purchase_order_options.find(
				(order) =>
					order.id == this.shipmentItem.orderItems[key].purchase_order_id
			);

			if (_.has(currentOrder, "consignee_id"))
				this.shipmentItem.orderItems[key].consignee_id =
					currentOrder?.consignee_id;
			if (_.has(currentOrder, "shipper_id"))
				this.shipmentItem.orderItems[key].shipper_id = currentOrder?.shipper_id;

			//if purchase order is unselected for some reason then clear all product options, products
			let otherOptions = _.find(
				this.purchaseOrderOptions,
				(e) => e.id == item.purchase_order_id
			);

			if (typeof otherOptions !== "undefined") {
				let supplier_id = otherOptions.supplier_id;
				let buyer_id = otherOptions.buyer_id;

				let payload = {
					purchase_order_id: item.purchase_order_id,
					consolidate: false,
				};

				//fetch purchase order products when a po is selected then update the product options of the selected po
				this.$store
					.dispatch("po/fetchPurchaseOrderProducts", payload)
					.then((res) => {
						this.shipmentItem.orderItems[key].loading = false;
						this.shipmentItem.orderItems[key].product_options = res.data;
						this.shipmentItem.orderItems[key].selectAll = false;
						this.shipmentItem.orderItems[key].supplier_id = supplier_id;
						this.shipmentItem.orderItems[key].buyer_id = buyer_id;
						this.shipmentItem.orderItems[key].cargo_ready_date =
							otherOptions.cargo_ready_date;
						this.productId++;
						if (!this.isMobile) {
							this.shipmentItem.orderItems[key].products = [
								{
									addSpecial: true,
									product_id: "",
									amount: 0,
									unit_price: 0,
									weight: 0,
									volume: 0,
									carton: 0,
									cargo_ready_date: "",
									description: "",
									classification_code: "",
									product_classification_description: "",
								},
							];
						}
					})
					.catch((e) => {
						console.log(e);
						this.shipmentItem.orderItems[key].loading = false;
					});
			} else {
				this.shipmentItem.orderItems[key].loading = false;
				this.shipmentItem.orderItems[key].product_options = [];
				this.shipmentItem.orderItems[key].products = [];
			}
		},
		getWarehouseName(item) {
			return typeof item.warehouse !== "undefined" &&
				item.warehouse !== null &&
				typeof item.warehouse.name !== "undefined"
				? item.warehouse.name
				: "N/A";
		},
		validateProduct(item, key) {
			//run validation against the selected product using the product_id
			//if the product is already used then return an error class
			let returnClass = "";
			if (item.product_id !== null && item.product_id !== "") {
				let productKey = this.shipmentItem.orderItems[key].products.indexOf(
					item
				);
				let findSelectedOption = _.findIndex(
					this.shipmentItem.orderItems[key].products,
					(e) => e.product_id == item.product_id
				);
				if (findSelectedOption !== productKey) {
					returnClass = "error";
				}
			}
			return returnClass;
		},
		cartonTooltipClass(item) {
			if (
				typeof item.unship_cartons !== "undefined" &&
				parseFloat(item.carton) > parseFloat(item.unship_cartons)
			) {
				return "tooltip-has-error";
			} else {
				if (parseInt(item.carton) == 0) {
					return "tooltip-has-error";
				} else {
					return "";
				}
			}
		},
		cartonTooltipMessage(item, quickAddOrder) {
			if (!item.quickAddProduct) {
				if (
					typeof item.unship_cartons !== "undefined" &&
					parseFloat(item.carton) > parseFloat(item.unship_cartons)
				) {
					return `You can only enter a maximum of ${
						item.unship_cartons
					} carton${item.unship_cartons > 1 ? "s" : ""}.`;
				} else {
					if (item.product_id == "" || item.product_id == null) {
						return "Select first a product";
					} else if (
						quickAddOrder &&
						(item.product_id !== "" || item.product_id !== null)
					) {
						return "Please enter carton";
					} else {
						return `Available ${item.unship_cartons} carton${
							item.unship_cartons > 1 ? "s" : ""
						}.`;
					}
				}
			} else {
				return "Please enter carton";
			}
		},
		addAllCartons(item, key) {
			//set product key of set carton
			let productKey = this.shipmentItem.orderItems[key].products.indexOf(item);

			if (!item.addAll) {
				this.shipmentItem.orderItems[key].products[productKey].carton =
					item.unship_cartons;
			} else {
				this.shipmentItem.orderItems[key].products[productKey].carton = 1;
			}
			//update unit according to carton logic
			this.updateProductCarton(item, key);

			//toggle add all option of purchase order
			this.shipmentItem.orderItems[key].products[
				productKey
			].addAll = !item.addAll;
			this.calculateOverAllTotal();
		},
		updateProductCarton(item, key) {
			let productKey = this.shipmentItem.orderItems[key].products.indexOf(item);

			this.shipmentItem.orderItems[key].products[productKey].unit =
				item.carton * item.units_per_carton;

			let volume_original = 0;
			let weight_original = 0;

			if (this.shipmentItem.orderItems[key].quickAddOrder) {
				volume_original = item?.meta?.volume;
				weight_original = item?.meta?.weight;
			} else {
				if (!this.consolidateBookingShipment) {
					volume_original = item?.meta?.product?.volume;
					weight_original = item?.meta?.product?.weight;
				} else {
					volume_original = item.volume;
					weight_original = item.weight;
				}
			}

			volume_original = volume_original ? volume_original : 0;
			weight_original = weight_original ? weight_original : 0;

			this.shipmentItem.orderItems[key].products[productKey].volume =
				volume_original *
				this.shipmentItem.orderItems[key].products[productKey].unit;

			this.shipmentItem.orderItems[key].products[productKey].volume =
				this.countShipmentsDecimals(
					this.shipmentItem.orderItems[key].products[productKey].volume
				) > 5
					? this.shipmentItem.orderItems[key].products[
							productKey
					  ].volume.toFixed(5)
					: this.shipmentItem.orderItems[key].products[productKey].volume;

			this.shipmentItem.orderItems[key].products[productKey].volume =
				this.shipmentItem.orderItems[key].products[productKey].volume == 0
					? 0.00001
					: this.shipmentItem.orderItems[key].products[productKey].volume;

			this.shipmentItem.orderItems[key].products[productKey].weight =
				weight_original *
				this.shipmentItem.orderItems[key].products[productKey].unit;
			// this.calculateOverAllTotal();
		},
		updateProductUnit(item, key) {
			if (!item.quickAddProduct) {
				//get product key against the products list of the selected purchase order
				let productKey = this.shipmentItem.orderItems[key].products.indexOf(
					item
				);

				//divide the carton
				let divide_carton = parseInt(item.unit / item.units_per_carton);

				//see if there is remainder
				//if there is then add 1 carton
				if (item.unit % item.units_per_carton !== 0) {
					divide_carton++;
				}
				//set now the carton
				this.shipmentItem.orderItems[key].products[
					productKey
				].carton = divide_carton;
			}
		},
		getImgUrl(pic) {
			//get image url directory from po online
			let url = process.env.VUE_APP_PO_URL.slice(0, -3);
			let imageUrl = `${url}storage/`;

			//if pic is not null and defined
			if (typeof pic !== "undefined" && pic !== null) {
				if (pic.includes(imageUrl) !== "undefined" && !pic.includes(imageUrl)) {
					//concatonate the imageurl with the pic
					let newImage = `${imageUrl}${pic}`;
					return newImage;
				} else return pic;
			} else return require("@/assets/icons/default-product-icon.svg");
		},
		calculateAmount(item) {
			if (this.defaultBtnDisable === false && this.manualBtnDisable === false) {
				this.checkOrdersButton();
			}
			//return this.currencyNumberFormat(item.unit_price * item.carton)
			//use parse float to format to decimal digit
			let total = parseFloat(item.unit_price) * parseFloat(item.unit);
			//assign now the total to item's amount
			item.amount = total;
			//fixed to 2 decimal point
			return `${isNaN(total) ? "0.00" : total.toFixed(2)}`;
		},
		calculateTotals(po, entity) {
			let { products } = po;

			let total = 0;

			let newProducts = products;
			let validProducts = _.filter(
				products,
				(e) =>
					typeof e.addSpecial == "undefined" && typeof e.special == "undefined"
			);

			if (
				po.selectAll ||
				(validProducts.length > 0 &&
					po.product_options.length > 0 &&
					validProducts.length == po.product_options.length)
			) {
				newProducts = _.filter(
					products,
					(e) => typeof e.addSpecial == "undefined"
				);
				po.selectAll = true;
			}

			if (newProducts.length > 0) {
				newProducts.map((np) => {
					if (entity === "volume" || entity === "weight") {
						total = parseFloat(total) + parseFloat(np[entity]);
						total = parseFloat(total).toFixed(5);
					} else {
						total = parseInt(total) + parseInt(np[entity]);
					}
				});
			}

			if (total === "00.00") total = "0.00";
			// this.calculateOverAllTotal();
			return isNaN(total) ? (entity === "volume" ? "0.00" : 0) : total;
		},
		calculateOverAllTotal() {
			const { userRole } = this.shipmentItem;
			const isManual = this.shipmentItem.orderItems.filter(
				(order) => order.layout == "manual"
			);
			const isDefault = this.shipmentItem.orderItems.filter(
				(order) => order.layout == "default"
			);
			this.shipmentItem.cargoDetailsData.forEach((cargoItem, key) => {
				const currentUserOrders = this.shipmentItem.orderItems.filter((order) =>
					userRole == "consignee"
						? cargoItem.shipper == order.selected_order_user_id
						: cargoItem.consignee == order.selected_order_user_id
				);
				var total_cartons = 0;
				var total_volumes = 0;
				var total_weights = 0;
				if (isManual.length > 0) {
					currentUserOrders.forEach((currentUserOrder) => {
						total_cartons += _.toNumber(currentUserOrder.total_cartons);
						total_volumes += _.toNumber(currentUserOrder.total_volumes);
						total_weights += _.toNumber(currentUserOrder.total_weights);
					});

					this.shipmentItem.cargoDetailsData[key].total_carton = total_cartons;
					this.shipmentItem.cargoDetailsData[key].total_volume =
						this.countShipmentsDecimals(total_volumes) > 5
							? total_volumes.toFixed(5)
							: total_volumes;
					this.shipmentItem.cargoDetailsData[key].total_weight = total_weights;
				}

				if (isDefault.length > 0) {
					currentUserOrders.forEach((currentUserOrder) => {
						let currentOrdersProducts = currentUserOrder.products;

						if (currentOrdersProducts.length > 0) {
							currentOrdersProducts.forEach((currentProducts) => {
								total_cartons += _.toNumber(currentProducts.carton);
								total_volumes += _.toNumber(currentProducts.volume);
								total_weights += _.toNumber(currentProducts.weight);
							});

							this.shipmentItem.cargoDetailsData[
								key
							].total_carton = total_cartons;
							this.shipmentItem.cargoDetailsData[key].total_volume =
								this.countShipmentsDecimals(total_volumes) > 5
									? total_volumes.toFixed(5)
									: total_volumes;
							this.shipmentItem.cargoDetailsData[
								key
							].total_weight = total_weights;
						}
					});
				}
			});
		},
		toFixed(x) {
			if (this.isString(x)) {
				x = x.toLowerCase();
			}

			if (Math.abs(x) < 1.0) {
				let e = parseInt(x.toString().split("e-")[1]);
				if (e) {
					x *= Math.pow(10, e - 1);
					x = "0." + new Array(e).join("0") + x.toString().substring(2);
				}
			} else {
				let e = parseInt(x.toString().split("+")[1]);
				if (e > 20) {
					e -= 20;
					x /= Math.pow(10, e);
					x += new Array(e + 1).join("0");
				}
			}
			return x;
		},
		isString(x) {
			return Object.prototype.toString.call(x) === "[object String]";
		},
		selectOpenedProductBox(item) {
			let key = parseInt(item.index) - 1;
			this.selectedProductForQuickAdd = key;
		},
		closeProduct() {
			this.$nextTick(() => {
				this.editedProductItem = Object.assign({}, this.defaultProductItem);
				this.editedIndexProduct = -1;
			});
			this.dialogAddProduct = false;
		},
		showField(product, poKey) {
			this.shipmentItem.orderItems[poKey].products.filter((item) => {
				if (item.id == product.id) {
					item.isEditingField = item.quickAddProduct;
					this.productDescription = item.description;
				} else {
					item.isEditingField = false;
				}
			});
		},
		saveProductDescriptions(product, poKey) {
			this.shipmentItem.orderItems[poKey].products.filter((item) => {
				if (item.id == product.id) {
					item.description = this.productDescription;
					item.isEditingField = false;
				}
			});
		},
		clearProductDescriptions(product, poKey) {
			this.shipmentItem.orderItems[poKey].products.filter((item) => {
				if (item.id == product.id) {
					this.productDescription = "";
					item.isEditingField = false;
				}
			});
		},
		async loadMoreCategories() {
			let getCategoriesDropdown = this.$store.getters[
				"category/getCategoriesDropdown"
			];
			try {
				if (
					typeof getCategoriesDropdown !== "undefined" &&
					getCategoriesDropdown !== null &&
					typeof getCategoriesDropdown.categories !== "undefined" &&
					Array.isArray(getCategoriesDropdown.categories) &&
					getCategoriesDropdown.categories.length > 0
				) {
					let newloaddata = getCategoriesDropdown.categories.map((value) => {
						let nameWithId = value.name + " (" + value.id + ")";

						return {
							id: value.id,
							name: value.name,
							nameWithId,
						};
					});

					this.categoryListData = [...this.categoryListData, ...newloaddata];

					this.$store.dispatch(
						"category/setAllCategoriesDropdown",
						this.categoryListData
					);
				} else {
					this.categoryListData;
					this.$store.dispatch(
						"category/setAllCategoriesDropdown",
						this.categoryListData
					);
				}
			} catch (e) {
				this.notificationError(e);
			}
		},
		disabledProductsField(quickProduct, quickOrder) {
			return quickOrder || quickProduct ? false : true;
		},
		filterOrderByUser(orders, user_id) {
			const userRole = this.shipmentItem.userRole;
			return orders.filter((order) =>
				userRole == "shipper"
					? order.consignee_id == user_id
					: order.shipper_id == user_id
			);
		},
		disabledProductFields(item, poi) {
			if (poi.quickAddOrder) {
				return false;
			} else if (item.quickAddProduct) {
				return false;
			} else {
				return true;
			}
		},
		IconColor(poi) {
			return this.filterProducts(poi).length == 2 ? "#D0D5DD" : "#B11B1B";
		},
	},
};
</script>

<style lang="scss">
@import "./scss/orders.scss";
@import "./scss/Common.scss";
@import "../../../Dialog/FormShipmentDialog/scss/fields.scss";
</style>
