<template>
    <div class="statement-content-wrapper">
        <div class="ach-statements-header">
            <p>ACH Statements</p>
        </div>

        <div class="overlay" :class="(nextPageACHLoading || getAchStatementsLoading) ? 'show' : ''">  
            <div class="preloader" v-if="(nextPageACHLoading || getAchStatementsLoading)">
                <v-progress-circular
                    :size="30"
                    color="#1A6D9E"
                    indeterminate>
                </v-progress-circular>
            </div>       
        </div>

        <div class="second-header-wrapper-mobile d-flex justify-start align-center">
            <v-btn color="primary" class="btn-white mr-2" @click="showReportSchedule" v-if="!getAchStatementsLoading"
                :disabled="statements.length === 0">
                <img src="../../../assets/icons/settings.svg" class="mr-1" alt=""/>
                Report Schedule
            </v-btn>

            <v-btn color="primary" class="btn-white mr-2" @click="showCBPDialog"
                v-if="statements !== null && statements.length !== 'undefined' && statements.length !== 0">
                <img src="../../../assets/icons/shipment-view-icons/calendar-view-blue.svg" class="mr-1" alt="" height="16px" width="16px" />
                CBP Calendar
            </v-btn>
        </div>

        <div class="ach-statements-body" v-if="!nextPageACHLoading && !getAchStatementsLoading && statements.length > 0"
            :class="getTotalPage <= 1 ? 'no-current-pagination' : ''">
            <div v-for="(item, i) in statements" :key="i" class="ach-statements-card">
                <div class="month-mobile-wrapper d-flex justify-space-between align-items-center mb-3">
                    <p class="font-semi-bold mb-0 d-flex align-center" style="color: #1A6D9E; font-size: 16px;">
                        {{ formatDate(item.month_year) | current }}
                    </p>
                    <p class="font-semi-bold mb-0 d-flex align-center" style="color: #253041; font-size: 16px;">
                        ${{ item.amount }}

                        <!-- dialog for monthly statement -->
                        <v-dialog
                            v-model="dialogMonthlyMore[item.statement_no]"
                            transition="dialog-bottom-transition" 
                            content-class="ach-dialog-view-monthly">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="btn-white mobile ml-2" color="primary" dark v-bind="attrs" v-on="on" v-if="item.statement_no !== null">
                                    <v-icon color="#1A6D9E" size="20px">mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <button icon dark class="btn-close" @click="dialogMonthlyMore[item.statement_no] = false;">
                                        <v-icon color="#ffffff">mdi-close</v-icon>
                                    </button>
                                </v-card-title>

                                <v-card-text>
                                    <div class="ach-monthly-dialog-header">
                                        <h3>Monthly Statement #{{item.statement_no}}</h3>
                                        <p class="mb-0">Date {{ formatDateShort(item) }}</p>
                                    </div>
                                    <hr class="mt-3 mb-2" />

                                    <div class="ach-monthly-dialog-body">
                                        <v-list>
                                            <v-list-item @click.stop="previewStatement(item, 'pdf', 'monthly')" :disabled="isDownloading">
                                                <v-list-item-title>
                                                    <img src="@/assets/icons/visibility-black.svg" alt="">
                                                    Preview as PDF
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click.stop="previewStatement(item, 'xlsx', 'monthly')" :disabled="isDownloading">
                                                <v-list-item-title>
                                                    <img src="@/assets/icons/visibility-black.svg" alt="">
                                                    Preview as Excel
                                                </v-list-item-title>
                                            </v-list-item>

                                            <hr class="my-2" />

                                            <v-list-item @click.stop="downloadStatement(item, 'pdf', 'monthly')" :disabled="isDownloading">
                                                <v-list-item-title>
                                                    <img src="@/assets/icons/download-black.svg" alt="">
                                                    Download as PDF
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click.stop="downloadStatement(item, 'xlsx', 'monthly')" :disabled="isDownloading">
                                                <v-list-item-title>
                                                    <img src="@/assets/icons/download-black.svg" alt="">
                                                    Download as Excel
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </p>
                </div>

                <div class="statement-mobile-info">
                    <p class="mb-1 d-flex justify-space-between align-center">
                        <span v-if="item.statement_no !== null">
                            <span class="statement-title mr-1">Statement #</span>
                            <span class="font-medium" style="color: #253041;">{{ item.statement_no || '-' }}</span>
                        </span>
                        <!-- <button @click.stop="downloadStatement(item, 'monthly')" v-if="item.statement_no !== null" :disabled="isDownloading">                    
                            <img src="@/assets/icons/download-po-blue.svg" class="mr-1" width="16px" height="16px">
                        </button> -->
                    </p>
                    <p class="mb-1">
                        <span class="statement-title mr-1" v-if="item.statement_no !== null">Periodic Statement Date</span> 
                        <span class="font-regular" :style="item.process_date === null ? 'color: #BC4410;' : 'color: #253041;'">
                            {{ formatDateShort(item) }}
                        </span>
                    </p>

                    <p class="mb-2" v-if="item.statement_no === null" style="color: #BC4410;">
                        Exp Payment on {{ formatAllDate(item) }}
                    </p>
                </div>
                
                <div class="daily statement-mobile-info d-flex justify-space-between align-items-center mt-2">
                    <span class="statement-title mr-1 font-semi-bold">Daily Statement</span>
                    <!-- showing daily statements in a dialog -->
                    <v-dialog
                        v-model="dialogDaily[item.statement_no]"
                        transition="dialog-bottom-transition" 
                        fullscreen
                        content-class="ach-dialog-view-daily"
                        v-if="item.statement_no !== null">
                        <!-- transition="slide-x-reverse-transition" -->

                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="btn-white view-more ml-1" color="primary" dark v-bind="attrs" v-on="on" v-if="item.statement_no !== null">
                                View <v-icon color="#1A6D9E" size="20px">mdi-chevron-right</v-icon>
                            </v-btn>
                        </template>

                        <v-card>
                            <v-card-title>
                                <span>
                                    <p class="mb-0">Monthly Statement #{{item.statement_no}}</p>
                                    <h3 class="mb-0">Daily Statements</h3>
                                </span>
                                <button icon dark class="btn-close" @click="dialogDaily[item.statement_no] = false;">
                                    <v-icon color="#253041">mdi-close</v-icon>
                                </button>
                            </v-card-title>

                            <v-card-text>
                                <v-data-table 
                                    :headers="expandedHeaders"
                                    mobile-breakpoint="0"
                                    :items="item.daily_statements"
                                    class="statements-table-mobile expanded-item-statements elevation-1"
                                    :class="item.daily_statements !== null && item.daily_statements.length !== 'undefined' && item.daily_statements.length !== 0 ? '' : 'no-data-table child-table'"
                                    hide-default-footer
                                    :items-per-page="300"
                                    fixed-header>

                                    <template v-slot:[`item.statement_date`]="{ item }">
                                        <p class="mb-0">{{ formatDateNumbers(item.statement_date, 'slashes') }}</p>                       
                                    </template> 
                                    
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <div class="actions d-flex justify-center align-center">
                                            <!-- showing daily statements actions in a dialog -->
                                            <v-dialog
                                                v-model="dialogDailyMore[item.statement_no]"
                                                transition="dialog-bottom-transition" 
                                                content-class="ach-dialog-view-monthly"
                                                @click:outside="dialogDailyMore[item.statement_no] = false;">

                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn class="btn-white mobile" color="primary" dark v-bind="attrs" v-on="on" v-if="item.statement_no !== null">
                                                        <v-icon color="#1A6D9E" size="20px">mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>

                                                <v-card>
                                                    <v-card-text>
                                                        <div class="ach-monthly-dialog-header">
                                                            <h3 class="mb-2">Daily Statement #{{item.statement_no}}</h3>
                                                            <p class="mb-0">Date {{ formatDateNumbers(item.statement_date, 'words') }}</p>
                                                        </div>
                                                        <hr class="mt-3 mb-2" />

                                                        <div class="ach-monthly-dialog-body">
                                                            <v-list>
                                                                <v-list-item :disabled="isDownloading">
                                                                    <v-list-item-title @click.stop="isShowDailyStatementEntries('showDailyStatementsEntriesDialog', true, item)">
                                                                        <img src="@/assets/icons/show-details.svg" alt="" width="18px" height="18px">
                                                                        Show Details
                                                                    </v-list-item-title>
                                                                </v-list-item>

                                                                <hr class="my-2" />

                                                                <v-list-item @click.stop="previewStatement(item, 'pdf', 'daily')" :disabled="isDownloading">
                                                                    <v-list-item-title>
                                                                        <img src="@/assets/icons/visibility-black.svg" alt="" width="18px" height="18px">
                                                                        Preview as PDF
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item @click.stop="previewStatement(item, 'xlsx', 'daily')" :disabled="isDownloading">
                                                                    <v-list-item-title>
                                                                        <img src="@/assets/icons/visibility-black.svg" alt="" width="18px" height="18px">
                                                                        Preview as Excel
                                                                    </v-list-item-title>
                                                                </v-list-item>

                                                                <hr class="my-2" />

                                                                <v-list-item @click.stop="downloadStatement(item, 'pdf', 'daily')" :disabled="isDownloading">
                                                                    <v-list-item-title>
                                                                        <img src="@/assets/icons/download-black.svg" alt="" width="18px" height="18px">
                                                                        Download as PDF
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item @click.stop="downloadStatement(item, 'xlsx', 'daily')" :disabled="isDownloading">
                                                                    <v-list-item-title>
                                                                        <img src="@/assets/icons/download-black.svg" alt="" width="18px" height="18px">
                                                                        Download as Excel
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-dialog>
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-card-text>

                            <v-card-actions>
                                <div class="v-card-actions-monthly">
                                    <button class="btn-white" @click.stop="downloadStatement(item, 'pdf', 'monthly')" :disabled="isDownloading">
                                        Download as PDF
                                    </button>
                                    <button class="btn-white" @click.stop="downloadStatement(item, 'pdf', 'monthly')" :disabled="isDownloading">
                                        Download as Excel
                                    </button>
                                </div>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog
                        v-model="dialogDailyNoStatement"
                        transition="dialog-bottom-transition" 
                        fullscreen
                        content-class="ach-dialog-view-daily"
                        v-else
                        scrollable>

                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="btn-white view-more ml-1" color="primary" dark v-bind="attrs" v-on="on">
                                View <v-icon color="#1A6D9E" size="20px">mdi-chevron-right</v-icon>
                            </v-btn>
                        </template>

                        <v-card>
                            <v-card-title class="align-center">
                                <span>
                                    <h3 class="mb-0">Daily Statements</h3>
                                </span>
                                <button icon dark class="btn-close" @click="dialogDailyNoStatement = false;">
                                    <v-icon color="#253041">mdi-close</v-icon>
                                </button>
                            </v-card-title>

                            <!-- for statement that doesn't have statement number -->
                            <v-card-text>
                                <v-data-table 
                                    :headers="expandedHeaders2"
                                    mobile-breakpoint="0"
                                    :items="item.daily_statements"
                                    class="statements-table-mobile expanded-item-statements-mobile-dialog-table elevation-1"
                                    :class="item.daily_statements !== null && item.daily_statements.length !== 'undefined' && item.daily_statements.length !== 0 ? '' : 'no-data-table child-table'"
                                    hide-default-footer
                                    :items-per-page="300"
                                    fixed-header
                                    item-key="entry_no"
                                    show-expand
                                    single-expand
                                    :expanded.sync="expanded">

                                    <template v-slot:expanded-item="{ headers, item }">
                                        <td :colspan="headers.length">
                                            <div style="overflow: hidden !important;">
                                                <div class="expanded-ach-mobile-data reference">
                                                    <p class="expanded-title d-flex align-center justify-space-between">
                                                        Reference

                                                        <v-menu offset-y bottom left min-width="120px" max-height="90px" content-class="ach-menu-dropdown-mobile">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn class="btn-white" color="primary" dark v-bind="attrs" v-on="on">
                                                                    <v-icon color="#1A6D9E" size="20px">mdi-dots-horizontal</v-icon>
                                                                </v-btn>
                                                            </template>

                                                            <v-list>
                                                                <v-list-item @click.stop="previewStatement(item, 'pdf', 'daily')" :disabled="isDownloading">
                                                                    <v-list-item-title>
                                                                        <img src="@/assets/icons/visibility-black.svg" alt="" width="18px" height="18px">
                                                                        Preview as PDF
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item @click.stop="previewStatement(item, 'xlsx', 'daily')" :disabled="isDownloading">
                                                                    <v-list-item-title>
                                                                        <img src="@/assets/icons/visibility-black.svg" alt="" width="18px" height="18px">
                                                                        Preview as Excel
                                                                    </v-list-item-title>
                                                                </v-list-item>

                                                                <v-list-item @click.stop="downloadStatement(item, 'pdf', 'daily')" :disabled="isDownloading">
                                                                    <v-list-item-title>
                                                                        <img src="@/assets/icons/download-black.svg" alt="" width="18px" height="18px">
                                                                        Download as PDF
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item @click.stop="downloadStatement(item, 'xlsx', 'daily')" :disabled="isDownloading">
                                                                    <v-list-item-title>
                                                                        <img src="@/assets/icons/download-black.svg" alt="" width="18px" height="18px">
                                                                        Download as Excel
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                    </p>
                                                    <p class="expanded-data">{{ item.reference_no }}</p>
                                                </div> 

                                                <div class="expanded-ach-mobile-data reference">
                                                    <p class="expanded-title">POs</p>
                                                    <p class="expanded-data" v-if="findValuesInDetails(item) !== ''">
                                                        {{ 
                                                            findValuesInDetails(item).pos_numbers !== undefined &&
                                                            findValuesInDetails(item).pos_numbers !== "" &&
                                                            findValuesInDetails(item).pos_numbers !== null ?
                                                            findValuesInDetails(item).pos_numbers.join(", ") : '--'
                                                        }}
                                                    </p>
                                                </div>    

                                                <div class="expanded-ach-mobile-data reference">
                                                    <p class="expanded-title">Containers</p>
                                                    <p class="expanded-data" v-if="findValuesInDetails(item) !== ''">
                                                        {{ 
                                                            findValuesInDetails(item).container_list !== undefined &&
                                                            findValuesInDetails(item).container_list !== "" && 
                                                            findValuesInDetails(item).container_list !== null ?
                                                            findValuesInDetails(item).container_list.join(",") : '--'
                                                        }}
                                                    </p>
                                                </div>   
                                            </div> 
                                        </td> 
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </div>  
            </div>         

            <PMSDatesDialog 
                :dialogData.sync="isShowStatementDialog" />

            <PaginationComponent 
                :totalPage.sync="getTotalPage"
                :currentPage.sync="getCurrentPage"
                @handlePageChange="handlePageChange"
                :isMobile="true" />
        </div>

        <div v-else-if="!nextPageACHLoading && !getAchStatementsLoading &&statements.length === 0" class="no-data-table">
            <div class="no-data-wrapper">
                <div class="no-data-heading">
                    <div class="shifl-custom-logo">
                        <img src="../../../assets/icons/shifl-custom-blue.svg" width="125px" height="48px" alt="">
                    </div>

                    <div class="no-data-content">
                        <h3> Sign up for ACH </h3>
                        <p>
                            For effortless payment of custom duties and fees, 
                            Please fill out the CBP ACH form below and share with Shifl Customs or your account rep.
                        </p> 

                        <div class="no-data-buttons">
                            <button class="btn-blue mr-2" @click="downloadACHForm" :disabled="isDownloading">
                                <img src="../../../assets/icons/download-white.svg" 
                                    width="16px" height="16px" alt="download" class="mr-1">                                    
                                {{ isDownloading ? 'Downloading...' : 'ACH Form' }}
                            </button>

                            <button class="btn-white" style="font-family: 'Inter-Medium', sans-serif !important;"
                                @click="showLearnMore">
                                Learn More
                                <img src="../../../assets/icons/play-video-blue.svg" 
                                    width="18px" height="18px" alt="video icon" class="ml-2">
                            </button>
                        </div>
                    </div>                     
                </div>

                <div class="ach-setup-details">
                    <div class="text-center">
                        Already set up with CBP? Please share your PUN 
                        <div class="d-flex justify-center align-center" style="ling-height: 22px;">
                            <v-tooltip bottom nudge-left="50" content-class="tooltip-no-data-wrapper tooltip-bottom">
                                <template v-slot:activator="{ on }">
                                    <span v-on="on" class="d-flex align-center mr-1">                                            
                                        <img src="../../../assets/icons/icon-filled-gray.svg" alt="info" width="20px" height="20px">
                                    </span>
                                </template>
                                <div style="padding: 8px 0; line-height: 16px;">
                                    <p class="mb-2">PUN</p>
                                    <p class="mb-0 text-center" style="line-height: 18px; font-size: 12px;">
                                        Payer Unit Number (PUN) is a unique number assigned by CBP to the filer as a security measure. 
                                    </p>
                                </div>
                            </v-tooltip>
                            with <span class="email-customs ml-1">customs@shifl.com</span>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

        <ViewStatementsDialog
            :dialogData.sync="previewDataDialog"
            :item="previewData"
            @close="closePreview"
            @downloadStatement="downloadStatement"
            :isMobile="true" />

        <ConfirmDialog :dialogData.sync="isShowLearnMoreDialog" customWidth="1100px" className="learn-more-dialog">
            <template v-slot:dialog_icon>
                <div class="header-wrapper-close">
                    <h2> Learn More on ACH </h2>

                    <v-icon @click="closeLearnMore">
                        mdi-close
                    </v-icon>
                </div>
            </template>

            <template v-slot:dialog_content>
                <div style="
                    position: relative; width: 100%; height: 0; padding-top: 56.2500%;
                    padding-bottom: 0; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); 
                    margin-top: 0; margin-bottom: 24px; overflow: hidden;
                    border-radius: 8px; will-change: transform;">
                    <iframe 
                        style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"                        
                        src="https://www.canva.com/design/DAFvuRsLm5M/view?embed"
                        allowfullscreen="allowfullscreen" 
                        allow="fullscreen">
                    </iframe>
                </div>

                <a style="color: #1A6D9E;" class="font-regular"
                    href="https://www.canva.com/design/DAFvuRsLm5M/view?utm_content=DAFvuRsLm5M&utm_campaign=designshare&utm_medium=embeds&utm_source=link" 
                    target="_blank" 
                    rel="noopener">
                    ACH Statement Presentation</a> <span style="color: #253041;" class="font-regular"> by Shifl</span>
            </template>
        </ConfirmDialog> 

        <!-- show statement entry details dialog -->
        <v-dialog
            v-model="showDailyStatementsEntriesDialog"
            transition="dialog-bottom-transition" 
            fullscreen
            content-class="ach-dialog-view-daily"
            @click:outside="isShowDailyStatementEntries('showDailyStatementsEntriesDialog', false, null)">
            
            <v-card>
                <v-card-title v-if="currentStatement !== null">
                    <span>
                        <h3 class="mb-0">Daily Statement #{{currentStatement.statement_no}}</h3>
                        <p class="mb-0">Date {{ formatDateNumbers(currentStatement.statement_date, 'words') }}</p>
                    </span>
                    <button icon dark class="btn-close" @click="isShowDailyStatementEntries('showDailyStatementsEntriesDialog', false, null);">
                        <v-icon color="#253041">mdi-close</v-icon>
                    </button>                    
                </v-card-title>

                <v-card-text v-if="currentStatement !== null">
                    <v-data-table 
                        :headers="expandedHeaders2"
                        mobile-breakpoint="0"
                        :items="currentStatement.details"
                        class="statements-table-mobile expanded-item-statements-mobile-dialog-table elevation-1"
                        :class="currentStatement.details !== null && currentStatement.details.length !== 'undefined' && currentStatement.details.length !== 0 ? '' : 'no-data-table child-table'"
                        hide-default-footer
                        :items-per-page="300"
                        fixed-header
                        item-key="entry_no"
                        show-expand
                        single-expand
                        :expanded.sync="expanded1">

                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <div style="overflow: hidden !important;">
                                    <div class="expanded-ach-mobile-data reference">
                                    <p class="expanded-title">Reference</p>
                                        <p class="expanded-data">{{ item.reference_no }}</p>
                                    </div> 

                                    <div class="expanded-ach-mobile-data reference">
                                        <p class="expanded-title">POs</p>
                                        <p class="expanded-data">{{ item.pos_numbers.join(", ") }}</p>
                                    </div>    

                                    <div class="expanded-ach-mobile-data reference">
                                        <p class="expanded-title">Containers</p>
                                        <p class="expanded-data">{{ item.container_list.join(", ") }}</p>
                                    </div> 
                                </div>   
                            </td> 
                        </template>
                    </v-data-table>
                </v-card-text>

                <v-card-actions>
                    <div class="v-card-actions-monthly">
                        <button class="btn-white" @click.stop="downloadStatement(currentStatement, 'pdf', 'daily')">
                            Download as PDF
                        </button>
                        <button class="btn-white" @click.stop="downloadStatement(currentStatement, 'pdf', 'daily')">
                            Download as Excel
                        </button>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import Search from '../../../components/Search.vue'
import moment from 'moment';
import PaginationComponent from '../../../components/PaginationComponent/PaginationComponent.vue';
import PMSDatesDialog from '../../AchStatementComponents/PMSDatesDialog.vue';
import globalMethods from '../../../utils/globalMethods';

const baseURL = process.env.VUE_APP_BASE_URL;
import axios from "axios";
import ConfirmDialog from '../../Dialog/GlobalDialog/ConfirmDialog.vue';
import ViewStatementsDialog from "../../AchStatementComponents/ViewStatementsDialog.vue";
import _ from "lodash";

export default {
    name: "StatementMobileTable",
    props: ['items'],
	components: {
		// Search,
		PaginationComponent,
        PMSDatesDialog,
        ConfirmDialog,
        ViewStatementsDialog
	},
    data: () => ({
		page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        headers: [
            {
                text: "Month",
                align: "start",
                sortable: false,
                value: "month_year",
                width: "12%", 
                fixed: false
            },
            {
                text: "Statement Number",
				align: "start",
                value: "statement_no",
                sortable: false,
                width: "20%", 
                fixed: false
            },
            {
                text: "Periodic Statement Date",
				align: "start",
                value: "process_date",
                sortable: true,
                width: "40%", 
                fixed: true
            },
			{
                text: "Amount",
				align: "end",
                value: "amount",
                sortable: false,
                width: "30%", 
                fixed: true,
                class: "amount-header"
            },
            {
                text: "",
				align: "end",
                value: "",
                sortable: false,
                width: "12%", 
                fixed: true,
            },
        ],
		search: '',
		expanded: [],
        expanded1: [],
		isMobile: true,        
        isShowStatementDialog: false,
        nextPageACHLoading: false,
        expandedHeaders: [
            {
                text: "Number",
                align: "start",
                sortable: false,
                value: "statement_no",
                width: "14%", 
                fixed: false
            },
            {
                text: "Date",
                align: "start",
                sortable: false,
                value: "statement_date",
                width: "7%", 
                fixed: false
            },
            {
                text: "Amount",
                align: "end",
                sortable: false,
                value: "amount",
                width: "22%", 
                fixed: false
            },
            {
                text: "",
				align: "end",
                value: "actions",
                sortable: false,
                width: "2%",
                fixed: true,
            },
        ],
        expandedHeaders2: [
            {
                text: "Entry",
                align: "start",
                sortable: false,
                value: "entry_no",
                width: "22%", 
                fixed: false
            },
            // {
            //     text: "Reference",
            //     align: "start",
            //     sortable: false,
            //     value: "reference_no",
            //     width: "7%", 
            //     fixed: false
            // },
            {
                text: "Amount",
                align: "end",
                sortable: false,
                value: "amount",
                width: "12%", 
                fixed: false
            },
            { text: '', align: "end", value: 'data-table-expand', width: "3%"},
        ],
        request: null,
        isDownloading: false,
        isShowLearnMoreDialog: false,
        previewData: {
            item: null,
            type: null,
            isFor: null
        },
        previewDataDialog: false,
        monthFullNames: {
            Jan: "January",
            Feb: "February",
            Mar: "March",
            Apr: "April",
            May: "May",
            Jun: "June",
            Jul: "July",
            Aug: "August",
            Sep: "September",
            Oct: "October",
            Nov: "November",
            Dec: "December"
        },
        dialogMonthlyMore: {},
        dialogDaily: {},
        dialogDailyMore: {},
        showDailyStatementsEntriesDialog: false,
        currentStatement: null,
        dialogDailyNoStatement: false
    }),
    computed: {
        ...mapGetters({
            getAchStatements: 'statements/getAchStatements',
            getUser: 'getUser',
            getAchStatementsLoading: 'statements/getAchStatementsLoading'
        }),
        formTitle() {
            return this.editedIndex === -1 ? "Add Statement" : "Edit Statement";
        },
        statements: {
            get() {
                let value = this.items;
                value.map((v, i) => {
                    return {
                        id: i, ...v
                    }
                })
                // return this.items
                return value;
            }, 
            set(value) {
                this.$emit('items', value)
            }
        },
        getTotalPage() {
            let pages = 1

            if (typeof this.getAchStatements !== 'undefined' && this.getAchStatements !== null) {
                if (typeof this.getAchStatements.last_page !== 'undefined') {
                    pages = this.getAchStatements.last_page
                }
            }

            return pages
        },
        getCurrentPage: {
            get() {
                let currentPage = 1

                if (typeof this.getAchStatements !== 'undefined' && this.getAchStatements !== null) {
                    if (typeof this.getAchStatements.current_page !== 'undefined') {
                        currentPage = this.getAchStatements.current_page
                    }
                }

				return currentPage
            },
            set() {
                return {}
            }
        }, 
    },
    watch: {},
    created() {},
    methods: {
        ...mapActions({
            fetchAchStatements: 'statements/fetchAchStatements',            
            fetchPeriodicStatements: 'statements/fetchPeriodicStatements'
        }),
        ...globalMethods,
        showReportSchedule() {
            this.$parent.showReportSchedule();
        },
        showCBPDialog() {
            this.isShowStatementDialog = true;
            this.fetchPeriodicStatements();
        },
        async handlePageChange(page) {
			if (page !== null) {
				let storePagination = this.$store.state.statements.statements

				try {
					if (storePagination.old_page !== page) {
						this.nextPageACHLoading = true
						await this.fetchAchStatements(page)
						storePagination.old_page = page
						this.nextPageACHLoading = false
					}
				} catch(e) {
					this.notificationError(e)
					console.log(e);
				}
			}
        },
        formatDate(date) {
            // return date !== null ? moment(date).format('MMMM YYYY') : this.current;
            if (date !== null) {                
                let splitDate = date.split(" ");
                let newDate = `${this.monthFullNames[splitDate[0]]} ${splitDate[1]}`;
                return newDate;
            }
            return this.current;
        },
        formatDateShort(item) {
            if (item !== null) {
                if (item.process_date !== null) {
                    return moment(item.process_date).format("MMM DD, YYYY");
                } else if (typeof item.expected_date !== "undefined") {
                    if (item.expected_date !== null) {
                        let date = null;
                        date = moment(item.expected_date).format("MMM DD, YYYY");
                        return `Expected Statement on ${date}`
                    } else {
                        return 'Expected on --'
                    }
                }
            }
        },
        formatDateNumbers(date, type) {
            // return date !== null ? moment(date).format('MM/DD/YYYY') : "-";
            if (date !== null) {
                if (type === "slashes") {
                    return moment(date).format('MM/DD/YYYY');
                } else {
                    return moment(date).format('MMM DD, YYYY');
                }
            }
            return "-";
        },
        async downloadStatement(item, docType, type) { // add type on third param - value is monthly or daily
            if (item.statement_no !== null) {
                this.isDownloading = true;
                // cancel
                this.cancel();
                let axiosSource = axios.CancelToken.source();
                this.request = { cancel: axiosSource.cancel };
                let url = "";

                if (docType === "pdf") {
                    url = type === "monthly" 
                        // ? `${baseURL}/ach-monthly-downlod-pdf/${item.statement_no}?file=final` 
                        ? `${baseURL}/ach-monthly-downlod-pdf/${item.statement_no}`
                        : `${baseURL}/ach-daily-downlod-pdf/${item.statement_no}`
                } else {
                    url = type === "monthly" 
                        ? `${baseURL}/ach-download-final/${item.statement_no}` 
                        : `${baseURL}/ach-download-daily/${item.statement_no}` 
                }

                let passedData = {
                    method: "get",
                    url,
                    responseType: 'blob',
                    cancelToken: axiosSource.token,
                }

                await axios(passedData)
                .then((res) => {
                    var url;
                    if (docType === "pdf") {
                        url = window.URL.createObjectURL(
                            new Blob([res.data], { type: "application/pdf", })
                        );
                    } else {
                        url = window.URL.createObjectURL(
                            new Blob([res.data], { type: "application/vnd.ms-excel", })
                        );
                    }
                    let name = `CBP_${item.statement_no}.${docType !== "pdf" ? 'xls' : 'pdf'}`
                    var a = document.createElement("a");
                    a.href = url;
                    a.setAttribute("download", name);
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    this.notificationError("Statement has been downloaded.");
                    this.isDownloading = false;
                })
                .catch((e) => {
                    this.isDownloading = false;
                    if (e.message !== undefined && e.message === "cancel_previous_request") return;
                    else this.notificationError("Something went wrong, the file might not exist. Please try again later.");
                })
            } else {
                this.notificationError("Statement number is null.");
                this.isDownloading = false;
            }
        },
        cancel() {
			if (this.request) this.request.cancel("cancel_previous_request");
		},
        async downloadACHForm() {
            this.isDownloading = true;
            let passedData = {
                method: "get",
                url: `${baseURL}/ach-form-download`,
                responseType: 'blob',
            }

            await axios(passedData)
            .then((res) => {
                var url = window.URL.createObjectURL(
                    new Blob([res.data], {
                        type: "application/pdf",
                    })
                );
                let name = `CBP_ACH_Form.pdf`
                var a = document.createElement("a");
                a.href = url;
                a.setAttribute("download", name);
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);
                this.notificationError("ACH Form has been downloaded.");
                this.isDownloading = false;
            })
            .catch((e) => {
                console.log(e.message);
                this.notificationError("Something went wrong, please try again later.");
                this.isDownloading = false;
            });
        },
        showLearnMore() {
            this.isShowLearnMoreDialog = true;
        },
        closeLearnMore() {
            this.isShowLearnMoreDialog = false;
        },
        formatAllDate(item) {
            if (item !== null && item.final_statement !== null)
                return moment(item.final_statement).format("MMM DD, YYYY");
            else return "--"
        },
        previewStatement(item, type, isFor) {
            this.previewData = {
                item, type, isFor
            }
            this.previewDataDialog = true;
        },
        closePreview() {
            this.previewData = {
                item: null, type: null, isFor: null
            }
            this.previewDataDialog = false;
        },
        isShowDailyStatementEntries(field, value, statement) {
            this[field]= value;
            this.expanded = [];
            this.expanded1 = [];

            if (value) 
                this.currentStatement = statement; 
            else                
                this.currentStatement = null;
        },
        findValuesInDetails(item) {
            if (item.details !== null && item.details.length > 0) {
                let entry = item.entry_no;

                let findValues = _.find(item.details, e => (e.entry_no === entry));
                if (findValues !== undefined) {
                    return findValues;
                } else {
                    return "";
                }
            } else return ""
        },
    },
    filters: {
        format_date(value) {
            if(!value) {
                return '--';
            }
            return moment(value).format('MMMM YYYY');
        },
        current(value) {
            if(!value) {
                return moment().format('MMMM')+' '+moment().get('year');
            }

            return value
        }
    },
    async mounted() {
        //set current page
        this.$store.dispatch("page/setPage", "statements");  
    },
    updated() {}
};
</script>