<!-- @format -->

<template>
	<div style="position: relative;">
		<v-dialog
			v-model="dialog"
			content-class="create-shipment-ultimate-wrapper"
			width="800px"
			persistent
		>
			<v-card id="single-shipment-dialog-id" class="single-shipment-dialog">
				<v-card-title class="headline">
					<slot name="title"></slot>
					<button
						icon
						dark
						class="btn-close"
						@click.stop="closeConsolidateDialog()"
					>
						<v-icon>mdi-close</v-icon>
					</button>
				</v-card-title>

				<v-card-text class="pb-0">
					<div ref="cardTextWrapper" class="d-flex flex-row">
						<div
							class="d-flex flex-column single-shipment-dialog-main-content second-column add-shipment-tracking"
						>
							<v-form :ref="reference" id="">
								<p style="font-weight: 400; font-size: 16px; line-height: 24px; color: #69758C;">
									Please provide at least one of the following information in
									order to mark the shipment as 'Booked Consolidated'
								</p>
								<v-row class="pa-3">
									<v-col class="pa-0" md="6">
										<div class="form-label">
											<span style="font-size: 11px; !important">MBL NUMBER</span>
										</div>
										<text-field-style id="final-address-wrapper">
											<template v-slot:content>
												<div class="d-flex flex-row">
													<div
														style="width: 100%;"
														id="input-text-1"
														class="text-field-wrapper input-text-wrapper"
													>
														<v-text-field
															color="#002F44"
															v-model="mbl_number"
															type="text"
															dense
															placeholder="Enter MBL Number"
															outlined
															hide-details="auto"
														>
														</v-text-field>
													</div>
												</div>
											</template>
										</text-field-style>
									</v-col>
									<v-col class="pa-0" md="6">
										<div class="form-label">
											<span style="font-size: 11px; !important">BOOKING NUMBER</span>
										</div>
										<text-field-style id="final-address-wrapper">
											<template v-slot:content>
												<div class="d-flex flex-row">
													<div
														style="width: 100%;"
														id="input-text-1"
														class="text-field-wrapper input-text-wrapper"
													>
														<v-text-field
															color="#002F44"
															v-model="booking_number"
															type="text"
															dense
															placeholder="Enter Booking Number"
															outlined
															hide-details="auto"
														>
														</v-text-field>
													</div>
												</div>
											</template>
										</text-field-style>
									</v-col>
									<v-col class="pa-0" md="6">
										<div class="form-label">
											<span style="font-size: 11px; !important">ETD</span>
										</div>
										<text-field-style id="final-address-wrapper">
											<template v-slot:content>
												<div class="d-flex flex-row">
													<div
														style="width: 100%;"
														id="input-text-1"
														class="text-field-wrapper input-text-wrapper"
													>
														<v-text-field
															color="#002F44"
															v-model="booking_number"
															type="date"
															dense
															placeholder="Select Date"
															outlined
															hide-details="auto"
														>
														</v-text-field>
													</div>
												</div>
											</template>
										</text-field-style>
									</v-col>
									<v-col class="pa-0" md="6">
										<div class="form-label">
											<span style="font-size: 11px; !important">ETA</span>
										</div>
										<text-field-style id="final-address-wrapper">
											<template v-slot:content>
												<div class="d-flex flex-row">
													<div
														style="width: 100%;"
														id="input-text-1"
														class="text-field-wrapper input-text-wrapper"
													>
														<v-text-field
															color="#002F44"
															v-model="booking_number"
															type="date"
															dense
															placeholder="Select Date"
															outlined
															hide-details="auto"
														>
														</v-text-field>
													</div>
												</div>
											</template>
										</text-field-style>
									</v-col>
								</v-row>
								<h4>
									Additional Info
									<span class="font-weight-regular">(Optional)</span>
								</h4>
								<v-col class="pa-0 mt-3" md="12">
									<div class="form-label">
										<span style="font-size: 11px; !important">CUSTOMER REFERENCE NO</span>
									</div>
									<text-field-style id="final-address-wrapper" style="width:96% !important">
										<template v-slot:content>
											<div class="d-flex flex-row">
												<div
													style="width: 100%;"
													id="input-text-1"
													class="text-field-wrapper input-text-wrapper"
												>
													<v-text-field
														color="#002F44"
														v-model="booking_number"
														type="text"
														dense
														placeholder="Enter the custom reference number"
														outlined
														hide-details="auto"
													>
													</v-text-field>
												</div>
											</div>
										</template>
									</text-field-style>
								</v-col>
							</v-form>
						</div>
					</div>
				</v-card-text>

				<v-card-actions>
					<div class="d-flex footer">
						<v-btn class="save-btn btn-blue" disabled>
							<span>Mark as Consolidated</span>
						</v-btn>
						<v-btn
							class="delete-cancel btn-white edit-shipment-cancel-btn btn-blue"
							@click.stop="closeConsolidateDialog()"
						>
							<span>Cancel</span>
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import TextFieldStyle from "./StyleWrappers/TextFieldStyle.vue";

export default {
	name: "MarkBookedConsolidate",
	props: ["show"],
	data: () => ({
		mbl_number: "",
		booking_number: "",
	}),
	components: {
		TextFieldStyle,
	},
	computed: {
		dialog: {
			get() {
				return this.show;
			},
			set(value) {
				this.$emit("update:show", value);
			},
		},
	},
	methods: {
		closeConsolidateDialog() {
			this.$emit("close");
		},
	},
};
</script>
