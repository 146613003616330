var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"po-details-wrapper-table history"},[(!_vm.isMobile)?_c('v-data-table',{staticClass:"desktop-podetails-product elevation-0",class:{
			'no-data-table': _vm.poActivityLogItems.length === 0,
		},attrs:{"headers":_vm.headers,"items":_vm.poActivityLogItems,"hide-default-footer":"","mobile-breakpoint":"1023","items-per-page":500},scopedSlots:_vm._u([{key:"item.updated_at",fn:function(ref){
		var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.updated_at !== null ? _vm.formatDate(item.updated_at) : "--")+" ")])]}},{key:"item.update_type",fn:function(ref){
		var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.update_type)+" ")])]}},{key:"item.action_name",fn:function(ref){
		var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.action_name)+" ")])]}},{key:"item.descriptionData",fn:function(ref){
		var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatDescriptionData(item, item.update_type))}})]}},{key:"no-data",fn:function(){return [(_vm.getPoActivityLogLoading)?_c('div',{staticClass:"loading-wrapper pt-5"},[_c('v-progress-circular',{attrs:{"size":40,"color":"#0171a1","indeterminate":""}})],1):_vm._e(),(!_vm.getPoActivityLogLoading && _vm.poActivityLogItems.length == 0)?_c('div',{staticClass:"no-data-wrapper"},[_c('div',{staticClass:"no-data-heading"},[_c('p',[_vm._v("No data available in activity log")])])]):_vm._e()]},proxy:true}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }