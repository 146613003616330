export const monthLists = 
[
    {
        name: "January",
        value: "January",
        short_name: "Jan",
        num_value: "01"
    },
    {
        name: "February",
        value: "February",
        short_name: "Feb",
        num_value: "02"
    },
    {
        name: "March",
        value: "March",
        short_name: "Mar",
        num_value: "03"
    },
    {
        name: "April",
        value: "April",
        short_name: "Apr",
        num_value: "04"
    },
    {
        name: "May",
        value: "May",
        short_name: "May",
        num_value: "05"
    },
    {
        name: "June",
        value: "June",
        short_name: "Jun",
        num_value: "06"
    },
    {
        name: "July",
        value: "July",
        short_name: "July",
        num_value: "07"
    },
    {
        name: "August",
        value: "August",
        short_name: "Aug",
        num_value: "08"
    },
    {
        name: "September",
        value: "September",
        short_name: "Sept",
        num_value: "09"
    },
    {
        name: "October",
        value: "October",
        short_name: "Oct",
        num_value: "10"
    },
    {
        name: "November",
        value: "November",
        short_name: "Nov",
        num_value: "11"
    },
    {
        name: "December",
        value: "December",
        short_name: "Dec",
        num_value: "12"
    },
];


// const year = new Date().getFullYear();
// export const yearLists = Array.from({length: year - 1922}, (value, index) => 1923 + index);

const year = new Date().getFullYear() + 2;
export const yearLists = [];

for (var i = year; i >= 2000; i--) { 
    yearLists.push({
        name: `${i}`,
        value: `${i}`
    });
}