var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:(_vm.contentClass + " table-wrapper"),attrs:{"id":"container-tracking-wrapper"}},[_c('v-data-table',{ref:"create-shipment-container-table",staticClass:"purchase-orders-table",staticStyle:{"box-shadow":"none !important"},attrs:{"headers":_vm.headers,"items":_vm.containerItems,"mobile-breakpoint":"769","page":1,"items-per-page":100,"hide-default-footer":"","fixed-header":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(item,index){return _c('th',{key:index,style:(("text-align: " + (item.textAlign) + "; color: #6D858F;width: " + (item.width) + ";")),attrs:{"role":"column-header","aria-label":item.text,"scope":"col"}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)])]}},{key:"item.cartons",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"height":"40px"}},[_c('v-text-field',{staticClass:"container-cartons",attrs:{"height":40,"color":"#002F44","width":"200px","type":"text","dense":"","placeholder":"Carton","outlined":"","hide-details":"auto"},model:{value:(item.cartons),callback:function ($$v) {_vm.$set(item, "cartons", $$v)},expression:"item.cartons"}})],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"d-flex",staticStyle:{"cursor":"pointer","height":"30px","margin-top":"10px"},on:{"click":function($event){$event.stopPropagation();return _vm.removeContainer(item)}}},[_vm._t("removeIcon")],2)]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"text-fields select-items select-items-general container-size",attrs:{"items":_vm.sizes,"height":"40px","outlined":"","hide-details":"auto","menu-props":{ bottom: true, offsetY: true }},model:{value:(item.size),callback:function ($$v) {_vm.$set(item, "size", $$v)},expression:"item.size"}})]}},{key:"item.cbm",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"100%"}},[_c('v-text-field',{staticClass:"container-volume container-cartons",attrs:{"background-color":"#ffffff","height":40,"color":"#002F44","width":"200px","type":"text","dense":"","placeholder":"Volume","outlined":"","hide-details":"auto"},model:{value:(item.cbm),callback:function ($$v) {_vm.$set(item, "cbm", $$v)},expression:"item.cbm"}})],1)]}},{key:"item.kg",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"container-weight container-cartons",attrs:{"background-color":"#ffffff","height":40,"color":"#002F44","width":"200px","type":"text","dense":"","placeholder":"Weight","outlined":"","hide-details":"auto"},model:{value:(item.kg),callback:function ($$v) {_vm.$set(item, "kg", $$v)},expression:"item.kg"}})]}},{key:"item.container_num",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"container-num",attrs:{"background-color":"#ffffff","height":40,"color":"#002F44","width":"200px","type":"text","dense":"","placeholder":"Type container number","outlined":"","hide-details":"auto"},model:{value:(item.container_num),callback:function ($$v) {_vm.$set(item, "container_num", $$v)},expression:"item.container_num"}})]}}],null,true)}),_c('div',{staticClass:"add-container-wrapper",staticStyle:{"padding-left":"0px !important"}},[_c('v-btn',{staticClass:"btn-white mr-4 shipment-header-button-btn",on:{"click":_vm.addContainer}},[_c('span',{staticClass:"add-container-span"},[_vm._v(_vm._s("+ Add Container"))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }