<!-- @format -->

<template>
	<div class="additonal-services-wrapper">
		<div class="additional-services booking-bg-white">
			<v-container class="bg-surface-variant">
				<h2 class="mb-4">Additional Services</h2>
				<v-row no-gutters>
					<v-col
						v-for="(service, index) in getCustomerDefaultRequirements"
						:key="index"
						cols="12"
						sm="6"
						class="services"
					>
						<v-sheet class="service-list">
							<div class="service-title">
								<v-checkbox
									class="shifl-ui-checkbox additional_services_checkbox"
									:value="service.slug"
									v-model="shipmentItem.additional_services"
									@change="checkService(service)"
									:id="service.slug"
									hide-details="auto"
								/>
								<label class="mb-0 text-isf" :for="service.slug">{{
									service.service_name
								}}</label>
								<span class="service-text">{{ service.other_desc }}</span>
							</div>
							<div class="service-desc">
								<p class="mb-0">{{ service.service_description }}</p>
							</div>
						</v-sheet>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import shipmentBookingItem from "../js/ShipmentBooking";
export default {
	name: "AdditionalServices",
	data: () => ({
		shipmentItem: shipmentBookingItem,
	}),
	computed: {
		...mapGetters({
			getDefaultRequirements: "booking/getDefaultRequirements",
		}),
		getCustomerDefaultRequirements() {
			return [
				{
					id: 1,
					slug: "door_to_door",
					service_name: "Door to Door",
					service_description:
						"This includes Ocean Freight, ISF, Customs & Drayage for this shipment",
					other_desc: "",
					service_enabled: false,
				},
				{
					id: 2,
					slug: "customs",
					service_name: "Customs",
					service_description: "Request to process the customs filing",
					other_desc: "",
					service_enabled: this.getDefaultRequirements.customs,
				},
				{
					id: 3,
					slug: "trucking_at_pickup_location",
					service_name: "Trucking at pickup location",
					service_description: "Request for trucking at pickup location",
					other_desc: "",
					service_enabled: this.getDefaultRequirements
						.trucking_at_pickup_location,
				},
				{
					id: 4,
					slug: "drayage",
					service_name: "Drayage",
					service_description:
						"Request to facilitate drayage pickup from port to your warehouse",
					other_desc: "",
					service_enabled: this.getDefaultRequirements.drayage,
				},
				{
					id: 5,
					slug: "ocean_freight",
					service_name: "Ocean Freight",
					service_description:
						"Request to process ocean freight on your behalf",
					other_desc: "",
					service_enabled: this.getDefaultRequirements.ocean_freight,
				},
				{
					id: 6,
					slug: "transload",
					service_name: "Transload",
					service_description:
						"Request to facilitate transload your goods at destination",
					other_desc: "",
					service_enabled: this.getDefaultRequirements.transload,
				},
				{
					id: 7,
					slug: "isf",
					service_name: "ISF (Importer Security Filing)",
					service_description: "Request to file ISF on your behalf",
					other_desc: "Free when filing customs with shifl",
					service_enabled: this.getDefaultRequirements.isf,
				},
			];
		},
	},
	methods: {
		checkService(service) {
			if (this.shipmentItem.additional_services.includes("door_to_door")) {
				this.getCustomerDefaultRequirements.map((element) => {
					if (
						element.slug &&
						!this.shipmentItem.additional_services.includes(element.slug)
					) {
						if (
							element.slug !== "transload" &&
							element.slug !== "trucking_at_pickup_location"
						) {
							this.shipmentItem.additional_services.push(element.slug);
						}
					}
				});
				if (service.id !== 1) {
					if (
						service.slug !== "trucking_at_pickup_location" &&
						service.slug !== "transload"
					) {
						let index = this.shipmentItem.additional_services.indexOf(
							service.slug
						);
						this.shipmentItem.additional_services.splice(index, 1);
					}
					if (
						!this.shipmentItem.additional_services.includes("ocean_freight") ||
						!this.shipmentItem.additional_services.includes("customs") ||
						!this.shipmentItem.additional_services.includes("drayage") ||
						!this.shipmentItem.additional_services.includes("isf")
					) {
						if (
							service.slug !== "trucking_at_pickup_location" &&
							service.slug !== "transload"
						) {
							let index = this.shipmentItem.additional_services.indexOf(
								"door_to_door"
							);
							this.shipmentItem.additional_services.splice(index, 1);
						}
					}
				}
			} else {
				if (
					!this.shipmentItem.additional_services.includes("door_to_door") &&
					this.shipmentItem.additional_services.includes("ocean_freight") &&
					this.shipmentItem.additional_services.includes("customs") &&
					this.shipmentItem.additional_services.includes("drayage") &&
					this.shipmentItem.additional_services.includes("isf")
				) {
					this.shipmentItem.additional_services.push("door_to_door");
				}
				if (service.id == 1) {
					var itemsToRemove = [
						"door_to_door",
						"ocean_freight",
						"customs",
						"drayage",
						"isf",
					];
					this.shipmentItem.additional_services = this.shipmentItem.additional_services.filter(
						function(value) {
							return !itemsToRemove.includes(value);
						}
					);
				}
			}
		},
	},
};
</script>
<style lang="scss">
@import "./scss/AdditionalServices.scss";
</style>
