<template>
    <div class="card-view-wrapper">
        <div class="shipments-card" v-for="(shipments, i) in cardViewShipmentsData" :key="i" @click="handleClick(shipments)">
            <div class="shipment-card-header-info-wrapper d-flex justify-space-between align-center">
                <div class="shipment-card-header-info">
                    <div class="shipment-reference d-flex align-center">
                        <span class="font-medium mr-3">
                            <span class="font-semi-bold mr-1" style="color: #69758C;">Reference: </span>
                            <span class="font-regular">{{ shipments.shifl_ref }}</span>
                        </span>

                        <span class="font-medium">
                            <span class="font-semi-bold mr-1" style="color: #69758C;">PO:  </span>
                            <span class="font-regular">{{ concatPO(shipments.po_num) }}</span>
                        </span>

                        <!-- <span class="status" v-if="shipments.tracking_status !== '' && 
                            (tab === 'shipments' || tab === 'completed')">
                            <span class="t-shipment-status-wrapper ml-2">
                                <div :class="getShipmentStatusClass(shipments, 'tracking')" class="pa-0">
                                    <v-chip class="pa-2" style="height: 25px;">
                                        <span class="font-medium">{{ shipments.tracking_status }}</span>
                                    </v-chip>
                                </div>
                            </span>
                        </span> -->
                    </div>                    

                    <div class="shipment-supplier">
                        <div class="d-flex justify-start align-start">
                            <div class="shipment-supplier-title mr-2">
                                <span class="font-semi-bold" style="color: #69758C;">Supplier:  </span>
                            </div>
                            <div class="shipment-supplier-data mr-4">
                                <span class="font-regular">{{ concatPO(shipments.suppliers_names) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="button-action-wrapper">
                    <div class="shipment-action-button d-flex align-center justify-center">
                        <button class="btn-white" @click="handleClick(shipments)"
                            style="width: 34px !important; height: 34px !important;">
                            <img src="@/assets/icons/visibility-gray.svg" alt="" height="16px" width="16px">
                        </button>
                        <div class="three-dots-wrapper">
                            <v-menu offset-y bottom left content-class="pending-dropdown-container">
                                <template v-slot:activator="{ on, attrs }">
                                    <button v-bind="attrs" v-on="on" class="shipment-three-dots-container"
                                    style="width: 34px !important; height: 34px !important;">
                                        <custom-icon
                                            iconName="three-dots"
                                            color="#1A6D9E"
                                            width="11"
                                            height="3" />
                                    </button>
                                </template>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title class="k-fw-sm k-font-inter-regular k-dark-blue" @click="markShipmentCompletedDialog(data.data)">
                                            Mark As Completed
                                        </v-list-item-title>
                                    </v-list-item>
                                    <!-- <v-list-item>
                                        <v-list-item-title class="k-fw-sm k-font-inter-regular">
                                            Edit Shipment
                                        </v-list-item-title>
                                    </v-list-item> -->
                                    <v-list-item>
                                        <v-list-item-title class="k-fw-sm k-font-inter-regular k-red" @click="cancelRequestForm(data.data.id)">
                                            Request Cancellation
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </div>  
                </div>
            </div>

            <div class="shipments-info-wrapper">
                <!-- <div class="shipment-crd text-center px-2">
                    <div class="crd-inactive">
                        <img src="@/assets/icons/milestones/warehouse-active.svg" alt="">
                        <p class="font-semi-bold mb-0">-</p>
                        <p class="mb-0">Cargo Ready Date: -</p>
                    </div>
                </div>

                <div class="shipment-etd text-center px-2">
                    <div class="etd-inactive">
                        <span v-if="isMilestoneDone(shipments, 'etd')">
                            <img src="@/assets/icons/milestones/truck-active.svg" alt="">
                        </span>
                        <span v-else>
                            <img src="@/assets/icons/milestones/truck-inactive.svg" alt="">
                        </span>

                        <p class="font-semi-bold mb-0">
                            {{ shipments.location_from_name !== null && shipments.location_from_name !== "" 
                                ? shipments.location_from_name : '-' 
                            }}
                        </p>
                        <p class="mb-0">ETD from POL: {{ convertDateAction(shipments.etd) }}</p>
                    </div>
                </div>

                <div class="shipment-eta text-center px-2">
                    <div class="eta-inactive">                        
                        <span v-if="isMilestoneDone(shipments, 'eta')">
                            <img src="@/assets/icons/milestones/plane-active.svg" alt="" v-if="shipments.mode === 'Air'">
                            <img src="@/assets/icons/milestones/ocean-active.svg" alt="" 
                                v-if="shipments.mode === 'Ocean' || shipments.mode === '' || shipments.mode === null">
                        </span>
                        <span v-else>
                            <img src="@/assets/icons/milestones/plane-inactive.svg" alt="" v-if="shipments.mode === 'Air'">
                            <img src="@/assets/icons/milestones/ocean-inactive.svg" alt="" 
                                v-if="shipments.mode === 'Ocean' || shipments.mode === '' || shipments.mode === null">
                        </span>
                        <p class="font-semi-bold mb-0">
                            {{ shipments.location_to_name !== null && shipments.location_to_name !== ""
                                ? shipments.location_to_name : '-' 
                            }}
                        </p>
                        <p class="mb-0">ETA in POD: {{ convertDateAction(shipments.eta) }}</p>
                    </div>
                </div>

                <div class="shipment-address text-center px-2">
                    <div class="address-inactive">
                        <img src="@/assets/icons/milestones/warehouse-inactive.svg" alt="">
                        <p class="font-semi-bold mb-0">-</p>
                        <p class="mb-0">Delivered Full Date: -</p>
                    </div>
                </div> -->

                <div class="card-stepper-horizontal">
                    <div class="card-step wrhs-section w-23" :class="checkCardMilestoneDoneClass(shipments, 'etd', i)">
                        <div class="card-step-image d-flex align-center justify-center">
                            <span>
                                <img src="@/assets/icons/milestones/port-of-loading-black.svg">
                            </span>
                        </div>
                        <div class="card-step-title font-semi-bold my-1">Port Of Loading</div>
                        <div class="card-step-optional">
                            <div class="card-pod-value">
                                <div class="card-content-title mr-1">CRD</div>
                                <div class="card-content-data">{{ getLastCRD(shipments) }}</div>
                            </div>
                            <div class="card-etd-value">
                                <div class="card-content-title mr-1">ETD</div>
                                <div class="card-content-data">{{ convertDateAction(shipments.etd) }}</div>
                            </div>
                        </div>
                        <div class="card-step-bar-left"></div>
                        <div class="card-step-bar-right"></div>
                    </div>

                    <div class="card-step etd-section w-23" :class="checkCardMilestoneDoneClass(shipments, 'eta', i)">
                        <div class="card-step-image d-flex align-center justify-center">
                           <span v-if="isMilestoneDone(shipments, 'eta')">
                                <img src="@/assets/icons/milestones/plane-active.svg" alt="" v-if="shipments.mode === 'Air'">
                                <img src="@/assets/icons/milestones/truck-transit-active.svg" alt="" v-if="shipments.mode === 'Truck'">
                                <img src="@/assets/icons/milestones/ocean-active.svg" alt="" 
                                    v-if="shipments.mode === 'Ocean' || shipments.mode === '' || shipments.mode === null">
                            </span>
                            <span v-else>
                                <img src="@/assets/icons/milestones/plane-inactive.svg" alt="" v-if="shipments.mode === 'Air'">
                                <img src="@/assets/icons/milestones/truck-transit-inactive.svg" alt="" v-if="shipments.mode === 'Truck'">
                                <img src="@/assets/icons/milestones/ocean-inactive.svg" alt="" 
                                    v-if="shipments.mode === 'Ocean' || shipments.mode === '' || shipments.mode === null">
                            </span>
                        </div>
                        <div class="card-step-title font-semi-bold my-1" 
                            :style="isMilestoneDone(shipments, 'eta') ? 'color:#253041;' : 'color:#69758C;'">
                            In Transit
                        </div>
                        <div class="card-step-optional">
                            <div class="card-etd-value">
                                <div class="card-content-title mr-1">ETA</div>
                                <div class="card-content-data">{{ convertDateAction(shipments.eta) }}</div>
                            </div>
                        </div>
                        <div class="card-step-bar-left"></div>
                        <div class="card-step-bar-right"></div>
                    </div>

                    <div class="card-step port-discharge-section" :class="checkCardMilestoneDoneClass(shipments, 'discharge_date', i)">
                        <div class="card-step-image d-flex align-center justify-center">
                            <span v-if="isMilestoneDone(shipments, 'discharge_date')">
                                <img src="@/assets/icons/milestones/port-of-discharge-active.svg" width="25px" alt="">
                            </span>
                            <span v-else>
                                <img src="@/assets/icons/milestones/port-of-discharge.svg" width="25px" alt="">
                            </span>
                        </div>
                        <div class="card-step-title font-semi-bold my-1"
                            :style="isMilestoneDone(shipments, 'discharge_date') ? 'color:#253041;' : 'color:#69758C;'">
                            Port Of Discharge
                        </div>
                        <div class="card-step-optional">
                            <div class="card-pod-value">
                                <div class="card-content-title mr-1" style="width: 108px;">Discharge Date</div>
                                <div class="card-content-data">{{ getPortDischargedDate(shipments, 'discharge_date') }}</div>
                            </div>
                            <div class="card-etd-value">
                                <div v-if="shipments.containers_qty > 1" style="min-width: 0;">
                                    <v-menu
                                        v-model="menu[i]"
                                        :close-on-content-click="false"
                                        bottom right offset-y
                                        max-width="215px"
                                        min-width="215px"
                                        content-class="container-menu-card-lists"
                                        ref="container-ref-menu">

                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="d-flex justify-start align-center dark-grey font-13">
                                                <div class="d-flex justify-start align-center">
                                                    <span class="d-flex align-center" style="min-width: 85px;">Container ({{ shipments.containers_qty }})</span>
                                                    <v-icon color="#1A6D9E" size="22" v-bind="attrs" v-on="on" class="ml-1" style="width: 18px;">
                                                        <template>mdi-chevron-down</template>
                                                    </v-icon>
                                                </div>
                                                <div class="d-flex justify-start align-center default-color font-13" style="min-width: 0;"
                                                    v-if="typeof shipments.containers !== 'undefined' && shipments.containers !== undefined && 
                                                        shipments.containers.length !== undefined && shipments.containers.length > 0">
                                                    <img src="@/assets/images/big-container-po.svg" height="15px" width="15px" class="mr-1 ml-2">
                                                    <p class="mb-0 font-13" style="text-overflow: ellipsis; overflow: hidden;">
                                                        {{ shipments.containers[0].container_number }}
                                                    </p>
                                                </div>
                                                <span v-else>N/A</span>
                                            </div>
                                        </template>

                                        <v-card>
                                            <v-data-table
                                                :page.sync="page"
                                                :headers="containerHeader"
                                                :items="shipments.containers"
                                                :items-per-page="100"
                                                hide-default-footer
                                                class="elevation-1 card-view-container-table">

                                                <template v-slot:[`item.pickup_lfd`]="{ item }">
                                                    <span :class="getLFDClass(item)">{{ convertDateAction(item.pickup_lfd) }}</span>
                                                </template>
                                            </v-data-table>
                                        </v-card>
                                    </v-menu>
                                </div>
                                <div class="containers-single-content" v-else>
                                    <div class="card-content-title mr-1" style="width: 108px;">
                                        Container
                                    </div>
                                    <div class="card-content-data">
                                        {{ getPortDischargedDate(shipments, 'container_number') }}
                                        <!-- {{ getPortDischargedDate(shipments, 'discharge_date') === "N/A" ? 
                                            `${getPortDischargedDate(shipments, 'lfd_date')}` : `${getPortDischargedDate(shipments, 'container_number')}`
                                        }} -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-step-bar-left"></div>
                        <div class="card-step-bar-right"></div>
                    </div>

                    <div class="card-step wrhs-section" :class="checkCardMilestoneDoneClass(shipments, 'delivery_date', i)">
                        <div class="card-step-image d-flex align-center justify-center">
                            <span v-if="isMilestoneDone(shipments, 'delivery_date')">
                                <img src="@/assets/icons/milestones/warehouse-active.svg" alt="">
                            </span>
                            <span v-else>
                                <img src="@/assets/icons/milestones/warehouse-inactive.svg" alt="">
                            </span>
                        </div>
                        <div class="card-step-title font-semi-bold my-1" 
                            :style="isMilestoneDone(shipments, 'delivery_date') ? 'color:#253041;' : 'color:#69758C;'">
                            Delivery Location
                        </div>
                        <div class="card-step-optional">
                            <div class="card-pod-value">
                                <div class="card-content-title mr-1" style="width: 108px;">Gated out Date</div>
                                <div class="card-content-data">{{ getPortDischargedDate(shipments, 'delivery_date') }}</div>
                            </div>
                        </div>
                        <div class="card-step-bar-left"></div>
                        <div class="card-step-bar-right"></div>
                    </div>

                    <div class="card-step">
                        <div class="card-step-image d-flex align-center justify-center">
                            <img src="@/assets/icons/milestones/return-to-port.svg" alt="">
                        </div>
                        <div class="card-step-title font-semi-bold my-1" 
                            :style="isMilestoneDone(shipments, 'return_port') ? 'color:#253041;' : 'color:#69758C;'">
                            Return To Port
                        </div>
                        <div class="card-step-optional">
                            <div class="card-pod-value">
                                <div class="card-content-title mr-1" style="width: 108px;">Return LFD</div>
                                <div class="card-content-data">N/A</div>
                            </div>
                            <div class="card-etd-value">
                                <div class="card-content-title mr-1" style="width: 108px;">Return Date</div>
                                <div class="card-content-data">N/A</div>
                            </div>
                        </div>
                        <div class="card-step-bar-left"></div>
                        <div class="card-step-bar-right"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shipments-card no-data" v-if="!isFetchingData && cardViewShipmentsData.length === 0">
            <div class="no-data-wrapper">
                <div class="no-data-icon">
                    <img src="@/assets/icons/noShipmentData.svg" alt="" width="65px" />
                </div>

                <div class="no-data-heading" v-if="search === ''">
                    <p v-if="tab !== 'shipments' && tab !== 'completed'">
                        <span v-if="pendingShipmentTab === 0">
                            No Draft Shipments
                        </span>
                        <span v-if="pendingShipmentTab === 1">
                            No Pending Quote Shipments
                        </span>
                        <span v-if="pendingShipmentTab === 2">
                            No Pending Shipments
                        </span>
                        <span v-if="pendingShipmentTab === 3">
                            No Consolidated Shipments
                        </span>
                        <span v-if="pendingShipmentTab === 4">
                            No Snoozed Shipments
                        </span>
                        <span v-if="pendingShipmentTab === 5">
                            No Expired Shipments
                        </span>
                    </p>

                    <p class="text-capitalize" v-else>
                        No <span>{{ tab }}</span> Shipments
                    </p>
                </div>
                <div class="no-data-heading" v-if="search !== ''">
                    <p>
                        No matches found. Try a different keyword.
                    </p>
                </div>
            </div>
        </div>

        <div class="no-data-preloader" :class="current_page > 1 ? 'greater' : ''" v-if="isFetchingData">
            <v-progress-circular
                :size="30"
                color="#1A6D9E"
                indeterminate
                v-if="isFetchingData">
            </v-progress-circular>
            <p class="mt-2 default-color" style="font-size: 14px;" v-if="current_page > 1">Fetching data...</p>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import globalMethods from '@/utils/globalMethods'
import _ from "lodash";
import moment from "moment";
import CustomIcon from "@/components/Icons/CustomIcon";

const baseURL = process.env.VUE_APP_BASE_URL;
import axios from "axios";

export default {
    props: ['activeTab', 'getCurrentShipmentsData', 'tab', 'search', 'pendingShipmentTab'],
    components: {
        CustomIcon
    },
    data: () => ({
        windowWidth: window.innerWidth,
        bottom: false,
        shipmentsData: [],
        current_page: 1,
        last_page: 1,
        isFetchingData: false,
        panel: null,
        menu: {},
        containerHeader: [
            {
                text: "Container",
                align: "start",
                sortable: false,
                value: "container_number",
                width: "10%", 
                fixed: true,
                class: "text-capitalize"
            },
            {
                text: "LFD",
                value: "pickup_lfd",
                align: "start",
                sortable: false,
                width: "10%", 
                fixed: true
            },
        ],
        page: 1
    }),
    computed: {
        ...mapGetters([
            "getAllShipmentsNoPaginationShipments",
            "getAllShipmentsNoPaginationCompleted",
            "getSearchedShipments",
            "getAllShipmentsNoPaginationBooking"
        ]),
        cardViewShipmentsData() {
            let shipmentsArray = []

            if (typeof this.getSearchedShipments !== 'undefined' && this.getSearchedShipments !== null) {
                if (this.search !== '' && this.getSearchedShipments.tab === 'shipments') {
                    shipmentsArray = this.getSearchedShipments.shipments
                } else {
                    shipmentsArray = this.shipmentsData
                }
            } else {
                shipmentsArray = this.shipmentsData
            }

            if (shipmentsArray.length > 0) {
                shipmentsArray.map(v => {
                    if (v.containers_qty > 1) {
                        v.containers = _.orderBy(v.containers, ["pickup_lfd"], ["asc"]);
                    }
                })
            }

            return shipmentsArray
        }
    },
    watch: {
        bottom(v) {
            if (v && this.current_page <= this.last_page && !this.isFetchingData) {
                this.fetchShipmentsData()
            }
        },
        pendingShipmentTab() {
            this.current_page = 1;
            this.last_page = 1;
            this.shipmentsData = [];
            
            this.getBookingShipmentData();
        }
    },
    created() {},
    mounted() {
        //set current page
        this.$store.dispatch("page/setPage", "shipments");
        const cardWrapper = document.querySelector('.card-view-wrapper');
        cardWrapper.addEventListener('scroll', this.bottomVisible);

        if (this.tab === "shipments") {
            if (this.getAllShipmentsNoPaginationShipments.shipments.length !== 0) {
                this.shipmentsData = this.getAllShipmentsNoPaginationShipments.shipments;
                this.current_page = this.getAllShipmentsNoPaginationShipments.current_page;
                this.last_page = this.getAllShipmentsNoPaginationShipments.last_page;
            } else {
                this.fetchShipmentsData();
            } 
        } else if (this.tab === "completed") {
            if (this.getAllShipmentsNoPaginationCompleted.shipments.length !== 0) {
                this.shipmentsData = this.getAllShipmentsNoPaginationCompleted.shipments;
                this.current_page = this.getAllShipmentsNoPaginationCompleted.current_page;
                this.last_page = this.getAllShipmentsNoPaginationCompleted.last_page;
            } else {
                this.fetchShipmentsData();
            } 
        } else {
            this.getBookingShipmentData();
        }
    },
    methods: {
        ...mapActions([
            "fetchShipments",
            "setShipmentOrder",
            "fetchShipmentsSearched",
            "cancelShipment",
            "markShipmentCompleted",
            "fetchCompletedShipments",
            "setShipmentsNoPaginationShipments",
            "setShipmentsNoPaginationCompleted",
            "setShipmentsNoPaginationDraft",
            "setShipmentsNoPaginationPendingQuote",
            "setShipmentsNoPaginationPending",
            "setShipmentsNoPaginationSnoozed",
            "setShipmentsNoPaginationExpired",
            "setShipmentsNoPaginationConsolidated"
        ]),
        ...globalMethods,
        bottomVisible() {
            const div = document.getElementsByClassName('card-view-wrapper')
            let target = div[0];
            let targetBottomHeight = target.scrollHeight - target.offsetHeight;

            // if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
            if (Math.ceil(target.scrollTop) >= targetBottomHeight - 100) {
                this.bottom = true;
                this.current_page+=1;
            } else {
                this.bottom = false;
            }

            // closes the menu when user scrolls the table
            let menuScrolls = this.$refs["container-ref-menu"];
            if (menuScrolls !== null && menuScrolls !== undefined && menuScrolls.length > 0) {
                menuScrolls.forEach(element => {
                    if (element.isActive) {
                        element.isActive = false;
                    }                    
                });
            }            
        },
        async fetchShipmentsData() {
            this.isFetchingData = true;

            let passedData = {
                method: "get",
                url: `${baseURL}/v2/shipment-tab`,
                params: { 
                    compact_view: false,
                    shipment_tab: this.tab,
                    order_by: this.tab !== 'completed' ? "asc" : "desc",
                    sort_column: "eta",
                    per_page: 20,
                    page: this.current_page
                }
            }

            if (this.search !== "") {
                passedData.params.search = this.search;
            }

            await axios(passedData)
            .then(res => {
                if (res.data) {
                    this.current_page = res.data.current_page;
                    this.last_page = res.data.last_page
                    this.shipmentsData = [ ...this.shipmentsData, ...res.data.data ]

                    let data = {
                        current_page: this.current_page,
                        last_page: this.last_page,
                        shipments: this.shipmentsData,
                        tab: this.tab
                    }     

                    if (this.tab === "completed") {
                        this.setShipmentsNoPaginationCompleted(data)
                    } else if (this.tab === "shipments") {
                        this.setShipmentsNoPaginationShipments(data)
                    } else {
                        if (this.pendingShipmentTab === 0) {
                            this.setShipmentsNoPaginationDraft(data)
                        } else if (this.pendingShipmentTab === 1) {
                            this.setShipmentsNoPaginationPendingQuote(data)
                        } else if (this.pendingShipmentTab === 2) {
                            this.setShipmentsNoPaginationPending(data)
                        } else if (this.pendingShipmentTab === 3) {
                            this.setShipmentsNoPaginationConsolidated(data)
                        } else if (this.pendingShipmentTab === 4) {
                            this.setShipmentsNoPaginationSnoozed(data)
                        } else if (this.pendingShipmentTab === 5) {
                            this.setShipmentsNoPaginationExpired(data)
                        }
                    }
                }
            }).catch(e => {
                console.log(e, 'error');
            })

            this.isFetchingData = false;
        },
        getBookingShipmentData() {
            if (this.pendingShipmentTab === 0) {
                if (this.getAllShipmentsNoPaginationBooking.draft.shipments.length !== 0) {
                    this.shipmentsData = this.getAllShipmentsNoPaginationBooking.draft.shipments;
                    this.current_page = this.getAllShipmentsNoPaginationBooking.draft.current_page;
                    this.last_page = this.getAllShipmentsNoPaginationBooking.draft.last_page;
                } else {
                    this.fetchShipmentsData();
                } 
            } else if (this.pendingShipmentTab === 1) {
                if (this.getAllShipmentsNoPaginationBooking.pendingQuote.shipments.length !== 0) {
                    this.shipmentsData = this.getAllShipmentsNoPaginationBooking.pendingQuote.shipments;
                    this.current_page = this.getAllShipmentsNoPaginationBooking.pendingQuote.current_page;
                    this.last_page = this.getAllShipmentsNoPaginationBooking.pendingQuote.last_page;
                } else {
                    this.fetchShipmentsData();
                } 
            } else if (this.pendingShipmentTab === 2) {
                if (this.getAllShipmentsNoPaginationBooking.pending.shipments.length !== 0) {
                    this.shipmentsData = this.getAllShipmentsNoPaginationBooking.pending.shipments;
                    this.current_page = this.getAllShipmentsNoPaginationBooking.pending.current_page;
                    this.last_page = this.getAllShipmentsNoPaginationBooking.pending.last_page;
                } else {
                    this.fetchShipmentsData();
                } 
            } else if (this.pendingShipmentTab === 3) {
                if (this.getAllShipmentsNoPaginationBooking.consolidated.shipments.length !== 0) {
                    this.shipmentsData = this.getAllShipmentsNoPaginationBooking.consolidated.shipments;
                    this.current_page = this.getAllShipmentsNoPaginationBooking.consolidated.current_page;
                    this.last_page = this.getAllShipmentsNoPaginationBooking.consolidated.last_page;
                } else {
                    this.fetchShipmentsData();
                } 
            } else if (this.pendingShipmentTab === 4) {
                if (this.getAllShipmentsNoPaginationBooking.snoozed.shipments.length !== 0) {
                    this.shipmentsData = this.getAllShipmentsNoPaginationBooking.snoozed.shipments;
                    this.current_page = this.getAllShipmentsNoPaginationBooking.snoozed.current_page;
                    this.last_page = this.getAllShipmentsNoPaginationBooking.snoozed.last_page;
                } else {
                    this.fetchShipmentsData();
                } 
            } else if (this.pendingShipmentTab === 5) {
                if (this.getAllShipmentsNoPaginationBooking.expired.shipments.length !== 0) {
                    this.shipmentsData = this.getAllShipmentsNoPaginationBooking.expired.shipments;
                    this.current_page = this.getAllShipmentsNoPaginationBooking.expired.current_page;
                    this.last_page = this.getAllShipmentsNoPaginationBooking.expired.last_page;
                } else {
                    this.fetchShipmentsData();
                } 
            }
        },
        handleClick(value) {
            this.$router.push(`/shipment/${value.id}`);
        },
        concatPO(v) {
            if (v.length > 0) {
                return v.join(', ');
            } else {
                return "N/A";
            }
        },
        convertDateAction(date) {
            if (date !== null && date !== "" && date !== " ") {
                return moment(date).format("MM/DD/YYYY");
            } else {
                return "N/A";
            }
        },
        isMilestoneDone(shipment, isFor) {
            let todayConvert = moment().format();
            // double check the fields get for discharged date, delivery date and gated out

            if (shipment !== null) {                
                if (isFor === "etd") {
                    if (shipment.etd !== null) {
                        let etdConvert = this.convertDateAction(shipment.etd);

                        if (moment(todayConvert).isAfter(etdConvert) ||
                            moment(todayConvert).isSame(etdConvert)) {
                            return true;
                        } else if (moment(todayConvert).isBefore(etdConvert)) {
                            return false;
                        }
                    }
                } else if (isFor === "eta") {
                    if (shipment.eta !== null) {
                        let etaConvert = this.convertDateAction(shipment.eta);

                        if (moment(todayConvert).isAfter(etaConvert) ||
                            moment(todayConvert).isSame(etaConvert)) {
                            return true;
                        } else if (moment(todayConvert).isBefore(etaConvert)) {
                            return false;
                        }
                    }
                } else if (isFor === "discharge_date") {
                    let containers = shipment.containers

                    if (typeof containers !== "undefined" && containers.length > 0) {
                        if (containers.length === 1) {
                            let discharge_date = this.convertDateAction(containers[0].vessel_discharged);

                            if (moment(todayConvert).isAfter(discharge_date) ||
                                moment(todayConvert).isSame(discharge_date)) {
                                return true;
                            } else if (moment(todayConvert).isBefore(discharge_date)) {
                                return false;
                            }
                        } else {
                            // get closest discharged date from today's date
                        }
                    } else {
                        return false;
                    }
                } else if (isFor === "delivery_date") {
                    let containers = shipment.containers

                    if (typeof containers !== "undefined" && containers.length > 0) {
                        if (containers.length === 1) {
                            let empty_out = this.convertDateAction(containers[0].empty_out);

                            if (moment(todayConvert).isAfter(empty_out) ||
                                moment(todayConvert).isSame(empty_out)) {
                                return true;
                            } else if (moment(todayConvert).isBefore(empty_out)) {
                                return false;
                            }
                        } else {
                            // get closest delivery date from today's date
                        }
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        },
        checkCardMilestoneDoneClass(shipment, isFor) {            
            if (shipment !== null) {
                if (isFor === "m1") {
                    if (this.isMilestoneDone(shipment, 'etd')) {
                        return 'active-milestone left right'
                    }
                }
                
                if (isFor === "etd") {
                    if (this.isMilestoneDone(shipment, isFor)) {
                        if (this.isMilestoneDone(shipment, 'eta')) {
                            return 'active-milestone left right'
                        } else {
                            return 'active-milestone left'
                        }
                    }
                }

                if (isFor === "eta") {
                    if (this.isMilestoneDone(shipment, 'eta')) {
                        return 'active-milestone left right'
                    } else {
                        return 'active-milestone left'
                    }
                }

                if (isFor === "discharge_date") {
                    if (this.isMilestoneDone(shipment, 'discharge_date')) {
                        if (shipment.containers_qty > 1) {
                            return 'active-milestone left right multiple'
                        } else {
                            return 'active-milestone left right'
                        }
                    } else {
                        if (shipment.containers_qty > 1) {
                            return 'active-milestone left multiple'
                        } else {
                            return 'active-milestone left'
                        }
                    }
                }

                if (isFor === "delivery_date") {
                    if (this.isMilestoneDone(shipment, 'delivery_date')) {
                        return 'active-milestone left right'
                    } else {
                        return 'active-milestone left'
                    }
                }

                // if (isFor === "eta") {
                //     if (this.isMilestoneDone(shipment, isFor)) {
                //         if (this.isMilestoneDone(shipment, 'eta')) {
                //             return 'active-milestone'
                //         }
                //     }
                // }
            }
        },
        getImage(shipment, isFor) {
            let todayConvert = moment().format();

            if (shipment !== null) {                
                if (isFor === "etd") {
                    if (shipment.etd !== null) {
                        let etdConvert = this.convertDateAction(shipment.etd);

                        if (moment(todayConvert).isAfter(etdConvert) ||
                            moment(todayConvert).isSame(etdConvert)) {
                            return require('../../../../../assets/icons/milestones/plane-active.svg')
                        } else if (moment(todayConvert).isBefore(etdConvert)) {
                            return require('../../../../../assets/icons/milestones/plane-inactive.svg')
                        }
                    }
                } else if (isFor === "eta") {
                    if (shipment.eta !== null) {
                        let etaConvert = this.convertDateAction(shipment.eta);

                        if (moment(todayConvert).isAfter(etaConvert) ||
                            moment(todayConvert).isSame(etaConvert)) {
                            return require('../../../../../assets/icons/milestones/truck-active.svg')
                        } else if (moment(todayConvert).isBefore(etaConvert)) {
                            return require('../../../../../assets/icons/milestones/truck-inactive.svg')
                        }
                    }
                }
            }
        },
        getLastCRD(shipment) {
            if (shipment !== null) {
                let { shipment_suppliers } = shipment

                if (Array.isArray(shipment_suppliers) && shipment_suppliers.length > 0) {
                    let getLastSupplier = shipment_suppliers[shipment_suppliers.length - 1];

                    if (getLastSupplier !== undefined) {
                        let crd = getLastSupplier.cargo_ready_date;
                        return crd !== null && crd !== "" ? moment(crd).format("MM/DD/YYYY") : "N/A";
                    }
                } else {
                    return "N/A";
                }
            }

            return "N/A";
        },
        getPortDischargedDate(shipment, isFor) {
            if (shipment !== null) {
                if (typeof shipment.containers !== "undefined" && Array.isArray(shipment.containers) && shipment.containers.length > 0) {
                    let { containers } = shipment

                    if (containers.length === 1) {
                        let discharged = containers[0].vessel_discharged;
                        let container_number = containers[0].container_number;
                        let lfd = containers[0].pickup_lfd;
                        let del_date = containers[0].empty_out;

                        if (isFor === "discharge_date") {
                            return discharged !== null && discharged !== "" ? moment(discharged).format("MM/DD/YYYY") : "N/A";
                        } else if (isFor === "lfd_date") {
                            return lfd !== null && lfd !== "" ? moment(lfd).format("MM/DD/YYYY") : "N/A";
                        } else if (isFor === "delivery_date") {
                            return del_date !== null && del_date !== "" ? moment(del_date).format("MM/DD/YYYY") : "N/A";
                        } else {
                            return container_number
                        }                        
                    }
                } else {
                    return "N/A";
                }
            }

            return "N/A";
        },
        getLFDClass(container) {
            let today = moment().format("YYYY-MM-DD");

            if (container !== null) {
                if (container.pickup_lfd !== "") {
                    let lfd = moment(container.pickup_lfd).format("YYYY-MM-DD");

                    if (moment(today).isAfter(lfd) ||
                        moment(today).isSame(lfd)) {
                        return "red--text";
                    } else if (moment(today).isBefore(lfd)) {
                        return "yellow--text";
                    }
                }
            }
            return ""
        }
    },
    updated() {},
    beforeDestroy() {
        const cardWrapper = document.querySelector('.card-view-wrapper');
        if (cardWrapper !== null) {
            cardWrapper.removeEventListener('scroll', this.bottomVisible);
        }
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import '@/assets/scss/utilities.scss';
@import '@/assets/scss/colors.scss';
@import '../scss/shipmentsTabTable.scss';

.vc-popover-content {
    z-index: 100;
}

.card-stepper-horizontal {
    display: table;
    width: 100%;
    margin: 0 auto;

    .card-step {
        display: table-cell;
        position: relative;
        // padding: 0 12px;
        padding: 0 8px;
        max-width: 155px;
        overflow: hidden;

        &.active-milestone {
            &.right {
                .card-step-bar-right {
                    border-top: 1px solid $shifl-success-g80;
                }
            }
            
            &.left {
                .card-step-bar-left {
                    border-top: 1px solid $shifl-success-g80;
                }
            }
        }

        &.port-discharge-section {
            &.multiple {
                max-width: 180px;

                .card-step-optional {
                    .card-content-title {
                        // width: 45% !important;
                        width: 110px !important;
                    }
                }
            }
        }

        &:first-child {
            .card-step-bar-left {
                display: none;
            }
        }

        &:last-child {
            .card-step-bar-right {
                display: none;
            }
        }

        &.w-23 {
            .card-step-image {
                width: 23px;
                height: 23px;
                line-height: 23px;

                img {
                    height: 23px;
                    width: 23px;
                }
            }
        }

        .card-step-image {
            width: 25px;
            height: 25px;
            // margin: 0 auto;
            // text-align: center;
            line-height: 25px;

            img {
                height: 25px;
                width: 25px;
            }
        }

        .card-step-title,
        .card-step-optional {
            font-size: 14px;
            color: $shifl-neutral-n90;
            // text-align: center;

            .card-pod-value,
            .card-etd-value,
            .containers-single-content {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                // width: 100%;
                min-width: 0;

                .card-content-title {
                    // width: 30%;
                    width: 55px;
                    color: $shifl-neutral-n60;
                    font-size: 13px;
                }

                .card-content-data {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 13px;
                }
            }

            
            .v-icon.mdi.mdi-chevron-down {
                &:focus::after {
                    opacity: 0;
                }
            }
        }

        .card-step-bar-left,
        .card-step-bar-right {
            position: absolute;
            // top: 38px;
            top: 12px;
            height: 1px;
            border-top: 2px dotted $shifl-neutral-n40;
        }

        .card-step-bar-left {
            left: 0;
            right: 100%;
            margin-right: 20px;
        }

        .card-step-bar-right {
            right: 0;
            // left: 9%;
            left: 25px;
            margin-left: 12px;
            // margin-left: 20px;
        }
    }
}

.containers-expansion-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .v-expansion-panels {
        .v-expansion-panel {
            background: transparent !important;

            .v-expansion-panel-header {
                padding: 0 !important;
                min-height: 21px;
                width: auto;

                span {
                    color: $shifl-primary-b90;
                    font-size: 13px;
                }

                .v-expansion-panel-header__icon {
                    i {
                        color: $shifl-primary-b90 !important;
                        margin-top: 1px;
                        margin-left: 4px;
                        font-size: 22px;
                    }
                }
            }    
            
            .v-expansion-panel-content {
                .v-expansion-panel-content__wrap {
                    padding: 0;

                    .other-container-items {
                        padding: 0;
                        border-bottom: 1px solid $shifl-neutral-n20;

                        .milestone-container-num {
                            padding-left: 42px;
                        }
                    }
                }
            }
        }
    }
}

.v-menu__content.container-menu-card-lists {
    .card-view-container-table {
        .v-data-table__wrapper {
            table {
                thead {
                    tr {
                        th {
                            background-color: $shifl-neutral-n10 !important;
                            height: 26px;
                            color: $shifl-neutral-n90;
                            padding: 8px 12px;

                            &:first-child {
                                padding-right: 0 !important;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            font-size: 13px;
                            color: $shifl-neutral-n90;
                            padding: 4px 12px;
                            height: 35px;

                            &:first-child {
                                padding-right: 0 !important;
                            }

                            .yellow--text {
                                color: #DC6B03 !important;
                                caret-color: #DC6B03 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.font-13 {
    font-size: 13px !important;
}

.dark-grey {
    color: $shifl-neutral-n60 !important;
}

.default-color {
    color: $shifl-neutral-n90 !important;
}
</style>
