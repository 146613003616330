<template>
    <div id="data-grid-demo">
        <DxDataGrid
            ref="dataGridReference"
            id="gridContainer"
            key-expr="id"
            :show-borders="true"
            :data-source="compactViewDataSource"
            :remote-operations="true"
            :scrolling="scrollingOptions"
            :syncLookupFilterValues="false"
            :loadPanel="loadPanelOptions"
            @cell-prepared="onCellPrepared"
            @content-ready="onContentReady"
            @editor-preparing="onEditorPreparing">

            <DxFilterRow :visible="isFilterEnabled" :show-all-text="'(All)'" />

            <DxColumn data-field="shifl_ref"
                :width="110"
                caption="Reference"
                :allow-sorting="false"
                :calculate-filter-expression="calculateFilterExpressionReference"
                :filter-operations="allowedOperationsDefault"
                :selected-filter-operation="selectedOperation">
            </DxColumn>

            <DxColumn data-field="location_from_name"
                :width="180"
                caption="Departure"
                :allow-sorting="false"
                cell-template="departure-template"
                :calculate-filter-expression="calculateFilterExpressionDepature"
                :filter-operations="allowedOperationsDefault"
                :selected-filter-operation="selectedOperation">
            </DxColumn>

            <DxColumn data-field="etd"
                :width="150"
                caption="Departure Date"
                :allow-sorting="false"
                cell-template="departure-template-date"
                data-type="date"
                :calculate-filter-expression="calculateFilterExpressionETD">
            </DxColumn>

            <DxColumn data-field="mode"
                :width="100"
                caption="Mode"
                :allow-sorting="false"
                cell-template="mode-template"
                :calculate-filter-expression="calculateFilterExpressionMode"
                :filter-operations="allowedOperationsDefault"
                :selected-filter-operation="selectedOperation">
            </DxColumn>

            <DxColumn data-field="shipment_type"
                :width="100"
                caption="Type"
                :allow-sorting="false"
                cell-template="type-template"
                :calculate-filter-expression="calculateFilterExpressionType"
                :filter-operations="allowedOperationsDefault"
                :selected-filter-operation="selectedOperation">
            </DxColumn>

            <DxColumn data-field="containers_qty"
                :width="100"
                caption="Container"
                :allow-sorting="false"
                cell-template="container-length-template"
                :calculate-filter-expression="calculateFilterExpressionContQty"
                :filter-operations="allowedOperations"
                :selected-filter-operation="selectedOperation">
            </DxColumn>

            <DxColumn data-field="location_to_name" 
                :width="190"
                caption="Arrival"
                :allow-sorting="false"
                cell-template="arrival-template"
                :calculate-filter-expression="calculateFilterExpressionArrival"
                :filter-operations="allowedOperationsDefault"
                :selected-filter-operation="selectedOperation">
            </DxColumn>

            <DxColumn data-field="eta"
                :width="150"
                caption="Arrival Date"
                :allow-sorting="false"
                cell-template="arrival-template-date"
                data-type="date"
                :calculate-filter-expression="calculateFilterExpressionETA">
            </DxColumn>

            <DxColumn data-field="PO"
                :width="200"
                caption="PO"
                :allow-sorting="false"
                cell-template="po-template"
                :filter-operations="allowedOperationsDefault"
                :selected-filter-operation="selectedOperation"
                :calculate-filter-expression="calculateFilterExpressionPO">
            </DxColumn>

            <DxColumn data-field="shipment_status"
                :width="210"
                caption="Status"
                :allow-sorting="false"
                cell-template="status-template"
                :calculate-filter-expression="calculateFilterExpressionStatus"
                :filter-operations="allowedOperations"
                :selected-filter-operation="selectedOperation">
            </DxColumn>

            <DxColumn cell-template="action-cell"
                :width="100"
                type="buttons"
                caption=""
                :allow-filtering="false" />

            <template #departure-template="{ data }">
                <div class="template-data">
                    {{ data.data.location_from_name === null ? 'N/A' : data.data.location_from_name }}
                </div>
            </template>

            <template #departure-template-date="{ data }">
                <div class="template-data">
                    <span :style="`color: ${data.data.etd === null ? '#253041' : '#1A6D9E'}`">
                        {{ convertDateAction(data.data.etd) }}
                    </span>
                </div>
            </template>

            <template #mode-template="{ data }">
                <div class="template-data d-flex justify-center align-center">
                    <div class="shipment-type" v-if="data.data.mode !== null && data.data.mode !== ''">
                        <img v-if="data.data.mode == 'Ocean'" src="@/assets/icons/ocean.svg" width="20px" height="20px" />
                        <img v-if="data.data.mode == 'Air'" src="@/assets/icons/airplane.svg" width="20px" height="20px" />
                        <img v-if="data.data.mode == 'Truck'" src="@/assets/icons/truck.svg" width="20px" height="20px" />
                    </div>

                    <div class="no-shipment-type" v-if="data.data.mode === null || data.data.mode === ''">
                        N/A
                    </div>

                    <!-- <div class="no-shipment-type" 
                        v-if="(data.data.mode == null && data.data.mode == '') && data.data.external_shipment_tracking==0">
                        N/A
                    </div> -->
                </div>
            </template>

            <template #type-template="{ data }">
                <div class="template-data d-flex justify-center align-center">
                    <div class="shipment-type d-flex justify-center align-center" 
                        v-if="data.data.shipment_type !== null && data.data.shipment_type !== ''">
                        <img v-if="data.data.shipment_type == 'LCL'" src="@/assets/images/small-container.svg" width="20px" height="20px" />
                        <img v-if="data.data.shipment_type == 'Air'" src="@/assets/images/airplane-shipment.svg" width="20px" height="20px" />
                        <img v-if="data.data.shipment_type == 'FCL'" src="@/assets/images/big-container.svg" width="20px" height="20px" />
                    </div>

                    <div class="no-shipment-type" v-if="(data.data.shipment_type == null || data.data.shipment_type == '')">
                        N/A
                    </div>
                </div>
            </template>

            <template #container-length-template="{ data }">
                <div class="template-data text-center"> {{ data.data.containers_qty }} </div>
            </template>

            <template #arrival-template="{ data }">
                <div class="template-data">
                    {{ data.data.location_to_name === null ? 'N/A' : data.data.location_to_name }}
                </div>
            </template>            

            <template #arrival-template-date="{ data }">
                <div class="template-data">
                    <span :style="`color: ${data.data.eta === null ? '#253041' : '#1A6D9E'}`">
                        {{ convertDateAction(data.data.eta) }}
                    </span>                            
                </div>
            </template>

            <template #po-template="{ data }">
                <div class="template-data">
                    {{ concatPO(data.data.po_num) }}                        
                </div>
            </template>

            <template #status-template="{ data }">
                <div class="compact-status-template-wrapper">
                    <div class="status d-flex compact" :class="getStatusClass(data.data.shipment_status)" 
                        style="height: 30px !important; padding:0;" v-if="tab === 'shipments'">
                        <div class="d-flex t-shipment-status-wrapper">
                            <div style="margin-right: 2px !important;" class="font-medium" 
                                v-if="data.data.shipment_status==='In transit - hold'">
                                <span style="color: #253041;">{{ "In Transit "}}</span>
                                <span style="color: #B11B1B;">{{" - Hold"}}</span>
                            </div>
                            <div style="margin-right: 2px !important;" class="font-medium" 
                                v-else-if="data.data.shipment_status==='Partial Discharged - Released'">
                                <span style="color: #253041;" class="pr-1 font-medium">{{ "Partial Discharged - " }}</span>
                                <span style="color: #04783A;" class="font-medium">{{ "Released" }}</span>
                            </div>
                            <div style="margin-right: 2px; !important" class="font-medium" 
                                v-else-if="data.data.shipment_status==='Partial Discharged - Hold'">
                                <span style="color: #253041;" class="pr-1 font-medium">{{ "Partial Discharged - " }}</span>
                                <span style="color: #B11B1B;" class="font-medium">{{ "Hold" }}</span>
                            </div>
                            <div style="margin-right: 2px !important;" class="font-medium" 
                                v-else :class="getShipmentStatusClass(data.data, 'default')">
                                {{ ucFirst(data.data.shipment_status.toLowerCase()) }}
                            </div>
                        </div>
                    </div>

                    <div class="status d-flex compact" :class="getStatusClass(data.data.status_v2)" 
                        style="height: 30px !important; padding:0;" v-else-if="tab === 'completed'">
                        <div class="d-flex t-shipment-status-wrapper">
                            <div style="margin-right: 2px !important;" class="font-medium" 
                                :class="getShipmentStatusClass(data.data, 'default')">
                                {{ ucFirst(data.data.status_v2.toLowerCase()) }}
                            </div>
                        </div>
                    </div>

                    <div v-else>N/A</div>
                </div>
            </template>

            <template #action-cell="{ data }">
                <div class="shipment-action-button d-flex align-center justify-center">
                    <button class="btn-white" @click="handleClick(data.data)"
                        style="width: 34px !important; height: 34px !important;">
                        <img src="@/assets/icons/visibility-gray.svg" alt="" height="16px" width="16px">
                    </button>
                    <div class="three-dots-wrapper">
                        <v-menu offset-y bottom left content-class="pending-dropdown-container">
                            <template v-slot:activator="{ on, attrs }">
                                <button v-bind="attrs" v-on="on" class="shipment-three-dots-container"
                                style="width: 34px !important; height: 34px !important;">
                                    <custom-icon
                                        iconName="three-dots"
                                        color="#1A6D9E"
                                        width="11"
                                        height="3" />
                                </button>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-title class="k-fw-sm k-font-inter-regular k-dark-blue" @click="markShipmentCompletedDialog(data.data)">
                                        Mark As Completed
                                    </v-list-item-title>
                                </v-list-item>
                                <!-- <v-list-item>
                                    <v-list-item-title class="k-fw-sm k-font-inter-regular">
                                        Edit Shipment
                                    </v-list-item-title>
                                </v-list-item> -->
                                <v-list-item>
                                    <v-list-item-title class="k-fw-sm k-font-inter-regular k-red" @click="cancelRequestForm(data.data.id)">
                                        Request Cancellation
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </div>  
            </template>
        </DxDataGrid>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import globalMethods from '@/utils/globalMethods'
import CustomIcon from "@/components/Icons/CustomIcon";

import DxDataGrid, { DxColumn, DxFilterRow } from 'devextreme-vue/data-grid';
import { GetStore, onCellPreparedFunction, getCombinedFilterFunction, onEditorPreparingFunction, onContentReadyFunction } from '../../methods/compactViewMethods';
import { 
        calculateFilterExpressionReference, 
        calculateFilterExpressionDepature,
        calculateFilterExpressionArrival,
        calculateFilterExpressionContQty,
        calculateFilterExpressionType,
        calculateFilterExpressionMode,
        calculateFilterExpressionStatus,
        calculateFilterExpressionPO,
        calculateFilterExpressionETD,
        calculateFilterExpressionETA
} from '../../methods/calculateFilterExpressions';

const baseURL = process.env.VUE_APP_BASE_URL;
import moment from 'moment';


// 
// import ArrayStore from 'devextreme/data/array_store';

// const arrayDataStore = new ArrayStore({
//     // ArrayStore is configured here
//     store: () {

//     }
// });

export default {
    props: ['activeTab', 'isMobile', 'search', 'isFilterEnabled', 'tab'],
    components: {
        CustomIcon,
        DxDataGrid,
        DxColumn,
        DxFilterRow,
    },
    data: () => ({
        pageCount: 0,
        windowWidth: window.innerWidth,
        openCancelRequestForm: false,
        cancel_reason: '',
        markShipmentDialog: false,
        cancelShipmentItemId: null,
        markCompletedShipment: null,
        // compact view
        loadPanelOptions: {
			text: 'Loading data...',
			showPane: true,
			shading: false,
			shadingColor: '#ffffff61',
			position: {
				my: 'top',
				at: 'top',
				of: '#data-grid-demo .dx-datagrid-content'
			},
			animation: 'disabled',
            enabled: true
		},
		scrollingOptions: {
			mode: "virtual",
			useNative: true,
			scrollByContent: true,
			scrollByThumb: true,
			direction: "both",
			showScrollbar: "always",
            timeout: 500,
            minTimeout: 500,
		},
		editingOptions: {
			allowUpdating: true,
			startEditAction: 'dblClick',
			useIcons: true,
			mode: "cell",
			refreshMode: 'repaint'
		},
        allowedOperationsDefault: [],
        allowedOperations: ['contains', '=', 'between'],
        selectedOperation: '',
        compactViewDataSource: [],
    }),
    computed: {
        ...mapGetters([
            "getAllShipments",
            "getShipmentLoadingStatus",
            "getSearchedShipments",
            "getSearchedShipmentLoading",
            "getSearchedShipmentPagination",
            "getCancelShipmentLoading",
            "getmarkShipmentCompletedLoading",
        ]),
        // getCompactDataSource() {
        //     let filteredShipments = []

        //     if (typeof this.getSearchedShipments !== 'undefined' && this.getSearchedShipments !== null) {
        //         if (this.search !== '' && this.getSearchedShipments.tab === 'shipments') {
        //             filteredShipments = this.getSearchedShipments.shipments
        //         } else {
        //             filteredShipments = GetStore(url)
        //         }
        //     } else {
        //         filteredShipments = GetStore(url)
        //     }
            
        //     if (filteredShipments.length > 0) {
        //         filteredShipments.map((fs, key ) => {
        //             filteredShipments[key].external_shipment = 0
        //             filteredShipments[key].external_shipment_tracking = 0
        //             if (!Array.isArray(fs.po_list)) {
        //                 filteredShipments[key].po_list = []
        //             }

        //             if (fs.is_tracking_shipment==1) {
        //                 filteredShipments[key].external_shipment = 1
        //             }
        //             if (typeof fs.terminal_fortynine!=='undefined' && fs.terminal_fortynine!==null && typeof fs.terminal_fortynine.attributes!=='undefined') {
                        
        //                 let getAttributes = fs.terminal_fortynine.attributes
        //                 let getContainers = fs.terminal_fortynine.containers
        //                 if (getAttributes !=='' && getAttributes!==null && fs.is_tracking_shipment == 1) {
        //                     filteredShipments[key].external_shipment_tracking = 1
        //                     filteredShipments[key].external_shipment_containers = []
        //                 }

        //                 if (getContainers!=='undefined' ) {
        //                     getContainers = JSON.parse(getContainers)
        //                     filteredShipments[key].external_shipment_containers = getContainers
        //                     // console.log(getContainers)
        //                 }
        //             }
        //         })
        //     }

        //     return filteredShipments
        // }
    },
    watch: {},
    methods: {
        ...mapActions([
            "fetchShipments",
            "setShipmentOrder",
            "fetchShipmentsSearched",
            "cancelShipment",
            "markShipmentCompleted",
            "fetchCompletedShipments",
        ]),
        ...globalMethods,
        calculateFilterExpressionReference,
        calculateFilterExpressionDepature,
        calculateFilterExpressionArrival,
        calculateFilterExpressionContQty,
        calculateFilterExpressionType,
        calculateFilterExpressionMode,
        calculateFilterExpressionStatus,
        calculateFilterExpressionPO,
        calculateFilterExpressionETD,
        calculateFilterExpressionETA,
        getCompactViewDataSource() {
            let sort = "eta";
            let order = this.tab === "completed" ? "desc" : "asc";

            let url = `${baseURL}/v2/shipment-tab?shipment_tab=${this.tab}&order_by=${order}&sort_column=${sort}`;

            if (this.search !== "") {
                url = url.concat("&", this.search)
            }

            this.compactViewDataSource = GetStore(url, this)

            // if (this.compactViewDataSource.length === 0) { }
            // let dataGridReference = this.$refs.dataGridReference.instance;
            // console.log(dataGridReference, "dataGridReference");
        },
        ucFirst(str) {
            let pieces = str.split(" ")
            for ( let i = 0; i < pieces.length; i++ ) {
                let j = pieces[i].charAt(0).toUpperCase()
                pieces[i] = j + pieces[i].substr(1)
            }
            return pieces.join(" ")
        },
        getShipmentStatusClass(item, type) {
            this.$emit("getShipmentStatusClass", item, type);
        },
        handleClick(value) {
            this.$emit('handleClick', value)
        },
        onResize() {
            this.windowWidth = window.innerWidth
        },
        getStatus(status) {
            this.$emit("getStatus", status);
        },
        getStatusClass(status) {
            if (status == 'Past last free day') {
                return 'Past-day'
            } else if (status == 'Empty In') {
                return 'Empty-in'
            } else if (status == 'Full Out') {
                return 'Full-out'
            } else if (status == 'Discharged - Hold') {
                return 'Discharged-hold'
            } else if (status == 'Discharged - released') {
                return 'Discharged-released'
            } else if (status == 'Partially discharged - hold') {
                return 'Partial-hold'
            } else if (status == 'Partially discharged - released') {
                return 'Partial-released'
            } else {
                return status
            }
        },
        cancelRequestForm(item) {
            this.cancelShipmentItemId = item;
            this.openCancelRequestForm = true;
            this.cancel_reason = '';
        },
        markShipmentCompletedDialog(item){
            this.markShipmentDialog = true
            this.markCompletedShipment = item
        },
        // Compact View Methods
        onCellPrepared(e) {
            return onCellPreparedFunction(e, this)
        },
        onContentReady(e) {
            return onContentReadyFunction(e, this)
		},
        onEditorPreparing(e) {
            return onEditorPreparingFunction(e, this)
        },
        getCombinedFilter(e) {
            return getCombinedFilterFunction(e)
        },
        convertDateAction(v) {
            if (v !== null && v !== "") {
                return moment.utc(v).format('MMM DD, YYYY');
            } else {
                return "N/A";
            }
        },
        concatPO(v) {
            if (v.length > 0) {
                return v.join(', ');
            } else {
                return "N/A";
            }
        }
    },
    mounted() {
        //set current page
        this.$store.dispatch("page/setPage", "shipments")
        this.getCompactViewDataSource();
    },
    updated() { }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import '@/assets/scss/utilities.scss';
@import '../scss/shipmentsTabTable.scss';

.vc-popover-content {
    z-index: 100;
}
</style>
