<template>
    <div id="mapContainer" class="basemap"></div>
</template>
  
<script>
import mapboxgl from "mapbox-gl";

export default {
    name: "BaseMap",
    props: ["longitude", "latitude"],

    data() {
        return {
            accessToken: `${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}`,
        };
    },
    mounted() {
       
        mapboxgl.accessToken = this.accessToken;

        const map=  new mapboxgl.Map({
            container: "mapContainer",
            style: "mapbox://styles/mapbox/streets-v11",
            center: [this.longitude, this.latitude],
            zoom: 12,
           
        });


         new mapboxgl.Marker()
            .setLngLat([this.longitude, this.latitude])
            .addTo(map);

    },
};
</script>


<style lang="scss" scoped>
.basemap {
    width: 100%;
    height: 100%;
}
</style>