<template>
    <div class="shipment-tabs-table-wrapper-component">
        <div class="default-view" v-if="currentViewTab === 0">            
            <!-- FOR DESKTOP -->
            <v-data-table 
                :headers="headersShipment" 
                :items="getCurrentShipmentsData"
                mobile-breakpoint="769"
                :page="getCurrentShipmentPage"
                :items-per-page="getShipmentItemsPerPage"
                item-key="name"
                class="shipments-table"
                :id="completed.length !== 0 ? '' : 'table-no-data'"
                v-bind:class="{
                    'no-data-table': (typeof getCurrentShipmentsData !== 'undefined' && getCurrentShipmentsData.length === 0),
                    'no-current-pagination' : getCurrentPaginationCountClass(),
                    'has-searched-data' : search !== '' && getCurrentShipmentsData.length === 0
                }"
                hide-default-footer
                style="box-shadow: none !important"
                @page-count="pageCount = $event"
                v-if="activeShipmentTab == 2 && !isMobile"
                hide-default-header
                fixed-header
                ref="my-table">

                <template v-slot:header="{ props: { headers } }">
                    <thead>
                        <tr>
                            <th v-for="(item, index) in headers" 
                                :key="index"
                                role="columnheader"
                                :aria-label="item.text"
                                scope="col"
                                v-bind:class="
                                    [item.sortable ? 'sortable' : '',
                                    item.align == ' d-none' ? 'd-none' : `text-${item.align}`]"
                                @click="item.sortable ? changeSort(item.value) : ''"
                                v-bind:style="`width: ${item.width}; min-width: ${item.width};`">

                                {{ item.text }}

                                <v-icon v-if="item.sortable" small>{{ completedIconSort }}</v-icon>
                            </th>
                        </tr>  
                    </thead>
                </template>
                
                <template v-slot:no-data>
                    <div class="no-data-preloader" v-if="getAllCompletedShipmentsLoading">
                        <v-progress-circular
                            :size="30"
                            color="#1A6D9E"
                            indeterminate>
                        </v-progress-circular>
                    </div>

                    <div v-if="(!getAllCompletedShipmentsLoading) && getCurrentShipmentsData.length == 0" class="no-data-wrapper">
                        <div class="no-data-icon">
                            <img src="@/assets/icons/noShipmentData.svg" alt="" width="65px">
                        </div>

                        <div class="no-data-heading">
                            <p> No Completed Shipments </p>
                        </div>
                    </div>
                </template>

                <template v-slot:item="props">
                    <tr class="shifl-shipment-row" :data-id="props.item.id" :id="props.item.id">
                        <td class="shifl-shipment-reference">
                            <div class="departure-content-wrapper">
                                <div class="flag-wrapper">
                                    <p style="margin-bottom: 0px"> {{ props.item.shifl_ref }} </p>
                                </div>

                                <div class="d-flex reference-column gap-1" @mouseenter="detectLineClamp(props.item, props.item.id, 'reference')">
                                    <!-- <p style="color: #69758C;"
                                        class="reference-number"
                                        v-if="props.item.customer_reference_number !== null">
                                        {{ props.item.customer_reference_number }}
                                    </p> -->

                                    <CalendarTooltip :data="props.item" isFor="event-info-inner-content" :isShowTooltip.sync="isTruncatedText" :nudge="false">
                                        <template slot="tooltip-main-data" slot-scope="{ on, attrs }">
                                            <div v-on="on" v-bind="attrs" :id="`td-reference-content-${props.item.id}`" class="reference-number">
                                                <p style="color: #69758C;" v-if="props.item.customer_reference_number !== null">
                                                    {{ props.item.customer_reference_number }}
                                                </p>
                                            </div>
                                        </template>

                                        <template slot="tooltip-data">
                                            <div class="tooltip-data-destination mb-2 mt-1">
                                                <span class="font-semi-bold tooltip-title">Customer Reference Number:</span>
                                                <p class="mb-0 mt-1 font-medium" style="font-size: 11px;">
                                                    {{ props.item.customer_reference_number }}
                                                </p>
                                            </div>
                                        </template>
                                    </CalendarTooltip>
                                    <p class="mb-0" v-if="props.item.consolidation_status == 2">
                                        <span class="consolidatation-status">
                                            {{ props.item.consolidation_status == 2 ? "Consolidated" : "" }}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </td>

                        <td class="shifl-shipment-location-etd">
                            <div class="departure-content-wrapper">
                                <div class="flag-wrapper">
                                    <p style="margin-bottom: 0px">
                                        {{ props.item.location_from_name !== null ? props.item.location_from_name : 'N/A' }}
                                    </p>
                                </div>

                                <div>
                                    <p style="color: #69758C;">
                                        {{ convertDateAction(props.item.etd) }}
                                    </p>
                                </div>
                            </div>
                        </td>

                        <td class="shifl-shipment-type">
                            <div class="shifl-shipment-type-content d-flex align-center">
                                <div class="shipment-modes d-flex align-center mr-2" 
                                    v-if="props.item.mode !== null && props.item.mode !== ''">
                                    <img :src="getImgUrl(props.item.mode, 'mode')" 
                                        height="20px" 
                                        width="22px">
                                </div>
                                <div class="shipment-type">
                                    <div class="shipment-type-content d-flex align-center">
                                        <img :src="getImgUrl(props.item.shipment_type, 'shipment_type')" 
                                            height="20px" 
                                            width="20px"
                                            v-if="props.item.shipment_type !== null && props.item.shipment_type !== '' && props.item.shipment_type !== 'null'">

                                        <span v-else> N/A </span>
                                    </div>

                                    <span style="padding-bottom: 2px;" 
                                        v-if="props.item.shipment_type == 'FCL' && props.item.containers_qty !== 0"> 
                                        ({{ props.item.containers_qty }})
                                    </span>
                                </div>
                            </div>
                            
                            <div class="shipment-type" v-if="
                                props.item.is_shipment_tracking_create === 1 && 
                                props.item.containers_qty > 0 && 
                                props.item.shipment_type === null">

                                <img src="@/assets/images/big-container.svg" />
                                <span style="padding-bottom:3px;">({{ props.item.containers_qty }})</span>
                            </div>
                        </td>

                        <td class="shifl-shipment-location-eta">
                            <div class="arrival-content-wrapper">
                                <div class="flag-wrapper">
                                    <p style="margin-bottom: 0px">
                                        {{ props.item.location_to_name !== null ? props.item.location_to_name : 'N/A' }}
                                    </p>
                                </div>
                                <p style="color: #69758C;">
                                    {{ convertDateAction(props.item.eta) }}
                                </p>
                            </div>
                        </td>

                        <td class="shifl-shipment-suppliers" @mouseenter="detectLineClamp(props.item, props.item.id, 'supplier')">
                            <CalendarTooltip :data="props.item" isFor="event-info-inner-content" :isShowTooltip.sync="isTruncatedText" :nudge="false">
                                <template slot="tooltip-main-data" slot-scope="{ on, attrs }">
                                    <div class="supplier-desktop" v-on="on" v-bind="attrs" :id="`td-supplier-content-${props.item.id}`"
                                        :class="activeShipmentTab == 0 ? 'active-tab' : ''">
                                        <span>{{ checkSuppliersName(props.item.suppliers_names) }}</span>

                                        <span v-if="props.item.suppliers_names === null || props.item.suppliers_names.length == 0">
                                            <span>N/A</span>
                                        </span>
                                    </div>
                                </template>

                                <template slot="tooltip-data">
                                    <div class="tooltip-data-destination mb-2 mt-1">
                                        <span class="font-semi-bold tooltip-title">Suppliers:</span>
                                        <p class="mb-0 mt-1 font-medium" style="font-size: 11px;">
                                            {{ checkSuppliersName(props.item.suppliers_names) }} 
                                        </p>
                                    </div>
                                </template>
                            </CalendarTooltip>
                        </td>

                        <td class="shifl-shipment-po" @mouseenter="detectLineClamp(props.item, props.item.id, 'po')">
                            <CalendarTooltip :data="props.item" isFor="event-info-inner-content" :isShowTooltip.sync="isTruncatedText" :nudge="false">
                                <template slot="tooltip-main-data" slot-scope="{ on, attrs }">
                                    <div class="po-num-desktop" v-on="on" v-bind="attrs">
                                        <p :id="`td-po-content-${props.item.id}`">
                                            {{ props.item.po_num.join(", ") }}
                                        </p>
                                    </div>

                                    <div v-if="props.item.po_num == null || props.item.po_num.length == 0">
                                        <p> N/A </p>
                                    </div> 
                                </template>

                                <template slot="tooltip-data">
                                    <div class="tooltip-data-destination mb-2 mt-1">
                                        <span class="font-semi-bold tooltip-title">POs:</span>
                                        <p class="mb-0 mt-1 font-medium" style="font-size: 11px;">
                                            <span v-for="(name, index) in props.item.po_num" :key="index">
                                                {{ name }}<template v-if="index + 1 < props.item.po_num.length">, </template>
                                            </span>
                                        </p>
                                    </div>
                                </template>
                            </CalendarTooltip>
                        </td>

                        <td class="shifl-shipment-status">
                            <div class="status d-flex" v-if="props.item.status !== null && props.item.tracking_status !== null">
                                <div class="d-flex t-shipment-status-wrapper">
                                    <!-- <div :class="getShipmentStatusClass(props.item,'tracking')" 
                                        v-if="typeof props.item.tracking_status !== 'undefined' && props.item.tracking_status !== ''" class="font-medium pa-0">
                                        <v-chip>{{ props.item.tracking_status }}</v-chip>
                                    </div> -->

                                    <div class="font-medium pa-0" :class="getShipmentStatusClass(props.item, 'default')">
                                        <v-chip> {{ ucFirst(props.item.status.toLowerCase()) }} </v-chip>
                                    </div>
                                </div>
                                <!-- <v-chip class="pa-0" v-html="getShipmentStatusClass(props.item, 'default')"></v-chip> -->
                            </div>
                        </td>

                        <td class="shifl-shipment-actions pr-2">
                            <div class="shipment-action-button d-flex align-end justify-end mr-1">
                                <button class="btn-white" @click.stop="handleClick(props.item)">
                                    <img src="../../../../assets/icons/visibility-gray.svg" alt="" height="16px" width="16px">
                                </button>
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table> 

            <!-- FOR MOBILE -->
            <v-data-table 
                :headers="headersShipment" 
                :items="getCurrentShipmentsData"
                mobile-breakpoint="769"
                :page="getCurrentShipmentPage"
                :items-per-page="getShipmentItemsPerPage"
                item-key="name"
                class="table-mobile shipments-table-mobile"
                :id="getCurrentShipmentsData.length !== 0 ? '' : 'table-no-data'"
                v-bind:class="{
                    'no-data-table': (typeof getCurrentShipmentsData !== 'undefined' && getCurrentShipmentsData.length === 0),
                    'no-current-pagination' : getCurrentPaginationCountClass(),
                    'has-searched-data' : search !== '' && getCurrentShipmentsData.length === 0
                }"
                hide-default-footer
                style="box-shadow: none !important"
                @page-count="pageCount = $event"
                @click:row="handleClick"
                v-if="activeShipmentTab == 2 && isMobile"
                hide-default-header
                fixed-header
                ref="my-table">
                
                <template v-slot:no-data>
                    <div class="no-data-preloader" v-if="getAllCompletedShipmentsLoading">
                        <v-progress-circular
                            :size="30"
                            color="#1A6D9E"
                            indeterminate>
                        </v-progress-circular>
                    </div>

                    <div v-if="(!getAllCompletedShipmentsLoading) && getCurrentShipmentsData.length == 0" class="no-data-wrapper">
                        <div class="no-data-icon">
                            <img src="@/assets/icons/noShipmentData.svg" alt="" width="65px">
                        </div>

                        <div class="no-data-heading">
                            <p v-if="activeTab == 1">
                                <span v-if="search === ''">No Shipments</span>
                                <span v-else>No Shipments found. <br/> Try searching another keyword.</span>
                            </p>
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.shifl_ref`]="{ item }">
                    <div class="table-mobile-data mobile-reference">
                        <div class="mobile-reference-content k-flex k-flex-col k-items-start">
                            <span class="k-flex k-items-start">
                                <span class="mr-2">{{ item.shifl_ref }}</span>

                                <div class="mobile-mode d-flex align-center">
                                    <span class="mr-2 d-flex align-center" v-if="item.mode !== null && item.mode !== ''">
                                        <img :src="getImgUrl(item.mode, 'mode')" height="18px"  width="20px">
                                    </span>

                                    <div class="d-flex align-center mr-1" 
                                        v-if="item.shipment_type !== null && item.shipment_type !== '' && item.shipment_type !== 'null'">                                    
                                        <img :src="getImgUrl(item.shipment_type, 'shipment_type')" 
                                            height="18px" 
                                            width="20px"
                                            v-if="item.shipment_type !== null && item.shipment_type !== '' && item.shipment_type !== 'null'">

                                        <span style="margin-left: 6px; font-weight: 500; font-size: 14px;" class="font-regular"
                                            v-if="item.shipment_type == 'FCL' && item.containers_qty !== 0"> 
                                            ({{ item.containers_qty }})
                                        </span>
                                    </div>
                                </div>
                            </span>
                        </div>

                        <div class="shipment-action-button d-flex align-end justify-end">
                                <button class="btn-white" @click.stop="handleClick(item)">
                                    <img src="../../../../assets/icons/visibility-gray.svg" alt="" height="14px" width="14px">
                                </button>
                            </div>

                        <!-- static only for viewing purposes of todos
                        <div class="shipment-item-todos-mobile d-flex align-center justify-center" 
                            v-if="index !== 1 && index !== 2">
                            <img src="../../../../assets/icons/to-dos-blue.svg" alt="" height="12px" width="12px"> 
                            <span class="todos-count ml-2 font-medium" style="color: #1A6D9E;">(1)</span>
                        </div> -->
                    </div>
                </template>

                <template v-slot:[`item.location_from_name`]="{ item }">
                    <div class="table-mobile-data-content">
                        <div class="table-mobile-data mb-2">
                            <div class="mobile-departure-wrapper">
                                <span style="color: #69758C;">Departure</span>
                            </div>

                            <div class="mobile-departure-wrapper" style="text-align: left !important;">
                                <div class="flag-wrapper">
                                    <p style="margin-bottom: 0px;">
                                        <span>
                                            {{ item.location_from_name !== null ? item.location_from_name : 'N/A' }}

                                            <span class="departure-date ml-1" style="color: #1A6D9E;">
                                                {{ convertDateAction(item.etd) }}
                                            </span>
                                        </span>                                    
                                    </p>
                                </div>
                            </div>      
                        </div>

                        <div class="table-mobile-data mb-2">
                            <div class="mobile-arrival-wrapper">
                                <span style="color: #69758C;">Arrival</span>
                            </div>

                            <div class="arrival-wrapper" style="text-align: right !important;">
                                <div class="flag-wrapper">
                                    <p style="margin-bottom: 0px">
                                        <span>
                                            {{ item.location_to_name !== null ? item.location_to_name : 'N/A' }}
                                            
                                            <span class="arrival-date ml-1" style="color: #1A6D9E;">
                                                {{ convertDateAction(item.eta) }}
                                            </span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="table-mobile-data mb-2">
                            <div class="mobile-supplier-wrapper">
                                <span style="color: #69758C;">Supplier</span>
                            </div>

                            <div class="po-num-mobile ml-3">
                                <!-- <div v-if="item.suppliers_names.length > 2">
                                    <p>{{ item.suppliers_names[0] }},</p>
                                    <div class="d-flex align-center justify-end">
                                        <p class="mr-1 second-supplier-name">{{ item.suppliers_names[1] }},</p>
                                        <span v-if="item.suppliers_names.length > 2" style="color: #1A6D9E;">
                                            +{{ item.suppliers_names.length - 2 }} Other
                                        </span>
                                    </div>
                                </div>
                                <div v-else>
                                    <p v-for="(name, index) in item.suppliers_names" :key="index">
                                        {{ name }}
                                    </p>
                                </div> -->

                                <div v-if="item.suppliers_names.length > 2">
                                    {{ checkSuppliersNameMobile(checkSuppliersName(item.suppliers_names), 50) }},
                                    <span style="color: #1A6D9E;">+{{ item.suppliers_names.length - 2 }} Other</span>
                                </div>
                                <div v-else>
                                    {{ checkSuppliersNameMobile(checkSuppliersName(item.suppliers_names), 55) }}
                                </div>
                            </div>

                            <div v-if="item.suppliers_names === null || item.suppliers_names.length == 0">
                                <span>N/A</span>
                            </div>
                        </div>

                        <div class="table-mobile-data mb-1">
                            <div class="mobile-pos-wrapper">
                                <span style="color: #69758C;">POs</span>
                            </div>

                            <div class="po-num-mobile">
                                <p>
                                    <span v-for="(name, index) in item.po_num" :key="index">
                                        <span v-if="index === 0 || index === 1">
                                            {{ name }}<template v-if="index + 1 < item.po_num.length">, </template>
                                        </span>
                                    </span>

                                    <span v-if="item.po_num.length > 2" style="color: #1A6D9E;">
                                        +{{ item.po_num.length - 2 }} Other
                                    </span>
                                </p>
                            </div>

                            <div v-if="item.po_num == null || item.po_num.length == 0">
                                <p> {{ item.is_tracking_shipment ? '' : 'N/A'}} </p>
                            </div>
                        </div>

                        <div class="table-mobile-data mt-1 mb-1">
                            <div class="mobile-supplier-wrapper">
                                <span style="color: #69758C;">Status</span>
                            </div>

                            <div class="status d-flex" v-if="item.status !== null">
                                <div class="d-flex t-shipment-status-wrapper">
                                    <div class="font-medium pa-0 mr-0" :class="getShipmentStatusClass(item, 'default')">
                                        <v-chip> {{ ucFirst(item.status.toLowerCase()) }} </v-chip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>               
                </template>

                <!-- <template v-slot:[`item.mode`]="{ item }">
                    <div class="status d-flex" v-if="item.status !== null && item.tracking_status !== null">
                        <div class="d-flex t-shipment-status-wrapper">
                            <div :class="getShipmentStatusClass(item,'tracking')" 
                                v-if="typeof item.tracking_status !== 'undefined' && item.tracking_status !== ''" class="font-medium pa-0">
                                <v-chip>{{ item.tracking_status }}</v-chip>
                            </div>

                            <div class="font-medium pa-0" :class="getShipmentStatusClass(item, 'default')">
                                <v-chip> {{ ucFirst(item.status.toLowerCase()) }} </v-chip>
                            </div>
                        </div>
                    </div>
                </template> -->
            </v-data-table>
        </div>

        <div class="calendar-view" v-if="currentViewTab === 1">
            <CalendarView 
                :isFilterEnabled="isFilterEnabled"
                @handleClick="handleClick"
                @getShipmentStatusClass="getShipmentStatusClass"
                :currentCalendarType.sync="type"
                :currentDateCalendar.sync="currentDateCalendar"
                :tab="tab"
                :search.sync="search"
                :selectedDefaultFilter.sync="selectedCalendarFilter"
                :selectedCalendarOption="selectedCalendarOption"
                :isAllSelected="isAllSelected"
                :isCalendarFilterSelectionEmpty.sync="isCalendarFilterSelectionEmpty" />
                <!-- @getStatus="getStatus"
                @getStatusClass="getStatusClass" -->
        </div>

        <div class="card-view" v-if="currentViewTab === 2">
            <CardView 
                :getCurrentShipmentsData="getCurrentShipmentsData"
                :getCurrentShipmentPage="getCurrentShipmentPage"
                :getShipmentLastPage="getShipmentPagination"
                @getShipmentStatusClass="getShipmentStatusClass"
                :tab="tab"
                :search.sync="search" />
        </div>

        <div class="compact-view" v-if="currentViewTab === 3">
            <ReportViewCustom 
                :isFilterEnabled="isFilterEnabled"
                @handleClick="handleClick"
                @getShipmentStatusClass="getShipmentStatusClass"
                :tab="tab"
                :search.sync="search"
                :shipmentIcon="shipmentIcon"
                :activeTab="2" />
                <!-- @getStatus="getStatus"
                @getStatusClass="getStatusClass" -->
        </div>

        <div class="pagination-wrapper pt-3" 
            v-if="activeShipmentTab === 2 && getCurrentShipmentsData !== 'undefined' && 
            search == '' && pagination.completedTab.total > 1 && currentViewTab === 0">
            <v-pagination
                v-model="pagination.completedTab.current_page"
                :length="pagination.completedTab.total"
                prev-icon="Previous"
                next-icon="Next"
                :total-visible="!isMobile ? '11' : '5' "
                @input="handlePageChange">
            </v-pagination>
        </div> 
        <!-- v-if="activeShipmentTab === 2 && getCurrentShipmentsData !== 'undefined' && getCurrentShipmentsData.length > 0 && search == ''" -->

        <div class="pagination-wrapper pt-3" 
            v-if="activeShipmentTab === 2 && getCurrentShipmentsData !== 'undefined' && 
            search !== '' && getShipmentPagination > 1 && currentViewTab === 0">
            <v-pagination
                v-model="getCurrentShipmentPage"
                :length="getShipmentPagination"
                prev-icon="Previous"
                next-icon="Next"
                :total-visible="!isMobile ? '11' : '5' "
                @input="handlePageSearched">
            </v-pagination>
        </div> 
        <!-- v-if="activeShipmentTab === 2 && getCurrentShipmentsData !== 'undefined' && getCurrentShipmentsData.length > 0 && search !== ''" -->
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import globalMethods from '../../../../utils/globalMethods'
import _ from 'lodash'
import CalendarView from "./Views/CalendarView.vue";
import ReportViewCustom from "./Views/ReportViewCustom.vue";
import CardView from "./Views/CardView.vue";
import moment from "moment";
import CalendarTooltip from './Views/components/CalendarTooltip.vue';
import { shipmentStatusesLists } from "./js/statusLists";

export default {
    props: [
        'completed', 
        'activeTab', 
        'isMobile', 
        'search', 
        'paginationData', 
        'completedIconSort', 
        'currentViewTab', 
        'isFilterEnabled', 
        'currentCalendarType', 
        'currentDateCalendar', 
        'tab', 
        'selectedDefaultFilter',
        'shipmentIcon',
        'selectedCalendarOption',
        'isAllSelected',
        'isCalendarFilterSelectionEmpty'
    ],
    components: {
        CalendarView,
        ReportViewCustom,
        CardView,
        CalendarTooltip
    },
    data: () => ({
        pageCount: 0,
        headersShipment: [
            {
                // text: "Ref#/Cus Ref",
                text: "Reference",
                align: "start",
                sortable: false,
                value: "shifl_ref",
                width: "10%",
                fixed: true
            },
            {
                text: "Departure",
                align: "start",
                value: "location_from_name",
                sortable: false,
                width: "13%", 
                fixed: true
            },
            {
                // text: "Mode/Type",
                text: "Type",
                align: "start",
                value: "mode",
                sortable: false,
                width: "9%",
                fixed: true
            },
            {
                text: "Arrival",
                align: "start",
                value: "location_to_name",
                sortable: true,
                width: "14%", 
                fixed: true
            },
            {
                text: "Suppliers",
                align: "start",
                value: "suppliers_names",
                sortable: false,
                width: "18%",
                fixed: true
            },
            {
                text: "PO",
                align: "start",
                value: "po_num",
                sortable: false,
                width: "16%",
                fixed: true
            },
            {
                text: "Status",
                value: "shipment_status",
                align: 'start',
                sortable: false,
                width: "8%",
                fixed: true
            },
            {
                text: "",
                value: "actions",
                align: 'end',
                sortable: false,
                width: "5%",
                fixed: true
            },
            {
                text: "Container",
                align: ' d-none',
                sortable: false,
                value: "container_num_list",
                width: "0",
                fixed: true
            },
            {
                text: "Mbl",
                align: ' d-none',
                sortable: false,
                value: "mbl_num",
                width: "0",
                fixed: true
            },
            {
                text: "Hbl",
                align: ' d-none',
                sortable: false,
                value: "hbl_num",
                width: "0",
                fixed: true
            },
            {
                text: "Ams",
                align: ' d-none',
                sortable: false,
                value: "ams_num",
                width: "0",
                fixed: true
            },
        ],
        isTruncatedText: false
    }),
    computed: {
        ...mapGetters([
            "getShipmentLoadingStatus",
            "getAllCompletedShipmentsLoading",
            "getSearchedShipments",
            "getSearchedShipmentPagination"
        ]),
        activeShipmentTab: {
            get() {
                return this.activeTab
            },
            set(value) {
                this.$emit('update:activeTab', value)
            }
        },
        pagination: {
            get() {
                return this.paginationData
            },
            set(value) {
                this.$emit('update:paginationData', value)
            }
        },
        getCurrentShipmentsData() {
            let filteredShipments = []

            if (typeof this.getSearchedShipments !== 'undefined' && this.getSearchedShipments !== null) {
                if (this.search !== '' && this.getSearchedShipments.tab === 'completed') {
                    filteredShipments = this.getSearchedShipments.shipments
                } else {
                    filteredShipments = this.completed
                }
            } else {
                filteredShipments = this.completed
            }


            if ( filteredShipments.length > 0) {
                filteredShipments.map((fs, key ) => {
                    filteredShipments[key].external_shipment = 0
                    filteredShipments[key].external_shipment_tracking = 0

                    if ( !Array.isArray(fs.po_list)) {
                        filteredShipments[key].po_list = []
                    }

                    if (fs.is_tracking_shipment==1) {
                        filteredShipments[key].external_shipment = 1
                    }
                    if (typeof fs.terminal_fortynine!=='undefined' && fs.terminal_fortynine!==null && typeof fs.terminal_fortynine.attributes!=='undefined') {
                        
                        let getAttributes = fs.terminal_fortynine.attributes
                        let getContainers = fs.terminal_fortynine.containers
                        if ( getAttributes !=='' && getAttributes!==null && fs.is_tracking_shipment == 1) {
                            filteredShipments[key].external_shipment_tracking = 1
                            filteredShipments[key].external_shipment_containers = []
                        }

                        if ( getContainers!=='undefined' ) {
                            getContainers = JSON.parse(getContainers)
                            filteredShipments[key].external_shipment_containers = getContainers
                        }

                    }

                })
            }

            return filteredShipments
        },
        getCurrentShipmentsDataBak() {
            if (typeof this.getSearchedShipments !== 'undefined' && this.getSearchedShipments !== null) {
                if (this.search !== '' && this.getSearchedShipments.tab === 'completed') {
                    return this.getSearchedShipments.shipments
                } else {
                    return this.completed
                }
            } else {
                return this.completed
            }
        },
        getCurrentShipmentPage: {
            get() {
                if (typeof this.getSearchedShipments !== 'undefined' && this.getSearchedShipments !== null) {
                    if (this.search !== '' && this.getSearchedShipments.tab === 'completed') {
                        return this.getSearchedShipmentPagination.current_page
                    } else {
                        return this.pagination.completedTab.current_page
                    }
                } else {
                    return this.pagination.completedTab.current_page
                }
            },
            set(value) {
                if (typeof this.getSearchedShipments !== 'undefined' && this.getSearchedShipments !== null) {
                    if (this.search !== '' && this.getSearchedShipments.tab === 'completed') {
                        this.$store.state.shipment.searchedShipmentsPagination.current_page = value
                    } else {
                        this.$emit('update:paginationData', value)

                    }
                } else {
                    this.$emit('update:paginationData', value)
                }
            }
        },
        getShipmentItemsPerPage() {
            if (typeof this.getSearchedShipments !== 'undefined' && this.getSearchedShipments !== null) {
                if (this.search !== '' && this.getSearchedShipments.tab === 'completed') {
                    return this.getSearchedShipmentPagination.per_page
                } else {
                    return this.pagination.completedTab.per_page
                }
            } else {
                return this.pagination.completedTab.per_page
            }
        },
        getShipmentPagination() {
            if (typeof this.getSearchedShipments !== 'undefined' && this.getSearchedShipments !== null) {
                if (this.search !== '' && this.getSearchedShipments.tab === 'completed') {
                    return this.getSearchedShipmentPagination.total
                } else {
                    return this.pagination.completedTab.total
                }
            } else {
                return this.pagination.completedTab.total
            }
        },
        type: {
            get() {
                return this.currentCalendarType;
            },
            set(v) {
                this.$emit("update:currentCalendarType", v)
            }
        },
        selectedCalendarFilter: {
            get() {
                return this.selectedDefaultFilter;
            },
            set(v) {
                this.$emit("update:selectedDefaultFilter", v)
            }
        }
    },
    watch: {
        currentViewTab(v) {
            if (v === 0 && !this.isMobile) { // shouldn't be applied to mobile
                setTimeout(() => {
                    this.checkTbodyWrapper();
                }, 100);
            }
        }
    },
    mounted() {
        //set current page
        this.$store.dispatch("page/setPage", "shipments");
        if (this.currentViewTab === 0 && !this.isMobile)  // shouldn't be applied to mobile
            this.checkTbodyWrapper();
    },
    methods: {
        ...mapActions([
            "fetchShipments",
        ]),
        ...globalMethods,
        ucFirst(str) {
            let pieces = str.split(" ")
            for ( let i = 0; i < pieces.length; i++ ) {
                let j = pieces[i].charAt(0).toUpperCase()
                pieces[i] = j + pieces[i].substr(1)
            }
            return pieces.join(" ")
        },
        getCurrentPaginationCountClass() {
            if (this.search === '') {
                return typeof this.pagination.completedTab.total !== 'undefined' ? this.pagination.completedTab.total <= 1 : false
            } else {
                return typeof this.getShipmentPagination !== 'undefined' ? this.getShipmentPagination <= 1 : false
            }
        },
        addWheelClickedEventListener(element, callback) {
            var started = false;
            var removed = false;
            var onDocumentMouseup = function() {
                started = false;
            };
            var onElementMousedown = function(e) {
                e.preventDefault();  // prevents auto-scrolling action
                started = (e.button === 1);
            };
            var onElementMouseup = function(e) {
                if (started) {
                    started = false;
                    callback(e);
                }
            };
            var onElementClick = function(e) {
                callback(e);
            }
            document.addEventListener('mouseup', onDocumentMouseup);
            element.addEventListener('mousedown', onElementMousedown);
            element.addEventListener('mouseup', onElementMouseup);
            element.addEventListener('click', onElementClick);

            return function() {
                if (removed) {
                    return;
                }
                removed = true;
                document.removeEventListener('mouseup', onDocumentMouseup);
                element.removeEventListener('mousedown', onElementMousedown);
                element.removeEventListener('mouseup', onElementMouseup);
                element.removeEventListener('click', onElementClick);
            };
        },
        handleClick(value) {
            this.$emit('handleClick', value)
        },
        async changeSort() {
            this.$emit('sort', 2)
        },
        getShipmentStatusClass(item, type) {
            let { tracking_status, status } = item;

            let setValue = (type ==='tracking') ? tracking_status : status;
            let setClass = _.find(shipmentStatusesLists, e => e.value === setValue.toLowerCase());
            setClass = (typeof setClass !== 'undefined') ? setClass.class : '';

            return setClass;
        },
        async handlePageChange(page) {
            this.$emit('handlePageChange', page)
            this.handleScrollToTop()
        },
        async handlePageSearched(page) {
            let data = { 
                page,
                tab: 'completed'
            }

            this.$emit('handlePageSearched', data)
            this.handleScrollToTop()
        },
        handleScrollToTop() {
            var table = this.$refs['my-table'];
            var wrapper = table.$el.querySelector('div.v-data-table__wrapper');
            
            this.$vuetify.goTo(table); // to table
            this.$vuetify.goTo(table, {container: wrapper}); // to header
        },
        checkSuppliersName(suppliers){
            if (Array.isArray(suppliers) && suppliers !== null) {
                return suppliers.filter(item => item !== '').join(', ');
            } else { return ''; }
        },
        checkSuppliersNameMobile(suppliers, count) {
            let str = suppliers;
            if (str.length > count) str = str.substring(0, count) + "...";
            return str;
        },
        convertDateAction(v) {
            if (v !== null && v !== "") {
                return moment.utc(v).format('MMM DD, YYYY');
            } else {
                return "N/A";
            }
        },
        getImgUrl(item, type) {
            if (type === "mode") {
                let shipmentMode = (item !== null && item !== "") ? item.toLowerCase() : "";
                if (shipmentMode !== "") {
                    // shipmentMode = shipmentMode === "air" ? "airplane" : shipmentMode;
                    return require(`@/assets/icons/shipment-table-icons/${shipmentMode}.svg`)
                }
            } else {
                let shipmentTypes = [
                    {
                        text: "LCL",
                        value: "small-container"
                    },
                    {
                        text: "LTL",
                        value: "small-container"
                    },
                    {
                        text: "Air",
                        value: "airplane-shipment"
                    },
                    {
                        text: "FCL",
                        value: "big-container"
                    },
                    {
                        text: "FTL",
                        value: "big-container"
                    }
                ]
                if (item !== null && item !== "") {
                    let findValue = _.find(shipmentTypes, e => ( e.text === item ));
                    if (findValue !== undefined) {
                        return require(`@/assets/icons/shipment-table-icons/${findValue.value}.svg`)
                    } else return ""
                } else {
                    return ""
                }
            }
		},
        // for detecting ellipsis
        detectLineClamp(item, id, elementId) {
            let element1 = null;
            if (elementId === "supplier")
                element1 = document.querySelector(`#td-supplier-content-${id}`);
            else if (elementId === "reference")
                element1 = document.querySelector(`#td-reference-content-${id}`);
            else
                element1 = document.querySelector(`#td-po-content-${id}`);            

            if (item !== null && element1 !== null) {
                let elementValues = this.isEllipsisActive(element1, "line");
                this.isTruncatedText = elementValues;
            }
        },
        isEllipsisActive(element, type) {
            if (type === "line") {
                if (element.clientHeight < element.scrollHeight) return true;                
            } else {
                if (element.clientWidth < element.scrollWidth) {
                    var style = element.currentStyle || window.getComputedStyle(element);
                    return style.textOverflow === 'ellipsis'
                }
            }
            return false;
        },
        checkTbodyWrapper() {
            let dis = this;
            const tbodyWrapper = document.querySelector('.v-data-table__wrapper tbody');
            this.addWheelClickedEventListener(tbodyWrapper, function(e) {
                let row = e.target.closest("tr");
                if (e.which === 2) {
                    if (row !== null) {
                        let link = window.location.origin + `/shipment/${row.id}`;
                        var anchor = document.createElement('a');
                        anchor.href = link;
                        anchor.target="_blank";
                        anchor.click();
                    }
                } else {
                    if (e.which === 1)
                        dis.$router.push(`shipment/${row.id}`).catch(() => {}); // redirect to shipment details
                }
            });
        }
    },
    updated() {}
}
</script>

<style></style>
