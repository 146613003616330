var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inbound-pallet-section"},[_c('v-data-table',{staticClass:"elevation-1 inbound-view-pallets",class:{
            'no-data-table': (typeof _vm.storableData.inbound_storable_units !== 'undefined' && _vm.storableData.inbound_storable_units.length !== null && _vm.storableData.inbound_storable_units.length === 0),
            'no-checkbox': !_vm.removeCheckboxIfAllIsReceived
        },attrs:{"headers":_vm.palletsHeader,"items":_vm.storableData.inbound_storable_units,"item-key":"label","items-per-page":_vm.itemsPerPage,"mobile-breakpoint":"769","hide-default-footer":"","show-select":_vm.removeCheckboxIfAllIsReceived,"fixed-header":"","expanded":_vm.expanded,"single-expand":"","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.storableData.inbound_storable_units.length == 0)?_c('div',{staticClass:"no-data-wrapper"},[_c('div',{staticClass:"no-data-heading"},[_c('div',[_c('h3',[_vm._v(" No Storable Units ")]),_c('p',[_vm._v(" You will be able to add storable units once the product has been received. ")])])])]):_vm._e()]},proxy:true},{key:"item.label",fn:function(ref){
        var item = ref.item;
return [(!_vm.isMobile)?_c('div',{staticClass:"label-info"},[_c('span',[_vm._v(_vm._s(item.label !== null ? item.label : '--'))]),(item.action === 'recieve')?_c('p',{staticClass:"mb-0"},[_vm._v("(Received)")]):_vm._e()]):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"mobile-content-wrapper"},[_c('div',{staticClass:"mobile-content"},[_c('span',[_vm._v(" "+_vm._s(item.label !== null ? item.label : '--')+" "),_c('span',{staticStyle:{"text-transform":"capitalize","font-family":"'Inter-Medium', sans-serif","color":"#6D858F"}},[_vm._v(" - "+_vm._s(item.type !== null ? item.type : '--')+" ")])])]),_c('div',{staticClass:"actions-wrapper"},[(item.status !== 'placed')?_c('button',{staticClass:"btn-status btn-white",on:{"click":function($event){$event.stopPropagation();return _vm.openPlaceStorableDialog(item)}}},[_c('span',{staticClass:"btn-content"},[_c('span',[_vm._v("Placed")])])]):_vm._e(),(item.status == 'placed')?_c('button',{staticClass:"btn-status btn-white placed"},[_c('img',{staticClass:"mr-1",attrs:{"src":require("../../../../assets/icons/checkMark.png"),"width":"12px","height":"12px"}}),_c('span',{staticClass:"btn-content"},[_c('span',[_vm._v("Placed")])])]):_vm._e(),(item.status !== 'placed')?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","content-class":"outbound-lists-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-white",attrs:{"icon":"","disabled":_vm.inboundStatus === 'completed'}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/dots.svg"),"alt":"dots","width":"16px","height":"16px"}})])]}}],null,true)},[_c('v-list',{staticClass:"outbound-lists"},[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.editStorableUnit(item)}}},[_c('v-list-item-title',[_vm._v(" Edit ")])],1)],1)],1):_vm._e()],1)]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"expanded-item-info"},[_c('div',{staticClass:"expanded-header-wrapper"},[_c('div',{staticClass:"expanded-header-content"},[_c('div',{staticClass:"header-title w-80"},[_vm._v("SKU")]),_c('div',{staticClass:"header-title w-10"},[_vm._v("CARTON")]),_c('div',{staticClass:"header-title w-10"},[_vm._v("UNIT")])])]),_c('div',{staticClass:"expanded-body-wrapper"},_vm._l((item.inbound_storable_products),function(v,index){return _c('div',{key:index,staticClass:"expanded-body-content"},[_c('div',{staticClass:"header-data w-80"},[_vm._v(" #"+_vm._s(v.category_sku !== '' && v.category_sku !== null ? v.category_sku : '')+" "+_vm._s((v.inbound_product !== null && v.inbound_product.product_name !== null) ? v.inbound_product.product_name : '')+" ")]),_c('div',{staticClass:"header-data w-10"},[_vm._v(" "+_vm._s(v.carton_count)+" ")]),_c('div',{staticClass:"header-data w-10"},[_vm._v(" "+_vm._s(v.total_unit)+" ")])])}),0)])])]}},{key:"item.type",fn:function(ref){
        var item = ref.item;
return [(!_vm.isMobile)?_c('div',{staticClass:"type-info"},[_c('span',[_vm._v(_vm._s(item.type !== null ? item.type : '--'))])]):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"mobile-content-wrapper"},[_c('div',{staticClass:"mobile-content"},[_c('div',{staticClass:"unit-weight-mobile mb-1"},[_c('span',[_vm._v(_vm._s(_vm.getLocationName(item)))])]),_c('div',{staticClass:"location-info-mobile"},[_c('span',[_vm._v(" "+_vm._s(typeof item.dimension.l !== 'undefined' && item.dimension.l !== '' ? item.dimension.l : 0)+" x ")]),_c('span',[_vm._v(" "+_vm._s(typeof item.dimension.w !== 'undefined' && item.dimension.w !== '' ? item.dimension.w : 0)+" x ")]),_c('span',[_vm._v(" "+_vm._s(typeof item.dimension.h !=='undefined' && item.dimension.h !== '' ? item.dimension.h : 0)+" ")]),_c('span',[_vm._v(_vm._s(item.dimension.uom))])]),_c('div',{staticClass:"unit-weight-mobile"},[_vm._v(" "+_vm._s(item.weight.value)+" "+_vm._s(item.weight.unit)+" ")])])]):_vm._e()]}},{key:"item.spec",fn:function(ref){
        var item = ref.item;
return [(!_vm.isMobile)?_c('div',{staticClass:"spec-info"},[_c('p',[_c('span',[_vm._v(" "+_vm._s(typeof item.dimension.l !== 'undefined' && item.dimension.l !== '' ? item.dimension.l : 0)+" x ")]),_c('span',[_vm._v(" "+_vm._s(typeof item.dimension.w !== 'undefined' && item.dimension.w !== '' ? item.dimension.w : 0)+" x ")]),_c('span',[_vm._v(" "+_vm._s(typeof item.dimension.h !=='undefined' && item.dimension.h !== '' ? item.dimension.h : 0)+" ")]),_c('span',[_vm._v(_vm._s(item.dimension.uom))])]),_c('p',{staticClass:"unit-weight"},[_vm._v(" "+_vm._s(item.weight.value)+" "+_vm._s(item.weight.unit)+" ")])]):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"cartons-separator"},[_c('p',[_vm._v(_vm._s(item.inbound_storable_products !== null && item.inbound_storable_products.length > 0 ? item.inbound_storable_products.length : 0)+" sku")]),_c('span',{staticClass:"separator"}),_c('p',[_vm._v(_vm._s(_vm.getTotalCartonsAndUnits(item, 'carton'))+" cartons")]),_c('span',{staticClass:"separator"}),_c('p',[_vm._v(" "+_vm._s(_vm.getTotalCartonsAndUnits(item, 'unit'))+" units ")])]):_vm._e()]}},{key:"item.location",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"location-info"},[_c('span',[_vm._v(_vm._s(_vm.getLocationName(item)))])])]}},{key:"item.no_of_sku",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"sku-info"},[_c('p',[_vm._v(_vm._s(item.inbound_storable_products !== null && item.inbound_storable_products.length > 0 ? item.inbound_storable_products.length : 0))])])]}},{key:"item.carton_count",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"carton-info"},[_c('span',[_vm._v(_vm._s(_vm.getTotalCartonsAndUnits(item, 'carton')))])])]}},{key:"item.units",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"units-info"},[_c('span',[_vm._v(_vm._s(_vm.getTotalCartonsAndUnits(item, 'unit')))])])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(!_vm.isWarehouseConnected)?_c('div',{staticClass:"actions-wrapper mr-2"},[(item.status !== 'placed')?_c('button',{staticClass:"btn-status btn-white not-placed",attrs:{"disabled":_vm.inboundStatus === 'cancelled'},on:{"click":function($event){$event.stopPropagation();return _vm.openPlaceStorableDialog(item)}}},[_c('span',{staticClass:"btn-content"},[_c('span',[_vm._v("Placed")])])]):_vm._e(),(item.status == 'placed')?_c('button',{staticClass:"btn-status btn-white placed",attrs:{"disabled":_vm.inboundStatus === 'cancelled'}},[_c('img',{staticClass:"mr-1",attrs:{"src":require("../../../../assets/icons/checkMark.png"),"width":"12px","height":"12px"}}),_c('span',{staticClass:"btn-content"},[_c('span',[_vm._v("Placed")])])]):_vm._e(),(item.status !== 'placed')?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","content-class":"outbound-lists-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-white pallet-menu",attrs:{"icon":"","disabled":_vm.inboundStatus === 'completed' || _vm.inboundStatus === 'cancelled'}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/dots.svg"),"alt":"dots","width":"16px","height":"16px"}})])]}}],null,true)},[_c('v-list',{staticClass:"outbound-lists"},[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.editStorableUnit(item)}}},[_c('v-list-item-title',[_vm._v(" Edit ")])],1)],1)],1):_vm._e()],1):_vm._e()]}}],null,true),model:{value:(_vm.storableSelected),callback:function ($$v) {_vm.storableSelected=$$v},expression:"storableSelected"}}),_c('PlaceStorableUnitDialog',{attrs:{"dialog":_vm.dialogPlaceStorable,"placeStorableUnitItems":_vm.placeStorableUnitItems,"isMultiple":false,"linkData":_vm.linkData,"inboundStatus":_vm.inboundStatus,"locationsLists":_vm.locationsLists,"fetchLocationsListsLoading":_vm.fetchLocationsListsLoading},on:{"update:dialog":function($event){_vm.dialogPlaceStorable=$event},"update:placeStorableUnitItems":function($event){_vm.placeStorableUnitItems=$event},"update:place-storable-unit-items":function($event){_vm.placeStorableUnitItems=$event},"close":_vm.closePlaceStorableDialog,"clickFetchLocations":_vm.clickFetchLocations}}),_c('NewStorableUnitDialog',{attrs:{"dialog":_vm.dialogNewStorableUnit,"editedItem":_vm.storableItemsData,"productsData":_vm.inboundProductsData,"linkData":_vm.linkData,"index":_vm.index},on:{"update:dialog":function($event){_vm.dialogNewStorableUnit=$event},"update:editedItem":function($event){_vm.storableItemsData=$event},"update:edited-item":function($event){_vm.storableItemsData=$event},"close":_vm.closeStorableUnit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }