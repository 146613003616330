export let shipmentStatusesLists = [
    {
        class: 'no-status gray-status',
        value: 'no status'
    },
    {
        class: 'draft gray-status',
        value: 'draft'
    },
    {
        class: 'pending-quote gray-status',
        value: 'pending quote'
    },
    {
        class: 'vessel-loaded gray-status',
        value: 'vessel loaded'
    },
    {
        class: 'vessel-loaded-partial gray-status',
        value: 'vessel loaded partial'
    },
    {
        class: 'pending-approval orange-status',
        value: 'pending approval'
    },
    {
        class: 'booking-confirmed green-status',
        value: 'booking confirmed'
    },
    {
        class: 'tracking-started green-status',
        value: 'tracking started'
    },
    {
        class: 'pending-departure gray-status',
        value: 'pending departure'
    },
    {
        class: 'in-transit blue-status',
        value: 'in transit'
    },
    {
        class: 'in-transit-hold blue-status',
        value: 'in transit - hold'
    },
    {
        class: 'in-transit-released blue-status',
        value: 'in transit - released'
    },
    {
        class: 'transshipment blue-status',
        value: 'trans-shipment in progress'
    },
    {
        class: 'transshipment blue-status',
        value: 'trans-shipment - hold'
    },
    {
        class: 'transshipment blue-status',
        value: 'trans-shipment - released'
    },
    {
        class: 'on-rail blue-status',
        value: 'on rail'
    },
    {
        class: 'on-rail-hold blue-status',
        value: 'on rail - hold'
    },
    {
        class: 'on-rail-released blue-status',
        value: 'on rail - released'
    },
    {
        class: 'vessel-arrived blue-status',
        value: 'vessel arrived'
    },
    {
        class: 'vessel-arrived-hold red-status',
        value: 'vessel arrived - hold'
    },
    {
        class: 'vessel-arrived-released green-status',
        value: 'vessel arrived - released'
    },
    {
        class: 'discharged green-status',
        value: 'discharged'
    },
    {
        class: 'discharged-released green-status',
        value: 'discharged - released'
    },
    {
        class: 'partially-discharged orange-status',
        value: 'partially discharged'
    },
    {
        class: 'partially-discharged orange-status',
        value: 'discharged partial'
    },
    {
        class: 'partially-discharged-rel orange-status',
        value: 'partially discharged - released'
    },
    {
        class: 'partially-discharged-rel orange-status',
        value: 'discharged partial - released'
    },
    {
        class: 'discharged-hold red-status',
        value: 'discharged - hold'
    },
    {
        class: 'partially-discharged-hold red-status',
        value: 'partially discharged - hold'
    },
    {
        class: 'partially-discharged-hold red-status',
        value: 'discharged partial - hold'
    },
    {
        class: 'past-last-free-day darkred-status',
        value: 'past last free day'
    },
    {
        class: 'pickup green-status',
        value: 'pickup scheduled'
    },
    {
        class: 'pickup-partially orange-status',
        value: 'pickup scheduled partially'
    },
    {
        class: 'pickup-partially orange-status',
        value: 'pickup scheduled partial'
    },
    {
        class: 'full-out green-status',
        value: 'full out'
    },
    {
        class: 'full-out-partially orange-status',
        value: 'full out partially'
    },
    {
        class: 'full-out-partially orange-status',
        value: 'full out partial'
    },
    {
        class: 'empty-in green-status',
        value: 'empty in'
    },
    {
        class: 'empty-in-partially blue-status',
        value: 'empty in partially'
    },
    {
        class: 'empty-in-partially blue-status',
        value: 'empty in partial'
    },
    {
        class: 'completed green-status',
        value: 'completed'
    },
    {
        class: 'expired gray-status',
        value: 'expired'
    },
    {
        class: 'cancelled gray-status',
        value: 'cancelled'
    },
    {
        class: 'manual-tracking blue-status',
        value: 'manual tracking'
    },
    {
        class: 'manually-tracked blue-status',
        value: 'manually tracked'
    },
    {
        class: 'auto-tracking green-status',
        value: 'auto tracking'
    },
    {
        class: 'auto-tracked green-status',
        value: 'auto tracked'
    },
    {
        class: 'pending-tracking gray-status',
        value: 'pending tracking'
    },
    {
        class: 'not-tracking gray-status',
        value: 'not tracking'
    },
    {
        class: 'snoozed orange-status',
        value: 'snoozed'
    },
]