var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isMobile)?_c('v-menu',{staticStyle:{"min-width":"330px !important"},attrs:{"close-on-content-click":false,"nudge-width":200,"content-class":"filter-content-menu from-inbound-outbound-inventory product-lists-items warehouse_customers","offset-y":"","right":"","bottom":"","min-width":"330px !important;"},on:{"click":function($event){_vm.active = true}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"btn-white filters",on:{"click":_vm.showOrHideOption}},'v-btn',attrs,false),[_c('img',{attrs:{"src":require("@/assets/icons/filters.svg"),"alt":"Filters","width":"16px","height":"16px"}}),(_vm.selectedWhCustomersLists.length == 1)?_c('span',{staticClass:"ml-1",staticStyle:{"color":"#0084BD","font-family":"'Inter-Medium', sans-serif"}},[_vm._v(" "+_vm._s(_vm.selectedWhCustomersLists.length)+" ")]):(_vm.selectedWhCustomersLists.length > 1)?_c('span',{staticClass:"ml-1",staticStyle:{"color":"#0084BD","font-family":"'Inter-Medium', sans-serif"}},[_vm._v(" "+_vm._s(_vm.selectedWhCustomersLists.length - 1)+"+ ")]):_vm._e()])]}}],null,false,2896432439),model:{value:(_vm.menuModel),callback:function ($$v) {_vm.menuModel=$$v},expression:"menuModel"}},[_c('v-card',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({handler: _vm.clickOutsideFilter,include: _vm.includeFunction}),expression:"{handler: clickOutsideFilter,include: includeFunction}"}],staticClass:"filters-card-wrapper"},[_c('div',{staticClass:"filter-component-title"},[_c('h3',[_vm._v("Filters")]),_c('button',{staticClass:"btn-white cancel-close-dropdown",on:{"click":_vm.cancelSelectingWarehouseCustomers}},[_c('v-icon',[_vm._v("mdi-close")])],1)]),_c('div',{staticClass:"filter-component-body"},[_c('div',{staticClass:"filter-customer-wrapper"},[_c('p',{staticClass:"filter-body-title"},[_vm._v("Filter with Customer")]),_c('v-autocomplete',{attrs:{"items":_vm.warehouseCustomerItems,"outlined":"","item-text":"name","item-value":_vm.getItemValue,"return-object":"","chips":"","deletable-chips":"","multiple":"","placeholder":"Select Customer","menu-props":{ contentClass: 'filter-warehouse', bottom: true, offsetY: true },"hide-details":"auto"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){return _vm.removeCustomerLists(item, 'single')}}},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(item.name))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({on:{"click":function($event){$event.stopPropagation();return _vm.setActiveTrue()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('p',{staticClass:"name mb-1 font-medium"},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"address mb-1"},[_vm._v(_vm._s(item.address))])])],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,false,3043939142),model:{value:(_vm.selectedWhCustomersLists),callback:function ($$v) {_vm.selectedWhCustomersLists=$$v},expression:"selectedWhCustomersLists"}})],1)]),(_vm.warehouseCustomerItems.length > 0)?_c('v-card-actions',{staticClass:"filters-card-actions d-flex align-center append-filter-button-inbound"},[_c('v-btn',{staticClass:"btn-apply btn-blue",attrs:{"disabled":_vm.selectedWhCustomersLists.length === 0},on:{"click":_vm.filterAllWarehouseCustomers}},[_vm._v(" Apply Filters ")]),_c('v-btn',{staticClass:"btn-cancel btn-white",on:{"click":_vm.clearAll}},[_vm._v(" Clear All ")])],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }