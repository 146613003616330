<!-- @format -->

<template>
	<div :class="className">
		<v-card class="booking-shipment-card-wrapper">
			<v-card-title class="booking-shipment-card-title-wrapper" :class="reference == 'booking-request-form' ? 'booking-form-title' : ''">
				<div class="title-section">
					<span class="headline">{{ cardTitle }}</span>
					<div class="d-flex gap-3 save-as-template-details pr-1">
						<v-btn
							class="btn-white reset-btn"
							v-if="ifTemplateExit"
							@click="resetForm"
						>
							Reset Form
						</v-btn>

						<v-btn
							class="btn-blue btn-white save-as-default"
							text
							v-if="!isBookingShipmentDialog && !ifTemplateExit"
							@click="saveUpdateDefaultTemplate()"
						>
							<InfoTooltip>
								<template v-slot:tooltip_icon>
									Save as Default Template
								</template>
								<template v-slot:tooltip_info>
									This will be saved as default template for any new booking
									request.
								</template>
							</InfoTooltip>
						</v-btn>
						<v-btn
							class="btn-white"
							v-if="ifTemplateExit"
							@click="saveUpdateDefaultTemplate()"
						>
							Update Template
						</v-btn>
					</div>
					<div
						class="select-booking-template d-flex align-center"
						v-if="
							reference == 'create-booking-shipment' ||
								reference == 'template-booking-shipment'
						"
					>
						<div class="save-as-template" v-if="bookingTemplateId !== null">
							<a @click="updateBookingTemplate(bookingTemplateId)">
								<span>Update Template</span>
							</a>
						</div>
						<div
							class="select-template"
							v-if="bookingTemplates && bookingTemplates.length > 0"
						>
							<span class="pr-2">Template:</span>
							<v-autocomplete
								:items="bookingTemplates"
								placeholder="Choose Template"
								class="chosoe-template"
								item-text="name"
								:height="40"
								item-value="id"
								:menu-props="{
									contentClass: 'booking-template-wrapper',
									bottom: true,
									offsetY: true,
								}"
								v-model="bookingTemplateId"
							>
								<v-list slot="prepend-item" class="template-details">
									<span> Use a template </span>
								</v-list>
								<template v-slot:item="{ item }">
									<div
										class="template-details-info d-flex"
										@click="checkTemplate(item)"
									>
										<div class="infos">
											<h4 class="template-name">{{ item.name }}</h4>
											<p class="template-desc mb-0">
												{{
													item.template_description
														? item.template_description
														: "N/A"
												}}
											</p>
										</div>
										<div class="icons" v-if="item.id === bookingTemplateId">
											<img
												src="@/assets/icons/checkMark-green.svg"
												width="12px"
												height="12px"
											/>
											<p
												v-tooltip="{
													text: 'Lorem ipsum dolor sit amet consectetur.',
													position: 'right',
												}"
											>
												<GenericIcon
													:color="'#4A4A4A'"
													:iconName="'info-icon'"
												/>
											</p>
										</div>
									</div>
								</template>
							</v-autocomplete>
						</div>
						<div class="save-as-template" v-if="bookingTemplateId == null">
							<a @click="saveNewBookingTemplate()">
								<span>Save as new Template</span>
							</a>
						</div>
					</div>
				</div>
				<button
					icon
					dark
					class="btn-close"
					@click="close"
					v-if="isBookingShipmentDialog"
				>
					<v-icon>mdi-close</v-icon>
				</button>
			</v-card-title>
			<v-card-text
				class="booking-shipment-card-text-wrapper"
				:class="reference == 'booking-request-form' ? 'booking-form-content-page' : ''"
			>
				<div v-if="isMobile">
					<v-tabs class="shipment-tabs" v-model="currentTab">
						<v-tab v-for="(n, i) in sidebarItems" :key="i">
							{{ n.label }}
						</v-tab>
					</v-tabs>
				</div>
				<div id="card-text-wrapper" class="d-flex booking-shipment-card-text">
					<ShipmentSideBars
						:sidebarItems="sidebarItems"
						@selectPage="selectPage"
						v-if="!isMobile"
					/>
					<div
						class="preloader d-flex w-full justify-center align-center pt-4 pb-4 shipment-loder"
						:class="
							reference == 'booking-request-form' ? 'shipment-form-loder' : ''
						"
						v-if="loaded"
					>
						<v-progress-circular :size="40" color="#1A6D9E" indeterminate>
						</v-progress-circular>
					</div>
					<div
						class="d-flex flex-column create-edit-booking-shipment-main-content second-column"
						id="second-column-id"
						:class="
							reference == 'booking-request-form' ? 'booking-request-form' : ''
						"
						v-if="!loaded"
					>
						<div
							class="content-title general-title"
							v-if="!isMobile"
							id="generalInformation"
						>
							<h3>General Information</h3>
							<div class="info-message">
								<img src="@/assets/icons/info.svg" width="16px" height="16px" />
								<span
									>Required fields are marked with a red asterisk
									<span class="red--text">*</span> other fields are
									optional</span
								>
							</div>
						</div>

						<v-form
							ref="shipmentForm"
							class="shipment-form-details"
							action="#"
							@submit.prevent=""
						>
							<GeneralInformations
								:key="GeneralInformations_2"
								:filteredShipperOptions="filteredShipperOptions"
								:reference="reference"
								:filteredConsigneeOptions="filteredConsigneeOptions"
								:userDetails="userDetails"
								:isMobile="isMobile"
								:bookingTemplateId="bookingTemplateId"
								v-if="!isMobile || (currentTab == 0 && isMobile)"
								@showConfirmRoleModal="showConfirmRoleModal"
							/>
							<Orders
								:key="componentKey"
								:filteredShipperOptions="filteredShipperOptions"
								:filteredConsigneeOptions="filteredConsigneeOptions"
								:isMobile="isMobile"
								:reference="reference"
								:ordersData="ordersData"
								:userDetails="userDetails"
								v-if="!isMobile || (currentTab == 1 && isMobile)"
							/>
							<CargoDetails
								:filteredShipperOptions="filteredShipperOptions"
								:filteredConsigneeOptions="filteredConsigneeOptions"
								v-if="!isMobile || (currentTab == 1 && isMobile)"
							/>
							<div
								class="containers-other-details-wrapper"
								id="containerSection"
								v-if="!isMobile || (currentTab == 2 && isMobile)"
							>
								<h2 class="mb-4">Containers & Others</h2>
								<div class="containers-other-details booking-bg-white">
									<div class="container-details">
										<ContainersTable
											:headers="headersNewContainers"
											:items.sync="shipmentItem.containerItems"
											headerBackground="#F9FAFB"
										/>
									</div>
									<div class="other-details">
										<div class="input-field-wrapper">
											<span class="input-field-label"
												>Additional instructions and notes for booking team
											</span>
											<v-textarea
												class="text-fields"
												outlined
												v-model="shipmentItem.additional_instructions_and_notes"
												placeholder="Enter instructions and notes for booking team"
												hide-details="auto"
												autocomplete="off"
											/>
										</div>
									</div>
								</div>
							</div>
							<AdditionalServices
								v-if="!isMobile || (currentTab == 2 && isMobile)"
							/>
						</v-form>
					</div>
				</div>
			</v-card-text>
			<v-card-actions class="booking-shipment-card-action-wrapper" :class="reference == 'booking-request-form' ? 'booking-form-action' : ''">
				<div class="d-flex footer">
					<v-btn
						class="save-btn btn-blue mr-2"
						text
						@click="submitBookingRequest()"
						:disabled="getCreateNewBookingShipmentLoading"
					>
						{{
							shipmentItem.request_consolidations
								? "Submit Consolidation Request"
								: "Submit Request"
						}}
					</v-btn>
					<!-- <v-btn
						class="save-btn btn-white mr-2"
						text
						@click="saveAsDraft()"
						v-if="!consolidateBookingShipment && isBookingShipmentDialog"
						:disabled="getCreateNewBookingShipmentLoading"
					>
						Save as Draft
					</v-btn> -->
					<v-btn
						class="btn-white btn-cancel"
						text
						@click="close()"
						:disabled="getCreateNewBookingShipmentLoading"
						v-if="isBookingShipmentDialog"
					>
						Cancel
					</v-btn>
				</div>
			</v-card-actions>
		</v-card>
	</div>
</template>
<script>
import SidebarItems from "../../Dialog/FormShipmentDialog/Datas/sidebarItems";
import ShipmentSideBars from "./SubComponents/ShipmentSideBars.vue";
import GeneralInformations from "./SubComponents/GeneralInformations.vue";
import CargoDetails from "./SubComponents/CargoDetails.vue";
import shipmentBookingItem from "./js/ShipmentBooking";
import jQuery from "jquery";
import _ from "lodash";
import { mapGetters } from "vuex";
import ShipmentTableHeader from "./js/ShipmentTableHeader";
import ContainersTable from "../../Dialog/FormShipmentDialog/Tables/ContainersTable.vue";
import AdditionalServices from "./SubComponents/AdditionalServices";
import Orders from "./SubComponents/Orders.vue";
import globalMethods from "../../../utils/globalMethods";
import GenericIcon from "../../Icons/GenericIcon.vue";
import InfoTooltip from "../../Tooltip/InfoTooltip.vue";

export default {
	name: "BookingShipmentContainer",
	props: [
		"className",
		"isMobile",
		"isBookingShipmentDialog",
		"reference",
		"shipmentData",
		"userDetails",
		"useTemplateId",
		"keepEditingTemplateId",
	],
	components: {
		ShipmentSideBars,
		GeneralInformations,
		CargoDetails,
		ContainersTable,
		AdditionalServices,
		Orders,
		GenericIcon,
		InfoTooltip,
	},
	data: () => ({
		currentTab: "General Information",
		loaded: false,
		sidebarItems: SidebarItems.shipmentBooking.data,
		headersNewContainers: ShipmentTableHeader.containerDetailsHeader.data,
		scrollAnimating: false,
		currentSection: "general",
		shipmentItem: shipmentBookingItem,
		bookingTemplateId: null,
		ordersData: [],
		componentKey: 0,
		ifTemplateExit: false,
	}),
	async created() {
		this.setupScroll();

		this.loaded = true;
		// setTimeout(async () => {
		//fetch consignee options
		await this.$store
			.dispatch("booking/getConsigneeOptions", {
				page: 1,
			})
			.then((res) => console.log(res));

		//fetch shipper options
		await this.$store.dispatch("booking/getShipperOptions", {
			page: 1,
		});

		await this.$store.dispatch("settings/fetchDeliveryLocationsApi", {
			page: 1,
		});

		//fetch container sizes
		await this.$store.dispatch("booking/fetchContainerSizes").then(() => {
			if (
				this.getContainerSizes.length > 0 &&
				this.shipmentItem.containerItems.length <= 0
			) {
				//iterate through container sizes
				this.getContainerSizes.map((item) => {
					item.id !== 5
						? this.shipmentItem.containerItems.push({
								size: item.name,
								size_id: item.id,
								capacity: item.capacity ? item.capacity + " CBM" : "N/A",
								checked: false,
								how_many: 0,
								plus: "+",
								minus: "-",
								ukey: 0,
						  })
						: "";
				});
			}
			!this.consolidateBookingShipment ? (this.loaded = false) : "";
		});
		if (this.consolidateBookingShipment) {
			this.preparedBookingDialogData(
				this.getConsolidatedShipment?.payload,
				"consolidate"
			);
		}
		if (this.bookingRequestShipment) {
			this.preparedBookingDialogData(
				this.getBookingOrdersData,
				"reuest_booking"
			);
		}
		// }, 5000);

		if (this.useTemplateId) {
			let templateData = this.bookingTemplates.find(
				(item) => item.id == this.useTemplateId
			);
			this.bookingTemplateId = this.useTemplateId;
			this.preparedBookingDialogData(JSON.parse(templateData.data), "template");
		}

		let BookingLinkData = this.$store.getters["booking/getbookingLinkData"];

		if (BookingLinkData) {
			const { template_data, booking_template_data } = BookingLinkData;
			let BookingtemplateData =
				booking_template_data !== null && booking_template_data !== "null"
					? JSON.parse(booking_template_data)
					: JSON.parse(template_data);
			if (BookingtemplateData) {
				this.ifTemplateExit = true;
				this.preparedBookingDialogData(BookingtemplateData, "template");
			}
		}

		if (this.keepEditingTemplateId) {
			this.bookingTemplateId = this.keepEditingTemplateId;
		}
	},
	watch: {
		bookingTemplateId(newValue, oldValue) {
			if (newValue !== oldValue && oldValue !== null) {
				let templateData = this.bookingTemplates.find(
					(item) => item.id == newValue
				);
				if (templateData !== undefined) {
					templateData.oldTemplateId = oldValue;
					this.$emit("replaceTemplateDialog", templateData);
				}
			}
		},
	},
	computed: {
		...mapGetters({
			getContainerSizes: "booking/getContainerSizes",
			getCreateNewBookingShipmentLoading:
				"booking/getCreateNewBookingShipmentLoading",
			getConsolidatedShipment: "booking/getConsolidatedShipment",
			getBookingOrdersData: "booking/getBookingOrdersData",
		}),
		cardTitle() {
			return this.reference == "edit-booking-shipment"
				? "Edit Booking Request"
				: "Create Booking Request";
		},
		filteredShipperOptions() {
			//shippers array container
			let newShippers = [];
			//modify it if the selected role is shipper, use the connected shipper options
			if (this.reference == "booking-request-form") {
				newShippers = this.$store.getters["booking/getShippers"];
			} else {
				if (this.shipmentItem.userRole === "shipper") {
					newShippers = [this.userDetails.default_customer];
				} else {
					newShippers = this.$store.getters["booking/getShippers"];
				}
			}
			//leaves only company name and address
			if (newShippers.length > 0) {
				newShippers =
					newShippers &&
					newShippers.map(({ company_name, address, emails, id }) => {
						return {
							name: company_name,
							id,
							address,
							emails,
						};
					});
			}
			return newShippers;
		},
		filteredConsigneeOptions() {
			//consignees array container
			let newConsignees = [];

			//modify it if the selected role is consignee, use the connected consignee options
			if (
				this.shipmentItem.userRole === "consignee" ||
				this.reference == "booking-request-form"
			) {
				newConsignees = [this.userDetails.default_customer];
			} else {
				newConsignees = this.$store.getters["booking/getConsignees"];
			}

			//leaves only company name and address
			if (newConsignees.length > 0) {
				newConsignees =
					newConsignees &&
					newConsignees.map(({ company_name, address, id, emails, phone }) => {
						return {
							name: company_name,
							id,
							address,
							emails,
							phone,
						};
					});
			}

			return newConsignees;
		},
		consolidateBookingShipment() {
			return this.reference === "consolidte-booking-shipment";
		},
		bookingRequestShipment() {
			return this.reference === "request-booking-shipment";
		},
		bookingTemplates() {
			let templates = this.$store.getters[
				"bookingTemplate/getBookingTemplatesList"
			];
			return templates.map(
				({ template_name, template_description, id, ...rest }) => {
					return { id, name: template_name, template_description, ...rest };
				}
			);
		},
	},
	methods: {
		...globalMethods,
		getAutoOrders(orderItems, selected_order_user_id) {
			if (orderItems.length > 0) {
				orderItems.map(async (item) => {
					let { products, purchase_order_id, ...others } = item;
					let payload = {
						purchase_order_id: purchase_order_id,
						consolidate: true,
						shipment_ids: this.shipmentItem.shipment_ids,
					};
					let product_options = await this.$store.dispatch(
						"po/fetchPurchaseOrderProducts",
						payload
					);

					const updatedProducts = products.map(
						({
							product_id,
							carton,
							units,
							volume,
							classification_code,
							product_classification_description,
							description,
							...rest
						}) => {
							let volumes;
							if (volume !== null) {
								volumes = this.toFixed(volume);
								volumes =
									this.countShipmentsDecimals(volumes) > 5
										? volumes.toFixed(5)
										: volumes;
								volumes = volumes == 0 ? 0.00001 : volumes;
							} else {
								volumes = 0.00001;
							}

							return {
								product_id: product_id,
								unit: units,
								carton: carton,
								volume: volumes,
								classification_code: classification_code,
								product_classification_description: product_classification_description,
								description: description,
								isEditingField: false,
								quickAddProduct: false,
								...rest,
							};
						}
					);

					updatedProducts.push({
						addSpecial: true,
						product_id: "",
						amount: 0,
						unit_price: 0,
						weight: 0,
						volume: 0,
						carton: 0,
						cargo_ready_date: "",
						description: "",
						classification_code: "",
						product_classification_description: "",
					});

					let options = this.$store.getters["po/getPurchaseOrderOptions"];

					options = _.filter(
						options,
						(e) => e.status === "pending" || e.status === "partial_shipped"
					);
					this.ordersData.push({
						products: updatedProducts,
						purchase_order_id: purchase_order_id,
						purchase_order_options: options,
						product_options: product_options.data,
						id: purchase_order_id,
						layout: "default",
						selectAll: false,
						quickAddOrder: false,
						selected_order_user_id: selected_order_user_id,
						...others,
					});
					this.componentKey += 1;
					this.shipmentItem.orderItems = this.ordersData;
				});
			}
		},
		getManualOrders(orderItems, selected_order_user_id) {
			if (orderItems.length > 0) {
				orderItems.map(async (item) => {
					let {
						purchase_order_number,
						cargo_ready_date,
						total_cartons,
						total_volume,
						total_weight,
						...others
					} = item;
					this.ordersData.push({
						selected_order_user_id: selected_order_user_id,
						order_number: purchase_order_number,
						cargo_ready_date: cargo_ready_date,
						total_cartons: total_cartons,
						total_volumes: total_volume,
						total_weights: total_weight,
						layout: "manual",
						...others,
					});
					this.componentKey += 1;
					this.shipmentItem.orderItems = this.ordersData;
				});
			}
		},
		fetchPurchaseOrderOptions(qry, type, entity_id, other_id) {
			this.loaded = true;
			return new Promise((resolve) => {
				this.$store
					.dispatch("po/fetchPurchaseOrderOptions", {
						qry,
						type,
						entity_id,
						other_id,
					})
					.then((r) => {
						resolve(r);
						this.loaded = false;
					});
			});
		},
		selectPage(item) {
			let findIndex = _.findIndex(this.sidebarItems, { icon: item.icon });
			this.sidebarItems.map((sidebarItem, key) => {
				this.sidebarItems[key].selected = false;
			});
			this.sidebarItems[findIndex].selected = true;
			if (!this.isMobile && typeof item.scrolling == "undefined") {
				this.scrollAnimating = true;
				if (item.icon === "general") {
					const element = document.getElementById("generalInformation");
					element.scrollIntoView({ behavior: "smooth" });
				} else if (item.icon === "po-icon") {
					const element = document.getElementById("OrderSection");
					element.scrollIntoView({ behavior: "smooth" });
				} else if (item.icon === "container-icon") {
					const element = document.getElementById("containerSection");
					element.scrollIntoView({ behavior: "smooth" });
				}
				setTimeout(() => {
					this.scrollAnimating = false;
				}, 500);
			}
		},
		setupScroll() {
			let t = setInterval(() => {
				let d = document.getElementById("second-column-id");
				if (d !== null) {
					d.addEventListener("scroll", () => {
						//get scroll top

						if (!this.scrollAnimating) {
							let x = jQuery("#second-column-id").scrollTop();
							//reuse the select page method
							if (x >= 0 && x < 560) {
								//general section
								if (this.currentSection != "general") {
									this.currentSection = "general";
									this.selectPage({
										icon: "general",
										scrolling: true,
									});
								}
							} else if (x >= 560 && x < 890) {
								//orders section
								if (this.currentSection != "po-icon") {
									this.currentSection = "po-icon";
									this.selectPage({
										icon: "po-icon",
										scrolling: true,
									});
								}
							} else {
								//containers section
								if (this.currentSection != "container-icon") {
									this.currentSection = "container-icon";
									this.selectPage({
										icon: "container-icon",
										scrolling: true,
									});
								}
							}
						}
					});
					clearInterval(t);
				}
			}, 100);
		},
		checkShipperConsigneeEmails(fromSave) {
			const {
				userRole,
				selectedConsigneeIds,
				selectedShipperIds,
			} = this.shipmentItem;
			if (userRole == "shipper") {
				let selectedConsigneeList = this.filteredConsigneeOptions.filter(
					(consignee) => selectedConsigneeIds.includes(consignee.id)
				);
				selectedConsigneeList.map((item) => {
					if (!item.emails || item.emails.length == 0) {
						this.$emit("missingEmailRequest", item, userRole);
					} else {
						if (fromSave !== "draft") {
							this.$emit("submittedRequest", true);
						} else {
							this.$emit("submitedDraftRequest");
						}
					}
				});
			} else {
				let selectedShipperList = this.filteredShipperOptions.filter(
					(shipper) => selectedShipperIds.includes(shipper.id)
				);
				selectedShipperList.map((item) => {
					if (!item.emails || item.emails.length == 0) {
						this.$emit("missingEmailRequest", item, userRole);
					} else {
						if (fromSave !== "draft") {
							this.$emit("submittedRequest", true);
						} else {
							this.$emit("submitedDraftRequest");
						}
					}
				});
			}
		},
		submitBookingRequest() {
			if (this.$refs.shipmentForm.validate()) {
				this.checkShipperConsigneeEmails("submit");
			} else {
				this.notificationErrorMessage();
				this.$emit("submittedRequest", false);

				this.selectPage({
					icon: "general",
					scrolling: true,
				});
			}
		},
		saveAsDraft() {
			if (this.$refs.shipmentForm.validate()) {
				this.checkShipperConsigneeEmails("draft");
			} else {
				this.notificationErrorMessage();
				this.selectPage({
					icon: "general",
					scrolling: true,
				});
			}
		},
		notificationErrorMessage() {
			const {
				userRole,
				shippers,
				consignees,
				type,
				mode,
				location_from,
				location_to,
				orderItems,
				cargoDetailsData,
			} = this.shipmentItem;
			if (userRole == 0 || userRole == null) {
				this.notificationError("Please Select Role");
			} else if (this.checkFields("consignee")) {
				shippers.map((item) => {
					if (item.shipper == 0) {
						this.notificationError("Please Select shipper");
					} else if (
						item.shipper_details_info == "" ||
						item.shipper_details_info == null
					) {
						this.notificationError("Please Enter shipper details");
					} else if (
						item.warehouse_pick_up_location == "" ||
						item.warehouse_pick_up_location == null
					) {
						this.notificationError("Please Enter Pickup Location");
					} else if (
						item.contact_person_name == "" ||
						item.contact_person_name == null
					) {
						this.notificationError("Please Enter Contact Person Name");
					} else if (
						item.contact_person_mobile_num == "" ||
						item.contact_person_mobile_num == null
					) {
						this.notificationError("Please Enter Contact Person Number");
					} else if (
						item.contact_person_email == "" ||
						item.contact_person_email == null
					) {
						this.notificationError("Please Enter Contact Person Email");
					}
				});
			} else if (this.checkFields("shipper")) {
				consignees.map((item) => {
					if (item.consignee == 0) {
						this.notificationError("Please Select consignee");
					} else if (
						item.consignee_details_info == "" ||
						item.consignee_details_info == null
					) {
						this.notificationError("Please Enter consignee details");
					}
				});
			} else if ((type == "" && mode !== "Air") || type == null) {
				this.notificationError("Please Select Type");
			} else if (mode == "" || mode == null) {
				this.notificationError("Please Select Mode");
			} else if (location_from == 0 || location_from == null) {
				this.notificationError("Please Select Location from");
			} else if (location_to == 0 || location_to == null) {
				this.notificationError("Please Select Location to");
			} else if (this.checkFields("orders")) {
				orderItems.map((item) => {
					if (
						item.selected_order_user_id == null ||
						item.selected_order_user_id == ""
					) {
						this.notificationError(
							`Please Select ${
								userRole == "consignee" ? "Supplier" : "Buyer"
							}   `
						);
					} else {
						if (item.layout !== "manual") {
							if (item.quickAddOrder) {
								if (item.order_number == "" || item.order_number == null) {
									this.notificationError("Please Enter order number");
								}
							} else {
								if (
									item.purchase_order_id == "" ||
									item.purchase_order_id == null
								) {
									this.notificationError("Please Select order");
								} else {
									item?.products.map((product) => {
										if (product.quickAddProduct) {
											if (
												product.product_number == "" ||
												product.product_number == null
											) {
												this.notificationError("Please Enter Product");
											} else if (
												product.carton == null ||
												product.carton == 0
											) {
												this.notificationError("Please Enter Carton");
											}
										} else {
											if (
												!product?.addSpecial &&
												(product.product_id == "" || product.product_id == null)
											) {
												this.notificationError("Please Select Product");
											}
										}
									});
								}
							}
						} else {
							if (item.order_number == "" || item.order_number == null) {
								this.notificationError("Please Enter order number");
							} else if (item.total_cartons == 0) {
								this.notificationError("Please Enter Total Carton");
							} else if (item.total_volumes == 0) {
								this.notificationError("Please Enter Total Volume");
							} else if (item.total_weights == 0) {
								this.notificationError("Please Enter Total Weight");
							}
						}
					}
				});
			} else if (this.checkFields("cargo")) {
				cargoDetailsData.map((item) => {
					if (item.total_volume == 0) {
						this.notificationError("Please Enter Total Volume");
					} else if (item.total_weight == 0) {
						this.notificationError("Please Enter Total Weight");
					} else if (!item.incoterm) {
						this.notificationError("Please Select an Incoterm");
					}
				});
			}
		},
		close() {
			this.ordersData = [];
			this.bookingTemplateId = null;
			this.$emit("close");
		},
		checkFields(field) {
			const {
				shippers,
				consignees,
				orderItems,
				cargoDetailsData,
			} = this.shipmentItem;

			if (field === "consignee") {
				return shippers.some((item) => {
					if (item.pick_up_location) {
						return (
							item.shipper == 0 ||
							item.shipper_details_info == null ||
							item.warehouse_pick_up_location == null ||
							item.contact_person_name == null ||
							item.contact_person_mobile_num == null ||
							item.contact_person_email == null
						);
					} else {
						return item.shipper == 0 || item.shipper_details_info == null;
					}
				});
			} else if (field === "shipper") {
				return consignees.some(
					(item) => item.consignee == 0 || item.consignee_details_info == null
				);
			} else if (field === "orders") {
				const isManualOrder = this.shipmentItem.orderItems.some(
					(order) => order.layout == "manual"
				);
				if (!isManualOrder) {
					return orderItems.some((item) => {
						if (item.quickAddOrder) {
							return (
								item.selected_order_user_id == null ||
								item.selected_order_user_id == "" ||
								item.order_number == "" ||
								item.order_number == null ||
								item.products.some((product) => {
									if (product.quickAddProduct) {
										return (
											product.product_number == "" ||
											product.product_number == null ||
											product.carton == null ||
											product.carton == 0
										);
									} else {
										return (
											product.product_id == "" || product.product_id == null
										);
									}
								})
							);
						} else {
							return (
								item.selected_order_user_id == null ||
								item.selected_order_user_id == "" ||
								item.purchase_order_id == "" ||
								item.purchase_order_id == null ||
								item.products.some((product) => {
									if (product.quickAddProduct) {
										return (
											product.product_number == "" ||
											product.product_number == null ||
											product.carton == null ||
											product.carton == 0
										);
									} else {
										return (
											product.product_id == "" || product.product_id == null
										);
									}
								})
							);
						}
					});
				} else {
					return orderItems.some(
						(item) =>
							item.selected_order_user_id == null ||
							item.total_volumes == 0 ||
							item.total_weights == 0 ||
							item.total_cartons == 0
					);
				}
			} else if (field === "cargo") {
				return cargoDetailsData.some(
					(item) =>
						item.total_volume == 0 || item.total_weight == 0 || !item.incoterm
				);
			}
		},
		countShipmentsDecimals(value) {
			value = Number(value);
			if (Number.isInteger(value)) {
				return 0;
			} else {
				return value.toString().split(".")[1].length;
			}
		},
		isString(x) {
			return Object.prototype.toString.call(x) === "[object String]";
		},
		toFixed(x) {
			if (this.isString(x)) {
				x = x.toLowerCase();
			}

			if (Math.abs(x) < 1.0) {
				let e = parseInt(x.toString().split("e-")[1]);
				if (e) {
					x *= Math.pow(10, e - 1);
					x = "0." + new Array(e).join("0") + x.toString().substring(2);
				}
			} else {
				let e = parseInt(x.toString().split("+")[1]);
				if (e > 20) {
					e -= 20;
					x /= Math.pow(10, e);
					x += new Array(e + 1).join("0");
				}
			}
			return x;
		},
		saveNewBookingTemplate() {
			this.$emit("bookingTemplateRequest");
		},
		checkTemplate(item) {
			let bookingData = JSON.parse(item.data);
			if (bookingData !== null) {
				this.preparedBookingDialogData(bookingData, "template");
			}
		},
		async preparedBookingDialogData(bookingData, fromData) {
			console.log("bookingData", bookingData);
			console.log("fromData", fromData);

			const {
				userRole,
				consignee,
				shippers,
				location_from,
				location_to,
				mode,
				type,
				give_me_best_location,
				port_of_discharge,
				earliest_shipment_date,
				latest_arrival_date,
				preferred_carrier,
				customer_reference_number,
				warehouseDeliveryLocation,
				additional_services,
				import_name_id,
				additional_instructions_and_notes,
			} = bookingData;

			this.shipmentItem.userRole = userRole;
			this.shipmentItem.shipment_ids = bookingData?.shipment_ids;
			if (userRole == "shipper") {
				let buyerd_ids = consignee.map((buyer) => buyer.buyer_id);
				this.shipmentItem.selectedConsigneeIds = buyerd_ids;

				await this.fetchPurchaseOrderOptions(
					"",
					"SO",
					shippers[0].shipper,
					buyerd_ids
				);

				this.loaded = false;
				this.shipmentItem.shippers = shippers;
				let consigneesData = [];
				let cargoDetailsData = [];
				consignee &&
					consignee.map(async (item) => {
						const {
							buyer_id,
							detailsInfo,
							is_notify_party,
							notify_details_info,
							cargoDetails,
							orders,
							manualOrders,
						} = item;
						const {
							incoterm_id,
							product_description,
							total_cartoon,
							total_weight,
							total_volume,
						} = cargoDetails;

						consigneesData.push({
							consignee: buyer_id,
							consignee_details_info: detailsInfo,
							is_notify_party: is_notify_party,
							notify_details_info: notify_details_info,
						});
						this.shipmentItem.consignees = consigneesData;

						cargoDetailsData.push({
							consignee: buyer_id,
							total_carton: total_cartoon,
							total_volume: total_volume,
							total_weight: total_weight,
							commodity_description: product_description,
							incoterm: incoterm_id,
						});
						this.shipmentItem.cargoDetailsData = cargoDetailsData;
						if (orders.length > 0) {
							await this.getAutoOrders(orders, buyer_id);
						} else if (manualOrders.length > 0) {
							await this.getManualOrders(manualOrders, buyer_id);
						}
					});
			} else {
				let supplier_ids = shippers.map((supplier) => supplier.supplier_id);
				this.shipmentItem.selectedShipperIds = supplier_ids;

				await this.fetchPurchaseOrderOptions(
					"",
					"PO",
					consignee[0].consignee,
					supplier_ids
				);

				this.loaded = false;
				this.shipmentItem.consignees = consignee;
				let shippersData = [];
				let cargoDetailsData = [];
				shippers &&
					shippers.map(async (item) => {
						const {
							supplier_id,
							pickupLocation,
							detailsInfo,
							cargoDetails,
							orders,
							manualOrders,
						} = item;
						const { email, location, name, phone } = pickupLocation;

						const {
							incoterm_id,
							product_description,
							total_cartoon,
							total_weight,
							total_volume,
						} = cargoDetails;

						shippersData.push({
							shipper: supplier_id,
							shipper_details_info: detailsInfo,
							pick_up_location:
								location || name || phone || email ? true : false,
							warehouse_pick_up_location: location,
							contact_person_name: name,
							contact_person_mobile_num: phone,
							contact_person_email: email,
						});
						this.shipmentItem.shippers = shippersData;

						cargoDetailsData.push({
							shipper: supplier_id,
							total_carton: total_cartoon,
							total_volume: total_volume,
							total_weight: total_weight,
							commodity_description: product_description,
							incoterm: incoterm_id,
						});
						this.shipmentItem.cargoDetailsData = cargoDetailsData;
						if (orders.length > 0) {
							await this.getAutoOrders(orders, supplier_id);
						} else if (manualOrders.length > 0) {
							await this.getManualOrders(manualOrders, supplier_id);
						}
					});
			}

			this.shipmentItem.mode = mode;
			this.shipmentItem.import_name_id = import_name_id;
			this.shipmentItem.type = type;
			this.shipmentItem.location_from = parseInt(location_from);
			this.shipmentItem.location_to = parseInt(location_to);
			this.shipmentItem.port_of_discharge = parseInt(port_of_discharge);
			if (give_me_best_location == 1 || port_of_discharge > 0) {
				this.shipmentItem.same_as_port_of_discharge = true;
			}
			this.shipmentItem.give_me_best_location =
				give_me_best_location == 1 ? true : false;
			this.shipmentItem.earliest_shipment_date = earliest_shipment_date;
			this.shipmentItem.latest_arrival_date = latest_arrival_date;
			this.shipmentItem.preferred_carrier = preferred_carrier ?? [];
			this.shipmentItem.customer_reference_number = customer_reference_number;
			if (warehouseDeliveryLocation?.location) {
				this.shipmentItem.warehouse_delivery_location = true;
			}
			this.shipmentItem.warehouse_pick_up_location =
				warehouseDeliveryLocation?.location;
			this.shipmentItem.additional_services = additional_services ?? [];
			this.shipmentItem.additional_instructions_and_notes =
				additional_instructions_and_notes ?? null;
		},
		updateBookingTemplate(templateId) {
			let findTemplate = this.bookingTemplates.find(
				(item) => templateId == item.id
			);
			if (
				findTemplate.vendor_ids !== "" &&
				findTemplate.vendor_ids !== null &&
				findTemplate.vendor_ids !== undefined
			) {
				findTemplate.vendor_id = findTemplate.vendor_ids
					.split(";")
					.map((id) => parseInt(id));
			} else {
				findTemplate.vendor_id = "";
			}

			this.$emit("updateBookingTemplateRequest", findTemplate);
		},
		showConfirmRoleModal(newRole) {
			this.$emit("showConfirmRoleModal", newRole);
		},
		resetForm() {
			this.$emit("resetForm");
		},
		saveUpdateDefaultTemplate() {
			this.$emit("saveAsDefaultTemplate");
		},
	},
};
</script>
<style lang="scss">
@import "../BookingShipment/scss/BookingShipmentContainer.scss";
</style>
