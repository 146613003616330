<!-- @format -->

<template>
	<div class="shipment-table-wrapper mobile" 
		:class="activeShipmentTab === 0 ? 'pending' : activeShipmentTab === 1 ? 'shipments' :''">
		<!-- FOR PENDING TABS TABLE -->
		<div
			class="overlay"
			:class="
				search == '' &&
				(loadingPendingData ||
					loadingExpiredData ||
					loadingPendingQuoteData ||
					loadingSnoozeData)
					? 'show'
					: ''
			"
			v-if="activeShipmentTab == 0"
		>
			<div
				class="preloader"
				v-if="
					search == '' &&
						(loadingPendingData ||
							loadingExpiredData ||
							loadingPendingQuoteData ||
							loadingSnoozeData)
				"
			>
				<v-progress-circular :size="40" color="#1A6D9E" indeterminate>
				</v-progress-circular>
			</div>
		</div>

		<LoadingSearch
			:search="search"
			:loading="getSearchedShipmentLoading"
			:className="'overlay search pending'"
			v-if="activeShipmentTab == 0" />

		<PendingTabTable
			:pending="pending"
			:expired="expired"
			:snooze="snooze"
			:draft="draft"
			:pendingQuote="pendingQuote"
			:isMobile="true"
			:activeTab.sync="activeShipmentTab"
			:pendingCurrentTab.sync="pendingShipmentTab"
			:search.sync="search"
			:paginationData.sync="pagination"
			@handleClick="handleClick"
			@handlePageChangeSnooze="handlePageChangeSnooze"
			@handlePageChangePending="handlePageChangePending"
			@handlePageChangePendingQuote="handlePageChangePendingQuote"
			@handlePageChangeConsolidationRequests="
				handlePageChangeConsolidationRequests
			"
			@handlePageChangeExpired="handlePageChangeExpired"
			v-if="activeShipmentTab === 0"
			@handlePageSearched="handlePageSearched"
			:consolidated="consolidated"
			:currentViewTab="currentViewTab"
			:isFilterEnabled="isFilterEnabled"
			:currentCalendarType.sync="type"
			:currentDateCalendar.sync="currentDateCalendar"
			tab="booking"
			:selectedDefaultFilter.sync="selectedCalendarFilter"
			:selectedCalendarOption="selectedCalendarOption"
			:isAllSelected="isAllSelected"
			:isCalendarFilterSelectionEmpty.sync="isCalendarFilterSelectionEmpty" />

		<!-- FOR SHIPMENT TABS TABLE -->
		<div class="overlay" :class="search == '' && loadingShipmentsData ? 'show' : ''" v-if="activeShipmentTab == 1">
			<div class="preloader" v-if="search == '' && loadingShipmentsData">
				<v-progress-circular :size="40" color="#1A6D9E" indeterminate>
				</v-progress-circular>
			</div>
		</div>

		<LoadingSearch
			:search="search"
			:loading="getSearchedShipmentLoading"
			:className="'overlay search'"
			v-if="activeShipmentTab == 1" />

		<ShipmentsTabTable
			:shipments="shipments"
			:isMobile="true"
			:activeTab.sync="activeShipmentTab"
			:search.sync="search"
			:paginationData.sync="pagination"
			@handleClick="handleClick"
			@handlePageChange="handlePageChangeShipments"
			@sort="changeSort"
			:shipmentIcon="shipmentIconSort"
			v-if="activeShipmentTab === 1"
			@handlePageSearched="handlePageSearched"
			:currentViewTab="currentViewTab"
			:isFilterEnabled="isFilterEnabled"
			:currentCalendarType.sync="type"
			:currentDateCalendar.sync="currentDateCalendar"
			tab="shipments"
			:selectedDefaultFilter.sync="selectedCalendarFilter"
			:selectedCalendarOption="selectedCalendarOption"
			:isAllSelected="isAllSelected"
			:isCalendarFilterSelectionEmpty.sync="isCalendarFilterSelectionEmpty"
			:shipmentCurrentTab.sync="shipmentCurrentTab"
			:awaitingDeparture="awaitingDeparture"
			:inTransit="inTransit"
			:arrived="arrived"
			:pickedUp="pickedUp"
			:requiresAttention="requiresAttention"
		/>

		<!-- FOR COMPLETED TAB ONLY -->
		<div class="overlay" :class="search == '' && loadingCompletedData ? 'show' : ''" v-if="activeShipmentTab == 2">
			<div class="preloader" v-if="search == '' && loadingCompletedData">
				<v-progress-circular :size="40" color="#1A6D9E" indeterminate>
				</v-progress-circular>
			</div>
		</div>

		<LoadingSearch
			:search="search"
			:loading="getSearchedShipmentLoading"
			:className="'overlay search'"
			v-if="activeShipmentTab == 2" />

		<CompletedTabTable
			:completed="completed"
			:isMobile="true"
			:activeTab.sync="activeShipmentTab"
			:search.sync="search"
			:paginationData.sync="pagination"
			@handleClick="handleClick"
			@handlePageChange="handlePageChangeCompleted"
			@sort="changeSort"
			:completedIconSort="completedIconSort"
			v-if="activeShipmentTab === 2"
			@handlePageSearched="handlePageSearched"
			:currentViewTab="currentViewTab"
			:isFilterEnabled="isFilterEnabled"
			:currentCalendarType.sync="type"
			:currentDateCalendar.sync="currentDateCalendar"
			tab="completed"
			:selectedDefaultFilter.sync="selectedCalendarFilter"
			:selectedCalendarOption="selectedCalendarOption"
			:isAllSelected="isAllSelected"
			:isCalendarFilterSelectionEmpty.sync="isCalendarFilterSelectionEmpty" />
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import globalMethods from "../../../utils/globalMethods";
import PendingTabTable from "./Tabs/PendingTabTable.vue";
import ShipmentsTabTable from "./Tabs/ShipmentsTabTable.vue";
import CompletedTabTable from "./Tabs/CompletedTabTable.vue";
import LoadingSearch from "./LoadingSearch.vue";
import { shipmentSubtabs } from "./Tabs/js/shipmentFilters";

export default {
	props: [
		"shipments",
		"pending",
		"pendingQuote",
		"snooze",
		"expired",
		"completed",
		"draft",
		"editedItem",
		"editedIndex",
		"activeTab",
		"isMobile",
		"tablePage",
		"search",
		"pendingCurrentTab",
		"paginationData",
		"currentViewTab",
		"consolidated",
		"isFilterEnabled",
		"currentCalendarType",
		"currentDateCalendar",
		"selectedDefaultFilter",
		"selectedCalendarOption",
		"isAllSelected",
		"isCalendarFilterSelectionEmpty",
		"shipmentCurrentTab",
		"awaitingDeparture",
		"inTransit",
		"arrived",
		"pickedUp",
		"requiresAttention"
	],
	components: {
		ShipmentsTabTable,
		PendingTabTable,
		CompletedTabTable,
		LoadingSearch,
	},
	data: () => ({
		loadingShipmentsData: false,
		loadingPendingData: false,
		loadingPendingQuoteData: false,
		loadingExpiredData: false,
		loadingCompletedData: false,
		loadingSnoozeData: false,
		shipmentIconSort: "mdi-arrow-up",
		completedIconSort: "mdi-arrow-down",
	}),
	computed: {
		...mapGetters(["getSearchedShipments", "getSearchedShipmentLoading"]),
		formTitle() {
			return this.editedIndex === -1 ? "New Item" : "Edit Item";
		},
		activeShipmentTab: {
			get() {
				return this.activeTab;
			},
			set(value) {
				this.$emit("update:activeTab", value);
			},
		},
		pendingShipmentTab: {
			get() {
				return this.pendingCurrentTab;
			},
			set(value) {
				this.$emit("update:pendingCurrentTab", value);
			},
		},
		page: {
			get() {
				return this.tablePage;
			},
			set(value) {
				this.$emit("update:tablePage", value);
			},
		},
		pagination: {
			get() {
				return this.paginationData;
			},
			set(value) {
				this.$emit("update:paginationData", value);
			},
		},
		type: {
			get() {
				return this.currentCalendarType;
			},
			set(v) {
				this.$emit("update:currentCalendarType", v);
			},
		},
		selectedCalendarFilter: {
			get() {
				return this.selectedDefaultFilter;
			},
			set(v) {
				this.$emit("update:selectedDefaultFilter", v);
			},
		},
	},
	mounted() {
		//set current page
		this.$store.dispatch("page/setPage", "shipments");

		// set current shipment page
		if (this.$store.state.page.currentShipmentPage !== "undefined") {
			if (this.page !== this.$store.state.page.currentShipmentPage) {
				this.page = this.$store.state.page.currentShipmentPage;
			}
		}
	},
	methods: {
		...mapActions([
			"fetchShipments",
			"requestNewSchedule",
			"fetchPendingShipments",
			"fetchPendingQuoteShipments",
			"fetchConsolidationRequests",
			"fetchSnoozeShipments",
			"fetchExpiredShipments",
			"fetchCompletedShipments",
			"setShipmentOrder",
			"setCompletedOrder",
			"fetchShipmentsSearched",
			"fetchAwaitingShipments",
			"fetchInTransitShipments",
			"fetchArrivedShipments",
			"fetchPickedUpShipments",
			"fetchRequiresAttentionShipments"
		]),
		...globalMethods,
		handleClick(value) {
			this.$router.push(`shipment/${value.id}`);
		},
		// booking tabs page change
		async handlePageChangePendingQuote(page) {
			let storePagination = this.$store.state.shipment.shipmentsPagination
				.pendingQuoteTab;

			if (page !== null) {
				if (storePagination.old_page !== page) {
					storePagination.old_page = page;
					this.loadingPendingQuoteData = true;
					await this.fetchPendingQuoteShipments(page);
					this.loadingPendingQuoteData = false;
				}
			}
		},
		async handlePageChangeConsolidationRequests(page) {
			let storePagination = this.$store.state.shipment.shipmentsPagination
				.consolidationTab;

			if (page !== null) {
				if (storePagination.old_page !== page) {
					storePagination.old_page = page;
					this.loadingConsolidationRequestsData = true;
					await this.fetchConsolidationRequests(page);
					this.loadingConsolidationRequestsData = false;
				}
			}
		},
		async handlePageChangeSnooze(page) {
			let storePagination = this.$store.state.shipment.shipmentsPagination
				.snoozeTab;

			if (page !== null) {
				if (storePagination.old_page !== page) {
					storePagination.old_page = page;
					this.loadingPendingData = true;
					await this.fetchSnoozeShipments(page);
					this.loadingSnoozeData = false;
				}
			}
		},
		async handlePageChangePending(page) {
			let storePagination = this.$store.state.shipment.shipmentsPagination
				.pendingTab;

			if (page !== null) {
				if (storePagination.old_page !== page) {
					storePagination.old_page = page;
					this.loadingPendingData = true;
					await this.fetchPendingShipments(page);
					this.loadingPendingData = false;
				}
			}
		},
		async handlePageChangeExpired(page) {
			let storePagination = this.$store.state.shipment.shipmentsPagination
				.expiredTab;

			if (page !== null) {
				if (storePagination.old_page !== page) {
					storePagination.old_page = page;
					this.loadingExpiredData = true;
					await this.fetchExpiredShipments(page);
					this.loadingExpiredData = false;
				}
			}
		},
		// shipments tab including the subtabs page change
		async handlePageChangeShipments(page) {
			let storePagination = this.$store.state.shipment.shipmentsPagination.shipmentTab;
			let storePagination1 = this.$store.state.shipmentSubTabs.shipmentsPaginationLists;
			let storeShipmentTabData = this.$store.state.shipment.shipmentOrder;

			if (page !== null) {
				let payloadShipments = { page, order: storeShipmentTabData.order, };

				if (this.shipmentCurrentTab === 0) { // all shipments
					if (storePagination.old_page !== page) {
						storePagination.old_page = page;
						this.loadingShipmentsData = true;
						await this.fetchShipments(payloadShipments);
						this.loadingShipmentsData = false;
					}

				} else if (this.shipmentCurrentTab === 1) { // awaiting departure
					if (storePagination1.awaitingDeparture.old_page !== page) {
						storePagination1.awaitingDeparture.old_page = page;
						this.loadingShipmentsData = true;
						await this.fetchAwaitingShipments(payloadShipments);
						this.loadingShipmentsData = false;
					}					

				} else if (this.shipmentCurrentTab === 2) { // in transit
					if (storePagination1.inTransit.old_page !== page) {
						storePagination1.inTransit.old_page = page;
						this.loadingShipmentsData = true;
						await this.fetchInTransitShipments(payloadShipments);
						this.loadingShipmentsData = false;
					}					

				} else if (this.shipmentCurrentTab === 3) { // arrived
					if (storePagination1.arrived.old_page !== page) {
						storePagination1.arrived.old_page = page;
						this.loadingShipmentsData = true;
						await this.fetchArrivedShipments(payloadShipments);
						this.loadingShipmentsData = false;
					}

				} else if (this.shipmentCurrentTab === 4) { // picked up
					if (storePagination1.pickedUp.old_page !== page) {
						storePagination1.pickedUp.old_page = page;
						this.loadingShipmentsData = true;
						await this.fetchPickedUpShipments(payloadShipments);
						this.loadingShipmentsData = false;
					}

				} else if (this.shipmentCurrentTab === 5) { // requires attention shipments
					if (storePagination1.requiresAttention.old_page !== page) {
						storePagination1.requiresAttention.old_page = page;
						this.loadingShipmentsData = true;
						await this.fetchRequiresAttentionShipments(payloadShipments);
						this.loadingShipmentsData = false;
					}
				}
			}
		},
		// completed tab page change
		async handlePageChangeCompleted(page) {
			let storePagination = this.$store.state.shipment.shipmentsPagination
				.completedTab;
			let storeShipmentTabData = this.$store.state.shipment.completedOrder;

			if (page !== null) {
				if (storePagination.old_page !== page) {
					storePagination.old_page = page;
					let payloadShipments = {
						page: page,
						order: storeShipmentTabData.order,
					};

					this.loadingCompletedData = true;
					await this.fetchCompletedShipments(payloadShipments);
					this.loadingCompletedData = false;
				}
			}
		},
		// pagination with search data
		async handlePageSearched(data) {
			let storePagination = this.$store.state.shipment.searchedShipmentsPagination;

			if (data !== null && this.search) {
				let shipmentTab = data.tab.includes("shipments");

				if (storePagination.old_page !== data.page) {
					storePagination.old_page = data.page;

					let passedData = {
						method: "get",
						url: "/v2/shipment-tab",
						params: {
							shipment_tab: data.tab,
							page: data.page,
							per_page: 20,
							compact_view: false,
							search: this.search,
							tab: data.tab,
						},
					};

					if (shipmentTab) {
						passedData.params.sort_column = "eta";
						passedData.params.order_by = this.$store.state.shipment.shipmentOrder.order;

						if (this.shipmentCurrentTab !== 0) {
							passedData.params.shipments_sub_tab = shipmentSubtabs[this.shipmentCurrentTab].subTabValue;
							passedData.params.tab = `shipments${this.shipmentCurrentTab + 1}`;
						}
					} else if (data.tab === "completed") {
						passedData.params.sort_column = "eta";
						passedData.params.order_by = this.$store.state.shipment.completedOrder.order;
					}

					try {
						await this.fetchShipmentsSearched(passedData);
					} catch (e) {
						let errorMessage = typeof e === String ? e : JSON.stringify(e);
						this.notificationError(
							`An error occured while trying to search. ${errorMessage}`
						);
						console.log("Error in searching", e);
					}
				}
			}
		},	
		async changeSort(tab) {
			let storePagination = this.$store.state.shipment.shipmentsPagination;
			let storeShipmentTabData = this.$store.state.shipment;

			try {
				if (tab === 1) {
					if (storeShipmentTabData.shipmentOrder.order === "asc") {
						this.setShipmentOrder({ order: "desc", field: "eta" });
						this.shipmentIconSort = "mdi-arrow-down";

						let payloadShipments = {
							page: storePagination.shipmentTab.current_page,
							order: storeShipmentTabData.shipmentOrder.order,
						};

						this.loadingShipmentsData = true;
						await this.fetchShipments(payloadShipments);
						this.loadingShipmentsData = false;
					} else {
						this.setShipmentOrder({ order: "desc", field: "eta" });
						this.shipmentIconSort = "mdi-arrow-up";

						let payloadShipments = {
							page: storePagination.shipmentTab.current_page,
							order: storeShipmentTabData.shipmentOrder.order,
						};

						this.loadingShipmentsData = true;
						await this.fetchShipments(payloadShipments);
						this.loadingShipmentsData = false;
					}
				} else if (tab === 2) {
					if (storeShipmentTabData.completedOrder.order === "asc") {
						this.setCompletedOrder("desc");
						this.completedIconSort = "mdi-arrow-down";

						let payloadShipments = {
							page: storePagination.completedTab.current_page,
							order: storeShipmentTabData.completedOrder.order,
						};

						this.loadingCompletedData = true;
						await this.fetchCompletedShipments(payloadShipments);
						this.loadingCompletedData = false;
					} else {
						this.setCompletedOrder("asc");
						this.completedIconSort = "mdi-arrow-up";

						let payloadShipments = {
							page: storePagination.completedTab.current_page,
							order: storeShipmentTabData.completedOrder.order,
						};

						this.loadingCompletedData = true;
						await this.fetchCompletedShipments(payloadShipments);
						this.loadingCompletedData = false;
					}
				}
			} catch (e) {
				this.loadingShipmentsData = false;
				this.loadingCompletedData = false;
				console.log(e, "something wrong with sorting");
			}
		},
	},
	updated() {},
};
</script>

<style></style>
