<!-- @format -->

<template>
	<div>
		<BookingShipmentDialogV2
			:reference="'booking-request-form'"
			v-if="isLoading == false"
		/>

		<ConfirmDialog
			:dialogData.sync="requestBookingConfirmation"
			class="shipment-booking-request-dialog"
		>
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Link Expired!</h2>
			</template>

			<template v-slot:dialog_content>
				<p class="mb-0">
					A one-time booking link has been Expired.
				</p>
			</template>
		</ConfirmDialog>
	</div>
</template>

<script>
import ConfirmDialog from "../../components/Dialog/GlobalDialog/ConfirmDialog.vue";
import shipmentBookingItem from "@/components/ShipmentComponents/BookingShipment/js/ShipmentBooking";
import BookingShipmentDialogV2 from "../../components/ShipmentComponents/BookingShipment/BookingShipmentDialogV2.vue";

export default {
	name: "BookingRequestFormV2",
	data: () => ({
		requestBookingConfirmation: false,
		shipmentItem: shipmentBookingItem,
	}),
	components: {
		ConfirmDialog,
		BookingShipmentDialogV2,
	},
	async mounted() {
		let data = document.querySelector(".v-main__wrap");
		data.classList.add("shipment-booking-request-form-wrapper");
	},
	methods: {
		addMoreConsignees() {
			this.shipmentItem.consignees.push({
				consignee: 0,
				consignee_details_info: null,
				is_notify_party: false,
				notify_details_info: null,
			});
		},
		addMoreShippers() {
			this.shipmentItem.shippers.push({
				shipper: 0,
				shipper_details_info: null,
				pick_up_location: false,
				warehouse_pick_up_location: null,
				contact_person_name: null,
				contact_person_mobile_num: null,
				contact_person_email: null,
			});
		},
		fetchPurchaseOrderOptions(qry, type, entity_id, other_id) {
			return new Promise((resolve) => {
				this.$store
					.dispatch("po/fetchPurchaseOrderOptions", {
						qry,
						type,
						entity_id,
						other_id,
					})
					.then((r) => {
						resolve(r);
					});
			});
		},
	},
	computed: {
		isLoading() {
			return this.$store.getters.getLoadingUserDetails;
		},
	},
	async beforeMount() {
		if (
			this.shipmentItem.consignees.length == 0 &&
			this.shipmentItem.shippers.length == 0
		) {
			await this.addMoreConsignees();
			await this.addMoreShippers();
		}

		const currentUrl = window.location.href;
		const urlArray = currentUrl.split("/").slice(-1);

		await this.$store
			.dispatch("booking/fetchBookingLinkData", {
				tokenKey: urlArray[0],
			})
			.then(async (response) => {
				if (response.status == 200) {
					this.shipmentItem.requestBookingToken = urlArray[0];
					this.shipmentItem.userRole = "consignee";
					const {
						supplier_id,
						consignee_id,
						is_request_submitted,
					} = response?.data?.data;
					if (is_request_submitted) {
						this.requestBookingConfirmation = true;
					}
					this.shipmentItem.selectedShipperIds = [supplier_id];

					await this.shipmentItem.shippers.map((item) => {
						item.shipper = supplier_id;
					});
					await this.shipmentItem.consignees.map((item) => {
						item.consignee = consignee_id;
					});
					await this.shipmentItem.cargoDetailsData.push({
						shipper: supplier_id,
						total_carton: 0,
						total_volume: 0,
						total_weight: 0,
						commodity_description: null,
						incoterm: 0,
					});

					await this.fetchPurchaseOrderOptions(
						"",
						"PO",
						consignee_id,
						supplier_id
					);
				}
			})
			.catch((error) => {
				this.requestBookingConfirmation = true;
				console.log("error", error);
			});
	},
};
</script>

<style>
.shipment-booking-request-form-wrapper {
	max-height: 100vh;
	overflow: hidden;
}
</style>
