<!-- @format -->

<template>
	<div>
		<v-dialog
			v-model="dialogCreate"
			max-width="1176px"
			content-class="select-cargo-dialog"
			:retain-focus="false"
			persistent
			scrollable
		>
			<v-card class="cargo-dialog-card">
				<v-card-title>
					<span class="headline">Select Cargo</span>
					<button icon dark class="btn-close" @click="close">
						<v-icon>mdi-close</v-icon>
					</button>
				</v-card-title>

				<v-card-text>
					<div class="po-booking-shipment py-2">
						<h3 class="po-booking-counter" v-if="!selectShipment">
							<span class="po-count"
								>{{ finalOrdersData.length }}
								{{ fromPoComponent ? "P" : "S" }}Os</span
							>
							are selected for booking request
						</h3>
						<h3 class="po-booking-counter" v-if="selectShipment">
							<span class="po-count"
								>{{ finalOrdersData.length }}
								{{ fromPoComponent ? "P" : "S" }}Os</span
							>
							is selected for shipment
						</h3>

						<div class="input-field-wrapper mb-0">
							<v-autocomplete
								spellcheck="false"
								class="select-shipment"
								:items="shipmentListsDropDown"
								placeholder="Select Shipment"
								outlined
								item-text="shifl_ref"
								item-value="id"
								:menu-props="{
									contentClass: 'shipment-lists-items',
									bottom: true,
									offsetY: true,
								}"
								hide-details="auto"
								v-if="selectShipment"
								v-model="shipment_id"
								:prefix="shipment_id ? `Ref: #` : ''"
								@change="(value) => checkShipmentStatus(value)"
							>
								<template v-slot:selection="{ item }">
									<div class="shipment-no mb-0">
										<span class="shifl_ref">{{ item.shifl_ref }}</span>
										<span
											:class="
												item.shipment_status.replace(/\s+/g, '_').toLowerCase()
											"
											class="shipment_status"
										>
											{{ item.shipment_status }}</span
										>
									</div>
								</template>

								<template v-slot:item="{ item }">
									<div class="option-items" :id="item.id">
										<div class="shipment-info">
											<p class="shipment-no">
												Ref # {{ item.shifl_ref }}
												<span
													:class="
														item.shipment_status
															.replace(/\s+/g, '_')
															.toLowerCase()
													"
													class="shipment_status"
												>
													{{ item.shipment_status }}</span
												>
											</p>

											<p class="mbl-no">
												MBL {{ item.mbl_num ? `#${item.mbl_num}` : "N/A" }}
											</p>

											<p class="mb-0 shipment-date">
												<span> ETD: {{ dateFormat(item.etd) }} </span>
												<span> ETA: {{ dateFormat(item.eta) }} </span>
											</p>
										</div>
									</div>
								</template>
							</v-autocomplete>
						</div>
					</div>
					<v-form ref="cargoform" v-model="valid" action="#" @submit.prevent="">
						<fieldset
							class="mt-4"
							v-for="(orderValue, i) in finalOrdersData"
							:key="i"
						>
							<legend class="po-num-product">
								<v-text-field
									:value="companyName(orderValue)"
									outlined
									class=" select-items product-desc"
									hide-details="auto"
									readonly
									disabled
								/>

								<v-text-field
									:value="
										`${fromPoComponent ? 'P' : 'S'}O ${orderValue.po_number}`
									"
									outlined
									class=" select-items product-desc"
									hide-details="auto"
									readonly
									disabled
								/>
								<v-checkbox
									v-model="orderValue.all_products"
									class="mt-0 product-checkbox shrink shifl-ui-checkbox"
									label="Select All Products"
									hide-details
									@click="selectAllProducts(orderValue)"
								/>
								<v-menu
									:close-on-content-click="true"
									transition="scale-transition"
									offset-y
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											placeholder="Select CRD"
											outlined
											v-bind="attrs"
											v-on="on"
											type="text"
											hide-details="auto"
											class="cargo-date"
											clear-icon
											:height="40"
											v-model="orderValue.cargo_ready_date"
											append-icon="mdi-calendar"
											prefix="CRD:"
											@input="
												(val) => updateCargoReadyDateInput(orderValue, val)
											"
											disabled
										/>
									</template>
									<v-date-picker v-model="orderValue.cargo_ready_date" />
								</v-menu>
								<button
									class="btn-delete"
									:disabled="finalOrdersData.length <= 1"
									@click="removeOrder(orderValue.order_id)"
								>
									<img src="@/assets/icons/delete-po.svg" alt="" />
								</button>
							</legend>

							<div class="create-cargo-wrapper">
								<div class="product-info-wrapper">
									<v-data-table
										:headers="headers"
										:items="orderValue.products"
										class="view-product-details"
										mobile-breakpoint="1023"
										hide-default-footer
										:items-per-page="500"
									>
										<template v-slot:body="{ items }">
											<tbody v-if="items.length > 0">
												<tr v-for="(item, index) in items" :key="index">
													<td>
														<v-checkbox
															v-model="item.selectProduct"
															hide-details="auto"
															class="mt-0 shifl-ui-checkbox product-checkbox"
															:label="`#${item.category_sku}`"
														/>
													</td>

													<td>
														<v-text-field
															v-model="item.product_description"
															outlined
															class="select-product-items"
															hide-details="auto"
															readonly
														/>
													</td>

													<td>
														<v-checkbox
															v-model="item.addAll"
															hide-details
															class="mt-0 shifl-ui-checkbox carton-checkbox"
															@click="selectAllCartons(item)"
														/>
													</td>

													<td>
														<v-text-field
															v-model="item.unship_cartons"
															placeholder="0"
															outlined
															class="select-product-items text-align-value"
															hide-details="auto"
															min="1"
															:max="item.total_unship_cartons"
															@change="updateCartonCount(item)"
															type="number"
															:rules="[(v) => !!v || '']"
															:disabled="item.addAll"
														/>
													</td>

													<td>
														<v-text-field
															v-model="item.units"
															placeholder="0"
															outlined
															class="select-product-items text-align-value"
															hide-details="auto"
															min="1"
															:max="item.units"
															type="number"
															:disabled="item.addAll"
															readonly
														/>
													</td>
													<td>
														<v-text-field
															v-model="item.volume"
															placeholder="0"
															outlined
															class="select-product-items text-align-value"
															hide-details="auto"
															min="1"
															suffix="CBM"
														/>
													</td>

													<td>
														<v-text-field
															v-model="item.weight"
															placeholder="0"
															outlined
															class="select-product-items text-align-value"
															hide-details="auto"
															min="1"
															type="number"
															suffix="KG"
														/>
													</td>

													<td>
														<v-text-field
															v-model="item.unit_price"
															class="select-product-items text-align-value"
															placeholder="0.00"
															outlined
															hide-details="auto"
															readonly
															:disabled="item.addAll"
														/>
													</td>
													<td>
														<v-text-field
															:value="calculateAmount(item)"
															class="select-product-items text-align-value"
															placeholder="0.00"
															outlined
															hide-details="auto"
															readonly
															:disabled="item.addAll"
														/>
													</td>
													<td>
														<v-text-field
															v-model="item.classification_code"
															class="select-product-items text-align-value"
															placeholder="Enter Code"
															outlined
															hide-details="auto"
															readonly
															:disabled="item.addAll"
														/>
													</td>
													<td>
														<v-text-field
															v-model="item.product_classification_description"
															class="select-product-items text-align-value"
															placeholder="Enter Description"
															outlined
															hide-details="auto"
															readonly
														/>
													</td>
												</tr>
											</tbody>
										</template>
									</v-data-table>
								</div>
								<div class="total-product-desc">
									<div class="total">
										<p>
											Total Cartons: <span>{{ totalCartons(orderValue) }}</span>
										</p>
										<p>
											Total Volume:
											<span>{{ totalVolume(orderValue) }} CBM</span>
										</p>
										<p>
											Total Weight:
											<span>{{ totalweight(orderValue) }} KG</span>
										</p>
									</div>
									<div class="info-message">
										<img
											src="@/assets/icons/info.svg"
											width="16px"
											height="16px"
										/>
										<span>
											Some of the information is required for customs filling
										</span>
									</div>
								</div>
							</div>
						</fieldset>
					</v-form>
				</v-card-text>

				<v-card-actions class="cargo-button-actions">
					<div class="d-flex footer">
						<v-btn
							class="save-btn btn-blue mr-2"
							text
							@click="proceedShipment()"
							v-if="!selectShipment"
							:disabled="btnDisabled || getBookingOrdersDataLoading"
						>
							Proceed
						</v-btn>
						<v-btn
							class="save-btn btn-blue mr-2"
							text
							@click="proceedShipment()"
							:disabled="btnDisabled"
							v-else
						>
							Add to Shipment
						</v-btn>
						<v-btn class="btn-white black--text btn-blue" text @click="close()">
							Cancel
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<BookingShipmentDialogV2
			:show.sync="viewBookingShipmentDialog"
			:reference="'request-booking-shipment'"
			@close="closeBookingShipmentDialog"
		/>

		<ConfirmDialog :dialogData.sync="confirmShipmentDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2 v-if="shipmentStatus == 'Pending Approval'">
					Shipment in Pending Approval
				</h2>
				<h2 v-else>Shipment In Transit</h2>
			</template>

			<template v-slot:dialog_content>
				<p v-if="shipmentStatus == 'Pending Approval'">
					The selected shipment is in pending approval, Once PO is added to
					shipment, our team will review and provide a new quote.
				</p>
				<p v-else>
					The selected shipment is in transit, and adding this PO to the
					shipment will now exceeds the initially booked quote due to the total
					volume and w.
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn
					class="btn-blue"
					text
					@click="addToShipment()"
					:disabled="getExisitingBookingDataLoading"
					v-if="shipmentStatus == 'Pending Approval'"
				>
					<span>Understood</span>
				</v-btn>
				<v-btn
					class="btn-white"
					text
					@click="confirmShipmentDialog = false"
					v-if="shipmentStatus == 'Pending Approval'"
				>
					Go Back
				</v-btn>
				<v-btn
					class="btn-blue"
					text
					@click="addToShipment()"
					v-else
					:disabled="getExisitingBookingDataLoading"
				>
					<span>Update Shipment</span>
				</v-btn>
			</template>
		</ConfirmDialog>
		<SubmitRequestModal
			:submitShipmentId="shipment_id"
			:requestConsolidation="0"
			:isMobile="isMobile"
			:showBookingRequestSubmittedModal.sync="showBookingRequestSubmittedModal"
		/>
	</div>
</template>

<script>
import globalMethods from "../../../utils/globalMethods";
import _ from "lodash";
import moment from "moment";
import BookingShipmentDialogV2 from "../../ShipmentComponents/BookingShipment/BookingShipmentDialogV2.vue";
import ConfirmDialog from "../../Dialog/GlobalDialog/ConfirmDialog.vue";
import { mapGetters } from "vuex";
import SubmitRequestModal from "../../Dialog/FormShipmentDialog/Modals/SubmitRequestModal.vue";

export default {
	name: "SelectCargoDialog",
	props: [
		"dialog",
		"editedItems",
		"isMobile",
		"selectShipment",
		"fromComponent",
	],
	components: {
		BookingShipmentDialogV2,
		ConfirmDialog,
		SubmitRequestModal,
	},
	data: () => ({
		valid: true,
		headers: [
			{
				text: "SKU",
				align: "start",
				sortable: false,
				value: "category_sku",
				fixed: true,
				width: "160px",
			},
			{
				text: "Description",
				align: "start",
				sortable: false,
				value: "product_description",
				fixed: false,
				width: "250px",
			},
			{
				text: "Add All",
				align: "center",
				sortable: false,
				value: "addAll",
				fixed: false,
				width: "80px",
			},
			{
				text: "Carton",
				align: "end",
				sortable: false,
				value: "carton",
				width: "110px",
				fixed: false,
			},
			{
				text: "Unit",
				align: "end",
				sortable: false,
				value: "unit",
				fixed: false,
				width: "110px",
			},
			{
				text: "Volume",
				align: "end",
				sortable: false,
				value: "volume",
				fixed: false,
				width: "130px",
			},
			{
				text: "Weight",
				align: "end",
				sortable: false,
				value: "weight",
				fixed: false,
				width: "130px",
			},
			{
				text: "Unit Price",
				align: "end",
				sortable: false,
				value: "unit_price",
				fixed: false,
				width: "120px",
			},
			{
				text: "Amount",
				align: "end",
				sortable: false,
				value: "amount",
				fixed: false,
				width: "120px",
			},
			{
				text: "HS Code",
				align: "end",
				sortable: false,
				value: "hs_code",
				fixed: false,
				width: "120px",
			},
			{
				text: "Commodity Description",
				align: "end",
				sortable: false,
				value: "commodity_description",
				fixed: false,
				width: "200px",
			},
		],
		finalOrdersData: [],
		viewBookingShipmentDialog: false,
		shipment_id: null,
		confirmShipmentDialog: false,
		shipmentStatus: null,
		newpayload: {},
		showBookingRequestSubmittedModal: false,
	}),
	computed: {
		...mapGetters({
			getBookingOrdersDataLoading: "booking/getBookingOrdersDataLoading",
			getExisitingBookingDataLoading: "booking/getExisitingBookingDataLoading",
		}),
		fromPoComponent() {
			return this.fromComponent == "po-desktop";
		},
		dialogCreate: {
			get() {
				if (this.editedItems) {
					let tempItem = [...this.editedItems];

					let productData = null;

					tempItem &&
						tempItem.map((order) => {
							productData =
								order.purchase_order_products &&
								order.purchase_order_products
									.filter((product) => product.unship_cartons >= 1)
									.map((value) => {
										return {
											purchase_order_product_id: value.id,
											category_sku: value.product.category_sku,
											product_description: value.product.description,
											unit_price: value.unit_price,
											product_classification_description:
												value.product_classification_description,
											classification_code: value.classification_code,
											unship_cartons: value.unship_cartons,
											total_unship_cartons: value.unship_cartons,
											units: value.units,
											volume: value.volume,
											weight: value.weight,
											volume_original: value.product.volume,
											weight_original: value.product.weight,
											ship_date: value.ship_date,
											units_per_carton: value.units_per_carton,
											selectProduct: false,
											addAll: false,
										};
									});

							this.finalOrdersData.push({
								all_products: false,
								po_number: order.po_number,
								supplier_company_name: order.supplier_company_name,
								buyer_company_name: order.buyer_company_name,
								customer_company_name: order.customer_company_name,
								is_issuer: order.is_issuer,
								cargo_ready_date: order.cargo_ready_date,
								order_type: order.order_type,
								order_id: order.id,
								supplier_id: order.supplier_id,
								buyer_id: order.buyer_id,
								customer_id: order.customer_id,
								products: productData,
							});
						});
				}
				return this.dialog;
			},
			set(value) {
				this.$refs.form.resetValidation();
				this.$emit("update:dialog", value);
			},
		},
		shipmentListsDropDown() {
			return this.$store.getters["booking/getBookingShipmentDataList"];
		},
		btnDisabled() {
			let selectedProduct = [];

			this.finalOrdersData &&
				this.finalOrdersData.map((value) => {
					value?.products.map((product) =>
						product.selectProduct && parseInt(product.unship_cartons) > 0
							? selectedProduct.push(product)
							: ""
					);
				});
			if (this.selectShipment) {
				return !(this.shipment_id && selectedProduct.length > 0);
			} else {
				return selectedProduct.length == 0;
			}
		},
	},
	methods: {
		...globalMethods,
		close() {
			this.$refs.cargoform.resetValidation();
			this.finalOrdersData = [];
			this.shipmentStatus = null;
			this.newpayload = {};
			this.$emit("close");
		},
		companyName(orderData) {
			if (orderData.is_issuer && orderData.order_type == "PO") {
				return orderData.supplier_company_name;
			} else if (orderData.is_issuer && orderData.order_type == "SO") {
				return orderData.buyer_company_name;
			} else {
				return orderData.customer_company_name;
			}
		},
		async updateCartonCount(item) {
			if (item.total_unship_cartons <= item.unship_cartons) {
				item.unship_cartons = item.total_unship_cartons;
				item.addAll = true;
			}
			item.units = item.unship_cartons * item.units_per_carton;
			item.volume = item.units * item.volume_original;
			item.volume =
				this.countDecimals(item.volume) > 5
					? item.volume.toFixed(5)
					: item.volume;
			item.weight = item.units * item.weight_original;
		},
		selectAllCartons(item) {
			item.unship_cartons = item.total_unship_cartons;
			item.units = item.unship_cartons * item.units_per_carton;
			item.volume = item.units * item.volume_original;
			item.volume =
				this.countDecimals(item.volume) > 5
					? item.volume.toFixed(5)
					: item.volume;
			item.weight = item.units * item.weight_original;
		},
		selectAllProducts(ordervalue) {
			ordervalue.products.map((item) => {
				if (ordervalue.all_products) {
					item.selectProduct = true;
				} else {
					item.selectProduct = false;
				}
			});
		},
		totalCartons(order) {
			let total = 0;
			order?.products.map((item) => {
				if (item.selectProduct) {
					total += parseFloat(item.unship_cartons);
				}
			});
			return total ?? total.toFixed(5);
		},
		totalVolume(order) {
			let total = 0;
			order?.products.map((item) => {
				if (item.selectProduct) {
					total += parseFloat(item.volume);
				}
			});
			return total ?? total.toFixed(5);
		},
		totalweight(order) {
			let total = 0;
			order?.products.map((item) => {
				if (item.selectProduct) {
					total += parseFloat(item.weight);
				}
			});
			return total ?? total.toFixed(2);
		},
		removeOrder(order_id) {
			let findIndex = _.findIndex(
				this.finalOrdersData,
				(e) => e.order_id == order_id
			);
			this.finalOrdersData.splice(findIndex, 1);
		},
		updateCargoReadyDateInput(ordervalue, value) {
			if (moment(value).isValid()) {
				ordervalue.cargo_ready_date = moment(value).format("DD/MM/YYYY");
			} else {
				ordervalue.cargo_ready_date = "";
			}
		},
		calculateAmount(item) {
			let total = parseFloat(item.unit_price) * parseFloat(item.units);
			return `${isNaN(total) ? "0.00" : total.toFixed(2)}`;
		},
		async proceedShipment() {
			if (this.$refs.cargoform.validate()) {
				let payload = [];
				let productdata = [];

				this.finalOrdersData.map((value) => {
					productdata = value.products
						.filter((product) => product.selectProduct)
						.map((item) => {
							return {
								purchase_order_product_id: item.purchase_order_product_id,
								cartons: item.unship_cartons,
								volumes: item.volume,
								weights: item.weight,
								units: item.units,
							};
						});

					if (productdata.length > 0) {
						payload.push({
							productdata: productdata,
							order_id: value.order_id,
							customer_id: value.customer_id,
							order_type: value.order_type,
							supplier_id: value.supplier_id,
							buyer_id: value.buyer_id,
						});
					}
				});
				if (!this.selectShipment) {
					this.$store
						.dispatch("booking/fetchBookingOrdersData", payload)
						.then((res) => {
							console.log("res", res);
							if (res.status == 200) {
								this.close();
								this.viewBookingShipmentDialog = true;
							}
						});
				} else {
					this.newpayload = {
						shipment_id: this.shipment_id,
						payload: payload,
					};
					if (
						this.shipmentStatus !== "Draft" &&
						this.shipmentStatus !== "Pending Quote"
					) {
						this.confirmShipmentDialog = true;
					} else {
						this.addToexistingShipment(this.newpayload);
					}
				}
				console.log("payload", payload);
			}
		},
		addToexistingShipment(payload) {
			this.$store
				.dispatch("booking/addToExisitingShipment", payload)
				.then((res) => {
					if (res.status == 200) {
						this.close();
						this.confirmShipmentDialog = false;
						this.showBookingRequestSubmittedModal = true;
					}
				});
		},
		async addToShipment() {
			await this.addToexistingShipment(this.newpayload);
		},
		closeBookingShipmentDialog() {
			this.viewBookingShipmentDialog = false;
		},
		dateFormat(date) {
			return date ? moment(date).format("MMM DD, YYYY") : "N/A";
		},
		checkShipmentStatus(shipment_id) {
			this.shipmentStatus = this.shipmentListsDropDown.find(
				(value) => value.id == shipment_id
			).shipment_status;
		},
	},
};
</script>

<style lang="scss">
@import "../../../assets/scss/pages_scss/po/selectCargoDialog.scss";
@import "../../ShipmentComponents/BookingShipment/SubComponents/scss/Common.scss";
</style>
